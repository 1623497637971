import React, { useContext } from 'react';

import logo from './logo.svg';
import CardClient from './ComponentsIndex/CardClient';
import CardOS from './ComponentsIndex/CardOS';
import CardRMA from './ComponentsIndex/CardRMA';
import CardOrca from './ComponentsIndex/CardOrca';
import FinanceiroInd from './ComponentsIndex/FinanceiroInd';
import ServicosInd from './ComponentsIndex/ServicosInd';

import { AuthContext } from '../../Prolog.PrologNet.Front/src/contexts/auth';

import 'bootstrap/dist/js/bootstrap';
import './Styles/NewBootstrapStyle/BootstrapTheme.css'
import AccordionModal from './GlobalComponents/AccordionModal'

function Dashboard() {
  const { authenticated, logout, expirationTime, reduceExpirationTime, showTokenExpiration, showRemainingSessionTime, decreaseExpirationTime } = useContext(AuthContext)

  const handleLogout = () => {
    logout();
  }

  const showTimeLeft = () => {
    const timeLeft = Math.floor((expirationTime - Date.now()) / 1000);
    alert(`Tempo restante para a sessão expirar: ${timeLeft} segundos`);
  }

  return (
    <div class="page">
      <div class="page-content container-fluid">
        <div class="row" data-plugin="matchHeight" data-by-row="true">
          <div class="col-xl-3 col-md-6">
            <CardClient></CardClient>
          </div>
          <div class="col-xl-3 col-md-6">
            <CardOS></CardOS>
          </div>
          <div class="col-xl-3 col-md-6">
            <CardRMA></CardRMA>
          </div>
          <div class="col-xl-3 col-md-6">
            <CardOrca></CardOrca>
          </div>
          <div class="col-xxl-5 col-lg-6">
            <FinanceiroInd></FinanceiroInd>
          </div>
          <div class="col-xxl-7 col-lg-6">
            <ServicosInd></ServicosInd>
          </div>
        </div>
        {/* <button onClick={showRemainingSessionTime}>Mostrar tempo restante</button>
        <button onClick={decreaseExpirationTime}>Diminuir tempo restante</button> */}

        {/* <button onClick={showTimeLeft}>Mostrar tempo restante</button>
        <button onClick={reduceExpirationTime}>Reduzir tempo restante em 1000s</button> */}
      </div>
    </div>
  );
}

export default Dashboard;
