import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { api, api_data } from '../../services/api'
import "../RegisterPage/registerstyle.css"

const RegisterPage = ({ setIsRegistering }) => {
  const navigate = useNavigate()
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  const handleCancel = () => {
    setIsRegistering(false);
  };

  useEffect(() => {

    const fetchEmpresas = async () => {
      try {
        const response = await api_data.get("/api/Bancos/GetEmpresasList");
        setEmpresas(response.data);
        console.log(empresas)
      } catch (error) {
        console.error(error);
      }
    };
    fetchEmpresas();
  }, []);



  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await api.post("/cadastroAAAA", {
        ...values,
        DataNascimento: "2010-01-01"
      })
      console.log(response.data)
      alert("Confirme seu email para ter acesso a sua Conta")
      setLoading(false);
      setIsRegistering(false);
    } catch (error) {
      setErrorMessage("Erro ao registrar o usuário");

      console.error(error)
      setLoading(false);
    }
  }

  return (
    <div className="loginDiv">
      <Formik
        initialValues={{
          username: "",
          email: "",
          password: "",
          rePassword: "",
          apelido: "",
          iD_Empresa: 1
        }}
        onSubmit={handleSubmit}
      >
        <Form className="formFormal">
          <div className="formalField">
            <label htmlFor="username" className="formalLabel">Usuario</label>
            <Field id="username" name="username" className="formalInput" />
          </div>
          <div className="formalField">
            <label htmlFor="apelido" className="formalLabel">Apelido</label>
            <Field id="apelido" name="apelido" className="formalInput" />
          </div>
          <div className="formalField">
            <label htmlFor="email" className="formalLabel">Email</label>
            <Field id="email" name="email" type="email" className="formalInput" />
          </div>
          <div className="formalField">
            <label htmlFor="password" className="formalLabel">Senha</label>
            <Field id="password" name="password" type="password" className="formalInput" />
          </div>
          <div className="formalField">
            <label htmlFor="rePassword" className="formalLabel">Confirme a sua senha novamente</label>
            <Field id="rePassword" name="rePassword" type="password" className="formalInput" />
          </div>
          <div className="formalField">
            <label htmlFor="iD_Empresa" className="formalLabel">Selecione a sua empresa</label>
            <Field as="select" id="iD_Empresa" name="iD_Empresa" className="selectEmpresa">
              {empresas.map((empresa) => (
                <option key={empresa.ID_Empresa} value={empresa.ID_Empresa}>
                  {empresa.Razao_Social.length > 25 ? empresa.Razao_Social.substring(0, 25) + "..." : empresa.Razao_Social}
                </option>
              ))}
            </Field>

          </div>
          <div className="formalField">
            {errorMessage && <div style={{ color: 'red', fontWeight: 'bold', marginLeft: '43px' }}>{errorMessage}</div>}
          </div>

          <div className="registerActions">
            <button type="button" id="btnCancel" onClick={handleCancel}>Cancelar</button>
            <button type="submit" id="btnRegister">
              {loading ? (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Registrar"
              )}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default RegisterPage
