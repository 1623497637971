import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_data } from '../../services/api';




function ModalDetails(props) {
    const navigate = useNavigate();

    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});
            console.log(props.selectId)
            const response = await api_data.get(`/api/GrupoAll/Grupos_Tributario/${props.selectId}`);
            const categoriaData = await response.data;
            setCategoria(categoriaData);
            console.log(response)
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };


    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };


    const handleEdit = () => {

        handleCloseModal();
        navigate(`/CentroDeCustos/Edit/${props.selectId}`);
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Grupo Tributario</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                    <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.iD_GrupoTributario} name="iD_GrupoTributario" />
                                        <label className='floating-label'>ID Grupo Tributario</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.nome_Grupo} name="nome_grupo" />
                                        <label className='floating-label'>Nome do Grupo</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.informacoes} name="informacoes" />
                                        <label className='floating-label'>Informações Complementares</label>
                                    </div>

                                </div>

                            </div>





                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails