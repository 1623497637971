import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import FormDate from "../../GlobalComponents/FormDate";
import FormInReal from "../../GlobalComponents/FormInReal";
import { estados } from '../EmpresasPage/ObjectsPage/Estados';

import { api_data } from './../../services/api';

import InputMask from 'react-input-mask';
import FormCheckbox from "../../GlobalComponents/FormCheckbox";
import produtos_page from "./produtos_page.css"
import { motion, AnimatePresence, color } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import { NumericFormat as NumberFormat } from 'react-number-format';
import ModalPesquisarEmp from "./ModalEmpresa/ModalPesquisarEmp";
import ModalPesquisarRep from "./ModalRepresentantes/ModalPesquisarRep";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from './favicon.png'; // Logo da empresa
import { addMonths, format } from 'date-fns';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';







const Estados = estados;
function CreateNFe() {


  const notaFiscal = {
    numero: 12345,
    emitente: {
      nome: "Empresa Emissora",
      endereco: "Rua Exemplo, 100",
      cnpj: "12.345.678/0001-99"
    },
    destinatario: {
      nome: "Cliente Destinatário",
      endereco: "Avenida Exemplo, 200",
      cnpjCpf: "987.654.321-00"
    },
    itens: [
      {
        descricao: "Produto 1",
        quantidade: 10,
        valorUnitario: 50.00
      },
      {
        descricao: "Produto 2",
        quantidade: 5,
        valorUnitario: 100.00
      }
    ],
    valoresTotais: {
      subtotal: 750.00,
      impostos: 150.00,
      descontos: 0.00,
      total: 900.00
    },
    impostos: {
      icms: 100.00,
      ipi: 20.00,
      pis: 10.00,
      cofins: 20.00
    },
    dataEmissao: "2024-01-02",
    chaveAcesso: "0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF012345",
    informacoesAdicionais: "Condicoes de pagamento, etc."
  };

  const [formData, setFormData] = useState(null);


  const [produtosLISTA_TESTE, setProdutosLISTA_TESTE] = useState([
    {
      "COD_PROD": "00111111111111111111111111111111111111111111111111",
      "DESCRICAO_PRODUTO_SERVICO": "Tamarindo Selecionado",
      "NCM_SH": "08134010",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "KG",
      "QUANT": "10",
      "VALOR_UNITARIO": "3.50",
      "VALOR_TOTAL": "35.00",
      "CALC_ICMS": "0.70",
      "VALOR_ICMS": "2.45",
      "VALOR_IPI": "1.75",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "4.20"
    },
    {
      "COD_PROD": "002",
      "DESCRICAO_PRODUTO_SERVICO": "Tamarindo em Calda",
      "NCM_SH": "20079910",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "UN",
      "QUANT": "20",
      "VALOR_UNITARIO": "5.00",
      "VALOR_TOTAL": "100.00",
      "CALC_ICMS": "2.00",
      "VALOR_ICMS": "7.00",
      "VALOR_IPI": "5.00",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "12.00"
    },
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    },
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }
    ,
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }
    ,
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }
    ,
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }
    ,
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }
    ,
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }
    ,
    {
      "COD_PROD": "003",
      "DESCRICAO_PRODUTO_SERVICO": "Doce de Tamarindo",
      "NCM_SH": "17049020",
      "CST": "060",
      "CFOP": "5102",
      "UNID": "CX",
      "QUANT": "15",
      "VALOR_UNITARIO": "6.00",
      "VALOR_TOTAL": "90.00",
      "CALC_ICMS": "1.80",
      "VALOR_ICMS": "6.30",
      "VALOR_IPI": "4.50",
      "ICMS": "7%",
      "IPI": "5%",
      "VALOR_TOTAL_TRIBUTOS": "10.80"
    }


  ]);

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderWidth: 1,
      borderColor: 'black',
      paddingTop: '10px',
      paddingRight: '10px',
      paddingLeft: '10px',
    },
    row: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      position: 'relative',

    },
    block: ({ heightMultiplier }) => ({
      flexGrow: 0,
      borderWidth: 1,
      borderColor: 'black',
      backgroundColor: 'white',
      padding: 2.5, // Padding do bloco de texto
      height: 30 * heightMultiplier,
    }),
    blockTitle: {

      fontWeight: 'bold',
      color: 'black',
      textAlign: 'left',
      fontSize: 6,
      marginBottom: 3.5,

    },
    blockContent: {

      textAlign: 'left',
      fontSize: 7,
      overflow: 'hidden'
    },
    customWidth: {
      flexBasis: '66.6666%',
    },
    standardWidth: {
      flexBasis: '33.3333%',
    },
    watermarkBlock: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    section3Absolute: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  });



  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.container}>
        <View style={{ position: 'absolute', top: 262, left: 10 }}>
          <Text style={{ ...styles.blockContent, fontSize: 9.5 }}>DESTINATARIO/REMETENTE</Text>
        </View>

        <View style={{ position: 'absolute', top: 372, left: 10 }}>
          <Text style={{ ...styles.blockContent, fontSize: 9.5 }}>FATURA</Text>
        </View>

        <View style={{ position: 'absolute', top: 444, left: 10 }}>
          <Text style={{ ...styles.blockContent, fontSize: 9.5 }}>TRANSPORTADOR/VOLUMES TRANSPORTADOS</Text>
        </View>

        <View style={{ position: 'absolute', top: 544, left: 10 }}>
          <Text style={{ ...styles.blockContent, fontSize: 9.5 }}>DADOS DO PRODUTO/SERVIÇO</Text>
        </View>

        <View style={{ position: 'absolute', top: 763, left: 10 }}>
          <Text style={{ ...styles.blockContent, fontSize: 9.5 }}>DADOS ADICIONAIS</Text>
        </View>





        <View style={{ position: 'absolute', top: 775, left: 10 }}>
          <View style={styles.row}>
            <View style={[styles.block({ heightMultiplier: 2 }), { flexBasis: '50%' }]}>
              <Text style={styles.blockTitle}>INFORMAÇÕES COMPLEMENTARES</Text>
            </View>
            <View style={[styles.block({ heightMultiplier: 2 }), { flexBasis: '50%' }]}>
              <Text style={styles.blockTitle}>RESERVADO AO FISICO</Text>
            </View>


          </View>
        </View>





        <View style={styles.row}>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '75%' }]}>
            <Text style={styles.blockTitle}>RECEBEMOS DE PROLOGNET CONSTANTES NA NOTA FISCAL INDICADA AO LADO</Text>
            <Text style={styles.blockContent}>SEM VALOR FISCAL</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15.3%' }]}>
            <Text style={styles.blockTitle}>VALOR NOTA</Text>
            <Text style={styles.blockContent}>R$ 0,01</Text>
          </View>
          <View style={{ position: 'absolute', top: 0, left: '85%', height: 400, width: '15%' }}>
            <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%' }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
              <Text style={{ fontSize: 10 }}>NF-e</Text>
              <Text style={{ fontSize: 8 }}>N*: 000.000.000</Text>
              <Text style={{ fontSize: 8 }}>N*: SÉRIE : 000.000.000</Text>
            </View>
          </View>

        </View>
        <View style={styles.row}>
          <View style={[styles.block({ heightMultiplier: 1 }), styles.standardWidth]}>
            <Text style={styles.blockTitle}>DATA DE RECEBIMENTO</Text>
            <Text style={{ ...styles.blockContent, fontSize: 11 }}>___/___/___/</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '25.9%' }]}>
            <Text style={styles.blockTitle}>IDENTIFICAÇÃO E ASSINATURA DO RECEBEDOR</Text>
            <Text style={styles.blockContent}></Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '25.9%' }]}>
            <Text style={styles.blockTitle}>DESTINATARIO</Text>
            <Text style={{ ...styles.blockContent, fontSize: 5.5 }}>NF-E Emitida em ambiente de homologação - sem valor fiscal</Text>

          </View>
        </View>

        <View style={[styles.row, { marginTop: 20 }]}>
          <View style={{ position: 'absolute', top: 0, left: '0%', height: 632, width: '45%' }}>
            <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%' }]}>
              <View style={{ alignItems: 'center' }}>
                <Text style={{ ...styles.blockTitle, fontSize: 15 }}>PROLOGNET</Text>
              </View>
              <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ flex: 7 }}>
                  <Text style={{ ...styles.blockTitle, fontSize: 20 }}>LOGO</Text>

                </View>
                <View style={{ flex: 3, flexDirection: 'column' }}>
                  <Text style={{ ...styles.blockContent, marginBottom: 5 }}>Rua Exemplo</Text>
                  <Text style={{ ...styles.blockContent, marginBottom: 5 }}>Bairro</Text>
                  <Text style={{ ...styles.blockContent, marginBottom: 5 }}>Estado</Text>
                  <Text style={{ ...styles.blockContent, marginBottom: 5 }}>Tel/Fax: 11111111</Text>
                  <Text style={styles.blockContent}>CEP: 111111</Text>
                </View>

              </View>
            </View>
          </View>





          <View style={{ position: 'absolute', top: 0, left: '45%', height: 632, width: '20%' }}>
            <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%', alignItems: 'center' }]}>
              <Text style={{ ...styles.blockTitle, fontSize: 15 }}>DANFE</Text>
              <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>DOCUMENTO AUXILIAR</Text>
              <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>DA NOTA FISCAL</Text>
              <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>ELETRONICA</Text>
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 1 }}>
                <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>0 - Entrada</Text>

                <View style={{ borderWidth: 1, marginLeft: 5, top: 5, position: 'relative', height: 20, width: 20, justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ fontSize: 7.5 }}>1</Text>
                </View>



              </View>

              <Text style={{ ...styles.blockTitle, fontSize: 7.5, left: -15, top: -2 }}>1 - Saída</Text>


              <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>N° 000.000.000</Text>
              <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>SÉRIE: 000</Text>
              <Text style={{ ...styles.blockTitle, fontSize: 7.5 }}>FOLHA: 1 de 1</Text>
            </View>
          </View>



          <View style={{ position: 'absolute', top: 0, left: '65%', height: 100, width: '35%' }}>
            <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>
              <Text style={styles.blockTitle}>CODIGO DE BARRAS</Text>
              <Text style={styles.blockContent}>Conteúdo da elemento pra mexer</Text>
            </View>
          </View>
          <View style={{ position: 'absolute', top: '30px', left: '65%', height: 100, width: '35%' }}>
            <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>
              <Text style={styles.blockTitle}>CHAVE DE ACESSO</Text>
              <Text style={styles.blockContent}>0000 0000 0000 0000 0000 0000 0000 0000 0000 0000 0000</Text>
            </View>
          </View>
          <View style={{ position: 'absolute', top: '60px', left: '65%', height: 100, width: '35%' }}>
            <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>
              <Text style={styles.blockTitle}>LINK</Text>
              <Text style={styles.blockContent}>LINK</Text>
            </View>
          </View>

        </View>

        <View style={[styles.row, { marginTop: 95 }]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '65%' }]}>
            <Text style={styles.blockTitle}>NATUREZA DA OPERAÇÃO</Text>
            <Text style={styles.blockContent}>Venda de mercadorias</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>
            <Text style={styles.blockTitle}>PROTOCOLO DE AUTORIZAÇÃO DE USO</Text>
            <Text style={styles.blockContent}>123456789012345</Text>
          </View>


        </View>

        <View style={[styles.row]}>

          <View style={[styles.block({ heightMultiplier: 1 }), styles.standardWidth]}>
            <Text style={styles.blockTitle}>INSCRIÇÃO ESTADUAL</Text>
            <Text style={styles.blockContent}>987.654.321.000</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), styles.standardWidth]}>
            <Text style={styles.blockTitle}>INSCRIÇÃO ESTADUAL SUB. TRIBUTARIA</Text>
            <Text style={styles.blockContent}>123.456.789.000</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), styles.standardWidth]}>
            <Text style={styles.blockTitle}>CNPJ/CPF</Text>
            <Text style={styles.blockContent}>12.345.678/0001-91</Text>
          </View>


        </View>


        <View style={[styles.row, { marginTop: 30 }]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '60%' }]}>

            <Text style={styles.blockTitle}>NOME/RAZAOSOCIAL</Text>
            <Text style={styles.blockContent}>NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '25%' }]}>

            <Text style={styles.blockTitle}>CNPJ/CPF</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%' }]}>

            <Text style={styles.blockTitle}>DATA DA EMISSAO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>

        </View>

        <View style={[styles.row]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>

            <Text style={styles.blockTitle}>ENDEREÇO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>

            <Text style={styles.blockTitle}>BAIRRO/DISTRITO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%' }]}>

            <Text style={styles.blockTitle}>CEP</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%' }]}>

            <Text style={styles.blockTitle}>DATA DE SAIDA/ENTRADA</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>

        </View>

        <View style={[styles.row]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '50%' }]}>

            <Text style={styles.blockTitle}>MUNICIPIO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '10%' }]}>

            <Text style={styles.blockTitle}>FONE/FAX</Text>
            <Text style={styles.blockContent}>(11)11111.1111</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '5%' }]}>

            <Text style={styles.blockTitle}>UF</Text>
            <Text style={styles.blockContent}>SP</Text>

          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '20%' }]}>

            <Text style={styles.blockTitle}>INSCRICAO ESTADUAL</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '15%' }]}>

            <Text style={styles.blockTitle}>HORA DE SAIDA</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>

        </View>
        {/* 
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
  <Text style={{ fontSize: 15 }}>TRANSPORTADOR VOLUMES TRANSPORTADOS</Text>
</View> */}
        <View style={[styles.row, { marginTop: 20 }]}>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '20%' }]}>

            <Text style={styles.blockTitle}>BASE DE CALCULO DE ICMS</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '20%' }]}>

            <Text style={styles.blockTitle}>VALOR DO ICMS</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '20%' }]}>

            <Text style={styles.blockTitle}>BASE DE CALCULO ICMS ST</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '20%' }]}>

            <Text style={styles.blockTitle}>VALOR DE ICMS SUBSTITUIÇÃO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '20%' }]}>

            <Text style={styles.blockTitle}>VALOR TOTAL DOS PRODUTOS</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 5</Text>
          </View>


        </View>

        <View style={[styles.row]}>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>VALOR DO FRETE</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>VALOR DO SEGURO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>DESCONTO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>OUTRAS DESPESAS ACESSORIAS</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>VALOR DO IPI</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>VALOR APROX. DOS TRIBUTOS</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '14.28%' }]}>
            <Text style={[styles.blockTitle, { fontSize: 5 }]}>VALOR TOTAL DA NOTA</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
        </View>


        <View style={[styles.row, { marginTop: 10 }]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '26.6%' }]}>

            <Text style={styles.blockTitle}>RAZÃO SOCIAL</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>FRETE POR CONTA</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>CÓDIGO ANTE</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>PLACA DE VEICULO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '5%' }]}>

            <Text style={styles.blockTitle}>UF</Text>
            <Text style={styles.blockContent}>SP</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '18.2%' }]}>

            <Text style={styles.blockTitle}>CNPJ/CPF</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>




        </View>

        <View style={[styles.row]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>

            <Text style={styles.blockTitle}>ENDEREÇO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '35%' }]}>

            <Text style={styles.blockTitle}>MUNICIPIO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '5%' }]}>

            <Text style={styles.blockTitle}>UF</Text>
            <Text style={styles.blockContent}>SP</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '24.7%' }]}>

            <Text style={styles.blockTitle}>INSCRICAO ESTADUAL</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>



        </View>

        <View style={[styles.row]}>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>QUANTIDADE</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>
          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>ESPECIE</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>MARCA</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>NUMERAÇÃO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>


          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>PRESO BRUTO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 1 }), { flexBasis: '16.6%' }]}>

            <Text style={styles.blockTitle}>PESO LIQUIDO</Text>
            <Text style={styles.blockContent}>Conteúdo da Seção 4</Text>
          </View>




        </View>


        <View style={[styles.row, { marginTop: 10 }]}>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '8%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>COD. PROD.</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '18%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>DESCRIÇÃO DO PRODUTO/SERVIÇO</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '6%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>NCM SH</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '4%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>CST</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '4%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>CFOP</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '5%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>UNID.</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '6%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>QUANT.</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '9%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>VALOR UNITÁRIO</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '8%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>VALOR TOTAL</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '5%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>CALC. ICMS</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '7%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>VALOR ICMSAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '7%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>VALOR IPI</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '3%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>ICMS</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '3%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>IPI</Text>
          </View>

          <View style={[styles.block({ heightMultiplier: 0.7 }), { flexBasis: '8%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
            <Text style={[{ fontSize: 5.5 }]}>VALOR TOTAL DOS TRIBUTOS</Text>
          </View>

        </View>




        <View>
          {produtosLISTA_TESTE.map((produto, index) => (
            <View key={index} style={[styles.row]}>
              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '8%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.COD_PROD}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '18%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.DESCRICAO_PRODUTO_SERVICO}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '6%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.NCM_SH}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '4%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.CST}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '4%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.CFOP}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '5%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.UNID}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '6%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.QUANT}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '9%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.VALOR_UNITARIO}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '8%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.VALOR_TOTAL}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '5%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.CALC_ICMS}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '7%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.VALOR_ICMS}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '7%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.VALOR_IPI}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '3%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.ICMS}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '3%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.IPI}</Text>
              </View>

              <View style={[styles.block({ heightMultiplier: 0.6 }), { flexBasis: '8%', padding: 1 }, { justifyContent: 'center' }, { alignItems: 'center' }]}>
                <Text style={[{ fontSize: 5.5 }]}>{produto.VALOR_TOTAL_TRIBUTOS}</Text>
              </View>
            </View>
          ))}
        </View>







      </Page>
    </Document>
  );


  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      margin: 1 // Define a margem mínima para o documento
    });

    doc.setFont('Courier');

    const lineHeight = 5;
    const lineSpacing = 0;

    const sections = {
      header: [
        { key: 'Recebimento de Empresa Básica sem Valor Fiscal', text: '12', width: 110 },
        { key: 'Valor da Nota', text: 'R$ 100', width: 35 },
        { key: 'EntradaGrandeAA', text: 'Exemplo', width: 35, lineSpan: 2 },

        { newLine: true },
        { newLine: true },
        { newLine: true },
        { key: 'Valor da Nota AleatórioCE', text: 'R$ 104214210', width: 35 },
        { newLine: true },
        { key: 'Valor da Nota Aleatório1CE', text: 'R$ 131230', width: 80 },
        { key: 'EntradaGrande', text: 'Exemplo', width: 35, lineSpan: 3 }
      ],
      body: [
        { key: 'Emitente', text: 'Empresa Exemplo S.A.', width: 90 },
        { key: 'Destinatário', text: 'Cliente Exemplo Ltda.', width: 90 },
      ],
      footer: [
        { key: 'Valor Total', text: 'R$ 100,00', width: 50 },
      ],
    };


    const drawSection = (sectionEntries, startX, startY, defaultWidth) => {
      let currentX = startX;
      let currentY = startY;

      sectionEntries.forEach(entry => {
        const hasLineSpan = entry.lineSpan;
        const blockHeight = hasLineSpan ? lineHeight * 2 * entry.lineSpan : lineHeight * 2;

        if (entry.newLine && !hasLineSpan) {
          currentX = startX;
          currentY += blockHeight + lineSpacing;
        }

        const value = entry.text || '';
        const width = entry.width || defaultWidth;

        doc.setLineWidth(0.5);
        doc.setDrawColor(0);
        doc.setFillColor(255, 255, 255);
        doc.rect(currentX, currentY, width, blockHeight);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        if (entry.key) {
          doc.text(entry.key + ":", currentX + 2, currentY + lineHeight);
        }
        doc.text(value, currentX + 2, currentY + lineHeight * 2 - 1);

        if (!hasLineSpan) {
          currentX += width;
        } else {
          if (!entry.newLine) {
            currentY += blockHeight + lineSpacing;
          }
        }
      });
    };






    let currentY = 5; 
    const defaultWidth = 45; 
    drawSection(sections.header, 10, currentY, defaultWidth);
    currentY += defaultWidth + 5; 
    drawSection(sections.body, 10, currentY, defaultWidth);
    currentY += defaultWidth + 5;
    drawSection(sections.footer, 10, currentY, defaultWidth);

    doc.save('nota_fiscal_personalizada.pdf');
  };










  const [startDate, setStartDate] = useState(new Date());


  const navigate = useNavigate();
  const [vendedores, setVendedores] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [tabelaprecos, setTabelaprecos] = useState([]);

  const [empresas, setEmpresas] = useState([]);
  const [representantes, setRepresentantes] = useState([]);


  const [empresaId, setEmpresaId] = useState(null);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);


  const [empresaCreated, setEmpresaCreated] = useState(false);

  const [showFormRen, setShowFormRen] = useState(false);


  const [empCreated, setEmpCreated] = useState(false);
  const [listaCreated, setListaCreated] = useState(false);
  const [repCreated, setRepCreated] = useState(false);
  const [venCreated, setVenCreated] = useState(false);
  const [traCreated, setTraCreated] = useState(false);

  const [activeTab, setActiveTab] = useState('cadastro');
  const [activeTab2, setActiveTab2] = useState('enderecoCadastral');
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const currentDate = new Date();


  const [clienteData, setClienteData] = useState({
    razao_Social: '',
    nome_Fantasia: '',
  });
  const [cnpj, setCnpj] = useState('');
  const [cep, setCep] = useState('');


  const handleEmpresaIdChange = (id) => {
    setEmpresaId(id);
  };



  const fetchAndSetData = (url, setter) => {
    api_data.get(url)
      .then((response) => response.data)
      .then((data) => setter(data));
  };




  const handleInputChange = (e, index, field) => {
    const newContatos = [...contatos];
    newContatos[index][field] = e.target.value;
    setContatos(newContatos);
  };

  const [contatos, setContatos] = useState([
    {
      contato: "",
      tipo_Contato: "",
      fone_Email: "Fone Fixo",
      obs: ""
    }
  ]);

  const adicionarContato = () => {
    setContatos([
      ...contatos,
      {
        contato: "",
        tipo_Contato: "",
        fone_Email: "Fone Fixo",
        obs: ""
      }
    ]);
  };

  const initialValues = {
    iD_Empresa: null,
    Contatos: []

  };


  const convertCurrencyFieldsToNumbers = (values) => {
    const currencyFields = [
      "Preco_de_Custo",
      "Comissao_Vendedor",
      "Taxa_Montagem",
      "MVA_Lucro",
      "Preco_de_Venda",
      "Valor_ultima_compra",
      "Preco_Minimo_para_Venda"
    ];

    const convertedValues = { ...values };

    currencyFields.forEach((field) => {
      if (convertedValues[field]) {
        convertedValues[field] = parseFloat(
          convertedValues[field].replace("R$ ", "").replace(".", "").replace(",", ".")
        );
      }
    });

    return convertedValues;
  };



  const onSubmit = (values, { setSubmitting }) => {
    const convertedValues = convertCurrencyFieldsToNumbers(values);
    setFormData(values);






    api_data
      .post("/api/NotaFiscal/AddNota", convertedValues)
      .then((response) => {
        console.log(response);
        generatePDF();
        notify();
        navigate("/NFe");
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.log("error!");
        if (error.response && error.response.data) {
          console.log("Error details:", error.response.data);
        }
        console.error(error);
        notifyError();
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const notifyError = () => {
    toast.error("Não foi possível criar a categoria.");
  };

  useEffect(() => {
    api_data.get('/api/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
        setRepCreated(false);
      });
  }, [repCreated]);



  const [currentStep, setCurrentStep] = useState(1);
  const steps = ['Dados', 'Fiscal', 'Composição', 'Qualidade', 'Custos e Precificação'];
  const maxStep = steps.length;

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, maxStep));
    updateProgress(20);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
    updateProgress(-20);
  };

  const [progress, setProgress] = useState(0);

  const updateProgress = (increment) => {
    setProgress(prevProgress => Math.min(Math.max(prevProgress + increment, 0), 100));
  };

  const setProgressFromStep = (step) => {
    const stepProgressValues = [0, 10, 30, 50, 70, 100];
    setProgress(stepProgressValues[step]);
  };

  const isReached = (progress, threshold) => progress >= threshold;

  const setStep = (step) => {
    setCurrentStep(step);
    setProgressFromStep(step);
  };
  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const [showTableInst, setShowTableInst] = useState(false);

  const [instRows, setInstRows] = useState([]);


  const handleButtonClickInst = (Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao) => {
    const newRow = {
      Nivel_Complexidade,
      Nivel_Prioridade,
      Descricao,
      Ordenacao: Ordenacao ? parseInt(Ordenacao, 10) : null
    };
    setInstRows(currentRows => [...currentRows, newRow]);
    setShowTableInst(true);

  };
  const notify = () => {
    toast.success("Categoria Criada com Sucesso", { autoClose: 3000 });
  };





  const [inputValues, setInputValues] = useState({
    precoCusto: '',
    mvaLucroR: '',
    mvaLucroPercent: '',
    precoVenda: '',
  });


  useEffect(() => {
    api_data.get('/api/Fornecedores/GetClientes')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);


  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState([]);

  const handleButtonClick = (Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao) => {
    setRows([...rows, { Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao }]);
    setShowTable(true);
  };


  const [selectedIDProduto, setSelectedIDProduto] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);



  useEffect(() => {
    api_data.get('/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
        if (selectedIDProduto === null && response.data.length > 0) {
          setSelectedIDProduto(response.data[0].iD_Produto);
        }
      });
  }, [selectedIDProduto]);


  const handleButtonClickProduct = (iD_Produto, quantidade) => {
    api_data.get(`/api/Produtos/GetProduto/${iD_Produto}`).then((productResponse) => {
      const newEntry = {
        iD_Produto: productResponse.data.iD_Produto,
        nome_Produto: productResponse.data.nome_Produto,
        preco_de_Custo: productResponse.data.preco_de_Custo,
        quantidade: quantidade,
        valor_Total: quantidade ? productResponse.data.preco_de_Custo * quantidade : productResponse.data.preco_de_Custo,
        seguro_pago_na_compra: productResponse.data.seguro_pago_na_compra,
        frete_Pago_na_Compra: productResponse.data.frete_Pago_na_Compra,
        desp_Operacionais: productResponse.data.desp_Operacionais,
        desconto: 0,
        icms: productResponse.data.icms,
        cofins: 0,
        CST_IPI: 0,
        cfoP_Padrao: productResponse.data.cfoP_Padrao,
        ncm: 0
      };
      console.log(newEntry);
      setRows([...rows, newEntry]);
      setShowTable(true);
    });
  };


  const fetchSelectedProducts = async () => {
    const fetchedRows = await Promise.all(selectedProducts.map(async (id) => {
      const response = await api_data.get(`/api/Produtos/GetProduto/${id}`);
      return {
        iD_Produto: response.data.iD_Produto,
        nome_Produto: response.data.nome_Produto,
        preco_de_Custo: response.data.preco_de_Custo,
        quantidade: "",
        valor_Total: response.data.preco_de_Custo
      };
    }));

    setRows([...rows, ...fetchedRows]);
    setShowTable(true);
  };

  useEffect(() => {
    if (selectedProducts.length > 0) {
      fetchSelectedProducts();
    }
  }, [selectedProducts]);

  const handleDeleteRow = (indexToDelete) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
  };


  const handleQuantityChange = (index, newQuantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantidade = newQuantity;
    updatedRows[index].valor_Total = updatedRows[index].preco_de_Custo * newQuantity;
    setRows(updatedRows);
  };

  const [depositos, setDepositos] = useState([]);

  useEffect(() => {
    api_data.get('/api/Depositos/GetDepositos')
      .then((response) => {
        setDepositos(response.data);
      });
  }, []);


  const [showBatata, setShowBatata] = useState(false);

  const [frete, setFrete] = useState('');

  const [nfe, setNfe] = useState('nfe');


  const [activeTabVeiculos, setActiveTabVeiculos] = useState('veiculos');


  const [tableDataFatura, setTableDataFatura] = useState([]);

  const handleAddClickFatura = (n_fatura, t_fatura) => {
    console.log(n_fatura);
    setTableDataFatura([...tableDataFatura, { n_fatura, t_fatura }]);
  };

  const [totalFaturas, setTotalFaturas] = useState(0);



  const [tableDataVolume, setTableDataVolume] = useState([]);

  const handleAddClickVolume = (quantidade_vol, especie_vol, marca_vol, peso_bruto_vol, peso_liquido_vol) => {
    if (quantidade_vol || especie_vol || marca_vol || peso_bruto_vol || peso_liquido_vol) {
      setTableDataVolume([...tableDataVolume, { quantidade_vol, especie_vol, marca_vol, peso_bruto_vol, peso_liquido_vol }]);
    }
  };


  const updateFaturaValue = (inputValue, index) => {
    const newData = tableDataFatura.map((fatura, idx) =>
      idx === index ? { ...fatura, valor: inputValue } : fatura
    );
    setTableDataFatura(newData);
  };


  useEffect(() => {
    const novoTotal = tableDataFatura.reduce((acumulador, faturaAtual) => {
      return acumulador + (Number(faturaAtual.valor) || 0);
    }, 0);
    setTotalFaturas(novoTotal);
  }, [tableDataFatura]);


  function removeFatura(index) {

    const filteredData = tableDataFatura.filter((_, i) => i !== index);
    setTableDataFatura(filteredData);
  }

  const handleRemoveClickVolume = index => {
    setTableDataVolume(tableDataVolume.filter((_, i) => i !== index));
  };
  return (
    <div>
      <div>

        <PDFDownloadLink document={<MyDocument />} fileName="nota-fiscal.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Carregando documento...' : 'Baixar PDF')}
        </PDFDownloadLink>
        {/*         
        <button onClick={generatePDF}>Gerar Novo Document</button>
        <button onClick={generatePdfRATO_1}>Gerar Novo DocumentAAAAA</button> */}



      </div>
      <ToastContainer />
      <page>

    

        <div className="page-header">
          <h1 className="page-title">Criar nota Fiscal</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Produtos</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/NFe">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Nota Fiscal</a>
              </p>
            </Link>
          </div>
        </div>

        <div className="page-content container-fluid">
          <div className="page-content container-fluid">


            <div className="container">
              <div className="container mt-6">
                <div className="progress position-relative">
                  <div
                    className={`progress-bar bg-success`}
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div className="icon-wrapper">
                    <div
                      className={`progress-icon ${isReached(progress, 20) ? 'reached' : ''}`}
                      style={{ left: '10%', cursor: 'pointer' }}
                      onClick={() => setStep(1)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label">Dados</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 40) ? 'reached' : ''}`}
                      style={{ left: '30%', cursor: 'pointer' }}
                      onClick={() => setStep(2)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label">Emitente</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 60) ? 'reached' : ''}`}
                      style={{ left: '50%', cursor: 'pointer' }}
                      onClick={() => setStep(3)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label" style={{ left: '0px' }}>Produtos</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 80) ? 'reached' : ''}`}
                      style={{ left: '70%', cursor: 'pointer' }}
                      onClick={() => setStep(4)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label" style={{ left: '-10px' }}>Complemento</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 100) ? 'reached' : ''}`}
                      style={{ left: '90%', cursor: 'pointer' }}
                      onClick={() => setStep(5)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label" style={{ left: '-3px' }}>Finalizando</div>
                    </div>


                  </div>
                </div>
              </div>
            </div>


          </div>


          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, handleChange, setFieldValue, values }) => (

              <Form>
               

                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <div className='panel'>
                    <div className="panel-body container-fluid">





                      {currentStep === 1 && <div>
                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>


                          <div className="form-group form-material row">





                            <FormInput colWidth={3} name="Serie_Nota_Fiscal" label="Série da Nota Fiscal " numero={true} />
                            <FormInput colWidth={3} name="Numero_Nota_Fiscal" label="Série da Nota Fiscal " numero={true} />


                            {/* <div className="form-group col-md-3">
                              <div className="input-group">

                                <Field name="Fornecedor_Padrao">
                                  {({ field, meta }) => (
                                    <>
                                      <select
                                        {...field}
                                        className="form-control form-select form-control-label-sm mb-2"
                                      >
                                        {empresas.map((empresas) => (
                                          <option key={empresas.id} value={empresas.id}>
                                            {empresas.razao_Social}
                                          </option>
                                        ))}

                                      </select>
                                      <label className='floating-label'>Fornecedor Padrão
                                      </label>


                                    </>
                                  )}
                                </Field>
                                <span className="input-group-append">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalEmpPesquisar"

                                    className="btn btn-icon btn-default"
                                    style={{ height: '40px', display: 'inline-block' }}

                                  >
                                    <i className="icon md-search" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                            </div> */}

                            <FormDate colWidth={3} name="Data_Emissao" label="Data Emissão" ></FormDate>

                            <FormDate colWidth={3} name="Hora_Emissao" label="Data Emissão" ></FormDate>

                            <FormSelect colWidth={3} name="Tipo_Operacao" label="Tipo de Operação" >
                              <option>Entrada</option>
                              <option>Saida</option>

                            </FormSelect>

                            <FormSelect colWidth={3} name="Finalidade_Operacao" label="Finalidade da Operação" >
                              <option value="1">Normal</option>
                              <option value="2">NF-e Complementar</option>
                              <option value="3">Ajuste</option>
                              <option value="4">Devolução de Mercadoria</option>
                              <option value="5">Transferência</option>
                            </FormSelect>
                            <FormSelect colWidth={3} name="Natureza_Operacao" label="Natureza da Operação" >
                              <option value="1">Normal</option>
                              <option value="2">NF-e Complementar</option>
                              <option value="3">Ajuste</option>
                              <option value="4">Devolução de Mercadoria</option>
                              <option value="5">Transferência</option>
                            </FormSelect>

                            <FormInput colWidth={3} name="Marca" label="Marca" />
                            <FormInput colWidth={2} name="Codigo_do_Sistema" label="Código do Sistema" />
                            <FormInput colWidth={2} name="codigo_Fornecedor_Padrao" label="Código Fornecedor Padrão" />
                            <FormInput colWidth={2} name="Codigo_do_Produto_SKU" label="Código Produto (SKU)" />

                            <FormSelect colWidth={3} name="Tipo_Genero" label="Tipo/Gênero" >

                              <option value="00 - Mercadoria para Revenda">00 - Mercadoria para Revenda</option>
                              <option value="01 - Matéria-Prima">01 - Matéria-Prima</option>
                              <option value="02 - Embalagem">02 - Embalagem</option>
                              <option value="03 - Produto em Processo">03 - Produto em Processo</option>
                              <option ng-selected="produto.Tipo > 2" value="04 - Produto Acabado">04 - Produto Acabado</option>
                              <option value="05 - Subproduto">05 - Subproduto</option>
                              <option value="06 - Produto Intermediário">06 - Produto Intermediário</option>
                              <option value="07 - Material de Uso e Consumo">07 - Material de Uso e Consumo</option>
                              <option value="08 - Ativo Imobilizado">08 - Ativo Imobilizado</option>
                              <option ng-hide="licencanfe" value="09 - Serviços" class="">09 - Serviços</option>
                              <option value="10 - Outros insumos">10 - Outros insumos</option>
                              <option value="99 - Outras">99 - Outras</option>
                            </FormSelect>

                            <FormInput colWidth={3} name="Modelo" label="Modelo" />
                            <FormInput colWidth={3} name="Categorias" label="Categorias" />
                            <FormCheckbox colWidth={3} name="Ocultar_Nas_Vendas" label="Ocultar nas Vendas" />
                            <FormCheckbox colWidth={3} name="Visivel_no_Catalogo" label="Visível no Catálogo" />
                            <FormCheckbox colWidth={3} name="Cadastro_Inativo" label="Cadastro Inativo" />
                            <FormInput colWidth={3} name="Validade" label="Validade" />
                            <FormSelect colWidth={3} name="Produto_e_vendido_por" label="Produto é vendido por" >

                            </FormSelect>



                          </div>
                          <br></br>





                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="observacoes" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Informacoes Adicionais</label>
                            </div>
                          </div>







                        </motion.div>
                      </div>
                      }






                      {currentStep === 2 && <div>
                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>
                          <h2 >Emitente</h2>

                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                          <div className="form-group form-material row">
                            <FormInput colWidth={3} name="Nome_Fantasia_Emitente" label="Nome Fantasia" />
                            <FormInput colWidth={3} name="CNPJ" label="CPF/CNPJ" numero={true} />
                            <FormInput colWidth={3} name="IE_Emitente" label="IE" numero={true} />
                            <FormInput colWidth={3} name="Email" label="Email" />
                            <FormInput colWidth={3} name="Telefone" label="Telefone" numero={true} />
                            <FormInput colWidth={3} name="Logradouro_Emitente" label="Logradouro" />
                            <FormInput colWidth={3} name="Numero_Emitente" label="Número" numero={true} />
                            <FormInput colWidth={3} name="Complemento_Emitente" label="Complemento" />
                            <FormInput colWidth={3} name="Bairro_Emitente" label="Bairro" />
                            <FormInput colWidth={3} name="CEP_Emitente" label="CEP" numero={true} />
                            <FormInput colWidth={3} name="Cidade_Emitente" label="Cidade" />
                            <FormInput colWidth={3} name="Codigo_Cidade_Emitente" label="Código da Cidade (IBGE)" numero={true} />
                            <FormSelect colWidth={3} name="UF_Emitente" label="UF" />

                            <br></br>
                          </div>


                          <h2>Remetente</h2>
                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>

                          <div className="form-material row">
                            <FormInput colWidth={3} name="Nome_Fantasia_Remetente" label="Nome Fantasia" />
                            <FormInput colWidth={3} name="CNPJ" label="CPF/CNPJ" numero={true} />
                            <FormInput colWidth={3} name="IE_Remetente" label="IE" numero={true} />
                            <FormInput colWidth={3} name="Email" label="Email" />
                            <FormInput colWidth={3} name="Telefone" label="Telefone" numero={true} />
                            <FormInput colWidth={3} name="Logradouro_Remetente" label="Logradouro" />
                            <FormInput colWidth={3} name="Numero_Remetente" label="Número" numero={true} />
                            <FormInput colWidth={3} name="Complemento_Remetente" label="Complemento" />
                            <FormInput colWidth={3} name="Bairro_Remetente" label="Bairro" />
                            <FormInput colWidth={3} name="CEP_Remetente" label="CEP" numero={true} />
                            <FormInput colWidth={3} name="Cidade_Remetente" label="Cidade" />
                            <FormInput colWidth={3} name="Codigo_Cidade_Remetente" label="Código da Cidade (IBGE)" numero={true} />
                            <FormSelect colWidth={3} name="UF_Remetente" label="UF" />
                            <br></br>
                            <br></br>
                            <div className="form-group col-md-3" data-plugin="formMaterial">
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <input name="Local_Retirada_Difere" type='checkbox' onChange={(e) => setShowFormRen(!showFormRen)} />
                                <label className='floating-label' style={{ flex: 1, marginLeft: '50px', overflowWrap: 'break-word', maxWidth: 'calc(100% - 60px)' }}>
                                  Local de Entrega Difere do Endereço do Remetente
                                </label>
                              </div>
                            </div>

                            {showFormRen && (
                              <>
                                <FormInput colWidth={3} name="NomeFantasiaRen_Remetente" label="Nome Fantasia" />
                                <FormInput colWidth={3} name="CPF_CNPJRen_Remetente" label="CPF/CNPJ" numero={true} />
                                <FormInput colWidth={3} name="IERen_Remetente" label="IE" numero={true} />
                                <FormInput colWidth={3} name="EmailRen_Remetente" label="Email" />
                                <FormInput colWidth={3} name="TelefoneRen_Remetente" label="Telefone" numero={true} />
                                <FormInput colWidth={3} name="LogradouroRen_Remetente" label="Logradouro" />
                                <FormInput colWidth={3} name="NumeroRen_Remetente" label="Número" numero={true} />
                                <FormInput colWidth={3} name="ComplementoRen_Remetente" label="Complemento" />
                                <FormInput colWidth={3} name="BairroRen_Remetente" label="Bairro" />
                                <FormInput colWidth={3} name="CEPRen_Remetente" label="CEP" numero={true} />
                                <FormInput colWidth={3} name="CidadeRen_Remetente" label="Cidade" />
                                <FormInput colWidth={3} name="CodigoCidadeIBGERen_Remetente" label="Código da Cidade (IBGE)" numero={true} />
                                <FormSelect colWidth={3} name="UFRen_Remetente" label="UF" />
                                <FormCheckbox colWidth={3} name="RemetenteExteriorRen_Remetente" label="Destinatário/Remetente do Exterior" />
                              </>
                            )}
                            <div className="form-group col-md-3" data-plugin="formMaterial">
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Field name="Local_Entrega_Difere" type='checkbox' onChange={(e) => setShowBatata(!showBatata)} />
                                <label className='floating-label' style={{ flex: 1, marginLeft: '50px', overflowWrap: 'break-word', maxWidth: 'calc(100% - 60px)' }}>
                                  Local de Entrega Difere do Endereço do Remetente
                                </label>
                              </div>
                            </div>

                            <br></br>
                            <br></br>
                            {showBatata && (
                              <>
                                <FormInput colWidth={3} name="NomeFantasiaEnd_Retirada" label="Nome Fantasia" />
                                <FormInput colWidth={3} name="CPF_CNPJEnd_Retirada" label="CPF/CNPJ" numero={true} />
                                <FormInput colWidth={3} name="IEEnd_Retirada" label="IE" numero={true} />
                                <FormInput colWidth={3} name="EmailEnd_Retirada" label="Email" />

                                <FormInput colWidth={3} name="LogradouroEnd_Retirada" label="Logradouro" />
                                <FormInput colWidth={3} name="NumeroEnd_Retirada" label="Número" numero={true} />
                                <FormInput colWidth={3} name="ComplementoEnd_Retirada" label="Complemento" />
                                <FormInput colWidth={3} name="BairroEnd_Retirada" label="Bairro" />
                                <FormInput colWidth={3} name="CEPEnd_Retirada" label="CEP" numero={true} />
                                <FormInput colWidth={3} name="CidadeEnd_Retirada" label="Cidade" />
                                <FormInput colWidth={3} name="CodigoCidadeIBGEEnd_Retirada" label="Código da Cidade (IBGE)" numero={true} />
                                <FormSelect colWidth={3} name="UFEnd_Retirada" label="UF" />
                                <FormCheckbox colWidth={3} name="LocalRetiradaExteriorEnd_Retirada" label="Destinatário/Remetente do Exterior" />
                              </>

                            )}
                          </div>



                        </motion.div>

                      </div>}
                      {currentStep === 3 && <div>
                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>



                          <div className="form-group form-material row">


                            <FormSelect colWidth={3} name="iD_Produto_fs" label="Produto" value={selectedIDProduto} onChange={(e) => setSelectedIDProduto(e.target.value)}>
                              {representantes.map((representante) => (
                                <option key={representante.iD_Produto} value={representante.iD_Produto}>
                                  {representante.nome_Produto}
                                </option>
                              ))}
                            </FormSelect>
                            <FormInput colWidth={1} name="quantidade_fs" label="Quantidade" numero={true} />


                            <div class="col-md-1">
                              <span class="input-group-append">
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#ModalEmpPesquisar"
                                  class="btn btn-icon btn-default"
                                  style={{ height: '40px', display: 'inline-block' }}
                                >
                                  <i class="icon md-search" aria-hidden="true"></i>
                                </a>
                              </span>
                            </div>
                            <div className="form-group col-md-1">

                              <div className="col-md-1">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleButtonClickProduct(values.iD_Produto_fs, values.quantidade_fs)}
                                >
                                  Adicionar
                                </button>
                              </div>

                            </div>
                            <div className="form-group col-md-1">
                            </div>



                          </div>


                          <div className="form-group form-material row">



                            {showTable && (

                              <table>
                                <thead>
                                  <tr>
                                    <th>Cod.</th>
                                    <th>Produto</th>
                                    <th>Vlr Unitario</th>
                                    <th>Qtd</th>
                                    <th>Seguro</th>
                                    <th>Frete</th>
                                    <th>Outras Desp.</th>
                                    <th>Desconto</th>
                                    <th>Vlr Total</th>
                                    <th>CST ICMS</th>
                                    <th>CST PIS</th>
                                    <th>CST COFINS</th>
                                    <th>CST IPI</th>
                                    <th>CFOP </th>
                                    <th>NCM</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.iD_Produto}</td>
                                      <td>{row.nome_Produto}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.preco_de_Custo)}</td>
                                      <td>
                                        <input
                                          type="number"
                                          value={row.quantidade}
                                          onChange={(e) => handleQuantityChange(index, e.target.value)}
                                        />
                                      </td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_Total)}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.seguro_pago_na_compra)}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.frete_Pago_na_Compra)}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.desp_Operacionais)}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.desconto)}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.icms)}</td>
                                      <td>{row.icms}</td>

                                      <td>{row.icms}</td>
                                      <td>{row.cfoP_Padrao}</td>
                                      <td>
                                        {row.ncm}
                                      </td>

                                      <td>
                                        <span className='btn btn-danger' onClick={() => handleDeleteRow(index)}>Deletar</span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>

                                <br></br>
                                <br></br>
                              </table>


                            )}
                            <div className="form-group col-md-3">
                              <select value={frete} onChange={(e) => setFrete(e.target.value)} className="form-control">
                                <option value="0">Por Conta do Emitente/Remetente (CIF)</option>
                                <option value="1">Por Conta do Destinatario (FOB)</option>
                                <option value="2">Por Conta de Terceiros</option>
                                <option value="3">Transporte Próprio por conta do Remetente</option>
                                <option value="4">Transporte Próprio por conta do Destinatário</option>
                                <option value="9">Sem Frete</option>
                              </select>


                            </div>


                          </div>
                          <div className="form-group form-material row">
                            {frete !== '9' && (
                              <>
                                < >
                                  <FormInput colWidth={2} name="NomeTransportador_transp" label="Nome Transportador" />
                                  <FormInput colWidth={2} name="RazaoSocialTransportador_transp" label="Razão Social Transportador" />
                                  <FormInput colWidth={2} name="ComplementoNome_transp" label="Complemento para o Nome" />
                                  <FormInput colWidth={2} name="CPFCNPJ_transp" label="CPF/CNPJ" />
                                  <FormInput colWidth={2} name="IE_transp" label="IE" />
                                  <FormInput colWidth={2} name="Isento_transp" label="ISENTO" />
                                  <FormInput colWidth={2} name="Logradouro_transp" label="Logradouro" />
                                  <FormInput colWidth={2} name="Numero_transp" label="Número" />
                                  <FormInput colWidth={2} name="Cidade_transp" label="Cidade" />
                                  <FormInput colWidth={2} name="UF_transp" label="UF" />
                                </>

                                <div className="container">
                                  <nav className="nav nav-pills nav-justified">
                                    <a className={`nav-link ${activeTabVeiculos === 'veiculos' ? 'active' : ''}`}
                                      onClick={() => setActiveTabVeiculos('veiculos')}>
                                      Veiculos e Reboque
                                    </a>
                                    <a className={`nav-link ${activeTabVeiculos === 'volumes' ? 'active' : ''}`}
                                      onClick={() => setActiveTabVeiculos('volumes')}>
                                      Volumes Transportados
                                    </a>
                                  </nav>

                                  <div className="mt-4">
                                    {activeTabVeiculos === 'veiculos' && (
                                      <>
                                        <h3>Dados do Veículo</h3>
                                        <div className="row">

                                          <FormInput colWidth={2} name="NomeTransportador_transp" label="Nome Transportador" />
                                          <FormInput colWidth={2} name="RazaoSocialTransportador_transp" label="Razão Social Transportador" />
                                          <FormSelect colWidth={1} name="UF_Dados_Veiculo" label="UF">
                                            {Estados.map((Estado, index) => (
                                              <option key={index} value={Estado.value}>
                                                {Estado.label}
                                              </option>
                                            ))}
                                          </FormSelect>

                                        </div>

                                        <h3>Reboques do Veiculo</h3>
                                        <div className="row">

                                          <FormInput colWidth={2} name="placa_veiculo_rtc" label="Nome Transportador" />
                                          <FormInput colWidth={2} name="rntc_veiculo_rtc" label="Razão Social Transportador" />
                                          <FormSelect colWidth={1} name="UF_Dados_Veiculo" label="UF">
                                            {Estados.map((Estado, index) => (
                                              <option key={index} value={Estado.value}>
                                                {Estado.label}
                                              </option>
                                            ))}
                                          </FormSelect>
                                          <div className="col-md-3">
                                            <span type="button" class="btn btn-primary">Adicionar</span>
                                          </div>

                                        </div>
                                      </>
                                    )}
                                    {activeTabVeiculos === 'volumes' && (
                                      <>


                                        <h3>Informacoes sobre Volumes</h3>
                                        <div className="row">
                                          <FormInput colWidth={2} name="quantidade_vol" label="Quantidade" numero={true} />
                                          <FormInput colWidth={2} name="especie_vol" label="Espécie (Big Bag, Caixa, Palete...)" />
                                          <FormInput colWidth={2} name="marca_vol" label="Marca" />
                                          <FormInput colWidth={2} name="peso_bruto_vol" label="Peso Bruto" />
                                          <FormInput colWidth={2} name="peso_liquido_vol" label="Peso Liquido" />

                                          <div className="col-md-2">
                                            <span type="button" className="btn btn-primary" onClick={() => handleAddClickVolume(values.quantidade_vol, values.especie_vol, values.marca_vol, values.peso_bruto_vol, values.peso_liquido_vol)}>Adicionar</span>
                                          </div>
                                        </div>

                                        {tableDataVolume.length > 0 && (
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Quantidade</th>
                                                <th>Espécie</th>
                                                <th>Marca</th>
                                                <th>Peso Bruto</th>
                                                <th>Peso Líquido</th>
                                                <th>Ações</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {tableDataVolume.map((item, index) => (
                                                <tr key={index}>
                                                  <td>{item.quantidade_vol}</td>
                                                  <td>{item.especie_vol}</td>
                                                  <td>{item.marca_vol}</td>
                                                  <td>{item.peso_bruto_vol}</td>
                                                  <td>{item.peso_liquido_vol}</td>
                                                  <td>
                                                    <span className="btn btn-danger" onClick={() => handleRemoveClickVolume(index)} className="btn btn-danger">Remover</span>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>

                              </>



                            )}
                          </div>


                          <br></br>
                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="Descricao" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Passo a Passo Produção</label>
                            </div>
                          </div>
                          <br></br>

                          <div className="form-group form-material row">
                            <div className="form-group col-md-1">
                            </div>
                            <FormCheckbox colWidth={2} name="Nao_Emitir_Alertas" label="Não emitir alertas e permitir vendas com estoque negativo " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Cada_Unidade_Numero_Serie" label="Cada unidade do produto possui um número de série " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Permitir_Qtd_Fracionada" label="Permitir Quantidade Fracionada no Loja Online " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Produto_Controlado_Lote" label="Produto Controlado por Lote " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Venda_Metragem" label="Venda por metragem quadrada (m²) " ></FormCheckbox>
                          </div>

                        </motion.div>


                      </div>}
                      {currentStep === 4 && <div>

                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>

                          <div>
                            <div className="form-group form-material row">
                              <FormInput colWidth={3} name="n_fatura" label="Numero da Fatura" />


                              <div className="col-md-2 ">
                                <input
                                  className="form-control"
                                  value={`R$ ${totalFaturas.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace('.', ',')}`}
                                  readOnly
                                />
                                <label className="floating-label">Total da Fatura</label>


                              </div>
                              <div className="col-md-2">
                                <span className="btn btn-primary" onClick={() => handleAddClickFatura(values.n_fatura, values.t_fatura)}>Adicionar</span>
                              </div>
                            </div>
                            {tableDataFatura.length > 0 ? (
                              <table>
                                <thead>
                                  <tr>
                                    <th>N Duplicata</th>
                                    <th>Forma Pagamento</th>
                                    <th>Indicador Pagamento</th>
                                    <th>CV/NSU</th>
                                    <th>Data Transação</th>
                                    <th>Valor</th>
                                    <th>Excluir</th>



                                  </tr>
                                </thead>
                                <tbody>
                                  {tableDataFatura.map((item, index) => (
                                    <tr key={index}>
                                      <td><input type="text" className="form-control" value={item.n_fatura} /></td>

                                      <td><select type="text" className="form-control" >

                                        <option value="0" selected="selected">Dinheiro</option>
                                        <option value="1">Cheque</option><option value="2">Cartão de Crédito</option>
                                        <option value="3">Cartão de Débito</option><option value="4">Crédito Loja</option>
                                        <option value="5">Vale Alimentação</option><option value="6">Vale Refeição</option>
                                        <option value="7">Vale Presente</option><option value="8">Vale Combustível</option>
                                        <option value="9">Duplicata Mercantil</option><option value="10">Boleto Bancário</option>
                                        <option value="11">Depósito Bancário</option><option value="12">Pagamento Instantâneo (PIX)</option>
                                        <option value="13">Transferência bancária, Carteira Digital</option>
                                        <option value="14">Programa de fidelidade, Cashback, Crédito Virtual</option>
                                        <option value="15">Sem Pagamento</option><option value="16">Outros</option>

                                      </select></td>
                                      <td><select type="text" className="form-control" >

                                        <option value="" selected="selected"></option>
                                        <option value="0">Pagamento à Vista</option>
                                        <option value="1">Pagamento à Prazo</option>


                                      </select></td>
                                      <td><input type="text" className="form-control" readOnly /> </td>
                                      <td> <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                                      </td>
                                      <td>
                                        <NumberFormat
                                          thousandSeparator={'.'}
                                          decimalSeparator={','}
                                          fixedDecimalScale={true}
                                          decimalScale={2}
                                          prefix={'R$ '}
                                          className="form-control"
                                          allowNegative={false}
                                          onValueChange={(values) => updateFaturaValue(values.floatValue, index)}
                                        />

                                      </td>

                                      <td>
                                        <span onClick={() => removeFatura(index)} className="btn btn-danger">
                                          Excluir
                                        </span>
                                      </td>

                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : null}
                          </div>
                          <br></br>
                          <br></br>

                          <div className="form-group form-material row">




                            <FormInput colWidth={2} name="Grupo_Tributario" label="Grupo Tributario" />
                            <FormInput colWidth={2} name="CFOP_Padrao" label="CFOP Padrão" />
                            <FormInput colWidth={2} name="Codigo_NCM" label="Código NCM" />
                            <FormInput colWidth={2} name="Codigo_CEST" label="Código CEST" />
                            <FormInput colWidth={2} name="ean" label="EAN - Código de Barras" />
                            <FormInput colWidth={2} name="Unidade_Comercial" label="Unidade Comercial" />
                            <FormSelect colWidth={2} name="Origem_da_Mercadoria" label="Origem da Mercadoria" />
                            <FormInput colWidth={2} name="Fabricante" label="Fabricante" />
                            <FormInput colWidth={2} name="Codigo_de_Beneficio_Fiscal" label="Código de Beneficio Fiscal" />
                            <FormSelect colWidth={2} name="Situacao_Tributaria_IPI" label="Situação Tributária IPI" />
                            <FormSelect colWidth={2} name="EX_TIPI" label="EX TIPI" numero={true} />



                          </div>

                          <div className="form-group form-material row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <select className="form-control" onChange={(e) => setNfe(e.target.value)}>
                                  <option selected="select" value="NFe">NFe/NFCe</option>
                                  <option value="NFA">NF Modelo 1/1A</option>
                                  <option value="NF2">NF Modelo 2</option>
                                  <option value="ECF">ECF</option>
                                </select>
                                <label className="floating-label">Tipo de Documento Referenciado</label>
                              </div>

                            </div>

                          </div>
                          <div className="form-group form-material row">

                            {nfe === 'NFe' &&
                              <>
                                <FormInput colWidth={3} name="MVA_Lucro_Porcento" label="Chave de Acesso da NFe Referenciada" readOnly={true} />
                                <div className="col-md-2">
                                  <span className="btn btn-primary">+</span>
                                </div>
                              </>

                            }
                            {nfe === 'NFA' &&
                              <>
                                <FormInput colWidth={3} name="MVA_Lucro_Porcento" label="(MVA) Lucro %" numero={true} />

                                <div className="form-group col-md-2">

                                  <DatePicker className="form-control"></DatePicker>
                                  <label className="floating-label">Data Emissão da NFA</label>

                                </div>

                                <FormSelect colWidth={2} name="bcsad" label="UF Emissão da NFA">
                                  {Estados.map((Estado, index) => (
                                    <option key={index} value={Estado.value}>
                                      {Estado.label}
                                    </option>
                                  ))}
                                </FormSelect>

                                <FormInput colWidth={3} name="serie_nfa" label="Serie da NFA" numero={true} />
                                <FormInput colWidth={3} name="serie_nfa" label="Numero da NFA" numero={true} />


                              </>
                            }
                            {nfe === 'NF2' &&

                              <>
                                <FormInput colWidth={3} name="MVA_Lucro_Porcento" label="CNPJ do Emitente da NF2" numero={true} />

                                <div className="form-group col-md-2">

                                  <DatePicker className="form-control"></DatePicker>
                                  <label className="floating-label">Data Emissão da NF2</label>

                                </div>

                                <FormSelect colWidth={2} name="bcsad" label="UF Emissão da NF2">
                                  {Estados.map((Estado, index) => (
                                    <option key={index} value={Estado.value}>
                                      {Estado.label}
                                    </option>
                                  ))}
                                </FormSelect>

                                <FormInput colWidth={3} name="serie_nfa" label="Serie da NF2" numero={true} />
                                <FormInput colWidth={3} name="serie_nfa" label="Numero da NF2" numero={true} />


                              </>

                            }
                            {nfe === 'ECF' && <>

                              <FormSelect name="teste" colWidth={3} label="Modelo de Documento">

                                <option value="2B">Cupom Fiscal emitido por máquina registradora (não ECF)</option>
                                <option value="2C">Cupom Fiscal PDV</option>
                                <option value="2D">Cupom Fiscal (emitido por ECF) (v2.0)</option>
                              </FormSelect>

                              <FormInput colWidth={3} name="serie_nfa" label="Serie da NF2" numero={true} />
                              <FormInput colWidth={3} name="serie_nfa" label="Numero da NF2" numero={true} />

                            </>}
                          </div>

                          {/* 
<div className="form-group form-material row">
                            {frete !== '9' && (
                              <>
                                < >
                                  <FormInput colWidth={2} name="NomeTransportador_transp" label="Nome Transportador" />
                                  <FormInput colWidth={2} name="RazaoSocialTransportador_transp" label="Razão Social Transportador" />
                                  <FormInput colWidth={2} name="ComplementoNome_transp" label="Complemento para o Nome" />
                                  <FormInput colWidth={2} name="CPFCNPJ_transp" label="CPF/CNPJ" />
                                  <FormInput colWidth={2} name="IE_transp" label="IE" />
                                  <FormInput colWidth={2} name="Isento_transp" label="ISENTO" />
                                  <FormInput colWidth={2} name="Logradouro_transp" label="Logradouro" />
                                  <FormInput colWidth={2} name="Numero_transp" label="Número" />
                                  <FormInput colWidth={2} name="Cidade_transp" label="Cidade" />
                                  <FormInput colWidth={2} name="UF_transp" label="UF" />
                                </>

                                <div className="container">
                                  <nav className="nav nav-pills nav-justified">
                                    <a className={`nav-link ${activeTabVeiculos === 'veiculos' ? 'active' : ''}`}
                                      onClick={() => setActiveTabVeiculos('veiculos')}>
                                      Veiculos e Reboque
                                    </a>
                                    <a className={`nav-link ${activeTabVeiculos === 'volumes' ? 'active' : ''}`}
                                      onClick={() => setActiveTabVeiculos('volumes')}>
                                      Volumes Transportados
                                    </a>
                                  </nav>

                                  <div className="mt-4">
                                    {activeTabVeiculos === 'veiculos' && (
                                      <>
                                        <h3>Dados do Veículo</h3>
                                        <div className="row">

                                          <FormInput colWidth={2} name="NomeTransportador_transp" label="Nome Transportador" />
                                          <FormInput colWidth={2} name="RazaoSocialTransportador_transp" label="Razão Social Transportador" />
                                          <FormSelect colWidth={1} name="UF_Dados_Veiculo" label="UF">
                                            {Estados.map((Estado, index) => (
                                              <option key={index} value={Estado.value}>
                                                {Estado.label}
                                              </option>
                                            ))}
                                          </FormSelect>

                                        </div>

                                        <h2>Reboques do Veiculo</h2>
                                        <div className="row">

                                          <FormInput colWidth={2} name="placa_veiculo_rtc" label="Nome Transportador" />
                                          <FormInput colWidth={2} name="rntc_veiculo_rtc" label="Razão Social Transportador" />
                                          <FormSelect colWidth={1} name="UF_Dados_Veiculo" label="UF">
                                            {Estados.map((Estado, index) => (
                                              <option key={index} value={Estado.value}>
                                                {Estado.label}
                                              </option>
                                            ))}
                                          </FormSelect>
                                          <div className="col-md-3">
                                            <span type="button" class="btn btn-primary">Adicionar</span>
                                          </div>

                                        </div>
                                      </>
                                    )}
                                    {activeTabVeiculos === 'volumes' && (
                                      <>


                                        <h2>Informacoes sobre Volumes</h2>
                                        <div className="row">

                                          <FormInput colWidth={2} name="quantidade_vol" label="Quantidade" numero={true} />
                                          <FormInput colWidth={2} name="especie_vol" label="Espécie (Big Bag, Caixa, Palete...)" />
                                          <FormInput colWidth={2} name="marca_vol" label="Marca" />
                                          <FormInput colWidth={2} name="peso_bruto_vol" label="Peso Bruto" />
                                          <FormInput colWidth={2} name="peso_liquido_vol" label="Peso Liquido" />


                                          <div className="col-md-3">
                                            <span type="button" class="btn btn-primary">Adicionar</span>
                                          </div>

                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>

                              </>



                            )}
                          </div> */}





                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="Informacoes_Adicionais_NFe" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Informações Adicionais para NFe</label>
                            </div>
                          </div>


                        </motion.div>

                      </div>}
                      {currentStep === 5 && <div>

                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>
                          <div className="form-group form-material row">


                            <FormInReal colWidth={3} name="Preco_de_Custo" label="Preço de Custo (R$)" readOnly={true} />
                            <FormInput colWidth={3} name="MVA_Lucro" label="(MVA) Lucro R$" readOnly={true} />
                            <FormInput colWidth={3} name="MVA_Lucro_Porcento" label="(MVA) Lucro %" readOnly={true} />
                            <FormInput colWidth={3} name="Preco_de_Venda" label="Preço de Venda (R$)" readOnly={true} />

                          </div>

                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="observacoes" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Observações</label>
                            </div>
                          </div>

                          <div className="form-group form-material row">

                            <FormSelect colWidth={2} name="Nivel_Complexidade" label="Nível Complexidade">
                              <option value="baixo">Baixo</option>
                              <option value="medio">Médio</option>
                              <option value="alto">Alto</option>
                            </FormSelect>

                            <FormSelect colWidth={2} name="Nivel_Prioridade" label="Nível Prioridade">
                              <option value="baixa">Baixa</option>
                              <option value="media">Média</option>
                              <option value="alta">Alta</option>
                            </FormSelect>

                            <FormInput colWidth={3} name="Descricao" label="Descrição" />
                            <FormInput colWidth={3} name="Ordenacao" label="Ordenação" />
                            <div className="col-md-1">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleButtonClickInst(values.Nivel_Complexidade, values.Nivel_Prioridade, values.Descricao, values.Ordenacao)}
                              >
                                Adicionar
                              </button>
                            </div>

                            {showTableInst && (
                              <table>
                                <thead>
                                  <tr>
                                    <th style={{ width: "25%" }}>Nível Complexidade</th>
                                    <th style={{ width: "25%" }}>Nível Prioridade</th>
                                    <th style={{ width: "25%" }}>Descrição</th>
                                    <th style={{ width: "25%" }}>Ordenação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {instRows.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.Nivel_Complexidade}</td>
                                      <td>{row.Nivel_Prioridade}</td>
                                      <td>{row.Descricao}</td>
                                      <td>{row.Ordenacao}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}




                          </div>

                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="observacoes" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Informações Complementares </label>
                            </div>
                          </div>

                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="observacoes" as="textarea" rows="2" className='form-control'></Field>
                              <label className='floating-label'>Informações Complementares de Interesse do Fisco </label>
                            </div>
                          </div>


                        </motion.div>
                      </div>

                      }



                    </div>
                  </div>





                </div>

                <br></br>

                <div className="panel">
                  <div className="panel-body container-fluid">
                    <div className="form-group form-material row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">

                        {currentStep === 5 ? (
                          <div>
                            <span className='btn-secondary btn' style={{ marginRight: '10px' }} onClick={() => setStep(currentStep - 1)}>
                              Retornar
                            </span>
                            <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit">
                              {isSubmitting ? "Criando ..." : "Criar o Cliente"}
                            </button>
                          </div>
                        ) : (
                          <>
                            {currentStep > 1 &&
                              <span className='btn-secondary btn' style={{ marginRight: '10px' }} onClick={() => setStep(currentStep - 1)}>
                                Retornar
                              </span>
                            }
                            <span className='btn-primary btn' style={{ marginRight: '20px' }} onClick={() => setStep(currentStep + 1)}>
                              Avançar
                            </span>
                          </>
                        )}

                        <Link to="/Fornecedores" style={{ marginLeft: '20px' }}>
                          <a className="btn btn-danger">
                            <i className="icon md-grid" aria-hidden="true"></i>
                            <span style={{ color: "white" }}>Cancelar e não salvar</span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>






                <ModalPesquisarEmp onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />
                <ModalPesquisarRep onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />




              </Form>
            )}
          </Formik>
        </div>
      </page>



    </div>
  )
}

export default CreateNFe


