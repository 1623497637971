import React from 'react';
import { Field } from 'formik';
import { NumericFormat as NumberFormat } from 'react-number-format';


const FormInReal = ({ colWidth, name, label, onChange, value }) => {
  return (
    <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
      <Field name={name}>
        {({ field, form }) => (
          <NumberFormat
            {...field}
            value={value || field.value}
            className='form-control'
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            onValueChange={({ formattedValue }) => {
              const floatValue = parseFloat(
                formattedValue.replace("R$ ", "").replace(".", "").replace(",", ".")
              );
              form.setFieldValue(name, floatValue);
              if (onChange) {
                onChange({
                  target: {
                    value: floatValue,
                  },
                });
              }
            }}
          />
        )}
      </Field>
      <label className='floating-label'>{label}</label>
    </div>
  );
};
export default FormInReal;


