// import axios from "axios";

// export const api = axios.create({
//        baseURL: "https://localhost:7012",
// })

// export const api_data = axios.create({
//     baseURL: "https://localhost:7140",
// });


// export const createSession = async (Username, Password) => {
//     return api.post('/login', {Username, Password})
// }
import axios from "axios";

export const api = axios.create({
        //   baseURL: "https://localhost:7012",
            baseURL: "https://login.appinfoshop.com.br"
   
})

export const api_data = axios.create({
        //    baseURL: "https://localhost:7140",
            baseURL: "https://api.appinfoshop.com.br"
});


export const createSession = async (Username, Password) => {
    return api.post('/login', {Username, Password})
}