import React from 'react';

const ModalDeleteUser = ({ user, show, onClose, onDelete }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg modal-danger" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Deseja deletar o usuário?</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3>Usuário: {user.Usuario}</h3>
          </div>
          <div className="modal-footer">
            <a type="button" className="btn btn-secondary text-white" onClick={onClose}>
              Cancelar
            </a>
            <a type="button" className="btn btn-danger text-white" onClick={onDelete}>
              Deletar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeleteUser;
