import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_data } from '../../services/api';




function ModalDetails(props) {
    const navigate = useNavigate();

    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});

            const response = await api_data.get(`/api/Bancos/GetTransacao/${props.selectId}`)
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };


    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };


    const handleEdit = () => {

        handleCloseModal();
        navigate(`/CentroDeCustos/Edit/${props.selectId}`);
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Centro de Custos</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                    <div className='form-group col-md-1' data-plugin="formMaterial">
                                        <input key={categoria.id} className='form-control' value={categoria.id} />
                                        <label className='floating-label'>ID</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input key={categoria.contaOrigemID} className='form-control' value={categoria.contaOrigemID} />
                                        <label className='floating-label'>Conta Origem ID</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input key={categoria.contaDestinoID} className='form-control' value={categoria.contaDestinoID} />
                                        <label className='floating-label'>Conta Destino ID</label>
                                    </div>
                                </div>
                                <br></br>
                                <br></br>
                                <div className='form-material row'>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input key={categoria.documento} className='form-control' value={categoria.documento} />
                                        <label className='floating-label'>Documento</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                    <input className="form-control" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(categoria.valor)} />

                                        <label className='floating-label'>Valor</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                    <input className="form-control" readOnly value={categoria.dataTransferencia ? categoria.dataTransferencia.slice(0, 10) : ''} />
                                        <label className='floating-label'>Data de Transferência</label>
                                    </div>
                                </div>
                                <br></br>
                                <br></br>
                                <div className='form-material row'>
                                    <div className='form-group col-md-12' data-plugin="formMaterial">
                                        <input key={categoria.observacoes} className='form-control' value={categoria.observacoes} />
                                        <label className='floating-label'>Observações</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails