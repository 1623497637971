import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Datatest from "./Datatable";


function Usuarios() {


  return (


    <div>
      
      <page>
        <div class="page-header">
          <h1 class="page-title">Usuarios</h1>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a asp-controller="Home" asp-action="Index">Home</a></li>

            <li class="breadcrumb-item active">Usuarios</li>
          </ol>
          <div class="page-header-actions">

            <p><Link to="/Usuarios/Create">
              <a class="btn btn-success" style={{ color: 'white' }}><i class="fa fa-plus-circle"></i>&nbsp;&nbsp; Incluir um novo Usuario</a>
            </Link>
            </p>
          </div>
        </div>
        <div className="panel">
          <header className="panel-heading">

          </header>


         <Datatest></Datatest>

          

        </div>


      </page>
    </div>

  );

}

export default Usuarios;
