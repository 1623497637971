
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import "../LoginPage/loginstyle.css";
import RegisterPage from "../RegisterPage";
import Esq_SenhaPage from "../EsqSenhaPage/EsqSenhaPage";

import { useNavigate, useLocation } from "react-router";
import { motion } from "framer-motion";


const LoginPage = () => {

  const [loading, setLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isEsqSenha, setIsEsqSenha] = useState(false);
  const location = useLocation();
  const { authenticated, login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);




  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit", { email, password });

    setLoading(true);

    login(email, password)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          setErrorMessage('Usuário ou senha incorreta, favor tentar novamente!');
        }
      });
  };


  const style_green = {
    backgroundImage: 'url(https://s3-sa-east-1.amazonaws.com/aprendaerp.com.br/customizacao/6398c4464d20680ce8ce4fb5/images/loginLateral6473538f3411c8cfe62c1600.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.8 } },
  };

  const slideIn = {
    hidden: { x: '30vw', opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.30,
        opacity: { delay: 0.20 }
      },
    },
  };




  const greenSectionStyle = {
    overflow: 'hidden',
  };


  return (
    <div className="page-container">
      <motion.div
        className="blue-section"
        style={style_green}
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      />
      <div className="green-section" style={greenSectionStyle}>
        <img alt="logo_img" src="https://s3-sa-east-1.amazonaws.com/aprendaerp.com.br/customizacao/6398c4464d20680ce8ce4fb5/images/logologin.png?v=1487076561" className="logo" width="80" height="auto" />
        {isRegistering ? (

          <h1 className="registerTitle">Registre sua Conta</h1>

        ) : (
          <h1 className="formalTitle">Acesse sua conta</h1>
        )}
        <div className="loginDiv">
          {isRegistering ? (
            <RegisterPage setIsRegistering={setIsRegistering} />
          ) : isEsqSenha ? (
            <Esq_SenhaPage setIsEsqSenha={setIsEsqSenha} />
          ) : (
            <motion.div id="loginFormal"
              initial="hidden"
              animate="visible"
              variants={slideIn}
            >
              <form action="" className="formFormal" onSubmit={handleSubmit}>
                <div className="formalField">

                  <label htmlFor="emailFormal" className="formalLabel">
                    Usuario
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="emailFormal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="formalInput"
                  />
                </div>

                <div className="formalField">
                  <label htmlFor="passwordFormal" className="formalLabel">
                    Senha
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="passwordFormal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="formalInput"
                  />
                  <div className="belowPassword">
                    {isRegistering ?
                      <div onClick={() => setIsRegistering(!isRegistering)}>
                        Voltar ao login
                      </div>
                      :
                      <div>
                        <span className="clickableText" onClick={() => setIsEsqSenha(!isEsqSenha)}>
                          'Esqueci a senha /  '
                        </span>
                        <span className="clickableText" onClick={() => setIsRegistering(!isRegistering)}>
                          ' Registrar-se '
                        </span>
                      </div>
                    }
                  </div>


                </div>
                <div className="errorContainer" style={{ maxWidth: '100%', overflow: 'auto' }}>
                  {errorMessage && <div style={{ color: 'red', fontSize: '0.864rem' ,fontWeight: 'bold'}}>{errorMessage}</div>}
                </div>


                <div className="formalActions">
                  <button type="submit" id="btnSubmit">
                    {loading ? (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      "Entrar"
                    )}
                  </button>
                </div>
              </form>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
