import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_data } from '../../services/api';




function ModalDetails(props) {
    const navigate = useNavigate();

    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});

            const response = await api_data.get(`/api/GrupoAll/Equipamentos/${props.selectId}`);
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };


    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };


    const handleEdit = () => {

        handleCloseModal();
        navigate(`/CentroDeCustos/Edit/${props.selectId}`);
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Centro de Custos</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                    <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.iD_Equipamento} name="iD_Equipamento" />
                                        <label className='floating-label'>ID Equipamento</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.nomeEquipamento} name="nomeEquipamento" />
                                        <label className='floating-label'>Nome do Equipamento</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.cliente} name="cliente" />
                                        <label className='floating-label'>Cliente</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' readOnly value={categoria.codigo_N_Serie} name="codigo_N_Serie" />
                                        <label className='floating-label'>Código Nº de Série</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' readOnly value={categoria.modelo} name="modelo" />
                                        <label className='floating-label'>Modelo</label>
                                    </div>
                                </div>
                                <br></br>
                                <br></br>
                                <div className='form-material row'>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' value={categoria.fabricante} name="fabricante" />
                                        <label className='floating-label'>Fabricante</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' readOnly value={categoria.n_Patrimonio} name="n_Patrimonio" />
                                        <label className='floating-label'>Nº de Patrimônio</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' readOnly value={categoria.n_Controle} name="n_Controle" />
                                        <label className='floating-label'>Nº de Controle</label>
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                         

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails