import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { api, api_data } from '../../services/api'
import "../EsqSenhaPage/esqSenha.css"

const Esq_SenhaPage = ({ setIsEsqSenha }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState("");

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await api.post("/solicita-reset", {
        email: values.email,
      })

      if (response.data.result === "Success") {
        alert("Email foi enviado")
        const receivedToken = response.data.message.split('=')[1];
        setToken(receivedToken);
        console.log("Token recebido: ", receivedToken);
        console.log("Resposta completa: ", response.data);

      } else {
        alert("Algo deu errado. Tente novamente.")
      }
      setLoading(false);
      setIsEsqSenha(false);
    } catch (error) {
      alert("Erro ao Enviar Email")
      console.error(error)
      setLoading(false);
    }
  }

  const handleCancel = () => {
    setIsEsqSenha(false);
  };

  return (
    <div className="loginDiv">
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={handleSubmit}
      >
        <Form className="formFormal">
          <div className="formalField">
            <label htmlFor="email" className="formalLabel">Email</label>
            <Field id="email" name="email" type="email" className="formalInput" />
          </div>

          <div className="registerActions">
            <button type="button" id="btnCancel" onClick={handleCancel}>Cancelar</button>
            <button type="submit" id="btnRegister">
              {loading ? (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Enviar"
              )}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default Esq_SenhaPage
