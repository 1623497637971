import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import { Link } from 'react-router-dom';

import 'datatables.net-dt/js/dataTables.dataTables';

import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';


import ModalDetails from './ModalDetails';
import ModalDelete from './ModalDelete';
import ModalSaldo from './ModalSaldo';
import ModalTransferencia from './ModalTransferencia';

class DatatablePlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedId: null,
      selectCreated: false,
      selectedEmpresaId: null, 
    };
  }



  fetchData(empresaId) {
    const url = empresaId
      ? `https://localhost:7140/api/Bancos/GetBancos?iD_Empresa=${empresaId}`
      : 'https://localhost:7140/api/Bancos/GetBancos';
  
    axios.get(url)
      .then(res => {
        this.setState({ data: res.data }, () => {
          if ($.fn.DataTable.isDataTable('#example')) {
            $('#example').DataTable().destroy();
          }
          this.initDataTable();
        });
      });
  }
  
  fetchData(empresaId) {
    const url = empresaId
      ? `https://localhost:7140/api/Bancos/GetBancos?iD_Empresa=${empresaId}`
      : 'https://localhost:7140/api/Bancos/GetBancos';
  
    axios.get(url)
      .then(res => {
        this.setState({ data: res.data }, () => {
          if ($.fn.DataTable.isDataTable('#example')) {
            $('#example').DataTable().destroy();
          }
          this.initDataTable();
        });
      });
  }
  

  componentDidMount() {
    axios.get('https://localhost:7140/api/Bancos/GetBancos')
      .then(res => {
        this.setState({ data: res.data });
        $(document).ready(() => {
          if ($.fn.DataTable.isDataTable('#example')) {
            $('#example').DataTable().destroy();
          }
          $('#example').DataTable({
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: 'Bfrtip',
            buttons: ['copy', 'csv', 'print'],
            language: {
              "sEmptyTable": "Nenhum registro encontrado",
              "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
              "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
              "sInfoFiltered": "(Filtrados de _MAX_ registros)",
              "sInfoPostFix": "",
              "sInfoThousands": ".",
              "sLengthMenu": "_MENU_ resultados por página",
              "sLoadingRecords": "Carregando...",
              "sProcessing": "Processando...",
              "sZeroRecords": "Nenhum registro encontrado",
              "sSearch": "Pesquisar",
              "oPaginate": {
                "sNext": "Próximo",
                "sPrevious": "Anterior",
                "sFirst": "Primeiro",
                "sLast": "Último"
              },
              "oAria": {
                "sSortAscending": ": Ordenar colunas de forma ascendente",
                "sSortDescending": ": Ordenar colunas de forma descendente"
              }
            }
          });
          $("#example_filter").appendTo(".dt-buttons");
        });

      });
  }

  formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }


  handleDelete(id) {
    axios.delete(`https://localhost:7140/api/Categorias_Contas/DeleteCategoria/${id}`)
      .then(res => {
        console.log(res);
        console.log(res.data);

        const updatedData = this.state.data.filter(item => item.iD_Empresa !== id);
        this.setState({ data: updatedData });
        window.location.reload();
      });
  }

  updateData = () => {
    axios.get('https://localhost:7140/api/Bancos/GetBancos')
      .then(res => {
        this.setState({ data: res.data });
        $('#example').DataTable().ajax.reload();
      });
  }


  handleModalOpen(id) {
    console.log(`Botão clicado com o ID: ${id}`);
    this.setState({ selectId: id })

  }

  handleModalDelete(id) {
    console.log(`Botão clicado com o ID: ${id}`);
    this.setState({ selectId: id })
  }


  handleModalSaldoOpen(id) {
    console.log(`Botão Saldo clicado com o ID: ${id}`);
    this.setState({ selectedId: id });
  }

  handleModalTransferenciaOpen(id) {
    console.log(`Botão Transferência clicado com o ID: ${id}`);
    this.setState({ selectedId: id });
  }



  render() {
    return (
      <div className="MainDiv">

        <div className="container p-5">
          <table id="example" className="table table-hover dataTable table-striped w-full dtr-inline">
            <thead>
              <tr className='trTable'>
                <th width="1%" className='thTable'>ID</th>
                <th width="20%">Banco</th>
                <th width="6%">Agencia</th>
                <th width="14%">Numero</th>
                <th width="13%">Saldo</th>

                <th width="12%">Ação</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((result) => {
                return (
                  <tr >
                    <td>{result.iD_Banco}</td>
                    <td>{result.banco}</td>
                    <td>
                      {result.agencia}
                    </td>
                    <td>{result.numero}</td>
                    <td>{this.formatCurrency(result.saldo)}</td>

                    <td>
                      <Link to={`/Bancos/Edit/${result.iD_Banco}`}
                        className="btn btn-info btn-icon on-default edit-row"
                        style={{ marginRight: '3px' }}>
                        <i class="icon md-edit" aria-hidden="true"></i>
                      </Link>
                      <button
                        className="btn btn-warning btn-icon on-default fc-row"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        onClick={() => this.handleModalOpen(result.iD_Banco)}
                        style={{ marginRight: '3px' }}
                      >
                        <i className="icon md-card" aria-hidden="true"></i>
                      </button>

                      <button
                        className="btn btn-success btn-icon on-default fc-row"
                        style={{ marginRight: '3px' }}
                        data-toggle="modal"
                        data-target="#modalSaldo"
                        onClick={() => this.handleModalSaldoOpen(result.iD_Banco)}
                      >
                        <i className="fa fa-usd" aria-hidden="true"></i>
                      </button>
                      <button
                        className="btn btn-primary btn-icon on-default fc-row"
                        style={{ marginRight: '3px' }}
                        data-toggle="modal"
                        data-target="#modalTransferencia"
                        onClick={() => this.handleModalTransferenciaOpen(result.iD_Banco)}
                      >
                        <i className="fa fa-exchange" aria-hidden="true"></i>
                      </button>


                      <button
                        className="btn btn-danger btn-icon on-default fc-row"
                        data-toggle="modal"
                        data-target="#exampleModalCenterDelete"
                        onClick={() => this.handleModalDelete(result.iD_Banco)}
                        style={{ marginRight: '3px' }}
                      >
                        <i className="icon md-delete" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <ModalDetails
          selectId={this.state.selectId}
          formatCurrency={this.formatCurrency}
        />

        <ModalDelete
          selectId={this.state.selectId}
          onUpdate={this.updateData}
          formatCurrency={this.formatCurrency}>
        </ModalDelete>
        <ModalSaldo selectedId={this.state.selectedId}>

        </ModalSaldo>
        <ModalTransferencia selectedId={this.state.selectedId}></ModalTransferencia>

      </div>
    )
  }
}

export default DatatablePlugin