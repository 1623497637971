import React, { useState, useContext, Children, useEffect } from 'react'


import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from './pages/LoginPage';
import ResSenhaPage from './pages/ResSenhaPage/ResSenhaPage';
import HomePage from "./Dashboard";

import { AuthContext, AuthProvider } from "./contexts/auth";
import { SidebarProvider } from './contexts/SidebarContext';

import Navbar from './Navbar';
import Sidebar from './Sidebar';

import Empresas from './pages/EmpresasPage/Empresas'
import CreateEmpresas from './pages/EmpresasPage/CreateEmpresa'
import EmpresasEdit from './pages/EmpresasPage/EmpresasEdit'


import Categorias_Contas from './pages/Categorias_ContasPage/Categorias_Contas'
import CreateCategorias from './pages/Categorias_ContasPage/CreateCategorias';
import EditCategorias from './pages/Categorias_ContasPage/EditCategorias';
import RegisterPage from './pages/RegisterPage';

import Bancos from './pages/BancosPage/Bancos';
import CreateBancos from './pages/BancosPage/CreateBancos';
import EditBancos from './pages/BancosPage/EditBancos';

import Clientes from './pages/ClientesPage/Clientes'
import CreateClientes from './pages/ClientesPage/CreateClientes';
import EditClientes from './pages/ClientesPage/EditClientes';

import { useLocation } from 'react-router-dom';
import ActivateConta from './pages/AtivarContaPage/ActivateConta';
import Usuarios from './pages/UsuariosPage/Usuarios';
import CreateUsuario from './pages/UsuariosPage/CreateUsuario';
import UsuariosEdit from './pages/UsuariosPage/UsuariosEdit';
import Fornecedores from './pages/FornecedoresPage/Fornecedores';
import CreateFornecedores from './pages/FornecedoresPage/CreateFornecedores';
import EditFornecedor from './pages/FornecedoresPage/EditFornecedor';

import Lancamentos from './pages/LancamentosPage/Lancamentos';
import CreateLancamentos from './pages/LancamentosPage/CreateLancamentos';
import EditLancamentos from './pages/LancamentosPage/EditLancamentos';

import CreateCentro_Custos from './pages/Centro_CustosPage/CreateCentro_Custos';
 import Centro_Custos from './pages/Centro_CustosPage/Centro_Custos';
import EditCentro_Custos from './pages/Centro_CustosPage/EditCentro_Custos';
import TransferenciaBancaria from './pages/TransferenciaPage/Transferencia';
import CreateTransferencia from './pages/TransferenciaPage/CreateTransferencia';

import Cheques from './pages/ChequesPage/Cheques';
import CreateCheque from './pages/ChequesPage/CreateCheque';
import EditCheque from './pages/ChequesPage/EditCheque';
import Depositos from './pages/Depositos/Depositos';
import CreateDeposito from './pages/Depositos/CreateDeposito';
import Produtos from './pages/ProdutosPage/Produtos';
import CreateProdutos from './pages/ProdutosPage/CreateProdutos';
import EditDepositos from './pages/Depositos/EditDeposito';


import NotaFiscal from './pages/NotaFiscalPage/NotaFiscal';
import Movimentacoes from './pages/NovaMovimentacaoPage/Movimentacoes';

import CreateNFe from './pages/NotaFiscalPage/CreateNFe';
import CreateMovimentacao from './pages/NovaMovimentacaoPage/CreateMovimentacao';
import EditProdutos from './pages/ProdutosPage/EditProdutos';
import Equipamentos from './pages/EquipamentosPage/Equipamentos';
import Grupo_Lancamentos from './pages/Grupo_LancamentosPage/Grupo_Lancamentos';
import Grupo_Pessoas from './pages/Grupo_PessoasPage/Grupo_Pessoas';
import Grupo_Pedidos from './pages/Grupo_PedidosPage/Grupo_Pedidos';

import Grupo_Tributario from './pages/Grupo_TributarioPage/Grupo_Tributario';



const AppRoutes = () => {
    const context = useContext(AuthContext);
    const [authenticated, setAuthenticated] = useState(undefined);  
    const [currentPage, setCurrentPage] = useState("");

    useEffect(() => {
        if (context) {
            setAuthenticated(context.authenticated);
            console.log('Status de autenticação:', context.authenticated);
        }
      }, [context, context?.authenticated]);  
      

    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);

        if (loading) {
            return <div className="loading"> Carregando. . </div>
        }

        if (!authenticated) {
            return <Navigate to="/login" />
        }

        return children;
    }

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login') {
      document.body.style.paddingTop = "0px";
    } else {
      document.body.style.paddingTop = "65px";
    }
  }, [location.pathname]);



    const pageClass = authenticated === true ? 'page' : 'loginPage'; 
    console.log(`A página agora é className ${pageClass}`);

    return (
       <>
                <Navbar onMenuClick={toggleSidebar}></Navbar>
                <Sidebar open={sidebarOpen}></Sidebar>
                <div className={pageClass}>
                    <Routes>
                        
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/register-user" element={<RegisterPage />} />
                        <Route exact path="/reset-password" element={<ResSenhaPage />} />
                        <Route exact path="/activate" element={<ActivateConta />}/>
                        <Route exact path="/" element={<Private><HomePage /></Private>} />
                        
                        
                        <Route exact path="/Usuarios" element={<Private><Usuarios /></Private>} />
                        <Route exact path="/Usuarios/Create" element={<Private><CreateUsuario /></Private>} />
                        <Route exact path="/Usuario/Edit/:id" element={<Private><UsuariosEdit /></Private>} />
                        


                        <Route exact path="/Empresas" element={<Private><Empresas /></Private>} />
                        <Route exact path="/Empresas/Create" element={<Private><CreateEmpresas /></Private>} />
                        <Route exact path="/Empresas/Edit/:id" element={<Private><EmpresasEdit /></Private>} />

                        <Route exact path="/Categorias_Contas" element={<Private><Categorias_Contas /></Private>} />
                        <Route exact path="/Categorias_Contas/Create" element={<Private><CreateCategorias /></Private>} />
                        <Route exact path="/Categorias_Contas/Edit/:id" element={<Private><EditCategorias /></Private>} />

                        <Route exact path="/Bancos" element={<Private><Bancos /></Private>} />
                        <Route exact path="/Bancos/Create" element={<Private><CreateBancos /></Private>} />
                        <Route exact path="/Bancos/Edit/:id" element={<Private><EditBancos /></Private>} />

                        <Route exact path="/Clientes" element={<Private><Clientes /></Private>} />
                        <Route exact path="/Clientes/Create" element={<Private><CreateClientes /></Private>} />
                        <Route exact path="/Clientes/Edit/:id" element={<Private><EditClientes /></Private>} />

                        <Route exact path="/Fornecedores" element={<Private><Fornecedores /></Private>} />
                        <Route exact path="/Fornecedores/Create" element={<Private><CreateFornecedores /></Private>} />
                        <Route exact path="/Fornecedores/Edit/:id" element={<Private><EditFornecedor /></Private>} />

                        <Route exact path="/Lancamentos" element={<Private><Lancamentos /></Private>} />
                        <Route exact path="/Lancamentos/Create" element={<Private><CreateLancamentos /></Private>} />
                        <Route exact path="/Lancamentos/Edit/:id" element={<Private><EditLancamentos /></Private>} />


                        <Route exact path="/CentroDeCustos" element={<Private><Centro_Custos /></Private>} /> 
                        <Route exact path="/CentroDeCustos/Create" element={<Private><CreateCentro_Custos /></Private>} />
                        <Route exact path="/CentroDeCustos/Edit/:id" element={<Private><EditCentro_Custos /></Private>} />

                        <Route exact path="/TransferenciaBancaria" element={<Private><TransferenciaBancaria /></Private>} /> 
                        <Route exact path="/TransferenciaBancaria/Create" element={<Private><CreateTransferencia /></Private>} /> 

                        <Route exact path="/ControleCheque" element={<Private><Cheques /></Private>} /> 
                        <Route exact path="/ControleCheque/Create" element={<Private><CreateCheque /></Private>} /> 
                        <Route exact path="/ControleCheque/Edit/:id" element={<Private><EditCheque /></Private>} />

                        <Route exact path="/Depositos" element={<Private><Depositos /></Private>} /> 
                        <Route exact path="/Depositos/Create" element={<Private><CreateDeposito /></Private>} /> 
                        <Route exact path="/Depositos/Edit/:id" element={<Private><EditDepositos /></Private>} /> 

                        <Route exact path="/Produtos" element={<Private><Produtos /></Private>} /> 
                        <Route exact path="/Produtos/Create" element={<Private><CreateProdutos /></Private>} /> 
                        <Route exact path="/Produtos/Edit/:id" element={<Private><EditProdutos /></Private>} /> 
                       
                        <Route exact path="/NFe" element={<NotaFiscal />} /> 
                        <Route exact path="/NFe/Create" element={<CreateNFe />} /> 
                       
                        <Route exact path="/NovaMovimentacao" element={<Private><Movimentacoes /></Private>} /> 
                        <Route exact path="/NovaMovimentacao/Create" element={<Private><CreateMovimentacao /></Private>} /> 
                       
                        <Route exact path="/Equipamentos" element={<Private><Equipamentos /></Private>} /> 
                      
                        <Route exact path="/Grupo_Lancamentos" element={<Private><Grupo_Lancamentos /></Private>} /> 
                    
                        <Route exact path="/Grupo_Pessoas" element={<Private><Grupo_Pessoas /></Private>} /> 
                        <Route exact path="/Grupo_Pedidos" element={<Private><Grupo_Pedidos /></Private>} /> 
                    
                        <Route exact path="/Grupo_Tributario" element={<Private><Grupo_Tributario /></Private>} /> 
                     
                    </Routes>
                  </div>
                  </>   
    )
}

export default AppRoutes
