import React, { useState } from 'react'
import FormInModal from "../../../GlobalComponents/ModalComponents/FormInModal"
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { api_data } from '../../../services/api';




function ModalPesFormEmp({ setIsCreating, onEmpresaCreated, setEmpCreated  }) {
  const [activeTab, setActiveTab] = useState('cadastro');

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  }

  const initialValues = {};

  const onSubmit = (values, { setSubmitting }) => {
  api_data.post("/api/Empresa/AddEmpresa", values)
  .then((response) => {
    console.log(response.data);
    setEmpCreated(true); 
  })

  .catch((error) => {
    console.error(error);
  })
  .finally(() => {
    setSubmitting(false);
    setIsCreating(false);
  });
};

  return (
    <div className='panel-body container-fluid'>
      <div className="form-material row">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'cadastro' ? 'active' : ''}`}
              id="dadosCadastraisTab"
              onClick={() => handleTabChange('cadastro')}
              role="tab"
              aria-controls="dadosCadastrais"
              aria-selected={activeTab === 'cadastro'}>Cadastro</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'detalhes' ? 'active' : ''}`}
              id="detalhesCadastraisTab"
              onClick={() => handleTabChange('detalhes')}
              role="tab"
              aria-controls="detalhesCadastrais"
              aria-selected={activeTab === 'detalhes'}>Cadastro Form 2</a>
          </li>
        </ul>

      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <div className="tab-content" id="nav-tabContet">
              <div className="tab-pane" id="dadosCadastrais" role="tabpanel" aria-labelledby="dadosCadastraisTab" style={{ display: activeTab === 'cadastro' ? 'block' : 'none' }}>

                <div className="panel-body container-fluid">
                  <div className="form-material row">
                    <FormInModal colWidth={3} label="Razao Social" name="razao_Social" />
                    <FormInModal colWidth={3} label="Nome Fantasia" name="nome_Fantasia" />
                    <FormInModal colWidth={3} label="CNPJ" name="cnpj" />
                    <FormInModal colWidth={3} label="Endereco" name="endereco" />
                    <FormInModal colWidth={3} label="Numero" name="numero" />
                    <FormInModal colWidth={3} label="Complemento" name="complemento" />
                    <FormInModal colWidth={3} label="Bairro" name="bairro" />
                    <FormInModal colWidth={3} label="CEP" name="cep" />
                    <FormInModal colWidth={3} label="UF" name="uf" />
                    <FormInModal colWidth={3} label="Codigo_UF" name="codigo_UF" />
                    <FormInModal colWidth={3} label="Municipio" name="municipio" />
                    <FormInModal colWidth={3} label="Telefone 1" name="telefone_1" />
                    <FormInModal colWidth={3} label="Contato 1" name="contato_1" />
                    <FormInModal colWidth={3} label="Telefone 2" name="telefone_2" />
                    <FormInModal colWidth={3} label="Contato 2" name="contato_2" />

                    <FormInModal colWidth={3} label="Codigo Municipio" name="codigo_Municipio" />


                  </div>
                </div>
              </div>
              <div className="tab-pane" id="detalhesCadastrais" role="tabpanel" aria-labelledby="detalhesCadastraisTab" style={{ display: activeTab === 'detalhes' ? 'block' : 'none' }}>
                <div className="panel-body container-fluid">
                  <div className="form-material row">
                    <FormInModal colWidth={3} label="Inscricao Estadual" name="inscricao_Estadual" />
                    <FormInModal colWidth={3} label="Inscricao Municipal" name="inscricao_Municipal" />
                    <FormInModal colWidth={3} label="Regime Tributario" name="regime_Tributario" />
                    <FormInModal colWidth={3} label="CNAE" name="cnae" />
                    <FormInModal colWidth={3} label="Perfil Fiscal" name="perfil_Fiscal" />
                    <FormInModal colWidth={3} label="Tipo Atividade" name="tipo_Atividade" />
                    <FormInModal colWidth={3} label="Aliquota Imposto" name="aliquota_Imposto" />
                    <FormInModal colWidth={3} label="PIS" name="pis" />
                    <FormInModal colWidth={3} label="COFINS" name="cofins" />
                    <FormInModal colWidth={3} label="Apuracao_IPI" name="apuracao_IPI" />
                    <FormInModal colWidth={3} label="Email" name="email" />
                    <FormInModal colWidth={3} label="Porta" name="porta" />
                    <FormInModal colWidth={3} label="SMTP" name="smtp" />
                    <FormInModal colWidth={3} label="Login" name="login" />
                    <FormInModal colWidth={3} label="Senha" name="senha" />
                    <FormInModal colWidth={3} label="Observacao" name="observacao" />


                  </div>
                </div>
              </div>
            </div>
            <span
              className='btn-success btn'
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Criando ..." : "Criar a Empresa"}
            </span>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ModalPesFormEmp
