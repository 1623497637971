import React, { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../src/contexts/auth';
import { useSidebar, sidebarOpen } from '../src/contexts/SidebarContext';
import { Link, useNavigate } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from 'framer-motion';


import jwtDecode from 'jwt-decode';

import './Styles/Navbar/Navbar.css';

function Navbar({ onMenuClick }) {
    const controls = useAnimation();
    const navigate = useNavigate();

    const { authenticated, logout } = useContext(AuthContext);

    const [showNotifications, setShowNotifications] = useState(false);
    const [showUser, setShowUser] = useState(false);

    const notificationsRef = useRef(null);
    const userRef = useRef(null);

    const { toggleSidebar } = useSidebar();
    const { sidebarOpen } = useSidebar();

    const handleLogout = () => {
        setShowUser(!showUser);
        logout();
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                notificationsRef.current &&
                !notificationsRef.current.contains(event.target)
            ) {
                setShowNotifications(false);
            }

            if (userRef.current && !userRef.current.contains(event.target)) {
                setShowUser(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const [username, setUsername] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            const username = decodedToken.username;
            console.log(username);
            setUsername(username);
        }
    }, []);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const toggleUser = () => {
        setShowUser(!showUser);

    };
    const [searchActive, setSearchActive] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [showOptions, setShowOptions] = useState(false);

    const allLinks = [
        { name: "Usuarios", path: "/Usuarios" },
        { name: "Empresas", path: "/Empresas" },
        { name: "Categorias de Contas", path: "/Categorias_Contas" },
        { name: "Bancos", path: "/Bancos" },
        { name: "Clientes", path: "/Clientes" },
        { name: "Fornecedores", path: "/Fornecedores" },
        { name: "Lancamentos", path: "/Lancamentos" },
        { name: "Centro De Custos", path: "/CentroDeCustos" },
        { name: "Transferencia Bancaria", path: "/TransferenciaBancaria" },
        { name: "Controle de Cheque", path: "/ControleCheque" },
        { name: "Depositos", path: "/Depositos" },
        { name: "Produtos", path: "/Produtos" },
        { name: "Nota Fiscal", path: "/NFe" },
        { name: "Movimentacoes", path: "/NovaMovimentacao" },
        { name: "Equipamentos", path: "/Equipamentos" },
        { name: "Grupo Lancamentos", path: "/Grupo_Lancamentos" },
        { name: "Grupo Pessoas", path: "/Grupo_Pessoas" },
        { name: "Grupo Pedidos", path: "/Grupo_Pedidos" },
    ];


    const filteredLinks = allLinks.filter(link =>
        link.name.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleOptionClick = (path) => {
        navigate(path);
        setSearchText("");
        setShowOptions(false);
    };





    const handleFocus = () => {
        controls.start({ width: 300 });
    };


    const [inputVisible, setInputVisible] = useState(false);



    useEffect(() => {
        if (inputVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputVisible]);

    // const [controls, setControls] = useAnimation();
    const inputRef = useRef(null);

    useEffect(() => {
        if (searchActive && inputRef.current) {
            inputRef.current.focus();
            controls.start({ width: 300 });
        }
    }, [searchActive]);

    const handleBlur = () => {
        controls.start({ width: 10 });
        setSearchText("");
        setShowOptions(false);
        setTimeout(() => {
            setSearchActive(false);
        }, 200);
    };


    const handleKeyPress = (e) => {
        if (e.key === "Enter" && filteredLinks.length > 0) {
            handleOptionClick(filteredLinks[0].path);
        }
    };
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener("keydown", handleKeyPress);
            return () => {
                if (inputRef.current) {
                    inputRef.current.removeEventListener("keydown", handleKeyPress);
                }
            };
        }
    }, [filteredLinks]);


    return (
        <>
            {authenticated ? (
                <nav className="navbar">
                    <div className="navbar-left">
                        <div onClick={toggleSidebar}>
                            {sidebarOpen ? <i className="fas fa-arrow-left fa-lg"></i> : <i className="fas fa-bars fa-lg"></i>}

                        </div>

                        <div className="container-input">
                            <div onClick={() => setSearchActive(!searchActive)} className="search-icon">
                                <i className="fas fa-search fa-lg lgvisualizer"></i>
                            </div>
                            {/* {searchActive && (
                                <motion.div
                                    initial={{ width: 10 }}
                                    animate={controls}
                                    className="search-input-container"
                                >
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={searchText}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                            if (e.target.value.length > 2) {
                                                setShowOptions(true);
                                            } else {
                                                setShowOptions(false);
                                            }
                                        }}
                                        placeholder="Search..."
                                        className="search-input"
                                    />
                                    {showOptions && (
                                        <div className="styled-suggestions">
                                            {filteredLinks.map((link, index) => (
                                                <div
                                                    className="styled-suggestion-item"
                                                    key={index}
                                                    onClick={() => handleOptionClick(link.path)}
                                                >
                                                    {link.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </motion.div>
                            )} */}

                            {searchActive && (
                                <motion.div
                                    initial={{ width: 10 }}
                                    animate={controls}
                                    className="search-input-container"
                                >
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={searchText}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                            if (e.target.value.length > 2) {
                                                setShowOptions(true);
                                            } else {
                                                setShowOptions(false);
                                            }
                                        }}
                                        placeholder="Pesquisar ..."
                                        className="search-input"
                                    />
                                    {showOptions && (
                                        <motion.div
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            className="styled-suggestions"
                                        >
                                            {filteredLinks.map((link, index) => (
                                                <div
                                                    className="styled-suggestion-item"
                                                    key={index}
                                                    onClick={() => handleOptionClick(link.path)}
                                                >
                                                    {link.name}
                                                </div>
                                            ))}
                                        </motion.div>
                                    )}
                                </motion.div>
                            )}

                        </div>













                        {/* <div className="search nav-items ripple" onClick={() => setSearchActive(!searchActive)}>
                            <i className="fas fa-search fa-lg"></i>
                        </div>
                        {searchActive && (
  <motion.div 
    initial={{ x: '-100%', opacity: 0 }} 
    animate={{ x: '0%', opacity: 1 }} 
    exit={{ x: '-100%', opacity: 0 }}
    transition={{ duration: 0.5, ease: "easeOut" }}
    style={{ position: 'relative' }} 
    className="search-container"
  >
    <input
      type="text"
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2) {
          setShowOptions(true);
        } else {
          setShowOptions(false);
        }
      }}
      placeholder="Search..."
      className="search-input"
    />
    {showOptions && (
      <div className="styled-suggestions">
        {filteredLinks.map((link, index) => (
          <div
            className="styled-suggestion-item"
            key={index}
            onClick={() => handleOptionClick(link.path)}
          >
            {link.name}
          </div>
        ))}
      </div>
    )}
  </motion.div>
)} */}

                    </div>



                    <div className="navbar-right ">
                        <div
                            className="notifications nav-items ripple"
                            onClick={toggleNotifications}
                            ref={notificationsRef}
                        >
                            <i className="fas fa-bell fa-lg"></i>
                        </div>

                        <div
                            className="user nav-items ripple"
                            onClick={toggleUser}
                            ref={userRef}
                        >
                            <i className="fas fa-user fa-lg"></i>
                        </div>
                        <div className="settings nav-items ripple">
                            <a href="#">
                                <i className="fas fa-cog fa-lg"></i>
                            </a>
                        </div>
                        <div className="info last-item nav-items ripple">
                            <i className="fas fa-info fa-lg"></i>
                        </div>

                        <div className={`notifications-dropdown ${showNotifications ? 'show' : ''}`}>
                            <div className="notification">
                                <p>Notification 1</p>
                            </div>
                            <div className="notification">
                                <p>Notification 2</p>
                            </div>
                            <div className="notification">
                                <p>Notification 3</p>
                            </div>
                        </div>

                        <div className={`user-dropdown ${showUser ? 'show' : ''}`}>
                            <div className="user-not">
                                <p>Notification 1</p>
                            </div>
                            <div className="user-not">
                                <p>Notification 2</p>
                            </div>
                            <div className="user-not logout-button" onClick={handleLogout}>
                                <p>Logout</p>
                            </div>

                        </div>
                    </div>
                </nav>
            ) : (
                <div></div>
            )}
        </>
    );
}

export default Navbar;


