import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';
import { estados } from './../EmpresasPage/ObjectsPage/Estados';
import ModalPesquisarEmp from "./ModalEmpresa/ModalPesquisarEmp";
import {  toast } from 'react-toastify';


import './Toggle.css';

function CreateDeposito() {


  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [exteriorToggle, setExteriorToggle] = useState(false);
  const [paisValue, setPaisValue] = useState("Brasil");
  const [empresas, setEmpresas] = useState([]);
  const [empCreated, setEmpCreated] = useState(false);
  const [empresaId, setEmpresaId] = useState(null);
  const initialValues = {
    pais: paisValue,
  };


  useEffect(() => {
    api_data.get('/api/Bancos/GetEmpresasList')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);

  
  const handleEmpresaIdChange = (id) => {
    setEmpresaId(id);
  };


  const notify = () => {
    toast.success("Criado com Sucesso", { autoClose: 3000 });
  };
  
  const notifyError = () => {
    toast.error("Falha na criação, tente novamente", { autoClose: 3000 });
  };

  const onSubmit = (values, { setSubmitting }) => {

    console.log(values.pais)
    if (values.cep) {
      values.cep = parseInt(values.cep.replace('-', ''), 10);
    }

    if (values.telefone_estoque) {
      values.telefone_estoque = parseInt(values.telefone_estoque.replace(/\D/g, ''), 10);
    }

    console.log(values)
    api_data
      .post("/api/Depositos/AddDeposito", values)
      .then((response) => {
        notify()
        console.log(response);
        navigate("/Depositos");
        setShowErrorMessage(false);
      })
      .catch((error) => {
        notifyError()
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const [file, setFile] = useState(null);




  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Cadastro do Depósito</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Depósito</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/Depositos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista Depósitos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Depósito:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={6} name="nome_Deposito" label="Nome do Depósito" />

                      <div className="form-group col-md-6">
                        <div className="input-group">

                          <Field name="iD_Empresa">
                            {({ field, meta }) => (
                              <>
                                <select
                                  {...field}
                                  className="form-control form-select form-control-label-sm mb-2"
                                >
                                  {empresas.map((empresas) => (
                                    <option key={empresas.ID_Empresa} value={empresas.ID_Empresa}>
                                      {empresas.Razao_Social}
                                    </option>
                                  ))}

                                </select>
                                <label className='floating-label'> Empresa
                                </label>


                              </>
                            )}
                          </Field>
                          <span className="input-group-append">
                            <a
                              data-bs-toggle="modal"
                              data-bs-target="#ModalEmpPesquisar"

                              className="btn btn-icon btn-default"
                              style={{ height: '40px', display: 'inline-block' }}

                            >
                              <i className="icon md-search" aria-hidden="true"></i>
                            </a>
                          </span>
                        </div>
                      </div>

                      <FormInput colWidth={4} name="responsavel_estoque" label="Responsavel pelo Estoque" />
                      <FormInput colWidth={4} name="telefone_estoque" label="Telefone Estoque" mask="(99) 99999-9999" numero={true} />

                      <FormInput colWidth={4} name="email_estoque" label="Email Estoque" />
                    </div>
                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultSix" href="#exampleCollapseDefaultSix" ariaControls="exampleCollapseDefaultSix"
                    title="Informações do Endereço:" defaultOpen={true}>
                    <div className="form-group form-material row">

                      <div className="col-md-1">
                        <div className={`toggle-button ${exteriorToggle ? 'active' : ''}`} onClick={() => {
                          setExteriorToggle(prevState => !prevState);
                          setFieldValue("pais", !exteriorToggle ? "" : "Brasil");
                        }}>
                          <div className="toggle-circle"></div>
                        </div>
                      </div>


                      <div className="col-md-2">
                        <p className='Paragraph-1'> O Deposito é no exterior</p>
                      </div>



                      <FormInput colWidth={4} name="logradouro" label="Logradouro" />
                      <FormInput colWidth={3} name="numero" label="Numero " />
                      <FormInput colWidth={3} name="complemento" label="Complemento" />
                      <FormInput colWidth={3} name="bairro" label="bairro" />
                      <FormInput colWidth={3} name="cep" label="CEP" mask="99999-999" numero={true} />

                      <FormInput colWidth={3} name="cidade" label="Cidade" />
                      <FormInput colWidth={3} name="codigo_Municipio" label="Código Municipio" />
                      <FormSelect colWidth={3} name="uf" label="UF">
                        {estados.map((estado) => (
                          <option key={estado.value} value={estado.value}>{estado.label}</option>
                        ))}
                      </FormSelect>
                      <FormInput colWidth={3} name="codigo_UF" label="Código UF" />

                      <div className={`form-group col-md-3`} data-plugin="formMaterial">
                        <Field name="pais">
                          {({ field }) => (
                            <input
                              className='form-control'
                              {...field}
                              readOnly={!exteriorToggle}
                              type="text"
                            />
                          )}
                        </Field>
                        <label className='floating-label'>País</label>
                      </div>






                    </div>
                  </FormPanel>






                  <br></br>




                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar o Deposito</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar o Deposito"}
                          </button>
                          <Link to="/Depositos">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <ModalPesquisarEmp onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />


              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateDeposito