import React from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 0,
    pv: 0,
    amt: 0,
  },
];

const CardClient = React.memo(({ }) => (
  <div className="card card-shadow" id="widgetLineareaFour">
  <div className="card-block p-20 pt-10">
      <div className="clearfix">
          <div className="grey-800 float-left py-10">
              <i className="icon md-view-list grey-600 font-size-24 vertical-align-bottom mr-5"></i>                    Novos Orçamentos
          </div>
          <span className="float-right grey-700 font-size-30">65</span>
      </div>
      <div className="mb-20 grey-500">
          <i className="icon md-long-arrow-up green-500 font-size-16"></i>                  18.4% no último dia
      </div>
      <div className="ct-chart h-50">
          <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                  data={data}
                  margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 5,
                  }}
              >
                  <Area type="monotone" dataKey="uv" stroke="#0c5bc9" fill="#083c85"  />
              </AreaChart>
          </ResponsiveContainer>
      </div>
  </div>
</div>
));

export default CardClient;
