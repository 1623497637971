import React from 'react'
import DatatablePlugin from './DatatablePlugin'
import { Link } from "react-router-dom";
import ModalCreate from './ModalCreate';

function Equipamentos() {
  return (
    <div>
      <div>

        <page>
          <div class="page-header">
            <h1 class="page-title">Equipamentos</h1>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a asp-controller="Home" asp-action="Index">Home</a></li>

              <li class="breadcrumb-item active">Equipamentos</li>
            </ol>
            <div class="page-header-actions">





              <p>
                <span
                  className="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalCreate"
                  style={{ color: 'white' }}
                >
                  <i className="fa fa-plus-circle"></i>&nbsp;&nbsp; Cadastrar um Equipamento
                </span>
              </p>
            </div>
          </div>
          <div className="panel">
            <header className="panel-heading">

            </header>


            <DatatablePlugin></DatatablePlugin>



          </div>
          <br></br>
          <br></br>

        </page>
      </div>
      <ModalCreate


      />
    </div>
  )
}

export default Equipamentos