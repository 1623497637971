import React, { useContext, useState } from 'react';
import { AuthContext } from '../src/contexts/auth';
import { motion, AnimatePresence } from 'framer-motion';
import { useSidebar } from '../src/contexts/SidebarContext';
import { Link } from 'react-router-dom';



import './Styles/Sidebar/Sidebar.css';

function Sidebar() {
  const { authenticated } = useContext(AuthContext);
  const [openMenu, setOpenMenu] = useState("");
  const { sidebarOpen, closeSidebar } = useSidebar();

  const variants = {
    open: { opacity: 1, height: "auto" },
    closed: { opacity: 0, height: 0 }
  };

  const handleMenuClick = (menu) => {
    setOpenMenu(openMenu === menu ? "" : menu);
  }

  const handleSubmenuClick = () => {

    closeSidebar();
  };

  return (
    <>
      {authenticated && (
        <div className={`sidebar ${sidebarOpen ? 'show' : ''}`}>
          {sidebarOpen ? (

            <>
              <ul className="cadastro-items highmenu">
                <Link to="/" className='tstLink'>
                  <li className='highmenu-li' onClick={handleSubmenuClick}
                    style={{ backgroundColor: '#f0efef' }}>
                    <i className="site-menu-icon md-view-compact" aria-hidden="true"></i> Dashboard
                  </li>
                </Link>
              </ul>
              <ul className='cadastro-items highmenu'>
                <li className='highmenu-li' onClick={() => handleMenuClick('cadastro')}
                  style={{ backgroundColor: openMenu === 'cadastro' ? '#f0efef' : '', color: openMenu === 'cadastro' ? 'rgb(20, 20, 20)' : '' }}>
                  <i className="fal fa-user-plus"></i> Cadastros
                  <span className={`site-menu-arrow ${openMenu === 'cadastro' ? 'rotate-90' : 'rotate-0'}`}></span>
                </li>
                <AnimatePresence>
                  {openMenu === 'cadastro' && (
                    <motion.div
                      style={{ overflow: "hidden", originY: 0 }}
                      variants={variants}
                      initial="closed"
                      animate="open"
                      exit="closed"
                      transition={{ duration: 0.185 }}
                    >
                      <ul className="nested-list">
                        <Link to="/Usuarios" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Usuarios</span>
                          </li>
                        </Link>


                        <Link to="/Empresas" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Empresas</span>
                          </li>
                        </Link>


                        <Link to="/Clientes" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Clientes</span>
                          </li>
                        </Link>
                        <Link to="/Fornecedores" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Fornecedores</span>
                          </li>
                        </Link>
                        <Link to="/CentroDeCustos" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Centro de Custos</span>
                          </li>
                        </Link>

                        <Link to="/Produtos" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Produtos</span>
                          </li>
                        </Link>

                        <Link to="/Equipamentos" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Equipamentos</span>
                          </li>
                        </Link>

                        <Link to="/UniMedida" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Unidade de Medidas</span>
                          </li>
                        </Link>

                        <Link to="/Grupo_Lancamentos" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Grupo Lançamentos</span>
                          </li>
                        </Link>

                        <Link to="/Grupo_Pessoas" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Grupo Pessoas</span>
                          </li>
                        </Link>

                        <Link to="/Grupo_Tributario" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Grupo Tributario</span>
                          </li>
                        </Link>

                        <Link to="/Grupo_Pedidos" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Grupo Pedidos</span>
                          </li>
                        </Link>

                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </ul>
              <ul className='financeiro-items highmenu'>
                <li className='highmenu-li' onClick={() => handleMenuClick('financeiro')}
                  style={{ backgroundColor: openMenu === 'financeiro' ? '#f0efef' : '', color: openMenu === 'financeiro' ? 'rgb(20, 20, 20)' : '' }}>
                  <i class="fa fa-line-chart"></i> Financeiro
                  <span className={`site-menu-arrow ${openMenu === 'financeiro' ? 'rotate-90' : 'rotate-0'}`}></span>
                </li>
                <AnimatePresence>
                  {openMenu === 'financeiro' && (
                    <motion.div
                      style={{ overflow: "hidden", originY: 0 }}
                      variants={variants}
                      initial="closed"
                      animate="open"
                      exit="closed"
                      transition={{ duration: 0.185 }}
                    >
                      <ul className="nested-list">
                        <Link to="/Bancos" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Bancos</span>
                          </li>
                        </Link>
                        <li className="submenu">
                          <span className="submenu-text">Caixas</span>
                        </li>
                        <Link to="/Categorias_Contas" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Categorias Contas</span>
                          </li>
                        </Link>
                        <Link to="/Lancamentos" onClick={handleSubmenuClick}>

                          <li className="submenu">
                            <span className="submenu-text">Lançamentos</span>
                          </li>
                        </Link>
                        <Link to="/TransferenciaBancaria" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Transferencia Bancaria</span>
                          </li>
                        </Link>
                        <Link to="/ControleCheque" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Controle de Cheque</span>
                          </li>
                        </Link>
                        <Link to="/NFe" onClick={handleSubmenuClick}>
                          <li className="submenu">
                            <span className="submenu-text">Nota Fiscal</span>
                          </li>
                        </Link>
                        <li className="submenu">
                          <span className="submenu-text">Baixas</span>
                        </li>
                        <li className="submenu">
                          <span className="submenu-text">Cheques</span>
                        </li>
                        <li className="submenu">
                          <span className="submenu-text">Cartões</span>
                        </li>
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </ul>
              <ul className='financeiro-items highmenu'>
                <li className='highmenu-li' onClick={() => handleMenuClick('compras')}
                  style={{ backgroundColor: openMenu === 'compras' ? '#f0efef' : '', color: openMenu === 'compras' ? 'rgb(20, 20, 20)' : '' }}>
                  <i class="fa fa-truck"></i> Compras
                  <span className={`site-menu-arrow ${openMenu === 'compras' ? 'rotate-90' : 'rotate-0'}`}></span>
                </li>
                <AnimatePresence>
                  {openMenu === 'compras' && (
                    <motion.div
                      style={{ overflow: "hidden", originY: 0 }}
                      variants={variants}
                      initial="closed"
                      animate="open"
                      exit="closed"
                      transition={{ duration: 0.185 }}
                    >
                      <ul className="nested-list">
                        <li className="submenu">
                          <span className="submenu-text">Compras</span>
                        </li>
                        <li className="submenu">
                          <span className="submenu-text">Ordens de Compras</span>
                        </li>
                        <li className="submenu">
                          <span className="submenu-text">Importar XML de Compras</span>
                        </li>

                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </ul>

              <ul className='financeiro-items highmenu'>
                <li className='highmenu-li' onClick={() => handleMenuClick('relatorios')}
                  style={{ backgroundColor: openMenu === 'relatorios' ? '#f0efef' : '', color: openMenu === 'relatorios' ? 'rgb(20, 20, 20)' : '' }}>
                  <i className="site-menu-icon md-view-compact" aria-hidden="true"></i> Relatorios
                  <span className={`site-menu-arrow ${openMenu === 'relatorios' ? 'rotate-90' : 'rotate-0'}`}></span>
                </li>
                <AnimatePresence>
                  {openMenu === 'relatorios' && (
                    <motion.div
                      style={{ overflow: "hidden", originY: 0 }}
                      variants={variants}
                      initial="closed"
                      animate="open"
                      exit="closed"
                      transition={{ duration: 0.185 }}
                    >
                      <ul className="nested-list">
                        <li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li>
                        <li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li><li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li><li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li><li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li>
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </ul>


              <ul className='financeiro-items highmenu'>
                <li className='highmenu-li' onClick={() => handleMenuClick('estoque')}
                  style={{ backgroundColor: openMenu === 'estoque' ? '#f0efef' : '', color: openMenu === 'estoque' ? 'rgb(20, 20, 20)' : '' }}>
                  <i className="site-menu-icon fa fa-cubes" aria-hidden="true"></i> Estoque
                  <span className={`site-menu-arrow ${openMenu === 'estoque' ? 'rotate-90' : 'rotate-0'}`}></span>
                </li>
                <AnimatePresence>
                  {openMenu === 'estoque' && (
                    <motion.div
                      style={{ overflow: "hidden", originY: 0 }}
                      variants={variants}
                      initial="closed"
                      animate="open"
                      exit="closed"
                      transition={{ duration: 0.185 }}
                    >
                      <ul className="nested-list">
                        <Link to="/Depositos" onClick={handleSubmenuClick}>

                          <li className="submenu">
                            <span className="submenu-text">Depositos</span>
                          </li>
                        </Link>
                        <Link to="/NovaMovimentacao" onClick={handleSubmenuClick}>

                          <li className="submenu">
                            <span className="submenu-text">Movimentacoes</span>
                          </li>
                        </Link><li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li><li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li><li className="submenu">
                          <span className="submenu-text">TEST</span>
                        </li>
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </ul>

            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default Sidebar;
