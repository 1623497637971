// import React, { Component } from 'react';
// import axios from 'axios';
// import $ from 'jquery';

// import { Link } from 'react-router-dom';

// import 'datatables.net-dt/js/dataTables.dataTables'; 

// import 'datatables.net-buttons/js/dataTables.buttons.js';
// import 'datatables.net-buttons/js/buttons.colVis.js';
// import 'datatables.net-buttons/js/buttons.flash.js';
// import 'datatables.net-buttons/js/buttons.html5.js';
// import 'datatables.net-buttons/js/buttons.print.js';
// import ModalDetails from './ModalDetails';
// import ModalDelete from './ModalDelete';

// class DatatablePlugin extends Component{
//     constructor(props){
//         super(props);
//         this.state = {
//             data: [],
//             selectedId: null,
//         };
//     }

//     componentDidMount() {
//         axios.get('https://localhost:7140/api/Categorias_Contas/GetCategorias')
//           .then(res => {
//             this.setState({ data: res.data });
//             $(document).ready(function () {
//               if ($.fn.DataTable.isDataTable('#example')) {
//                 $('#example').DataTable().destroy();
//               }
//               $('#example').DataTable({
//                 pagingType: 'full_numbers',
//                 pageLength: 10,
//                 processing: true,
//                 dom: 'Bfrtip',
//                 buttons: ['copy', 'csv', 'print'],
//                 language: {
//                   "sEmptyTable": "Nenhum registro encontrado",
//                   "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
//                   "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
//                   "sInfoFiltered": "(Filtrados de _MAX_ registros)",
//                   "sInfoPostFix": "",
//                   "sInfoThousands": ".",
//                   "sLengthMenu": "_MENU_ resultados por página",
//                   "sLoadingRecords": "Carregando...",
//                   "sProcessing": "Processando...",
//                   "sZeroRecords": "Nenhum registro encontrado",
//                   "sSearch": "Pesquisar",
//                   "oPaginate": {
//                     "sNext": "Próximo",
//                     "sPrevious": "Anterior",
//                     "sFirst": "Primeiro",
//                     "sLast": "Último"
//                   },
//                   "oAria": {
//                     "sSortAscending": ": Ordenar colunas de forma ascendente",
//                     "sSortDescending": ": Ordenar colunas de forma descendente"
//                   }
//                 }
//               });
//               $("#example_filter").appendTo(".dt-buttons");
//             });

//           });
//       }



//       handleDelete(id) {
//         axios.delete(`https://localhost:7140/api/Categorias_Contas/DeleteCategoria/${id}`)
//           .then(res => {
//             console.log(res);
//             console.log(res.data);

//             const updatedData = this.state.data.filter(item => item.iD_Empresa !== id);
//             this.setState({ data: updatedData });
//             window.location.reload();
//           });
//       }

//       updateData = () => {
//         axios.get('https://localhost:7140/api/Categorias_Contas/GetCategorias')
//           .then(res => {
//             this.setState({ data: res.data });
//             $('#example').DataTable().ajax.reload(); 
//           });
//       }


//       handleModalOpen(id) {
//         console.log(`Botão clicado com o ID: ${id}`);
//         this.setState({selectId: id})

//       }

//       handleModalDelete(id) {
//         console.log(`Botão clicado com o ID: ${id}`);
//         this.setState({selectId: id})
//       }




//     render() {
//         return (
//             <div className="MainDiv">
//             <div className="container p-5">
//               <table id="example" className="table table-hover dataTable table-striped w-full dtr-inline">
//                 <thead>
//                   <tr className='trTable'>
//                     <th width="1%" className='thTable'>ID</th>
//                     <th width="25%">Nome</th>
//                     <th width="3%">Despesa</th>
//                     <th width="27%">Hierarquia</th>
//                     <th width="30%">Grupo Contas/DRE</th>

//                     <th width="12%">Ação</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {this.state.data.map((result) => {
//                     return (
//                       <tr >
//                         <td>{result.iD_Categorias_Contas}</td>
//                         <td>{result.nome}</td>

//                         <td>
//                           {result.despesa ? <input type="checkbox" checked={result.despesa} /> : <input type="checkbox" />}
//                         </td>

//                         <td>{result.codHierarquico}</td>
//                         <td>{result.tipoConta}</td>

//                         <td>

//                         <Link to={`/Categorias_Contas/Edit/${result.iD_Categorias_Contas}`} 
//                         className="btn btn-info btn-icon on-default edit-row" 
//                         style={{ marginRight: '3px' }}>
//                           <i class="icon md-edit" aria-hidden="true"></i>
//                         </Link>
//                           <button
//                             className="btn btn-warning btn-icon on-default fc-row"
//                             data-toggle="modal"
//                             data-target="#exampleModalCenter"
//                             onClick={() => this.handleModalOpen(result.iD_Categorias_Contas)}
//                             style={{ marginRight: '3px' }}
//                           >
//                           <i className="icon md-card" aria-hidden="true"></i>
//                          </button>
//                          <button  
//                             className="btn btn-danger btn-icon on-default fc-row" 
//                             data-toggle="modal"
//                             data-target="#exampleModalCenterDelete"
//                             onClick={() => this.handleModalDelete(result.iD_Categorias_Contas)}
//                             style={{ marginRight: '3px' }}
//                           >
//                             <i className="icon md-delete" aria-hidden="true"></i>
//                           </button>
//                         </td>
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>



//             <ModalDetails selectId={this.state.selectId} ></ModalDetails>
//             <ModalDelete selectId={this.state.selectId} onUpdate={this.updateData}></ModalDelete>
//           </div>
//     )
//     }
// }

// export default DatatablePlugin


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';

import "../../Styles/MediaPrintContext/print.css"
import "../../Styles/DatatableStyle/tableStyle.css"


import copy from 'clipboard-copy';
import { unparse } from 'papaparse';

import ModalDetails from './ModalDetails';
import ModalDelete from './ModalDelete';
import { api_data } from '../../services/api';




function copyToClipboard(data) {
  const clipboardText = data.map(row => Object.values(row).join('\t')).join('\n');
  copy(clipboardText);
}

function downloadCSV(data) {

  const csv = unparse(data);


  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  link.download = 'data.csv';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


//No style, é possivel adicionar o estilo que vai ser feito para a tabela
function printTable(data, columns) {
  const html = `
    <html>
      <head>
        <title>Print table</title>
        <style>
         
        </style>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              ${columns.map(column => `<th>${column.Header}</th>`).join('')}
            </tr>
          </thead>
          <tbody>
            ${data.map(row => `
              <tr>
                ${columns.map(column => `<td>${row[column.accessor]}</td>`).join('')}
              </tr>
            `).join('')}
          </tbody>
        </table>
        <script>
          (function() {
            window.print();
            setTimeout(function() { window.close(); }, 500);
          })();
        </script>
      </body>
    </html>
  `;

  const printWindow = window.open('', '_blank');
  printWindow.document.write(html);
  printWindow.document.close();
}




function DatatablePlugin() {
  const [data, setData] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  const [selectId, setSelectId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaldoModalOpen, setIsSaldoModalOpen] = useState(false);
  const [isTransferenciaModalOpen, setIsTransferenciaModalOpen] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [idEmpresa, setIdEmpresa] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let result;

      result = await api_data.get('/api/Categorias_Contas/GetCategorias');


      const dataWithActionID = result.data.map(item => ({ ...item, actionID: item.iD_Categorias_Contas }));
      setData(dataWithActionID);
      setIsLoading(false);
    };

    fetchData();
  }, [idEmpresa]);



  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'iD_Categorias_Contas',
        width: '5.3%',
      },
      {
        Header: 'Nome',
        accessor: 'nome',
        width: '17%',
      },
      {
        Header: 'Despesa',
        accessor: 'despesa',
        width: '6%',
        Cell: ({ value }) => (value ? <input type="checkbox" checked={true} /> : <input type="checkbox" />)
      },
      {
        Header: 'Hierarquia',
        accessor: 'codHierarquico',
        width: '14%',
      },
      {
        Header: 'Tipo Conta',
        accessor: 'tipoConta',
        width: '14%',
      },

      {
        Header: 'Ação',
        width: '12%',
        accessor: 'actionID',
        Cell: ({ value }) => (
          <>
            <Link to={`/Categorias_Contas/Edit/${value}`}
              className="btn btn-info btn-icon on-default edit-row"
              style={{ marginRight: '3px' }}>
              <i className="icon md-edit" aria-hidden="true"></i>
            </Link>
            <button
              className="btn btn-warning btn-icon on-default fc-row"
              data-bs-toggle="modal"
              style={{ marginRight: '3px' }}
              data-bs-target="#exampleModalCenter"
              onClick={() => handleModalOpen(value)}
            >
              <i className="icon md-card" aria-hidden="true"></i>
            </button>

            <button
              className="btn btn-danger btn-icon on-default fc-row"
              data-bs-toggle="modal"
              style={{ marginRight: '3px' }}
              data-bs-target="#exampleModalCenterDelete"
              onClick={() => handleDeleteModalOpen(value)}>
              <i className="icon md-delete" aria-hidden="true"></i>
            </button>

          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    pageCount
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  function handleModalOpen(id) {
    setSelectId(id);
    setIsModalOpen(true);
  }

  function handleDeleteModalOpen(id) {
    setSelectId(id);
    setIsDeleteModalOpen(true);
  }


  function handleSaldoModalOpen(id) {
    setSelectId(id);
    setIsSaldoModalOpen(true);
  }

  function handleTransferenciaModalOpen(id) {
    setSelectId(id);
    setIsTransferenciaModalOpen(true);
  }

  function handleCompanyIdChange(e) {
    const value = e.target.value;
    setSelectedCompanyId(value === "Todos" ? null : parseInt(value));
  }

  const pagesToShow = 5;
  const pagesIndex = Math.max(Math.min(pageIndex - Math.floor(pagesToShow / 2), pageCount - pagesToShow), 0);

  return (
    <div>
      {isLoading ? (
        <div className="loadingContainer">
        <div className="spinner"></div>
        <span className="loadingText">Carregando . . .</span>
      </div>
      ) : (
        <div className="MainDiv">
          <div className="container p-5">
            <div className='topTable'>
              <div className='btn-div'>
                <button className='CopyBtn' onClick={() => copyToClipboard(data)}>Copy</button>
                <button className='csvBtn' onClick={() => downloadCSV(data)}>CSV</button>
                <button className='pdfBtn' onClick={() => printTable(data, columns)}>Print</button>
              </div>
              <input
                className='form-control input-search'
                onChange={e => setGlobalFilter(e.target.value || undefined)}
                placeholder="Procurar"
              />
            </div>
            <table {...getTableProps()} className="table table-hover dataTable table-striped w-full dtr-inline">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="trTable">
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }} className="thTable">
                        {column.render('Header')}
                        <span className="sort-indicator">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <i className="fas fa-sort-down"></i>
                              : <i className="fas fa-sort-up"></i>
                            : <i className="fas fa-sort"></i>
                          }
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} style={{ width: cell.column.width }}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='bottomTable'>
              <div>
                Mostrando de {(pageIndex * pageSize) + 1} até {(pageIndex * pageSize) + page.length} de {data.length} registros
              </div>
              <div className="pagination-div">
                <button className='btnBack btnPagination' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button className='btnBack btnPagination' onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>
                {[...Array(Math.min(pageCount, pagesToShow)).keys()].map(pageNumber => {
                  const realPageNumber = pagesIndex + pageNumber;
                  return (
                    <button
                      className={`btnGoToPage ${realPageNumber === pageIndex ? 'active' : ''}`}
                      onClick={() => gotoPage(realPageNumber)}
                      disabled={realPageNumber >= pageCount}>
                      {realPageNumber + 1}
                    </button>
                  );
                })}
                <button className='btnNext btnPagination' onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
                <button className='btnNext btnPagination' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>
              </div>
            </div>

            <ModalDetails
            selectId={selectId}
            onClose={() => setIsModalOpen(false)}

          />


          <ModalDelete
            selectId={selectId}
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          />
          </div>
        </div>
      )}
    </div>
  );
}

export default DatatablePlugin;
