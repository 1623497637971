import React from 'react';
import { Field } from 'formik';


function FormSelectLess({ colWidth, name, label, children, onChange }) {
    return (
      <div className={`form-group col-md-${colWidth}`}>
        <select className={`form-control`} onChange={onChange}>
          {children}
        </select>
        <label className='floating-label'>{label}</label>
      </div>
    );
  }
  
  export default FormSelectLess;