import React, { useEffect, useState } from 'react';
import { api, api_data } from '../../services/api'
import { useNavigate, useParams, useLocation } from "react-router-dom"
import './resSenha.css';

function ActivateConta() {
  const { UsuarioId, CodigoDeAtivacao } = useParams();
  const [usuarioId, setUsuarioId] = useState(null);
  const [codigoDeAtivacao, setCodigoDeAtivacao] = useState(null);
  const [mensagem, setMensagem] = useState('');
  const [isSucesso, setIsSucesso] = useState(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const checkmark = 'https://upload.wikimedia.org/wikipedia/commons/2/27/White_check.svg';
  const xmark = 'https://upload.wikimedia.org/wikipedia/commons/5/5f/Red_X.svg';


  useEffect(() => {
    document.body.classList.add('no-padding-top_reset-senha');

    const hash = window.location.hash;
    const parts = hash.split("&CodigoDeAtivacao=");

    if (parts.length > 1) {
      const usuarioIdParts = parts[0].split("UsuarioId=");
      if (usuarioIdParts.length > 1) {
        const usuarioIdFromUrl = usuarioIdParts[1];
        setUsuarioId(usuarioIdFromUrl);
        console.log("UsuarioId: ", usuarioIdFromUrl);
      }

      const codigoDeAtivacaoFromUrl = parts[1];
      setCodigoDeAtivacao(codigoDeAtivacaoFromUrl);
      console.log(codigoDeAtivacaoFromUrl);
    }

    return () => {
      document.body.classList.remove('no-padding-top_reset-senha');
    }
  }, []);

  useEffect(() => {
    

    if (usuarioId && codigoDeAtivacao) {
      console.log(`${api.defaults.baseURL}/ativa?UsuarioId=${usuarioId}&CodigoDeAtivacao=${codigoDeAtivacao}`);

      api.get(`/ativa?UsuarioId=${usuarioId}&CodigoDeAtivacao=${codigoDeAtivacao}`)
        .then(response => {
          setIsSucesso(true);
          setMensagem('Sua conta foi ativada com sucesso');
        })
        .catch(error => {
          setIsSucesso(false);
          setMensagem('Falha ao ativar a conta');
        });

    }
  }, [usuarioId, codigoDeAtivacao]);

  return (
    <div className="form-container_reset-senha">
      {mensagem && (
        <div className={`alerta ${isSucesso ? 'alerta-sucesso' : 'alerta-erro'}`}>
          <img src={isSucesso ? checkmark : xmark} alt="Ícone de status" className="alerta-icon" />
          <p className="alerta-texto">{mensagem}</p>
          <p style={{color: "black"}}>Por favor feche esta janela</p>

        </div>
      )}
    </div>
  );


}

export default ActivateConta;
