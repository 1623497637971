import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { api_data } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

function ModalSaldo(props) {


    const navigate = useNavigate();

    const [banco, setBanco] = useState(null);
    const [saldo, setSaldo] = useState(null);

    useEffect(() => {
        if (props.selectId) {
            fetchBankData(props.selectId);
        }
    }, [props.selectId]);

    const fetchBankData = (id) => {
        console.log(id)
        api_data
            .get(`/api/Bancos/GetBanco/${id}`)
            .then((res) => {
                setBanco(res.data);
                setSaldo(res.data.saldo);
            })
            .catch((error) => {
                console.error('Erro ao buscar o banco', error);
            });
    };

    const updateSaldo = () => {
        api_data
          .put(`/api/Bancos/UpdateSaldo/${props.selectId}`, {
            saldo: parseFloat(saldo),
          })
          .then((res) => {
            console.log('Saldo atualizado com sucesso');
      
          
            navigate(0);
          })
          .catch((error) => {
            console.error('Erro ao atualizar o saldo', error);
          });
      };
      
    

    return (
        <div
        className="modal fade"
        id="modalSaldo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalSaldoTitle"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-lg modal-success modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h3 className="modal-title" id="modalSaldoTitle">
                        Detalhes do Saldo
                    </h3>
                    <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div class="modal-body">
                        <div class="panel-body container-fluid">
                            <div class="form-group form-material row">
                                <div class="form-group col-12 col-md-3" data-plugin="formMaterial">
                                    <div class="input-group">
                                        <div class="form-control-wrap pointclick">
                                            <input class="form-control" readonly value={banco?.banco || ''} />
                                            <label class="floating-label">Banco</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 pointclick" data-plugin="formMaterial">
                                    <input class="form-control" readonly value={banco?.agencia || ''} />
                                    <label class="floating-label">Agência</label>
                                </div>

                                <div
                                    class="form-group col-12 col-md-3"
                                    contenteditable="false"
                                    data-plugin="formMaterial"
                                >
                                    <input class="form-control" readonly value={banco?.numero || ''} />
                                    <label class="floating-label">Número</label>
                                </div>
                            </div>
                            <div class="form-material row">
                                <div class="form-group col-md-6" data-plugin="formMaterial">
                                    <label>Saldo</label>
                                    {/* <input
                                        id="saldo"
                                        name="saldo"
                                        type="text"
                                        value={saldo || ''}
                                        onChange={(e) => setSaldo(e.target.value)}
                                        className="form-control"
                                    />  */}
                                    <NumberFormat
                                        value={saldo || ''}
                                        className="form-control"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        prefix="R$ "
                                        onValueChange={({ formattedValue }) => {
                                            const floatValue = parseFloat(
                                                formattedValue.replace("R$ ", "").replace(".", "").replace(",", ".")
                                            );
                                            setSaldo(floatValue);
                                        }}
                                    />



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={updateSaldo}
                        >
                            Salvar
                        </button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            Fechar
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default ModalSaldo