import React, { useState } from 'react'
import FormInModal from "../../GlobalComponents/ModalComponents/FormInModal"
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { api_data } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';



function ModalCreate(props) {

    const notify = () => {
        toast.success("Equipamento Cadastrado com Sucesso", { autoClose: 3000 });
      };

      const notifyError = () => {
        toast.error("Não foi possivel efetuar o Cadastro", { autoClose: 3000 });
      };
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('cadastro');

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    }

    const initialValues = {};

    const onSubmit = (values, { setSubmitting }) => {
        api_data.post("/api/GrupoAll/AddEquipamento", values)
          .then((response) => {
            console.log(response);
            notify();
            setTimeout(() => navigate(0), 1600); 
          })
          .catch((error) => {
            notifyError();
            console.log("error!");
            if (error.response && error.response.data) {
              console.log("Error details:", error.response.data);
            }
            console.error(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      };
      

    return (
        <div className="modal fade" id="exampleModalCreate" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-primary modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="exampleModalLongTitle">Cadastrar um Equipamento</h2>
                        <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Formik initialValues={initialValues} onSubmit={onSubmit}>
                            {({ isSubmitting, handleSubmit }) => (

                                <Form>
                                    <br></br>
                                    <div className="form-material row">


                                        <FormInModal colWidth={3} label="Código Nº Série" name="codigo_N_Serie" />
                                        <FormInModal colWidth={3} label="Nome do Equipamento" name="nomeEquipamento" />
                                        <FormInModal colWidth={3} label="Modelo" name="Modelo" />
                                        <FormInModal colWidth={3} label="Fabricante" name="Fabricante" />
                                        <FormInModal colWidth={3} label="Nº Patrimônio" name="n_Patrimonio" />
                                        <FormInModal colWidth={3} label="Nº Controle" name="n_Controle" />
                                        <FormInModal colWidth={3} label="Cliente" name="Cliente" />
                                    </div>
                                    <br></br>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                                            {isSubmitting ? "Criando..." : "Registrar o Equipamento"}
                                        </button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCreate