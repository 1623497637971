
import './App.css';
import LoginPage from './pages/LoginPage';
import AppRoutes from './AppRoutes';
import 'react-toastify/dist/ReactToastify.css';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { AuthContext, AuthProvider } from "./contexts/auth";
import { SidebarProvider } from './contexts/SidebarContext';

import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
function App() {




  return (
    <div className="app">

      <Router>
        <AuthProvider>
          <SidebarProvider>
            <AppRoutes />
          </SidebarProvider>
          <ToastContainer />
        </AuthProvider>
      </Router>

    </div>
  ); 
}

export default App;
