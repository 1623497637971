import React, { useState, useEffect } from 'react';
import FormPanel from '../../GlobalComponents/FormPanel'
import { api_data } from '../../services/api';
import { useNavigate } from "react-router-dom";
import { Modal } from 'bootstrap';


function ModalDelete(props) {
    const [banco, setCategoria] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchEmpresa() {
            console.log(props.selectId)
            setCategoria({});
            try {
                const response = await api_data.get(`/api/Bancos/GetBanco/${props.selectId}`);
                setCategoria(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchEmpresa();
    }, [props.selectId]);


    const limparEmpresa = () => {
        setCategoria({});

        console.log('cliquei')

    };

    const closeModal = () => {
        const modal = document.querySelector('#exampleModalCenterDelete');
        const modalBackdrop = document.querySelector('.modal-backdrop');

        modal.classList.remove('show');
        modal.setAttribute('aria-modal', 'false');
        modal.setAttribute('style', 'display: none');
        document.body.classList.remove('modal-open');

        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
    };

    const handleDelete = () => {
        api_data.delete(`/api/Bancos/DeleteBanco/${props.selectId}`)
            .then(response => {
                if (response.status === 200) {
                    limparEmpresa();
                    closeModal();

              
                } else {
                    console.log('Erro ao excluir Categoria');
                }
            })
            .catch(error => console.log(error));
    };



    return (
        <div><div>
            <div className="modal fade" id="exampleModalCenterDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDeleteTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-danger modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Deletar o Banco ?</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className='panel'>
                                <div className="panel">

                                    <div class="accordion" id="accordionPanelsStayOpenExample">


                                        <FormPanel id="exampleHeadingDefaultTwo"
                                            title="Informações de Endereço:" context="modal">

                                            <br></br>
                                            <br></br>

                                            <div className="form-material row">
                                                <div className="form-group col-md-2" data-plugin="formMaterial">
                                                    <input className="form-control " readOnly value={banco.banco} />
                                                    <label className="floating-label">Banco</label>

                                                </div>
                                                <div className="form-group col-md-2" data-plugin="formMaterial">
                                                    <input className="form-control " readOnly value={banco.agencia} />
                                                    <label className="floating-label">Agencia</label>
                                                </div>
                                                <div className="form-group col-md-2" data-plugin="formMaterial">
                                                    <input className="form-control " readOnly value={banco.numero} />
                                                    <label className="floating-label">Numero</label>
                                                </div>
                                                <div className="form-group col-md-3" data-plugin="formMaterial">
                                                    <input className="form-control " readOnly value={banco.saldo} />
                                                    <label className="floating-label">Saldo</label>
                                                </div>

                                                <div className="form-group col-md-3" data-plugin="formMaterial">
                                                    <input className="form-control " readOnly value={banco.iD_Empresa} />
                                                    <label className="floating-label">Empresa</label>
                                                </div>
                                            </div>


                                        </FormPanel>

                                        <FormPanel id="exampleHeadingDefaultThree" title="Observação :" context="modal">
                                            <div className='panel-body container-fluid'>
                                                <div className="form-material row">
                                                    <div className="form-group col-md-6" data-plugin="formMaterial">
                                                        <div className="panel-heading" id="exampleHeadingDefaultSeven" role="tab">
                                                            <label>Observação:</label>
                                                        </div>
                                                        <textarea className="form-control" id="textareaDefault" rows="4"></textarea>
                                                    </div>

                                                    <div className="form-group col-md-6" data-plugin="formMaterial">
                                                        <table className="table table-hover table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Usuários de Bancos</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {banco.bancos_Usuarios && banco.bancos_Usuarios.map((usuarioBanco, index) => (
                                                                    <tr key={index}>
                                                                        <td>{usuarioBanco.usuario}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormPanel>

                                    </div>


                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>

                            <button type="button" className="btn btn-primary" onClick={handleDelete}>Excluir</button>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ModalDelete