import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';



import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel';
import FormSelect from '../../GlobalComponents/FormSelect';
import FormPassword from '../../GlobalComponents/FormPassword';

import { api_data, api } from '../../services/api';

function UsuariosEdit() {


    const navigate = useNavigate();

    const [usuario, setUsuario] = useState(null);
    const { id } = useParams();
    const [empresas, setEmpresas] = useState([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);


    useEffect(() => {

        const fetchEmpresas = async () => {
            try {
                const response = await api_data.get("/api/Bancos/GetEmpresasList");
                setEmpresas(response.data);
                console.log(empresas)
            } catch (error) {
                console.error(error);
            }
        };
        fetchEmpresas();
    }, []);




    useEffect(() => {
        api.get(`/GetUsuario/${id}`)
            .then(response => setUsuario(response.data));
    }, [id]);

    if (!usuario) {
        return <div>Carregando...</div>;
    }

    const initialValues = {
        iD: id,
        email: usuario.email,
        userName: usuario.userName,
        apelido: usuario.apelido,
        dataNascimento: "2010-01-01",
        iD_Empresa: usuario.iD_Empresa,


    };

    const onSubmit = (values, { setSubmitting }) => {
        values.iD = parseInt(values.iD, 10);
        console.log('Valores enviados:', values);
        api.put(`/EditUsuario`, values)
            .then((response) => {
                console.log(response);
                navigate("/Usuarios");
                setShowErrorMessage(false);

            })
            .catch((error) => {
                console.error(error);
                setShowErrorMessage(true);

            })
            .finally(() => {
                setSubmitting(false);
            });
    };



    return (
        <div>
            <page>
                <div className="page-header">


                    <h1 className="page-title">Usuarios</h1>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a>Home</a></li>
                        <li className="breadcrumb-item"><a>Lista</a></li>
                        <li className="breadcrumb-item active">Usuarios</li>


                    </ol>
                    <div className="page-header-actions">
                        <Link to="/Usuarios">
                            <p>
                                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Usuarios</a>
                            </p>
                        </Link>
                    </div>
                </div>
                <div className="page-content container-fluid">
                    <Formik initialValues={initialValues} onSubmit={onSubmit} >
                        {({ isSubmitting }) => (
                            <Form>
                                <div class="accordion accordion-flush" id="accordionFlushExample">


                                    <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                                        title="Editar o Usuario" defaultOpen={true}>
                                        <div className="form-group form-material row">
                                            <FormInput colWidth={3} name="userName" label="Nome" />
                                            <FormInput colWidth={3} name="email" label="Email" />
                                            <FormPassword colWidth={3} name="password" label="Senha"></FormPassword>
                                            <FormPassword colWidth={3} name="rePassword" label="Digite a Senha novamente" ></FormPassword>




                                        </div>
                                        <div className="form-group form-material row">

                                            <FormInput colWidth={3} name="apelido" label="Apelido" />
                                            {/* <FormInput colWidth={3} name="dataNascimento" label="Data de Nascimento" /> */}

                                            <FormSelect colWidth={3} name="iD_Empresa" label="ID Empresa">
                                                {empresas.map((empresas) => (
                                                    <option key={empresas.ID_Empresa} value={empresas.ID_Empresa}>
                                                        {empresas.Razao_Social}
                                                    </option>
                                                ))}
                                            </FormSelect>


                                            <FormInput colWidth={3} name="dataNascimento" label="Data de Nascimento" />



                                        </div>
                                    </FormPanel>









                                    <br></br>




                                    <div className="panel">
                                        <div className="panel-body container-fluid">
                                            <div className="form-group form-material row">
                                                <div className="col-md-6"></div>
                                                <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                    {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel editar o Usuario</p>}

                                                    <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? "Salvando ..." : "Salvar"}
                                                    </button>
                                                    <Link to="/Usuarios">
                                                        <a className="btn btn-danger">
                                                            <i className="icon md-grid" aria-hidden="true"></i>
                                                            <span style={{ color: "white" }}>Cancelar e não salvar</span>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </page>

        </div>
    )
}

export default UsuariosEdit