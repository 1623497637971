import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import FormDate from "../../GlobalComponents/FormDate";
import FormInReal from "../../GlobalComponents/FormInReal";
import { estados } from '../EmpresasPage/ObjectsPage/Estados';

import { api_data } from './../../services/api';

import InputMask from 'react-input-mask';
import FormCheckbox from "../../GlobalComponents/FormCheckbox";
import produtos_page from "./produtos_page.css"
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import { NumericFormat as NumberFormat } from 'react-number-format';
import ModalPesquisarEmp from "./ModalEmpresa/ModalPesquisarEmp";
import ModalPesquisarRep from "./ModalRepresentantes/ModalPesquisarRep";



import { addMonths, format } from 'date-fns';

function CreateProdutos() {

  const navigate = useNavigate();
  const [vendedores, setVendedores] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [tabelaprecos, setTabelaprecos] = useState([]);

  const [empresas, setEmpresas] = useState([]);
  const [representantes, setRepresentantes] = useState([]);


  const [empresaId, setEmpresaId] = useState(null);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);


  const [empresaCreated, setEmpresaCreated] = useState(false);



  const [empCreated, setEmpCreated] = useState(false);
  const [listaCreated, setListaCreated] = useState(false);
  const [repCreated, setRepCreated] = useState(false);
  const [venCreated, setVenCreated] = useState(false);
  const [traCreated, setTraCreated] = useState(false);

  const [activeTab, setActiveTab] = useState('cadastro');
  const [activeTab2, setActiveTab2] = useState('enderecoCadastral');
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const currentDate = new Date();


  const [clienteData, setClienteData] = useState({
    razao_Social: '',
    nome_Fantasia: '',
  });
  const [cnpj, setCnpj] = useState('');
  const [cep, setCep] = useState('');


  const handleEmpresaIdChange = (id) => {
    setEmpresaId(id);
  };



  const fetchAndSetData = (url, setter) => {
    api_data.get(url)
      .then((response) => response.data)
      .then((data) => setter(data));
  };




  const handleInputChange = (e, index, field) => {
    const newContatos = [...contatos];
    newContatos[index][field] = e.target.value;
    setContatos(newContatos);
  };

  const [contatos, setContatos] = useState([
    {
      contato: "",
      tipo_Contato: "",
      fone_Email: "Fone Fixo",
      obs: ""
    }
  ]);

  const adicionarContato = () => {
    setContatos([
      ...contatos,
      {
        contato: "",
        tipo_Contato: "",
        fone_Email: "Fone Fixo",
        obs: ""
      }
    ]);
  };

  const initialValues = {
    iD_Empresa: null,
    Contatos: []

  };


  const convertCurrencyFieldsToNumbers = (values) => {
    const currencyFields = [
      "Preco_de_Custo",
      "Comissao_Vendedor",
      "Taxa_Montagem",
      "MVA_Lucro",
      "Preco_de_Venda",
      "Valor_ultima_compra",
      "Preco_Minimo_para_Venda"
    ];

    const convertedValues = { ...values };

    currencyFields.forEach((field) => {
      if (convertedValues[field]) {
        convertedValues[field] = parseFloat(
          convertedValues[field].replace("R$ ", "").replace(".", "").replace(",", ".")
        );
      }
    });

    return convertedValues;
  };



  const onSubmit = (values, { setSubmitting }) => {
    const convertedValues = convertCurrencyFieldsToNumbers(values);

    const payload = {
      ...convertedValues,
      Produtos_Rel: rows.map((row) => ({
        ID_Deposito: 1,
        ID_Produto: row.iD_Produto,
        Nome_Produto: row.nome_Produto,
        Quantidade: row.quantidade === "" ? null : parseInt(row.quantidade, 10),
        Valor_Unitario: row.preco_de_Custo,
        Valor_Total: row.valor_Total,
        Unidade: row.unidade_de_Estoque,
        Outros_Custos: row.outros_Custos,
        Incidir_Impostos_Pagos: row.incidir_Impostos_Pagos,

      })),

      Produtos_Inst: instRows.map((row) => ({
        ID_Produto: 1,
        Nivel_Complexidade: row.Nivel_Complexidade,
        Nivel_Prioridade: row.Nivel_Prioridade,
        Descricao: row.Descricao,
        Ordenacao_Inst: row.Ordenacao ? parseInt(row.Ordenacao, 10) : null,
      }))
    };





    api_data
      .post("/api/Produtos/AddProduto", payload)
      .then((response) => {
        console.log(response);
        notify();
        navigate("/Produtos");
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.log("error!");
        if (error.response && error.response.data) {
          console.log("Error details:", error.response.data);
        }
        console.error(error);
        notifyError();
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const notifyError = () => {
    toast.error("Não foi possível criar a categoria.");
  };

  useEffect(() => {
    api_data.get('/api/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
        setRepCreated(false);
      });
  }, [repCreated]);



  const [currentStep, setCurrentStep] = useState(1);
  const steps = ['Dados', 'Fiscal', 'Composição', 'Qualidade', 'Custos e Precificação'];
  const maxStep = steps.length;

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, maxStep));
    updateProgress(20);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
    updateProgress(-20);
  };

  const [progress, setProgress] = useState(0);

  const updateProgress = (increment) => {
    setProgress(prevProgress => Math.min(Math.max(prevProgress + increment, 0), 100));
  };

  const setProgressFromStep = (step) => {
    const stepProgressValues = [0, 10,  30, 50,70, 100];
    setProgress(stepProgressValues[step]);
  };

  const isReached = (progress, threshold) => progress >= threshold;

  const setStep = (step) => {
    setCurrentStep(step);
    setProgressFromStep(step);
  };
  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const [showTableInst, setShowTableInst] = useState(false);

  const [instRows, setInstRows] = useState([]);


  const handleButtonClickInst = (Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao) => {
    const newRow = {
      Nivel_Complexidade,
      Nivel_Prioridade,
      Descricao,
      Ordenacao: Ordenacao ? parseInt(Ordenacao, 10) : null
    };
    setInstRows(currentRows => [...currentRows, newRow]);
    setShowTableInst(true);

  };
  const notify = () => {
    toast.success("Categoria Criada com Sucesso", { autoClose: 3000 });
  };



  // useEffect(() => {
  //   const precoCusto = values.Preco_de_Custo;
  //   const mvaLucroReais = values.MVA_Lucro;
  //   const mvaLucroPercentual = values.MVA_Lucro_Porcento;
  //   const precoVenda = values.Preco_de_Venda;

  //   if(precoCusto && mvaLucroReais) {
  //     const newPrecoVenda = precoCusto + mvaLucroReais;
  //     setFieldValue('Preco_de_Venda', newPrecoVenda);
  //   }

  //   if(precoCusto && mvaLucroPercentual) {
  //     const newPrecoVenda = precoCusto + (precoCusto * (mvaLucroPercentual / 100));
  //     setFieldValue('Preco_de_Venda', newPrecoVenda);
  //   }

  //   if(precoCusto && precoVenda) {
  //     const newMvaLucroReais = precoVenda - precoCusto;
  //     const newMvaLucroPercentual = ((precoVenda - precoCusto) / precoCusto) * 100;

  //     setFieldValue('MVA_Lucro', newMvaLucroReais);
  //     setFieldValue('MVA_Lucro_Porcento', newMvaLucroPercentual);
  //   }

  // }, [values, setFieldValue]);



  const [inputValues, setInputValues] = useState({
    precoCusto: '',
    mvaLucroR: '',
    mvaLucroPercent: '',
    precoVenda: '',
  });

  const updateInputValues = (values, fieldName) => {
    const value = values.value ? parseFloat(values.value) : '';
    setInputValues((prevInputValues) => {
      let newInputValues = { ...prevInputValues, [fieldName]: value };

      if (fieldName === 'precoCusto' || fieldName === 'precoVenda') {
        if (!newInputValues.precoCusto || !newInputValues.precoVenda) {
          newInputValues.mvaLucroR = '';
          newInputValues.mvaLucroPercent = '';
        } else {
          newInputValues.mvaLucroR = newInputValues.precoVenda - newInputValues.precoCusto;
          newInputValues.mvaLucroPercent = ((newInputValues.precoVenda - newInputValues.precoCusto) / newInputValues.precoCusto) * 100;
        }
      } else if (fieldName === 'mvaLucroR') {
        if (!newInputValues.mvaLucroR || !newInputValues.precoCusto) {
          newInputValues.mvaLucroPercent = '';
          newInputValues.precoVenda = '';
        } else {
          newInputValues.precoVenda = newInputValues.precoCusto + newInputValues.mvaLucroR;
          newInputValues.mvaLucroPercent = (newInputValues.mvaLucroR / newInputValues.precoCusto) * 100;
        }
      } else if (fieldName === 'mvaLucroPercent') {
        if (!newInputValues.mvaLucroPercent || !newInputValues.precoCusto) {
          newInputValues.mvaLucroR = '';
          newInputValues.precoVenda = '';
        } else {
          newInputValues.mvaLucroR = newInputValues.precoCusto * (value / 100);
          newInputValues.precoVenda = newInputValues.precoCusto + newInputValues.mvaLucroR;
        }
      }

      return newInputValues;
    });
  };


  useEffect(() => {
    api_data.get('/api/Fornecedores/GetClientes')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);


  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState([]);

  const handleButtonClick = (Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao) => {
    setRows([...rows, { Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao }]);
    setShowTable(true);
  };


  const [selectedIDProduto, setSelectedIDProduto] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDeposito, setSelectedDeposito] = useState(null);


  useEffect(() => {
    api_data.get('/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
        if (selectedIDProduto === null && response.data.length > 0) {
          setSelectedIDProduto(response.data[0].iD_Produto);
        }
      });
  }, [selectedIDProduto]);


  const handleButtonClickProduct = (iD_Produto, quantidade) => {
    Promise.all([
      api_data.get(`/api/Produtos/GetProduto/${selectedIDProduto}`),
      api_data.get(`/api/Depositos/GetDeposito/${selectedDeposito}`)
    ]).then(([productResponse, depositoResponse]) => {
      const newEntry = {
        iD_Produto: productResponse.data.iD_Produto,
        nome_Produto: productResponse.data.nome_Produto,
        preco_de_Custo: productResponse.data.preco_de_Custo,
        unidade_de_Estoque: productResponse.data.unidade_de_Estoque,
        quantidade: quantidade,
        valor_Total: quantidade ? productResponse.data.preco_de_Custo * quantidade : productResponse.data.preco_de_Custo,
        nome_Deposito: depositoResponse.data.nome_Deposito
      };
      console.log(newEntry);
      setRows([...rows, newEntry]);
      setShowTable(true);
    });
  };



  const handleDateChange = (index, field, value) => {

    const formattedDate = value ? `${value}T00:00:00` : null;

  };

  const handleAddSelectedProducts = (newProducts) => {
    setSelectedProducts(newProducts);
  };

  const fetchSelectedProducts = async () => {
    const fetchedRows = await Promise.all(selectedProducts.map(async (id) => {
      const response = await api_data.get(`/api/Produtos/GetProduto/${id}`);
      return {
        iD_Produto: response.data.iD_Produto,
        nome_Produto: response.data.nome_Produto,
        preco_de_Custo: response.data.preco_de_Custo,
        quantidade: "",
        valor_Total: response.data.preco_de_Custo
      };
    }));

    setRows([...rows, ...fetchedRows]);
    setShowTable(true);
  };

  useEffect(() => {
    if (selectedProducts.length > 0) {
      fetchSelectedProducts();
    }
  }, [selectedProducts]);

  const handleDeleteRow = (indexToDelete) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
  };


  const handleQuantityChange = (index, newQuantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantidade = newQuantity;
    updatedRows[index].valor_Total = updatedRows[index].preco_de_Custo * newQuantity;
    setRows(updatedRows);
  };

  const [depositos, setDepositos] = useState([]);

  useEffect(() => {
    api_data.get('/api/Depositos/GetDepositos')
      .then((response) => {
        setDepositos(response.data);
      });
  }, []);


 

  return (
    <div>

      <ToastContainer />
      <page>
        <div className="page-header">
          <h1 className="page-title">Produtos</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Produtos</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/Produtos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Produtos</a>
              </p>
            </Link>
          </div>
        </div>

        <div className="page-content container-fluid">
          <div className="page-content container-fluid">


            <div className="container">
              <div className="container mt-6">
                <div className="progress position-relative">
                <div
                    className={`progress-bar bg-success`}
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div className="icon-wrapper">
                    <div
                      className={`progress-icon ${isReached(progress, 20) ? 'reached' : ''}`}
                      style={{ left: '10%', cursor: 'pointer' }}
                      onClick={() => setStep(1)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label">Dados</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 40) ? 'reached' : ''}`}
                      style={{ left: '30%', cursor: 'pointer' }}
                      onClick={() => setStep(2)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label">Precificação</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 60) ? 'reached' : ''}`}
                      style={{ left: '50%', cursor: 'pointer' }}
                      onClick={() => setStep(3)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label" style={{ left: '-10px' }}>Composição</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 80) ? 'reached' : ''}`}
                      style={{ left: '70%', cursor: 'pointer' }}
                      onClick={() => setStep(4)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label" style={{ left: '-4px' }}>Fiscal</div>
                    </div>
                    <div
                      className={`progress-icon ${isReached(progress, 100) ? 'reached' : ''}`}
                      style={{ left: '90%', cursor: 'pointer' }}
                      onClick={() => setStep(5)}
                    >
                      <i className="fa fa-check"></i>
                      <div className="icon-label" style={{ left: '1px' }}>Finalizando</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>


          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, handleChange, setFieldValue, values }) => (

              <Form>


                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <div className='panel'>
                    <div className="panel-body container-fluid">





                      {currentStep === 1 && <div>
                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>


                          <div className="form-group form-material row">

                            <div className="form-group col-md-3" data-plugin="formMaterial">
                              <Field name="Preco_de_Custo">
                                {({ field }) => (
                                  <NumberFormat className='form-control' {...field} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} value={inputValues.precoCusto} onValueChange={(values) => updateInputValues(values, 'precoCusto')} />
                                )}
                              </Field>
                              <label className='floating-label'>Preço de Custo (R$)</label>
                            </div>

                            <div className="form-group col-md-3" data-plugin="formMaterial">
                              <Field name="mvaLucroR">
                                {({ field }) => (
                                  <NumberFormat className='form-control' {...field} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} value={inputValues.mvaLucroR} onValueChange={(values) => updateInputValues(values, 'mvaLucroR')} />
                                )}
                              </Field>
                              <label className='floating-label'>(MVA) Lucro R$</label>
                            </div>

                            <div className="form-group col-md-3" data-plugin="formMaterial">
                              <Field name="mvaLucroPercent">
                                {({ field }) => (
                                  <NumberFormat className='form-control' {...field} suffix={'%'} value={inputValues.mvaLucroPercent} onValueChange={(values) => updateInputValues(values, 'mvaLucroPercent')} />
                                )}
                              </Field>
                              <label className='floating-label'>(MVA) Lucro % Fixado</label>
                            </div>

                            <div className="form-group col-md-3" data-plugin="formMaterial">
                              <Field name="precoVenda">
                                {({ field }) => (
                                  <NumberFormat className='form-control' {...field} decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator={','} prefix={'R$ '} value={inputValues.precoVenda} onValueChange={(values) => updateInputValues(values, 'precoVenda')} />
                                )}
                              </Field>
                              <label className='floating-label'>Preço de Venda (R$)</label>
                            </div>




                            <FormInput colWidth={3} name="Nome_Produto" label="Nome do Produto" >

                            </FormInput>

                            <div className="form-group col-md-3">
                              <div className="input-group">

                                <Field name="Fornecedor_Padrao">
                                  {({ field, meta }) => (
                                    <>
                                      <select
                                        {...field}
                                        className="form-control form-select form-control-label-sm mb-2"
                                      >
                                        {empresas.map((empresas) => (
                                          <option key={empresas.id} value={empresas.id}>
                                            {empresas.razao_Social}
                                          </option>
                                        ))}

                                      </select>
                                      <label className='floating-label'>Fornecedor Padrão
                                      </label>


                                    </>
                                  )}
                                </Field>
                                <span className="input-group-append">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalEmpPesquisar"

                                    className="btn btn-icon btn-default"
                                    style={{ height: '40px', display: 'inline-block' }}

                                  >
                                    <i className="icon md-search" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                            </div>

                            <FormSelect colWidth={3} name="Tipo_do_Produto" label="Tipo do Produto" >

                            </FormSelect>
                            <FormInput colWidth={3} name="Marca" label="Marca" />
                            <FormInput colWidth={2} name="Codigo_do_Sistema" label="Código do Sistema" />
                            <FormInput colWidth={2} name="codigo_Fornecedor_Padrao" label="Código Fornecedor Padrão" />
                            <FormInput colWidth={2} name="Codigo_do_Produto_SKU" label="Código Produto (SKU)" />

                            <FormSelect colWidth={3} name="Tipo_Genero" label="Tipo/Gênero" >

                              <option value="00 - Mercadoria para Revenda">00 - Mercadoria para Revenda</option>
                              <option value="01 - Matéria-Prima">01 - Matéria-Prima</option>
                              <option value="02 - Embalagem">02 - Embalagem</option>
                              <option value="03 - Produto em Processo">03 - Produto em Processo</option>
                              <option ng-selected="produto.Tipo > 2" value="04 - Produto Acabado">04 - Produto Acabado</option>
                              <option value="05 - Subproduto">05 - Subproduto</option>
                              <option value="06 - Produto Intermediário">06 - Produto Intermediário</option>
                              <option value="07 - Material de Uso e Consumo">07 - Material de Uso e Consumo</option>
                              <option value="08 - Ativo Imobilizado">08 - Ativo Imobilizado</option>
                              <option ng-hide="licencanfe" value="09 - Serviços" class="">09 - Serviços</option>
                              <option value="10 - Outros insumos">10 - Outros insumos</option>
                              <option value="99 - Outras">99 - Outras</option>
                            </FormSelect>

                            <FormInput colWidth={3} name="Modelo" label="Modelo" />
                            <FormInput colWidth={3} name="Categorias" label="Categorias" />
                            <FormCheckbox colWidth={3} name="Ocultar_Nas_Vendas" label="Ocultar nas Vendas" />
                            <FormCheckbox colWidth={3} name="Visivel_no_Catalogo" label="Visível no Catálogo" />
                            <FormCheckbox colWidth={3} name="Cadastro_Inativo" label="Cadastro Inativo" />
                            <FormInput colWidth={3} name="Validade" label="Validade" />
                            <FormSelect colWidth={3} name="Produto_e_vendido_por" label="Produto é vendido por" >

                            </FormSelect>



                          </div>
                          <br></br>





                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="observacoes" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Informacoes Adicionais</label>
                            </div>
                          </div>


                      




                        </motion.div>
                      </div>
                      }






                      {currentStep === 2 && <div>
                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>

                          <div className="form-group form-material row">

                            <FormInReal colWidth={3} name="Preco_de_Custo" label="Preço de Custo (R$)" />
                            <FormInput colWidth={3} name="Desp_Operacionais" label="Desp. Operacionais (%)" numero={true} />
                            <FormInput colWidth={3} name="Frete_Pago_na_Compra" label="Frete pago na Compra (%) " numero={true} />
                            <FormInput colWidth={3} name="Seguro_pago_na_Compra" label="Seguro pago na Compra (%) " numero={true} />

                            <FormInput colWidth={3} name="ipi" label="IPI (%)" numero={true} />
                            <FormInput colWidth={3} name="ICMS" label="ICMS (%)" numero={true} />
                            <FormInput colWidth={3} name="ICMS_ST" label="ICMS ST(%)" numero={true} />
                            <FormInput colWidth={3} name="FCP_ST" label="FCP ST (%)" numero={true} />
                            <FormInReal colWidth={6} name="Preco_de_custo_com_Acrescimos" label="Preço de custo de Acréscimos" />
                            <FormInput colWidth={6} name="Comissao_Representacao" label="Comissão Representação (%) " numero={true} />
                            <FormInReal colWidth={6} name="Preco_Minimo_para_Venda" label="Preço Mínimo Para Venda (R$)" />
                            <FormInReal colWidth={3} name="Comissao_Vendedor" label="Comissão Vendedor (R$)" />
                            <FormInput colWidth={3} name="Comissao_Vendedor_Porcento" label="Comissão Vendedor (%) " numero={true} />
                            <FormInReal colWidth={3} name="Taxa_Montagem" label="Taxa Montagem (R$)" />
                            <FormInput colWidth={3} name="Taxa_Montagem_Porcento" label="Taxa Montagem (%) " numero={true} />
                            <FormInReal colWidth={3} name="MVA_Lucro" label="(MVA) Lucro R$" />
                            <FormInput colWidth={3} name="MVA_Lucro_Porcento" label="(MVA) Lucro % " numero={true} />
                            <FormInReal colWidth={3} name="Preco_de_Venda" label="Preço de Venda (R$) " />
                            <FormInReal colWidth={3} name="Valor_ultima_compra" label="Valor última compra (R$) " />




                          </div>
                          <br></br>
                          <br></br>
                          <br></br>

                          <div className="form-group form-material row">
                            <FormInput colWidth={2} name="Plano_de_Conta" label="Plano de Conta" />

                            <FormCheckbox colWidth={2} name="Nao_Emitir_Alertas" label="Não emitir alertas e permitir vendas com estoque negativo " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Cada_Unidade_Numero_Serie" label="Cada unidade do produto possui um número de série " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Permitir_Qtd_Fracionada" label="Permitir Quantidade Fracionada no Loja Online " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Produto_Controlado_Lote" label="Produto Controlado por Lote " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Venda_Metragem" label="Venda por metragem quadrada (m²) " ></FormCheckbox>

                          </div>

                        </motion.div>

                      </div>}
                      {currentStep === 3 && <div>
                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>

                       

                          <div className="form-group form-material row">
                            <FormSelect
                              colWidth={2}
                              name="Marca"
                              label="Localizar Deposito"
                              value={selectedDeposito}
                              onChange={(e) => setSelectedDeposito(e.target.value)}
                            >
                              {depositos.map((deposito) => (
                                <option key={deposito.iD_Deposito} value={deposito.iD_Deposito}>
                                  {deposito.nome_Deposito}
                                </option>
                              ))}
                            </FormSelect>

                            <FormSelect colWidth={3} name="iD_Produto_fs" label="Produto" value={selectedIDProduto} onChange={(e) => setSelectedIDProduto(e.target.value)}>
                              {representantes.map((representante) => (
                                <option key={representante.iD_Produto} value={representante.iD_Produto}>
                                  {representante.nome_Produto}
                                </option>
                              ))}
                            </FormSelect>
                            <FormInput colWidth={1} name="quantidade_fs" label="Quantidade" numero={true} />


                            <div class="col-md-1">
                              <span class="input-group-append">
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#ModalEmpPesquisar"
                                  class="btn btn-icon btn-default"
                                  style={{ height: '40px', display: 'inline-block' }}
                                >
                                  <i class="icon md-search" aria-hidden="true"></i>
                                </a>
                              </span>
                            </div>
                            <div className="form-group col-md-1">

                              <div className="col-md-1">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleButtonClickProduct(values.iD_Produto_fs, values.quantidade_fs)}
                                >
                                  Adicionar
                                </button>
                              </div>

                            </div>
                            <div className="form-group col-md-1">
                            </div>



                          </div>


                          <div className="form-group form-material row">



                            {showTable && (

                              <table>
                                <thead>
                                  <tr>
                                    <th>Deposito</th>
                                    <th>ID Produto</th>
                                    <th>Nome Produto</th>
                                    <th>Quantidade</th>
                                    <th>Valor Unitario (R$)</th>
                                    <th>Valor Total</th>
                                    <th>Unidade</th>
                                    <th>Outros Custos</th>
                                    <th>Incidir Impostos Pagos ?</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.nome_Deposito}</td>
                                      <td>{row.iD_Produto}</td>
                                      <td>{row.nome_Produto}</td>
                                      <td>
                                        <input
                                          type="number"
                                          value={row.quantidade}
                                          onChange={(e) => handleQuantityChange(index, e.target.value)}
                                        />
                                      </td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.preco_de_Custo)}</td>
                                      <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_Total)}</td>
                                      <td>{row.unidade_de_Estoque}</td>
                                      <td>
                                        <input type="number" />
                                      </td>
                                      <td>
                                        <input type="checkbox"></input>
                                      </td>
                                      <td>
                                        <span className='btn btn-danger' onClick={() => handleDeleteRow(index)}>Deletar</span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <br></br>
                                <br></br>
                              </table>


                            )}
                            <div className="form-group col-md-1">
                            </div>

                            <FormCheckbox colWidth={2} name="Produto_Controlado_Lote" label="Produto Controlado por Lote " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Venda_Metragem" label="Venda por metragem quadrada (m²) " ></FormCheckbox>


                          </div>
                          <br></br>
                          <br></br>
                          <div className="form-group form-material row">

                            <FormInput colWidth={3} name="Estoque_para_Compra" label="Estoque Para compra" numero={true} />
                            <FormInput colWidth={3} name="Peso_Bruto" label="Peso Bruto (KG) " numero={true} />
                            <FormInput colWidth={3} name="Peso_Liquido" label="Peso Líquido (KG)" numero={true} />
                            <FormInput colWidth={3} name="Unidade_de_Estoque" label="Unidade de Estoque" />
                            <FormInput colWidth={3} name="Numero_de_Serie" label="Número de Série" />
                            <FormInput colWidth={3} name="Prateleira" label="Prateleira" />
                            <FormInput colWidth={3} name="Tamanho_Dimensoes" label="Tamanho/Dimensões" />



                          </div>

                          <br></br>
                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="Descricao" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Passo a Passo Produção</label>
                            </div>
                          </div>
                          <br></br>

                          <div className="form-group form-material row">
                            <div className="form-group col-md-1">
                            </div>
                            <FormCheckbox colWidth={2} name="Nao_Emitir_Alertas" label="Não emitir alertas e permitir vendas com estoque negativo " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Cada_Unidade_Numero_Serie" label="Cada unidade do produto possui um número de série " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Permitir_Qtd_Fracionada" label="Permitir Quantidade Fracionada no Loja Online " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Produto_Controlado_Lote" label="Produto Controlado por Lote " ></FormCheckbox>
                            <FormCheckbox colWidth={2} name="Venda_Metragem" label="Venda por metragem quadrada (m²) " ></FormCheckbox>
                          </div>

                        </motion.div>


                      </div>}
                      {currentStep === 4 && <div>

                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>


                          <div className="form-group form-material row">
                            <FormInput colWidth={2} name="Grupo_Tributario" label="Grupo Tributario" />
                            <FormInput colWidth={2} name="CFOP_Padrao" label="CFOP Padrão" />
                            <FormInput colWidth={2} name="Codigo_NCM" label="Código NCM" />
                            <FormInput colWidth={2} name="Codigo_CEST" label="Código CEST" />
                            <FormInput colWidth={2} name="ean" label="EAN - Código de Barras" />
                            <FormInput colWidth={2} name="Unidade_Comercial" label="Unidade Comercial" />
                            <FormSelect colWidth={2} name="Origem_da_Mercadoria" label="Origem da Mercadoria" />
                            <FormInput colWidth={2} name="Fabricante" label="Fabricante" />
                            <FormInput colWidth={2} name="Codigo_de_Beneficio_Fiscal" label="Código de Beneficio Fiscal" />
                            <FormSelect colWidth={2} name="Situacao_Tributaria_IPI" label="Situação Tributária IPI" />
                            <FormSelect colWidth={2} name="EX_TIPI" label="EX TIPI" numero={true} />



                          </div>

                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="Informacoes_Adicionais_NFe" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Informações Adicionais para NFe</label>
                            </div>
                          </div>


                        </motion.div>

                      </div>}
                      {currentStep === 5 && <div>

                        <motion.div initial="initial" animate="animate" exit="exit" variants={fadeIn}>
                          <div className="form-group form-material row">


                            <FormInReal colWidth={3} name="Preco_de_Custo" label="Preço de Custo (R$)" readOnly={true} />
                            <FormInput colWidth={3} name="MVA_Lucro" label="(MVA) Lucro R$" readOnly={true} />
                            <FormInput colWidth={3} name="MVA_Lucro_Porcento" label="(MVA) Lucro %" readOnly={true} />
                            <FormInput colWidth={3} name="Preco_de_Venda" label="Preço de Venda (R$)" readOnly={true} />

                          </div>

                          <div className="form-group form-material row">
                            <div className='form-group col-12 col-md-12'>
                              <Field name="observacoes" as="textarea" rows="3" className='form-control'></Field>
                              <label className='floating-label'>Observações</label>
                            </div>
                          </div>

                          <div className="form-group form-material row">

                            <FormSelect colWidth={2} name="Nivel_Complexidade" label="Nível Complexidade">
                              <option value="baixo">Baixo</option>
                              <option value="medio">Médio</option>
                              <option value="alto">Alto</option>
                            </FormSelect>

                            <FormSelect colWidth={2} name="Nivel_Prioridade" label="Nível Prioridade">
                              <option value="baixa">Baixa</option>
                              <option value="media">Média</option>
                              <option value="alta">Alta</option>
                            </FormSelect>

                            <FormInput colWidth={3} name="Descricao" label="Descrição" />
                            <FormInput colWidth={3} name="Ordenacao" label="Ordenação" />
                            <div className="col-md-1">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleButtonClickInst(values.Nivel_Complexidade, values.Nivel_Prioridade, values.Descricao, values.Ordenacao)}
                              >
                                Adicionar
                              </button>
                            </div>

                            {showTableInst && (
                              <table>
                                <thead>
                                  <tr>
                                    <th style={{ width: "25%" }}>Nível Complexidade</th>
                                    <th style={{ width: "25%" }}>Nível Prioridade</th>
                                    <th style={{ width: "25%" }}>Descrição</th>
                                    <th style={{ width: "25%" }}>Ordenação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {instRows.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.Nivel_Complexidade}</td>
                                      <td>{row.Nivel_Prioridade}</td>
                                      <td>{row.Descricao}</td>
                                      <td>{row.Ordenacao}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}

                          </div>




                        </motion.div>
                      </div>

                      }



                    </div>
                  </div>





                </div>

                <br></br>

                <div className="panel">
                  <div className="panel-body container-fluid">
                    <div className="form-group form-material row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">

                        {currentStep === 5 ? (
                          <div>
                            <span className='btn-secondary btn' style={{ marginRight: '10px' }} onClick={() => setStep(currentStep - 1)}>
                              Retornar
                            </span>
                            <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit">
                              {isSubmitting ? "Criando ..." : "Criar o Cliente"}
                            </button>
                          </div>
                        ) : (
                          <>
                            {currentStep > 1 &&
                              <span className='btn-secondary btn' style={{ marginRight: '10px' }} onClick={() => setStep(currentStep - 1)}>
                                Retornar
                              </span>
                            }
                            <span className='btn-primary btn' style={{ marginRight: '20px' }} onClick={() => setStep(currentStep + 1)}>
                              Avançar
                            </span>
                          </>
                        )}

                        <Link to="/Fornecedores" style={{ marginLeft: '20px' }}>
                          <a className="btn btn-danger">
                            <i className="icon md-grid" aria-hidden="true"></i>
                            <span style={{ color: "white" }}>Cancelar e não salvar</span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>






                <ModalPesquisarEmp onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />
                <ModalPesquisarRep onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />




              </Form>
            )}
          </Formik>
        </div>
      </page>



    </div>
  )
}

export default CreateProdutos


