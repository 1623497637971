import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_data } from '../../services/api';




function ModalDetails(props) {
    const navigate = useNavigate();

    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});
            console.log(props.selectId)
            const response = await api_data.get(`/api/GrupoAll/Grupos_Pedido/${props.selectId}`);
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };


    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };


    const handleEdit = () => {

        handleCloseModal();
        navigate(`/CentroDeCustos/Edit/${props.selectId}`);
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Grupo Pedidos</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                    <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.iD_Grupos_Pedido} name="iD_Grupos_Pedido" />
                                        <label className='floating-label'>ID Grupo Pedidos</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.nome} name="nome" />
                                        <label className='floating-label'>Nome</label>
                                    </div>
                                    <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input type="checkbox"
                                            className='form-check-input'
                                            id="movimentaEstoque"
                                            checked={categoria.movimenta_Estoque === true}
                                            onChange={() => { }}
                                            name="movimenta_Estoque" />
                                        <label className='form-check-label' htmlFor="movimentaEstoque" style={{ marginLeft: '20px' }}>Movimenta Estoque</label>
                                    </div>
                                    <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input type="checkbox"
                                            className='form-check-input'
                                            id="movimentaFinanceiro"
                                            checked={categoria.movimenta_Financeiro === true}
                                            onChange={() => { }}
                                            name="movimenta_Financeiro" />
                                        <label className='form-check-label' htmlFor="movimentaFinanceiro" style={{ marginLeft: '20px' }}>Movimenta Financeiro</label>
                                    </div>
                                    <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input type="checkbox"
                                            className='form-check-input'
                                            id="visivelNasOperacoesFiscais"
                                            checked={categoria.visivel_nas_Operacoes_Fiscais === true}
                                            onChange={() => { }}
                                            name="visivel_nas_Operacoes_Fiscais" />
                                        <label className='form-check-label' htmlFor="visivelNasOperacoesFiscais" style={{ marginLeft: '20px' }}>Visível nas Operações Fiscais</label>
                                    </div>



                                </div>

                            </div>





                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails