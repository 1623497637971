import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const FinanceiroInd = () => {
    // Exemplo de dados para os gráficos pie
    const data1 = [
        { name: 'Group A', value: 7 },
        { name: 'Group B', value: 5 },
    ];

    const data2 = [
        { name: 'Group A', value: 6 },
        { name: 'Group B', value: 1 },
    ];

    const data3 = [
        { name: 'Group A', value: 1 },
        { name: 'Group B', value: 6 },
    ];

    const data4 = [
        { name: 'Group A', value: 2 },
        { name: 'Group B', value: 9 },
    ];

    const data5 = [
        { name: 'Group A', value: 1 },
        { name: 'Group B', value: 10 },
    ];

    const COLORS1 = ['#0088FE', '#00C49F'];
    const COLORS2 = ['#A7E366', '#E3D466'];
    const COLORS3 = ['#E3B754', '#E66457'];
    const COLORS4 = ['#8181E6', '#B5D8FA'];
    const COLORS5 = ['#E3AC86', '#D5ADFF'];



    return (
        <div className="panel" id="projects">
            <div className="panel-heading">
                <h3 className="panel-title">Financeiro</h3>
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>Contas</td>
                            <td>Status</td>
                            <td>Data</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Despesas Diversas</td>
                            <td>
                                <ResponsiveContainer width="65%" height={30}>
                                    <PieChart>
                                        <Pie
                                            data={data1}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={15}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data1.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS1.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </td>
                            <td>Mar 1, 2020</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Edit">
                                    <i className="icon md-wrench" aria-hidden="true"></i>
                                </button>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Delete">
                                    <i className="icon md-close" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Receitas de Cartão de Débito</td>
                            <td>
                                <ResponsiveContainer width="65%" height={30}>
                                    <PieChart>
                                        <Pie
                                            data={data2}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={15}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data2.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </td>
                            <td>Feb 12, 2020</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Edit">
                                    <i className="icon md-wrench" aria-hidden="true"></i>
                                </button>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Delete">
                                    <i className="icon md-close" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Conta de Luz</td>
                            <td>
                                <ResponsiveContainer width="65%" height={30}>
                                    <PieChart>
                                        <Pie
                                            data={data3}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={15}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data3.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS3[index % COLORS3.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </td>
                            <td>Apr 9, 2020</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Edit">
                                    <i className="icon md-wrench" aria-hidden="true"></i>
                                </button>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Delete">
                                    <i className="icon md-close" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Créditos Diversos</td>
                            <td>
                                <ResponsiveContainer width="65%" height={30}>
                                    <PieChart>
                                        <Pie
                                            data={data4}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={15}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data4.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS4[index % COLORS4.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </td>
                            <td>May 15, 2020</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Edit">
                                    <i className="icon md-wrench" aria-hidden="true"></i>
                                </button>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Delete">
                                    <i className="icon md-close" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Pagamento de Salários</td>
                            <td>
                                <ResponsiveContainer width="65%" height={30}>
                                    <PieChart>
                                        <Pie
                                            data={data5}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={15}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data5.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS5[index % COLORS5.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </td>
                            <td>Jun 2, 2020</td>
                            <td>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Edit">
                                    <i className="icon md-wrench" aria-hidden="true"></i>
                                </button>
                                <button type="button" className="btn btn-sm btn-icon btn-pure btn-default"
                                    data-toggle="tooltip" data-original-title="Delete">
                                    <i className="icon md-close" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FinanceiroInd;
