import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import FormInReal from '../../GlobalComponents/FormInReal';

import ModalRemoveUser from './ModalRemoveUser';

import { api_data, api } from './../../services/api';


function CreateBancos() {


  const navigate = useNavigate();
  const [created, setCreated] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [authorizedUsers, setAuthorizedUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [userToRemove, setUserToRemove] = React.useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const initialValues = {

    Bancos_Usuarios: []

  };

  const handleOpenModal = (userId) => {
    const user = authorizedUsers.find(user => user.id === userId);
    setUserToRemove(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setUserToRemove(null);
    setShowModal(false);
  };

  const handleRemoveUserWithModal = (userId) => {
    handleRemoveUser(userId);
    handleCloseModal();
  };


  useEffect(() => {
    api_data.get('/api/Bancos/GetEmpresasList')
      .then((response) => {
        setEmpresas(response.data);
      });
  }, []);

  useEffect(() => {
    api.get('/GetUsuariosList')
    .then((response) => {
      setUsers(response.data);
    });
  }, []);


  const onSubmit = (values, { setSubmitting }) => {


    if (values.saldo) {
      values.saldo = parseFloat(
        values.saldo
          .toString()
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", ".")
      );
    } else {
      values.saldo = 0;
    }

    values.Bancos_Usuarios = authorizedUsers.map((user) => ({
      ID_Bancos_Usuarios: 0,
      Usuario: user.name,
      ID_Usuario: user.id,
      ID_Banco: 0
    }));



    api_data
      .post("/api/Bancos/AddBanco", values)
      .then((response) => {
        console.log(response);
        navigate("/Bancos");
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.error(error);
        console.log(values.saldo);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSelectChange = (e) => {
    setSelectedUser(e.target.value);
  };


  const handleAuthorizeUser = () => {
    if (selectedUser) {
      const [id, name] = selectedUser.split(',');
      const userId = parseInt(id);


      const userExists = authorizedUsers.some(user => user.id === userId);

      if (userExists) {

        alert('Não é possível adicionar um usuário que já está na listaa');
      } else {

        setAuthorizedUsers([...authorizedUsers, { id: userId, name }]);
      }
    }
  };


  const handleRemoveUser = (userId) => {
    setAuthorizedUsers(authorizedUsers.filter(user => user.id !== userId));
  };


  return (
    <div>
      <page>
        <div className="page-header">
          <h1 className="page-title">Bancos</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Bancos</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/Bancos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Bancos</a>
              </p>
            </Link>
          </div>
        </div>

        <Formik initialValues={initialValues} onSubmit={onSubmit}
          validate={(values) => {
            const errors = {};
            if (!values.banco || !values.agencia) {
              setShowErrorMessage(true);
            } else {
              setShowErrorMessage(false);
            }

            if (!values.banco) {
              errors.banco = 'Campo obrigatório';
            }
            if (!values.agencia) {
              errors.agencia = 'Campo obrigatório';
            }
            return errors;
          }}

        >
          {({ isSubmitting }) => (
            <Form>
              <div class="accordion accordion-flush" id="accordionFlushExample">


                <FormPanel id="exampleHeadingDefaultFive"
                  title="Informações do Bancos:" defaultOpen={true}>
                  <div className="form-group form-material row">

                    <FormInput colWidth={2} name="banco" label="Banco" />
                    <FormInput colWidth={2} name="agencia" label="Agencia" />
                    <FormInput colWidth={2} name="numero" label="Numero" />
                    <FormInReal colWidth={3} name="saldo" label="Saldo" />


                    <FormSelect colWidth={3} name="iD_Empresa" label="ID Empresa">
                      {empresas.map((item) => (
                        <option key={item.ID_Empresa} value={item.ID_Empresa}>
                          {item.Razao_Social}
                        </option>
                      ))}
                    </FormSelect>
                  </div>
                  <div className="form-group form-material row">
                  </div>
                </FormPanel>
                <FormPanel id="exampleHeadingDefaultOne" href="#exampleCollapseDefaultOne" ariaControls="exampleCollapseDefaultOne"
                  title="Informacoes de usuario" defaultOpen={true}>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <div className="panel-heading" id="exampleHeadingDefaultSeven" role="tab">
                        <a className='panel-title' data-toggle="collapse" href='#exampleCollapseDefaultSeven'
                          data-parent="#exampleAccordionDefault" aria-expanded="false" aria-controls='exampleCollapseDefaultSeven'
                        ><h5><i>Observacoes</i></h5></a>
                      </div>
                      <div className="panel-collapse collapse show" id="exampleCollapseDefaultSeven" aria-labelledby='exampleHeadingDefaultSeven'
                        role="tabpanel">
                        <div className="panel-body container-fluid">
                          <div className="form-group row">
                            <textarea className='form-control' id='textareaDefault' rows="8"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-md-6' data-plugin="formMaterial">
                      <div className='row'>
                        <div className='col-md-2'>
                          Selecione
                        </div>
                        <div className='col-md-6'>
                          <select className='form-control aria-label' onChange={handleSelectChange}>
                            <option value="">Selecione um usuário</option>
                            {users.map((usuario) => (
                              <option key={usuario.id} value={`${usuario.id},${usuario.userName}`}>
                                {usuario.userName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-6">
                            <a className='btn btn-primary text-white' onClick={handleAuthorizeUser}>
                              <i className='icon md-plus '></i> Autorizar Usuario
                            </a>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div className="form-group col-md-12" data-plugin="formMaterial">
                        <div className="table-resposive">
                          <table className='table table-hover dataTable table-striped w-full' id='dtUsuarios'>
                            <thead>
                              <th width="1%" >ID</th>
                              <th width="69%" >Usuarios Autorizados</th>
                              <th width="30%" >Remover</th>
                            </thead>
                            <tbody>
                              {authorizedUsers.map(user => (
                                <tr key={user.id}>
                                  <td>{user.id}</td>
                                  <td>{user.name}</td>
                                  <td>
                                    <a onClick={() => handleOpenModal(user.id)} className='btn btn-danger text-white'>remover</a>

                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </FormPanel>
                <br></br>
                <div className="panel">
                  <div className="panel-body container-fluid">
                    <div className="form-group form-material row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                        {showErrorMessage && <p style={{ marginRight: '20px', color: 'red' }}>Não foi possivel criar o Banco</p>}
                        <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                          {isSubmitting ? "Criando ..." : "Criar o banco"}
                        </button>
                        <Link to="/Bancos">
                          <a className="btn btn-danger">
                            <i className="icon md-grid" aria-hidden="true"></i>
                            <span style={{ color: "white" }}>Cancelar e não salvar</span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </Form>
          )}
        </Formik>

      </page>


      <ModalRemoveUser
        user={userToRemove}
        show={showModal}
        onClose={handleCloseModal}
        onRemove={() => handleRemoveUserWithModal(userToRemove.id)}
      />


    </div>
  )
}

export default CreateBancos