import React from 'react'
import DatatablePlugin from './DatatablePlugin'
import { Link } from "react-router-dom";

function Clientes() {
  return (
    <div>
    <div>
    
    <page>
      <div class="page-header">
        <h1 class="page-title">Clientes</h1>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a asp-controller="Home" asp-action="Index">Home</a></li>

          <li class="breadcrumb-item active">Categorias</li>
        </ol>
        <div class="page-header-actions">

          <p>
          <Link to="/Clientes/Create">
            <a class="btn btn-success" style={{ color: 'white' }}><i class="fa fa-plus-circle"></i>&nbsp;&nbsp; Registrar um novo Cliente</a>
          </Link>
          </p>
        </div>
      </div>
      <div className="panel">
        <header className="panel-heading">

        </header>


        <DatatablePlugin></DatatablePlugin>

      

      </div>
      <br></br>
      <br></br>

    </page>
  </div>
 </div>
  )
}

export default Clientes