import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';



import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel';
import FormSelect from '../../GlobalComponents/FormSelect';
import FormInReal from '../../GlobalComponents/FormInReal';

import ModalRemoveUser from './ModalRemoveUser';
import ModalDeleteUser from './ModalDeleteUser';

import { api_data } from '../../services/api';


function EditBancos() {

  const navigate = useNavigate();
  const [banco, setBanco] = useState(null);
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [empresas, setEmpresas] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const { id } = useParams();

  useEffect(() => {
    fetch('https://localhost:7012/GetUsuariosList')
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    api_data.get('/api/Bancos/GetEmpresasList')
      .then((response) => {
        setEmpresas(response.data);
      });
  }, []);

  useEffect(() => {
    api_data.get(`/api/Bancos/GetBanco/${id}`)
      .then(response => {
        console.log("Dados recebidos:", response.data);
        setBanco(response.data);
        setAuthorizedUsers(response.data.bancos_Usuarios.map(user => ({
          id: user.iD_Bancos_Usuarios,
          iD_Bancos_Usuarios: user.iD_Bancos_Usuarios,
          ID_Usuario: user.iD_Usuario,
          Usuario: user.usuario,
          existing: true,
        })));
      });
  }, [id]);

  if (!banco) {
    return <div>Carregando...</div>;
  }

  const initialValues = {
    iD_Banco: id,
    banco: banco.banco,
    agencia: banco.agencia,
    numero: banco.numero,
    saldo: banco.saldo,
    descricao: banco.descricao,
    iD_Empresa: banco.iD_Empresa,
    Bancos_Usuarios: authorizedUsers,
  };

  const onSubmit = (values, { setSubmitting }) => {

    if (values.saldo) {
      values.saldo = parseFloat(
        values.saldo
          .toString()
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", ".")
      );
    } else {
      values.saldo = 0;
    }

    const requestBody = {
      ...values,
      Bancos_Usuarios: authorizedUsers.map(user => ({
        ...user,
        iD_Bancos_Usuarios: user.existing ? user.iD_Bancos_Usuarios : undefined,
      })),
    };


    api_data.put(`/api/Bancos/UpdateBanco/${id}`, requestBody)
      .then((response) => {
        console.log(response);
        navigate("/Bancos")
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };



  const handleOpenModal = (userId) => {
    const user = authorizedUsers.find(user => user.id === userId);
    setUserToRemove(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setUserToRemove(null);
    setShowModal(false);
  };

  const handleRemoveUserWithModal = (userId) => {
    handleRemoveUser(userId);
    handleCloseModal();
  };

  const handleTest = (iD_Bancos_Usuarios) => {
    api_data
      .delete(`/api/Bancos/DeleteBancosUsuarios/${iD_Bancos_Usuarios}`)
      .then(response => {
        console.log(response);
        setAuthorizedUsers(
          authorizedUsers.filter(
            user => user.iD_Bancos_Usuarios !== iD_Bancos_Usuarios
          )
        );
      })
      .catch(error => {
        console.error(error);
      });
  };


  const handleSelectUser = (event) => {
    setSelectedUser(event.target.value);
  };




  const handleRemoveUser = (userId) => {
    setAuthorizedUsers(authorizedUsers.filter(user => user.id !== userId));
  };

  const handleAuthorizeUser = () => {
    if (selectedUser) {

      const [userId, userName] = selectedUser.split(',');

      const userExists = authorizedUsers.some(user => user.ID_Usuario === userId);

      if (!userExists) {
        const newUser = {
          id: Date.now(),
          ID_Usuario: userId,
          Usuario: userName,
          existing: false,
        };

        setAuthorizedUsers([...authorizedUsers, newUser]);
      } else {
        alert('Usuário já adicionado!');
      }
    }
  };


  const handleOpenDeleteModal = (userId) => {
    const user = authorizedUsers.find((user) => user.id === userId);
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDeleteUserWithModal = (iD_Bancos_Usuarios) => {
    handleTest(iD_Bancos_Usuarios);
    handleCloseDeleteModal();
  };
  return (

    <div>
      <page>
        <div className="page-header">
          <h1 className="page-title">Bancos</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Bancos</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/Bancos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Bancos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações do Bancos:" defaultOpen={true}>
                    <div className="form-group form-material row">

                      <FormInput colWidth={2} name="banco" label="Banco" />
                      <FormInput colWidth={2} name="agencia" label="Agencia" />
                      <FormInput colWidth={2} name="numero" label="Numero" />
                      <FormInReal colWidth={3} name="saldo" label="Saldo"></FormInReal>

                      <FormSelect colWidth={3} name="iD_Empresa" label="ID Empresa">
                        {empresas.map((item) => (
                          <option key={item.ID_Empresa} value={item.ID_Empresa}>
                            {item.Razao_Social}
                          </option>
                        ))}
                      </FormSelect>
                    </div>
                    <div className="form-group form-material row">
                    </div>
                  </FormPanel>
                  <FormPanel id="exampleHeadingDefaultOne" href="#exampleCollapseDefaultOne" ariaControls="exampleCollapseDefaultOne"
                    title="Informacoes de usuario" defaultOpen={true}>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <div className="panel-heading" id="exampleHeadingDefaultSeven" role="tab">
                          <a className='panel-title' data-toggle="collapse" href='#exampleCollapseDefaultSeven'
                            data-parent="#exampleAccordionDefault" aria-expanded="false" aria-controls='exampleCollapseDefaultSeven'
                          ><h5><i>Observacoes</i></h5></a>
                        </div>
                        <div className="panel-collapse collapse show" id="exampleCollapseDefaultSeven" aria-labelledby='exampleHeadingDefaultSeven'
                          role="tabpanel">
                          <div className="panel-body container-fluid">
                            <div className="form-group row">
                              <textarea className='form-control' id='textareaDefault' rows="8"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='form-group col-md-6' data-plugin="formMaterial">
                        <div className='row'>
                          <div className='col-md-2'>
                            Selecione
                          </div>
                          <div className='col-md-6'>
                            <select className='form-control aria-label' onChange={handleSelectUser}>
                              <option value="">Selecione um usuário</option>
                              {users.map((usuario) => (
                                <option key={usuario.id} value={`${usuario.id},${usuario.userName}`}>
                                  {usuario.userName}
                                </option>
                              ))}
                            </select>

                          </div>
                          <div className="col-md-4">
                            <div className="mb-6">
                              <a className='btn btn-primary text-white' onClick={handleAuthorizeUser}>
                                <i className='icon md-plus '></i> Autorizar Usuario
                              </a>

                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="form-group col-md-12" data-plugin="formMaterial">
                          <div className="table-responsive">
                            <table className='table table-hover dataTable table-striped w-full' id='dtUsuarios'>
                              <thead>
                                <th>ID_Usuario</th>
                                <th>Usuario</th>
                                <th>Remover</th>
                              </thead>
                              <tbody>
                                {authorizedUsers.map((user) => (
                                  <>
                                    <tr key={user.id}>
                                      <td>{user.ID_Usuario}</td>
                                      <td>{user.Usuario}</td>
                                      <td>
                                        {!user.existing && (
                                          <a className='btn btn-danger text-white' onClick={() => handleOpenModal(user.id)}>
                                            Remover
                                          </a>

                                        )}
                                        {user.existing && (
                                          <a className='btn btn-danger text-white'
                                            onClick={() => handleOpenDeleteModal(user.id)}

                                          >
                                            Remover
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                    {user.existing && (
                                      <tr key={`hidden-${user.id}`} style={{ display: "none" }}>
                                        <td colSpan="3">
                                          <input type="hidden" value={user.iD_Bancos_Usuarios} />
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>
                  </FormPanel>

<br></br>

                  <div className="panel">
                  <div className="panel-body container-fluid">
                    <div className="form-group form-material row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                        {showErrorMessage && <p style={{ marginRight: '20px', color: 'red' }}>Não foi possivel editar o banco</p>}
                        <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                          {isSubmitting ? "Criando ..." : "Editar o banco"}
                        </button>
                        <Link to="/Bancos">
                          <a className="btn btn-danger">
                            <i className="icon md-grid" aria-hidden="true"></i>
                            <span style={{ color: "white" }}>Cancelar e não salvar</span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </page>
      <ModalRemoveUser
        user={userToRemove}
        show={showModal}
        onClose={handleCloseModal}
        onRemove={() => handleRemoveUserWithModal(userToRemove.id)}
      />

      <ModalDeleteUser
        user={userToDelete}
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={() => handleDeleteUserWithModal(userToDelete.iD_Bancos_Usuarios)}
      />


    </div>
  )
}

export default EditBancos