import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const data = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 1000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: -1000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 500, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: -2000, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: -250, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];
const data2 = [
    { name: 'Page A', uv: 7598, pv: 1327, amt: 6035 },
    { name: 'Page B', uv: -10000, pv: 9000, amt: 3000 },
    { name: 'Page C', uv: -3000, pv: 8000, amt: 2000 },
    { name: 'Page D', uv: 993, pv: 2178, amt: 1206 },
    { name: 'Page E', uv: 2381, pv: 414, amt: 9138 },
    { name: 'Page F', uv: 5000, pv: 10000, amt: 4000 },
    { name: 'Page G', uv: -9757, pv: 639, amt: 1043 },
];

const data3 = [
    { name: 'Page A', uv: 10692, pv: 2271, amt: 7582 },
    { name: 'Page B', uv: 2753, pv: -12000, amt: 4000 },
    { name: 'Page C', uv: 5931, pv: 5688, amt: 4811 },
    { name: 'Page D', uv: 1000, pv: 11000, amt: 5000 },
    { name: 'Page E', uv: -8000, pv: 13957, amt: 13592 },
    { name: 'Page F', uv: -20000, pv: 15000, amt: 6000 },
    { name: 'Page G', uv: 20487, pv: 412, amt: 2046 },
];

const data4 = [
    { name: 'Page A', uv: 15379, pv: 3581, amt: -25000 },
    { name: 'Page B', uv: 4022, pv: 2496, amt: 9802 },
    { name: 'Page C', uv: -15297, pv: 14748, amt: 12851 },
    { name: 'Page D', uv: 5931, pv: -18000, amt: 6974 },
    { name: 'Page E', uv: 12000, pv: 20725, amt: 18746 },
    { name: 'Page F', uv: 8000, pv: 14821, amt: 13577 },
    { name: 'Page G', uv: 30112, pv: 703, amt: -12000 },
];

const data5 = [
    { name: 'Page A', uv: 20968, pv: -20000, amt: 14432 },
    { name: 'Page B', uv: -24000, pv: 3074, amt: 13957 },
    { name: 'Page C', uv: 18000, pv: -15000, amt: 18341 },
    { name: 'Page D', uv: 9117, pv: 10815, amt: 10213 },
    { name: 'Page E', uv: 24536, pv: 31046, amt: 25647 },
    { name: 'Page F', uv: 25000, pv: 24752, amt: -28000 },
    { name: 'Page G', uv: -38729, pv: 1769, amt: 3497 },
];

const gradientOffset = () => {
    const dataMax = Math.max(...data.map((i) => i.uv));
    const dataMin = Math.min(...data.map((i) => i.uv));
  
    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }
  
    return dataMax / (dataMax - dataMin);
  };
  
  const off = gradientOffset();


const ServicosInd = () => (
    <div className="panel" id="projects-status">
        <div className="panel-heading">
            <h3 className="panel-title">
                Ordens de Serviços
                <span className="badge badge-pill badge-info">5</span>
            </h3>
        </div>
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <td>OS</td>
                        <td>Cliente</td>
                        <td>Status</td>
                        <td className="text-left">Progresso</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>3761</td>
                        <td>Deise Medeiros</td>
                        <td>
                            <span className="badge badge-primary">Developing</span>
                        </td>
                        <td>
                            <ResponsiveContainer width={70} height={35}>
                                <AreaChart data={data}>

                                    <XAxis dataKey="name" hide={true} />
                                    <YAxis hide={true} />

                                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </td>
                    </tr>

                    <tr>
                        <td>3763</td>
                        <td>Gulherme Barros</td>
                        <td>
                            <span class="badge badge-warning">Design</span>
                        </td>
                        <td>
                            <ResponsiveContainer width={70} height={35}>
                                <AreaChart data={data2}>

                                    <XAxis dataKey="name" hide={true} />
                                    <YAxis hide={true} />

                                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </td>
                    </tr>
                    <tr>
                        <td>3743</td>
                        <td>Odon Ferrini</td>
                        <td>
                            <span class="badge badge-info">Testing</span>
                        </td>
                        <td>
                            <ResponsiveContainer width={70} height={35}>
                                <AreaChart data={data3}>

                                    <XAxis dataKey="name" hide={true} />
                                    <YAxis hide={true} />

                                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </td>
                    </tr>
                    <tr>
                        <td>3780</td>
                        <td>Luan Pereira</td>
                        <td>
                            <span class="badge badge-danger">Canceled</span>
                        </td>
                        <td>
                            <ResponsiveContainer width={70} height={35}>
                                <AreaChart data={data4}>

                                    <XAxis dataKey="name" hide={true} />
                                    <YAxis hide={true} />

                                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </td>
                    </tr>
                    <tr>
                        <td>3781</td>
                        <td>Simone Yoda</td>
                        <td>
                            <span class="badge badge-default">Reply waiting</span>
                        </td>
                        <td>
                            <ResponsiveContainer width={70} height={35}>
                                <AreaChart data={data5}>

                                    <XAxis dataKey="name" hide={true} />
                                    <YAxis hide={true} />

                                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);

export default ServicosInd;
