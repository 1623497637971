import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import FormDate from '../../GlobalComponents/FormDate'


import { api_data } from '../../services/api';
import { estados } from './../EmpresasPage/ObjectsPage/Estados';
import ModalPesquisarEmp from "./ModalEmpresa/ModalPesquisarEmp";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Toggle.css';
import {  toast } from 'react-toastify';

function CreateMovimentacao() {


  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [exteriorToggle, setExteriorToggle] = useState(false);
  const [paisValue, setPaisValue] = useState("Brasil");
  const [empresas, setEmpresas] = useState([]);
  const [empCreated, setEmpCreated] = useState(false);
  const [empresaId, setEmpresaId] = useState(null);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [depositos, setDepositos] = useState([]);


  const [representantes, setRepresentantes] = useState([]);


  const initialValues = {
    quantidade: ''
  };


  useEffect(() => {
    api_data.get('/api/Bancos/GetEmpresasList')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);


  const handleEmpresaIdChange = (id) => {
    setEmpresaId(id);
  };

  // const convertCurrencyToInt = (currencyString) => {
  //   console.log(currencyString)
  //   if (typeof currencyString !== 'string') {
  //     console.warn('Unexpected type for currencyString:', typeof currencyString, currencyString);
  //     return 0;
  //   }
  //   return parseInt(currencyString.replace('R$', '').replace(',', '').replace('.', ''));
  // };
  const handleDateChange = (index, field, value) => {
    // Existing logic
    const formattedDate = value ? `${value}T00:00:00` : null;
    // Use `formattedDate` when updating the row
  };


  const notify = () => {
    toast.success("Criado com Sucesso", { autoClose: 3000 });
  };
  
  const notifyError = () => {
    toast.error("Falha na criação, tente novamente", { autoClose: 3000 });
  };
  
  const onSubmit = (values, { setSubmitting }) => {

    const payload = {
      iD_Movimentacao: 0,
      data_Movimentacao: null,
      nota_Fiscal: "NF123",
      iD_Produto: null,
      observacoes: null,
      nome_Produto: null,
      quantidade: null,
      valor_Unitario: null,
      valor_Total: null,
      validade: null,
      fabricacao: null,
      tipo: null,
      categoria: "Food",
      rowMovimentacao: rows.map((row) => {
        return {
          iD_Produto: row.iD_Produto || null,
          Nome_Produto: row.nome_Produto || null,
          Quantidade: row.quantidade === "" ? null : parseInt(row.quantidade, 10),
          Valor_Unitario: row.valor_Unitario,
          Valor_Total: row.valor_Total,
          Validade: "2023-12-31T00:00:00",
        Fabricacao: "2023-01-01T00:00:00",
          
          Tipo: row.tipo || null,
        };
      }),
    };
    


    api_data
      .post("/api/Movimentacoes/AddMovimentacao", payload)
      .then((response) => {
        notify();
        navigate("/NovaMovimentacao");
        
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.log("error!");
        notifyError()
        console.log(payload)
        console.error(error);
        if (error.response && error.response.data && error.response.data.errors) {
          console.log('Validation errors:', error.response.data.errors);
  
          Object.keys(error.response.data.errors).forEach(field => {
            console.log(`Field causing error: ${field}`);
          });
        }
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  
  
  
  


  const [file, setFile] = useState(null);
  const [repCreated, setRepCreated] = useState(false);



  const [selectedIDProduto, setSelectedIDProduto] = useState(null);
  const [initialValueSet, setInitialValueSet] = useState(false);

  useEffect(() => {
    api_data.get('/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
        if (selectedIDProduto === null && response.data.length > 0) {
          setSelectedIDProduto(response.data[0].iD_Produto);
        }
      });
  }, [selectedIDProduto]);


  
  

  const [rows, setRows] = useState([]);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    api_data.get('/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
      });
  }, []);


  useEffect(() => {
    api_data.get('/api/Depositos/GetDepositos')
      .then((response) => {
        setDepositos(response.data);
      });
  }, []);


  const handleButtonClick = (iD_Produto, quantidade) => {
    console.log(quantidade)
    api_data.get(`/api/Produtos/GetProduto/${selectedIDProduto}`)
      .then((response) => {
        const newEntry = {
          iD_Produto: response.data.iD_Produto,
          nome_Produto: response.data.nome_Produto,
          preco_de_Custo: response.data.preco_de_Custo,
          quantidade: quantidade,
          valor_Total: quantidade ? response.data.preco_de_Custo * quantidade : response.data.preco_de_Custo

        };
        setRows([...rows, newEntry]);
        setShowTable(true);
      });
  };



  const handleAddSelectedProducts = (newProducts) => {
    setSelectedProducts(newProducts);
  };

  const fetchSelectedProducts = async () => {
    const fetchedRows = await Promise.all(selectedProducts.map(async (id) => {
      const response = await api_data.get(`/api/Produtos/GetProduto/${id}`);
      return {
        iD_Produto: response.data.iD_Produto,
        nome_Produto: response.data.nome_Produto,
        preco_de_Custo: response.data.preco_de_Custo,
        quantidade: "",
        valor_Total: response.data.preco_de_Custo
      };
    }));

    setRows([...rows, ...fetchedRows]);
    setShowTable(true);
  };

  useEffect(() => {
    if (selectedProducts.length > 0) {
      fetchSelectedProducts();
    }
  }, [selectedProducts]);

  const handleDeleteRow = (indexToDelete) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
  };


  const handleQuantityChange = (index, newQuantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantidade = newQuantity;
    updatedRows[index].valor_Total = updatedRows[index].preco_de_Custo * newQuantity;
    setRows(updatedRows);
  };
 

  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Cadastro do Depósito</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Depósito</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/Depositos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista Depósitos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Depósito:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormSelect colWidth={3} name="iD_Deposito" label="Deposito">
                        {depositos.map((deposito) => (
                          <option key={deposito.iD_Deposito} value={deposito.iD_Deposito}>
                            {deposito.nome_Deposito}
                          </option>
                        ))}
                      </FormSelect>


                      <FormDate colWidth={3} name="Data_Movimentação" label="Data Movimentação" />
                      <FormInput colWidth={3} name="nota_fiscal" label="Nota Fiscal" />

                      <FormSelect colWidth={2} name="tipo_movimentacao" label="Categoria">
                        <option>Venda</option>
                        <option>Compra</option>
                      </FormSelect>
                    </div>
                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultSix" href="#exampleCollapseDefaultSix" ariaControls="exampleCollapseDefaultSix"
                    title="Informações do Endereço:" defaultOpen={true}>


                    <div className="form-group form-material row">

                      <FormInput colWidth={2} name="quantidade_fs" label="Quantidade" numero={true} />


                      <FormSelect colWidth={3} name="iD_Produto" label="Produto" value={selectedIDProduto} onChange={(e) => setSelectedIDProduto(e.target.value)}>
                        {representantes.map((representante) => (
                          <option key={representante.iD_Produto} value={representante.iD_Produto}>
                            {representante.nome_Produto}
                          </option>
                        ))}
                      </FormSelect>

                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalEmpPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>
                      <div className="form-group col-md-1">

                        <div className="col-md-1">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleButtonClick(values.iD_Produto, values.quantidade_fs)}
                          >
                            Adicionar
                          </button>
                        </div>

                      </div>
                      <div className="form-group col-md-1">
                      </div>



                    </div>


                    <div className="form-group form-material row">



                      {showTable && (
                        <table>
                          <thead>
                            <tr>
                              <th>ID Produto</th>
                              <th>Nome Produto</th>
                              <th>Quantidade</th>
                              <th>Valor Unitario (R$)</th>
                              <th>Valor Total</th>
                              <th>Validade</th>
                              <th>Fabricacao</th>
                              <th>Tipo</th>
                              <th>Ações</th>


                         

                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((row, index) => (
                              <tr key={index}>
                                <td>{row.iD_Produto}</td>
                                <td>{row.nome_Produto}</td>
                                <td>
                                  <input
                                    type="number"
                                    value={row.quantidade}
                                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                                  />
                                </td>
                                <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.preco_de_Custo)}</td>
                                <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_Total)}</td>
                                <td>
                                  <input
                                    type="date"
                                    value={row.validade}
                                    onChange={(e) => handleDateChange(index, 'validade', e.target.value)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    value={row.fabricacao}
                                    onChange={(e) => handleDateChange(index, 'fabricacao', e.target.value)}
                                  />
                                </td>
                                <td>
                                  <select className='form-control'>
                                    <option>Saida</option>
                                    <option>Entrada</option>
                                  </select>
                                </td>
                                <td>
                                  <span className='btn btn-danger' onClick={() => handleDeleteRow(index)}>Delete</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                     
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="form-group form-material row">
                        <div className='form-group col-12 col-md-12'>
                          <Field name="observacoes" as="textarea" rows="3" className='form-control'></Field>
                          <label className='floating-label'>Observacoes</label>
                        </div>
                      </div>
                  </FormPanel>






                  <br></br>




                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar o Deposito</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar o Deposito"}
                          </button>
                          <Link to="/Depositos">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <ModalPesquisarEmp handleAddSelectedProducts={handleAddSelectedProducts} onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />


              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateMovimentacao