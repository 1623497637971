import React from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';

const FormInput = ({ colWidth, name, label, value, mask, numero, readOnly }) => {
  return (
    <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
      <Field name={name}>
        {({ field }) => (
          mask 
            ? <InputMask {...field} mask={mask} className='form-control' value={value || field.value} readOnly={readOnly} />
            : <input className='form-control' {...field} value={value || field.value} type={numero ? "number" : "text"} style={numero ? {appearance: 'textfield'} : {}} readOnly={readOnly} />
        )}
      </Field>
      <label className='floating-label'>{label}</label>
    </div>
  );
};



export default FormInput;
