import React from 'react';
import { Field } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FormDate = ({ colWidth, name, label, onChange, value }) => {
  return (
    <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
      <Field name={name}>
        {({ field, form }) => (
          <DatePicker
            className="form-control"
            selected={value || (field.value && new Date(field.value)) || null}
            onChange={(val) => {
              form.setFieldValue(field.name, val);
              if (onChange) {
                onChange({
                  target: {
                    value: val,
                  },
                });
              }
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
          />
        )}
      </Field>
      <label className="floating-label" style={{ marginTop: "-7px" }}>{label}</label>
    </div>
  );
};

export default FormDate;



