import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState } from 'react';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';
import FormInReal from '../../GlobalComponents/FormInReal';
import FormDate from '../../GlobalComponents/FormDate';
import './Toggle.css';

function CreateCentro_Custos() {


  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const initialValues = {};
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dataVencToggle, setDataVencToggle] = useState(false);

  const onSubmit = (values, { setSubmitting }) => {
    values.con_data_venc = dataVencToggle;
    console.log(values.valor_limite)
    if (values.valor_limite) {
      values.valor_limite = parseFloat(
        values.valor_limite
          .toString()
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", ".")
      );
    } else {
      values.valor_limite = 0;
    }
    console.log(values.valor_limite)

    api_data
      .post("/api/Centro_Custos/AddCentroCusto", values)
      .then((response) => {
        console.log(response);
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  

  const [file, setFile] = useState(null);




  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Centro de Custos</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Centro de Custos</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/CentroDeCustos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista Centro de Custos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Centro de Custos:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={4} name="Hierarquia" label="Hierarquia" />
                      <FormInput colWidth={4} name="Nome" label="Nome " />
                      <FormInput colWidth={4} name="Codigo" label="Código" />



                    </div>
                    <div className="form-group form-material row">
                      <div className="col-md-1">
                        <div className={`toggle-button ${toggle ? 'active' : ''}`} onClick={() => setToggle(!toggle)}>
                          <div className="toggle-circle"></div>
                        </div>

                      </div>
                      <div className="col-md-2">
                        <p className='Paragraph-1'> Definir Limite de Gastos</p>
                      </div>


                    </div>
                    {toggle && (
                      <div className="form-group form-material row">
                        <FormInput colWidth={4} name="Molde" label="Molde" />
                        <FormInReal colWidth={4} name="valor_limite" label="Valor Limite (R$)" />
                        <div className="col-md-1">
                          <div className={`toggle-button ${dataVencToggle ? 'active' : ''}`} onClick={() => setDataVencToggle(!dataVencToggle)}>
                            <div className="toggle-circle"></div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <p className='Paragraph-1'> Considerar data de vencimento</p>
                        </div>
                      </div>
                    )}
                  </FormPanel>









                  <br></br>




                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar a Categoria</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar a Categoria"}
                          </button>
                          <Link to="/Categoria_Contas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateCentro_Custos