import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';


import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel';
import { api_data } from '../../services/api';
import { estados } from './../EmpresasPage/ObjectsPage/Estados';
import FormSelect from '../../GlobalComponents/FormSelect'
import ModalPesquisarEmp from "./ModalEmpresa/ModalPesquisarEmp";
import Depositos from './Depositos';


function EmpresasEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [empresa, setEmpresa] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [exteriorToggle, setExteriorToggle] = useState(false);
  const [empCreated, setEmpCreated] = useState(false);
  const [empresaId, setEmpresaId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api_data.get(`/api/Depositos/GetDeposito/${id}`);
        setEmpresa(response.data);
      } catch (error) {
        console.log("Erro ao recuperar os dados do usuario")
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    api_data.get('/api/Bancos/GetEmpresasList')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);

  const initialValues = useMemo(
    () => ({
      iD_Deposito: id,
      ...empresa,
    }),
    [empresa, id]
  );

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await api_data.put(`/api/Depositos/UpdateDeposito/${id}`, values);
      navigate("/Depositos");
      setShowErrorMessage(false);
    } catch (error) {
      setShowErrorMessage(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEmpresaIdChange = (id) => {
    setEmpresaId(id);
  };
  
  

  if (!empresa) return <div>Carregando...</div>;


console.log(initialValues)


  return (

    <div>
    <page>
      <div className="page-header">
        <h1 className="page-title">Editar o Deposito</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a>Home</a></li>
          <li className="breadcrumb-item"><a>Lista</a></li>
          <li className="breadcrumb-item active">Depositos</li>
        </ol>
        <div className="page-header-actions">
          <Link to="/Depositos">
            <p>
              <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Depositos</a>
            </p>
          </Link>
        </div>
      </div>
      <div className="page-content container-fluid">
        <Formik initialValues={initialValues} onSubmit={onSubmit} >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
            <div class="accordion accordion-flush" id="accordionFlushExample">


              <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                title="Informações de Centro de Custos:" defaultOpen={true}>
                <div className="form-group form-material row">
                  <FormInput colWidth={6} name="nome_Deposito" label="Nome do Deposito" />

                  <div className="form-group col-md-6">
                    <div className="input-group">

                      <Field name="iD_Empresa">
                        {({ field, meta }) => (
                          <>
                            <select
                              {...field}
                              className="form-control form-select form-control-label-sm mb-2"
                            >
                              {empresas.map((empresas) => (
                                <option key={empresas.ID_Empresa} value={empresas.ID_Empresa}>
                                  {empresas.Razao_Social}
                                </option>
                              ))}

                            </select>
                            <label className='floating-label'>
                            </label>


                          </>
                        )}
                      </Field>
                      <span className="input-group-append">
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#ModalEmpPesquisar"

                          className="btn btn-icon btn-default"
                          style={{ height: '40px', display: 'inline-block' }}

                        >
                          <i className="icon md-search" aria-hidden="true"></i>
                        </a>
                      </span>
                    </div>
                  </div>

                  <FormInput colWidth={4} name="responsavel_Estoque" label="Responsavel pelo Estoque" />
                  <FormInput colWidth={4} name="telefone_Estoque" label="Telefone Estoque" mask="(99) 99999-9999" numero={true} />

                  <FormInput colWidth={4} name="email_Estoque" label="Email Estoque" />
                </div>
              </FormPanel>

              <FormPanel id="exampleHeadingDefaultSix" href="#exampleCollapseDefaultSix" ariaControls="exampleCollapseDefaultSix"
                title="Informações do Endereço:" defaultOpen={true}>
                <div className="form-group form-material row">

                  <div className="col-md-1">
                    <div className={`toggle-button ${exteriorToggle ? 'active' : ''}`} onClick={() => {
                      setExteriorToggle(prevState => !prevState);
                      setFieldValue("pais", !exteriorToggle ? "" : "Brasil");
                    }}>
                      <div className="toggle-circle"></div>
                    </div>
                  </div>


                  <div className="col-md-2">
                    <p className='Paragraph-1'> O Deposito é no exterior</p>
                  </div>



                  <FormInput colWidth={4} name="logradouro" label="Logradouro" />
                  <FormInput colWidth={3} name="numero" label="Numero " />
                  <FormInput colWidth={3} name="complemento" label="Complemento" />
                  <FormInput colWidth={3} name="bairro" label="bairro" />
                  <FormInput colWidth={3} name="cep" label="CEP" mask="99999-999" numero={true} />

                  <FormInput colWidth={3} name="cidade" label="Cidade" />
                  <FormInput colWidth={3} name="codigo_Municipio" label="Código Municipio" />
                  <FormSelect colWidth={3} name="uf" label="UF">
                    {estados.map((estado) => (
                      <option key={estado.value} value={estado.value}>{estado.label}</option>
                    ))}
                  </FormSelect>
                  <FormInput colWidth={3} name="codigo_UF" label="Código UF" />

                  <div className={`form-group col-md-3`} data-plugin="formMaterial">
                    <Field name="pais">
                      {({ field }) => (
                        <input
                          className='form-control'
                          {...field}
                          readOnly={!exteriorToggle}
                          type="text"
                        />
                      )}
                    </Field>
                    <label className='floating-label'>País</label>
                  </div>






                </div>
              </FormPanel>






              <br></br>




              <div className="panel">
                <div className="panel-body container-fluid">
                  <div className="form-group form-material row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                      {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel editar o Deposito</p>}
                      <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Editando ..." : "Editar o Deposito"}
                      </button>
                      <Link to="/Depositos">
                        <a className="btn btn-danger">
                          <i className="icon md-grid" aria-hidden="true"></i>
                          <span style={{ color: "white" }}>Cancelar e não salvar</span>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <ModalPesquisarEmp onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />


          </Form>
          )}
        </Formik>
      </div>
    </page>

  </div>
  )
}

export default EmpresasEdit