import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';

import "../../Styles/MediaPrintContext/print.css"
import "../../Styles/DatatableStyle/tableStyle.css"


import copy from 'clipboard-copy';
import { unparse } from 'papaparse';

import ModalDetails from './ModalDetails';
import ModalDelete from './ModalDelete';
import { api } from '../../services/api';




function copyToClipboard(data) {
  const clipboardText = data.map(row => Object.values(row).join('\t')).join('\n');
  copy(clipboardText);
}

function downloadCSV(data) {

  const csv = unparse(data);


  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  link.download = 'data.csv';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


//No style, é possivel adicionar o estilo que vai ser feito para a tabela
function printTable(data, columns) {
  const html = `
    <html>
      <head>
        <title>Print table</title>
        <style>
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid black; padding: 8px; text-align: left; }
        </style>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              ${columns.slice(0, -1).map(column => `<th>${column.Header}</th>`).join('')}
            </tr>
          </thead>
          <tbody>
            ${data.map(row => `
              <tr>
                ${columns.slice(0, -1).map(column => `<td>${row[column.accessor] ?? ''}</td>`).join('')}
              </tr>
            `).join('')}
          </tbody>
        </table>
        <script>
          (function() {
            window.print();
            setTimeout(function() { window.close(); }, 500);
          })();
        </script>
      </body>
    </html>
  `;

  const printWindow = window.open('', '_blank');
  printWindow.document.write(html);
  printWindow.document.close();
}








function Datatest() {
  const [data, setData] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  const [selectId, setSelectId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaldoModalOpen, setIsSaldoModalOpen] = useState(false);
  const [isTransferenciaModalOpen, setIsTransferenciaModalOpen] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [idEmpresa, setIdEmpresa] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let result;
  
      result = await api.get('/GetUsuarios');
  
      const dataWithActionID = result.data.map(item => ({ ...item, actionID: item.id }));
      setData(dataWithActionID);
      setIsLoading(false);

    };
  
    fetchData();
  }, [idEmpresa]); 
  



  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: '6.3%',
      },
      {
        Header: 'Nome de Usuario',
        accessor: 'userName',
        width: '20%',
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: '13%',
      },
      {
        Header: 'Apelido',
        accessor: 'apelido',
        width: '18%',
      },   
      {
        Header: 'Ação',
        width: '8%',
        accessor: 'actionID',
        Cell: ({ value }) => (
          <>
            <Link to={`/Usuario/Edit/${value}`}
              className="btn btn-info btn-icon on-default edit-row"
              style={{ marginRight: '3px' }}>
              <i className="icon md-edit" aria-hidden="true"></i>
            </Link>
            <button
              className="btn btn-warning btn-icon on-default fc-row"
              data-bs-toggle="modal"
              style={{ marginRight: '3px' }}
              data-bs-target="#exampleModalCenter"
              onClick={() => handleModalOpen(value)}
            >
              <i className="icon md-card" aria-hidden="true"></i>
            </button>




            <button
              className="btn btn-danger btn-icon on-default fc-row"
              data-bs-toggle="modal"
              style={{ marginRight: '3px' }}
              data-bs-target="#exampleModalCenterDelete"
              onClick={() => handleDeleteModalOpen(value)}>
              <i className="icon md-delete" aria-hidden="true"></i>
            </button>

          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    pageCount
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  function handleModalOpen(id) {
    setSelectId(id);
    setIsModalOpen(true);
  }

  function handleDeleteModalOpen(id) {
    setSelectId(id);
    setIsDeleteModalOpen(true);
  }


  function handleSaldoModalOpen(id) {
    setSelectId(id);
    setIsSaldoModalOpen(true);
  }

  function handleTransferenciaModalOpen(id) {
    setSelectId(id);
    setIsTransferenciaModalOpen(true);
  }

  function handleCompanyIdChange(e) {
    const value = e.target.value;
    setSelectedCompanyId(value === "Todos" ? null : parseInt(value));
  }

  const pagesToShow = 5;
  const pagesIndex = Math.max(Math.min(pageIndex - Math.floor(pagesToShow / 2), pageCount - pagesToShow), 0);

  return (
    <div>
    {isLoading ? (
      <div className="loadingContainer">
      <div className="spinner"></div>
      <span className="loadingText">Carregando . . .</span>
    </div>
    ) : (
      <div className="MainDiv">
        <div className="container p-5">
          <div className='topTable'>
            <div className='btn-div'>
              <button className='CopyBtn' onClick={() => copyToClipboard(data)}>Copy</button>
              <button className='csvBtn' onClick={() => downloadCSV(data)}>CSV</button>
              <button className='pdfBtn' onClick={() => printTable(data, columns)}>Print</button>
            </div>
            <input
              className='form-control input-search'
              onChange={e => setGlobalFilter(e.target.value || undefined)}
              placeholder="Procurar"
            />
          </div>
          <table {...getTableProps()} className="table table-hover dataTable table-striped w-full dtr-inline">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="trTable">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }} className="thTable">
                      {column.render('Header')}
                      <span className="sort-indicator">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <i className="fas fa-sort-down"></i>
                            : <i className="fas fa-sort-up"></i>
                          : <i className="fas fa-sort"></i>
                        }
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{ width: cell.column.width }}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className='bottomTable'>
            <div>
              Mostrando de {(pageIndex * pageSize) + 1} até {(pageIndex * pageSize) + page.length} de {data.length} registros
            </div>
            <div className="pagination-div">
              <button className='btnBack btnPagination' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>
              <button className='btnBack btnPagination' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>
              {[...Array(Math.min(pageCount, pagesToShow)).keys()].map(pageNumber => {
                const realPageNumber = pagesIndex + pageNumber;
                return (
                  <button
                    className={`btnGoToPage ${realPageNumber === pageIndex ? 'active' : ''}`}
                    onClick={() => gotoPage(realPageNumber)}
                    disabled={realPageNumber >= pageCount}>
                    {realPageNumber + 1}
                  </button>
                );
              })}
              <button className='btnNext btnPagination' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>
              <button className='btnNext btnPagination' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>
            </div>
          </div>
          <ModalDetails
            selectId={selectId}
            onClose={() => setIsModalOpen(false)}

          />


          <ModalDelete
            selectId={selectId}
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          />
        </div>
      </div>
    )}
  </div>
  );
}

export default Datatest;
