import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_data } from '../../services/api';


import FormPanel from '../../GlobalComponents/FormPanel';

function ModalDetails(props) {
    const navigate = useNavigate();

    const [empresa, setEmpresa] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setEmpresa({});

            const response = await api_data.get(`/api/Empresa/GetEmpresa/${props.selectId}`);
            const empresaData = await response.data;
            setEmpresa(empresaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setEmpresa({});
        console.log(empresa)
        console.log('cliquei')

    };

    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };

    const handleEdit = () => {
        limparEmpresa();
        handleCloseModal();
        navigate(`/Empresas/Edit/${props.selectId}`);
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes da Empresa</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className='form-material row'>
                                <div className='form-group col-md-3' data-plugin="formMaterial">
                                    <input key={empresa.cnpj} className='form-control' data-plugin="formatter" value={empresa.cnpj} />
                                    <label className='floating-label'>CNPJ</label>
                                </div>
                                <div className='form-group col-md-4' data-plugin="formMaterial">
                                    <input key={empresa.razao_Social} className='form-control' readOnly value={empresa.razao_Social} />
                                    <label className='floating-label'>Razao Social</label>
                                </div>
                                <div className='form-group col-md-5' data-plugin="formMaterial">
                                    <input key={empresa.nome_Fantasia} className='form-control' readOnly value={empresa.nome_Fantasia} />
                                    <label className='floating-label'>Nome Fantasia</label>
                                </div>
                            </div>


                            <div class="accordion" id="accordionPanelsStayOpenExample">
                                <FormPanel id="exampleHeadingDefaultFour"
                                    title="Endereço:" context="modal">


                                    <div className="panel-body container-fluid">
                                        <div className="form-material row">
                                            <div className="form-group col-md-5" data-plugin="formMaterial">
                                                <input key={empresa.endereco} className="form-control " readOnly value={empresa.endereco} />
                                                <label className="floating-label">Logradouro</label>

                                            </div>
                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.numero} className="form-control " readOnly value={empresa.numero} />
                                                <label className="floating-label">Numero</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input key={empresa.complemento} className="form-control " readOnly value={empresa.complemento} />
                                                <label className="floating-label">Complemento</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input key={empresa.bairro} className="form-control " readOnly value={empresa.bairro} />
                                                <label className="floating-label">Bairro</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel-body container-fluid">
                                        <div class="form-material row">
                                            <div class="form-group col-md-12" data-plugin="formMaterial">
                                                <textarea class="form-control " rows="5" cols="15" readonly>
                                                </textarea>
                                                <label class="floating-label">Observação</label>
                                            </div>
                                        </div>
                                    </div>


                                </FormPanel>
                                <FormPanel id="exampleHeadingDefaultFive"
                                    title="Contato:" context="modal">


                                    <div className="panel-body container-fluid">

                                        <div className="form-material row">
                                            <div className="form-group col-md-5" data-plugin="formMaterial">
                                                <input key={empresa.endereco} className="form-control " readOnly value={empresa.endereco} />
                                                <label className="floating-label">Logradouro</label>

                                            </div>
                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.numero} className="form-control " readOnly value={empresa.numero} />
                                                <label className="floating-label">Numero</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input key={empresa.complemento} className="form-control " readOnly value={empresa.complemento} />
                                                <label className="floating-label">Complemento</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input key={empresa.bairro} className="form-control " readOnly value={empresa.bairro} />
                                                <label className="floating-label">Bairro</label>
                                            </div>
                                        </div>

                                    </div>


                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultSix"
                                    title="Email:" context="modal">


                                    <div className="panel-body container-fluid">

                                        <div className="form-material row">
                                            <div className="form-group col-md-4" data-plugin="formMaterial">
                                                <input key={empresa.email} className="form-control " readOnly value={empresa.email} />
                                                <label className="floating-label">Email</label>

                                            </div>
                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.porta} className="form-control " readOnly value={empresa.porta} />
                                                <label className="floating-label">Porta</label>
                                            </div>
                                            <div className="form-group col-md-2" data-plugin="formMaterial">
                                                <input key={empresa.smtp} className="form-control " readOnly value={empresa.smtp} />
                                                <label className="floating-label">SMTP</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input key={empresa.login} className="form-control " readOnly value={empresa.login} />
                                                <label className="floating-label">Login</label>
                                            </div>
                                            <div className="form-group col-md-2" data-plugin="formMaterial">
                                                <input key={empresa.senha} className="form-control " readOnly value={empresa.senha} />
                                                <label className="floating-label">Senha</label>
                                            </div>
                                        </div>

                                    </div>


                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultSeven"
                                    title="Fiscais:" context="modal">


                                    <div className="panel-body container-fluid">

                                        <div className="form-material row">
                                            <div className="form-group col-md-2" data-plugin="formMaterial">
                                                <input key={empresa.inscricao_Estadual} className="form-control " readOnly value={empresa.inscricao_Estadual} />
                                                <label className="floating-label">Inscri. Estadual</label>

                                            </div>
                                            <div className="form-group col-md-2" data-plugin="formMaterial">
                                                <input key={empresa.inscricao_Municipal} className="form-control " readOnly value={empresa.inscricao_Municipal} />
                                                <label className="floating-label">Inscri. Municipal</label>
                                            </div>
                                            <div className="form-group col-md-4" data-plugin="formMaterial">
                                                <input key={empresa.regime_Tributario} className="form-control " readOnly value={empresa.regime_Tributario} />
                                                <label className="floating-label">Regim. Tributario</label>
                                            </div>
                                            <div className="form-group col-md-4" data-plugin="formMaterial">
                                                <input key={empresa.cnae} className="form-control " readOnly value={empresa.cnae} />
                                                <label className="floating-label">CNAE</label>
                                            </div>

                                        </div>
                                        <div className='form-group form-material row'>

                                            <div className="form-group col-md-4" data-plugin="formMaterial">
                                                <input key={empresa.perfil_Fiscal} className="form-control " readOnly value={empresa.perfil_Fiscal} />
                                                <label className="floating-label">Perfil Fiscal</label>

                                            </div>
                                            <div className="form-group col-md-4" data-plugin="formMaterial">
                                                <input key={empresa.tipo_Atividade} className="form-control " readOnly value={empresa.tipo_Atividade} />
                                                <label className="floating-label">Tipo Atividade</label>
                                            </div>
                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.aliquota_Imposto} className="form-control " readOnly value={empresa.aliquota_Imposto} />
                                                <label className="floating-label">Aliq. Imposto</label>
                                            </div>
                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.pis} className="form-control " readOnly value={empresa.pis} />
                                                <label className="floating-label">PIS</label>
                                            </div>

                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.cofins} className="form-control " readOnly value={empresa.cofins} />
                                                <label className="floating-label">COFINS</label>
                                            </div>

                                            <div className="form-group col-md-1" data-plugin="formMaterial">
                                                <input key={empresa.apuracao_IPI} className="form-control " readOnly value={empresa.apuracao_IPI} />
                                                <label className="floating-label">IPI</label>
                                            </div>
                                        </div>
                                    </div>


                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultEight"
                                    title="Obs:" context="modal">

                                    <div className="form-material row">
                                        <div className="panel-body container-fluid">

                                            <textarea className='form-control' id="textareaDefault" rows="8" readOnly></textarea>



                                        </div>
                                    </div>


                                </FormPanel>
                            </div>

                         

                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" onClick={handleEdit}>Editar</button>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails