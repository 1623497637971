import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const FormPanel = ({ id, title, children, context, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const variants = {
    open: { opacity: 1, height: "auto" },
    closed: { opacity: 0, height: 0 }
  };

  return (
    <div className="accordion-item">
      <h2 className={`accordion-header ${context === 'modal' ? 'modal-title' : ''}`} id={`flush-heading${id}`}>
        <button
          className="accordion-button collapsed"
          type="button"
          onClick={toggleOpen}
          aria-expanded={isOpen}
          aria-controls={`flush-collapse${id}`}
        >
          {title}
        </button>
      </h2>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            id={`flush-collapse${id}`}
            className="accordion-collapse collapse show"
            aria-labelledby={`flush-heading${id}`}
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
          >
            <div className="accordion-body">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FormPanel;
