
import React, { useState, useEffect, useMemo } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';



import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel';
import FormSelect from '../../GlobalComponents/FormSelect';
import FormCheckbox from '../../GlobalComponents/FormCheckbox';
import FormInReal from '../../GlobalComponents/FormInReal';
import FormDate from '../../GlobalComponents/FormDate';

import { estados } from '../EmpresasPage/ObjectsPage/Estados';



import InputMask from 'react-input-mask';
import { api_data } from './../../services/api';


function EditProdutos() {

  const [activeTab, setActiveTab] = useState('dados');
  const navigate = useNavigate();
  const { id } = useParams();
  const [empresa, setEmpresa] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [empCreated, setEmpCreated] = useState(false);

  const [produtosInst, setProdutosInst] = useState([]);
  const [produtosRel, setProdutosRel] = useState([]);
  const [depositos, setDepositos] = useState([]);
  const [selectedDeposito, setSelectedDeposito] = useState(null);
  const [selectedIDProduto, setSelectedIDProduto] = useState(null);
  const [representantes, setRepresentantes] = useState([]);

  const [InstRows, setInstRows] = useState([]);
  const [showTableInst, setShowTableInst] = useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    api_data.get('/api/Depositos/GetDepositos')
      .then((response) => {
        setDepositos(response.data);
      });
  }, []);


  useEffect(() => {
    api_data.get('/api/Produtos/GetProdutos')
      .then((response) => {
        setRepresentantes(response.data);
        if (selectedIDProduto === null && response.data.length > 0) {
          setSelectedIDProduto(response.data[0].iD_Produto);
        }
      });
  }, [selectedIDProduto]);

  useEffect(() => {
    api_data.get('/api/Fornecedores/GetClientes')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api_data.get(`/api/Produtos/GetProduto/${id}`);
        setEmpresa(response.data);
        setProdutosInst(response.data.produtos_Inst || []);
        setProdutosRel(response.data.produtos_Rel || []);
        console.log(response)
      } catch (error) {
        console.log("Erro ao recuperar os dados do usuario")
      }
    };
    fetchData();
  }, [id]);

  const initialValues = useMemo(
    () => ({
      iD_Empresa: id,
      ...empresa,
    }),
    [empresa, id]
  );

  const onSubmit = async (values, { setSubmitting }) => {
    const precoDeCusto = parseInt(values.preco_De_Custo.replace(/[R$\.,]/g, ''), 10);
    const precoDeVenda = parseInt(values.preco_De_Venda.replace(/[R$\.,]/g, ''), 10);

    const payload = {
      ...values,
      preco_De_Venda : precoDeVenda,
      preco_De_Custo: precoDeCusto,
      Produtos_Rel: produtosRel,
      Produtos_Inst: produtosInst,
    };
    
    
console.log(values.preco_De_Venda,values.preco_De_Custo)

    try {
      await api_data.put(`/api/Produtos/UpdateProduto/${id}`, payload);
      navigate("/Produtos");
      setShowErrorMessage(false);
    } catch (error) {
      console.log("error!");
      if (error.response && error.response.data) {
        console.log("Error details:", error.response.data);
      }
      console.error(error);
      setShowErrorMessage(true);
    } finally {
      setSubmitting(false);
    }
};



  if (!empresa) return <div>Carregando...</div>;





  const handleButtonClickProduct = (iD_Produto, quantidade) => {
    Promise.all([
      api_data.get(`/api/Produtos/GetProduto/${iD_Produto}`),
      api_data.get(`/api/Depositos/GetDeposito/${selectedDeposito}`)
    ]).then(([productResponse, depositoResponse]) => {
      const newEntry = {
        id: produtosRel.length > 0 ? Math.max(...produtosRel.map(p => p.id)) + 1 : 1, // Assuming 'id' needs to be unique and incremental.
        iD_Produto: productResponse.data.iD_Produto,
        nome_Produto: productResponse.data.nome_Produto,
        quantidade: quantidade,
        valor_Unitario: productResponse.data.preco_de_Custo,
        valor_Total: quantidade * productResponse.data.preco_de_Custo,
        unidade: productResponse.data.unidade_de_Estoque,
        nome_Deposito: depositoResponse.data.nome_Deposito,

      };
      setProdutosRel([...produtosRel, newEntry]);
    }).catch(error => {
      console.error("Error adding product:", error);
    });
  };

  const handleQuantityChange = (index, value) => {
    const newQuantity = value === '' ? '' : parseFloat(value);
    const updatedProdutosRel = [...produtosRel];
    const valorTotal = newQuantity === '' ? '' : updatedProdutosRel[index].valor_Unitario * newQuantity;

    updatedProdutosRel[index] = {
      ...updatedProdutosRel[index],
      quantidade: newQuantity,
      valor_Total: valorTotal,
    };

    setProdutosRel(updatedProdutosRel);
  };





  const handleOtherCostsChange = (index, value) => {
    const newOtherCosts = value === '' ? 0 : value;
    const updatedProdutosRel = [...produtosRel];


    const valorTotal = (updatedProdutosRel[index].valor_Unitario * updatedProdutosRel[index].quantidade) + parseFloat(newOtherCosts);

    updatedProdutosRel[index] = {
      ...updatedProdutosRel[index],
      outros_Custos: newOtherCosts,
      valor_Total: valorTotal,
    };

    setProdutosRel(updatedProdutosRel);
  };


  const handleTaxChange = (index, isChecked) => {
    const updatedRows = rows.map((row, idx) => {
      if (index === idx) {
        return { ...row, incidir_Impostos_Pagos: isChecked };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(updatedRows);
  };
  const handleButtonClickInst = (Nivel_Complexidade, Nivel_Prioridade, Descricao, Ordenacao) => {
    const newRow = {
      nivel_Complexidade: Nivel_Complexidade,
      nivel_Prioridade: Nivel_Prioridade,
      descricao: Descricao,
      ordenacao: Ordenacao ? parseInt(Ordenacao, 10) : null
    };
    setProdutosInst(currentRows => [...currentRows, newRow]);
  };


  return (

    <div>
      <page>
        <div className="page-header">
          <h1 className="page-title">Editar um Produto </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Salvar</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/Empresas">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Produtos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, values }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">



                  <div className='panel'>
                    <div className="panel-body container-fluid">

                      <div>
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a className={`nav-link clickable-tab ${activeTab === 'dados' ? 'active' : ''}`} onClick={() => setActiveTab('dados')}>

                              Dados
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className={`nav-link clickable-tab ${activeTab === 'fornecedores' ? 'active' : ''}`} onClick={() => setActiveTab('fornecedores')}>
                              Composição
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className={`nav-link clickable-tab ${activeTab === 'fiscal' ? 'active' : ''}`} onClick={() => setActiveTab('fiscal')}>
                              Fiscal
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className={`nav-link clickable-tab ${activeTab === 'composicao' ? 'active' : ''}`} onClick={() => setActiveTab('composicao')}>
                              Composição
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className={`tab-pane ${activeTab === 'dados' ? 'active' : ''}`} id="dados">
                            <br></br>
                            <div className="form-group form-material row">
                              <FormInput colWidth={3} name="nome_Produto" label="Nome do Produto" />

                              <div className="form-group col-md-3">
                                <div className="input-group">
                                  <Field name="fornecedor_Padrao">
                                    {({ field, meta }) => (
                                      <>
                                        <select
                                          {...field}
                                          className="form-control form-select form-control-label-sm mb-2"
                                        >
                                          {empresas.map((empresa) => (
                                            <option key={empresa.iD_Fornecedor} value={empresa.iD_Fornecedor}>
                                              {empresa.razao_Social}
                                            </option>
                                          ))}
                                        </select>
                                        <label className='floating-label'>Fornecedor Padrão</label>
                                      </>
                                    )}
                                  </Field>
                                  <span className="input-group-append">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#ModalEmpPesquisar"
                                      className="btn btn-icon btn-default"
                                      style={{ height: '40px', display: 'inline-block' }}
                                    >
                                      <i className="icon md-search" aria-hidden="true"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>

                              <FormSelect colWidth={3} name="tipo_do_produto" label="Tipo do Produto" />
                              <FormInput colWidth={3} name="marca" label="Marca" />
                              <FormInput colWidth={2} name="codigo_do_sistema" label="Código do Sistema" />
                              <FormInput colWidth={2} name="codigo_fornecedor_padrao" label="Código Fornecedor Padrão" />
                              <FormInput colWidth={2} name="codigo_do_produto_sku" label="Código Produto (SKU)" />

                              <FormSelect colWidth={3} name="tipo_genero" label="Tipo/Gênero" >
                                {/* Opções aqui */}
                              </FormSelect>

                              <FormInput colWidth={3} name="modelo" label="Modelo" />
                              <FormInput colWidth={3} name="categorias" label="Categorias" />

                              <FormInput colWidth={3} name="validade" label="Validade" />
                              <FormSelect colWidth={3} name="produto_e_vendido_por" label="Produto é vendido por" >
                                {/* Opções aqui */}
                              </FormSelect>

                              <FormCheckbox colWidth={3} name="Ocultar_Nas_Vendas" label="Ocultar nas Vendas" />
                              <FormCheckbox colWidth={3} name="Visivel_no_Catalogo" label="Visível no Catálogo" />
                              <FormCheckbox colWidth={3} name="Cadastro_Inativo" label="Cadastro Inativo" />
                            </div>
                          </div>
                          <div className={`tab-pane ${activeTab === 'fornecedores' ? 'active' : ''}`} id="fornecedores">
                            <br
                            >

                            </br>

                          </div>
                          <div className={`tab-pane ${activeTab === 'fiscal' ? 'active' : ''}`} id="fiscal">
                            <br>
                            </br>
                            <div className="form-group form-material row">
                              <FormInReal colWidth={3} name="preco_De_Custo" label="Preço de Custo (R$)" />
                              <FormInReal colWidth={3} name="preco_De_Venda" label="Preço de Venda (R$)" />
                              <FormInput colWidth={3} name="desp_Operacionais" label="Desp. Operacionais (%)" numero={true} />
                              <FormInput colWidth={3} name="frete_Pago_na_Compra" label="Frete pago na Compra (%)" numero={true} />
                              <FormInput colWidth={3} name="seguro_Pago_na_Compra" label="Seguro pago na Compra (%)" numero={true} />

                              <FormInput colWidth={3} name="ipi" label="IPI (%)" numero={true} />
                              <FormInput colWidth={3} name="icms" label="ICMS (%)" numero={true} />
                              <FormInput colWidth={3} name="icms_ST" label="ICMS ST(%)" numero={true} />
                              <FormInput colWidth={3} name="fcp_ST" label="FCP ST (%)" numero={true} />
                              <FormInReal colWidth={6} name="preco_De_custo_com_Acrescimos" label="Preço de custo com Acréscimos" />
                              <FormInput colWidth={6} name="comissao_Representacao" label="Comissão Representação (%)" numero={true} />
                              <FormInReal colWidth={6} name="preco_Minimo_para_Venda" label="Preço Mínimo Para Venda (R$)" />
                              <FormInReal colWidth={3} name="comissao_Vendedor" label="Comissão Vendedor (R$)" />
                              <FormInput colWidth={3} name="comissao_Vendedor_Porcento" label="Comissão Vendedor (%)" numero={true} />
                              <FormInReal colWidth={3} name="taxa_Montagem" label="Taxa Montagem (R$)" />
                              <FormInput colWidth={3} name="taxa_Montagem_Porcento" label="Taxa Montagem (%)" numero={true} />
                              <FormInReal colWidth={3} name="mva_Lucro" label="(MVA) Lucro R$" />
                              <FormInput colWidth={3} name="mva_Lucro_Porcento" label="(MVA) Lucro %" numero={true} />
                              <FormInReal colWidth={3} name="valor_Ultima_compra" label="Valor última compra (R$)" />
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className="form-group form-material row">
                              <FormInput colWidth={2} name="plano_De_Conta" label="Plano de Conta" />

                              <FormCheckbox colWidth={2} name="nao_Emitir_Alertas" label="Não emitir alertas e permitir vendas com estoque negativo"></FormCheckbox>
                              <FormCheckbox colWidth={2} name="cada_Unidade_Numero_Serie" label="Cada unidade do produto possui um número de série"></FormCheckbox>
                              <FormCheckbox colWidth={2} name="permitir_Qtd_Fracionada" label="Permitir Quantidade Fracionada na Loja Online"></FormCheckbox>
                              <FormCheckbox colWidth={2} name="produto_Controlado_Lote" label="Produto Controlado por Lote"></FormCheckbox>
                              <FormCheckbox colWidth={2} name="venda_Metragem" label="Venda por metragem quadrada (m²)"></FormCheckbox>
                            </div>

                          </div>
                          <div className={`tab-pane ${activeTab === 'composicao' ? 'active' : ''}`} id="composicao">
                            <div>
                              <br></br>




                              <div className="form-group form-material row">

                                <FormSelect colWidth={2} name="Nivel_Complexidade" label="Nível Complexidade">
                                  <option value="baixo">Baixo</option>
                                  <option value="medio">Médio</option>
                                  <option value="alto">Alto</option>
                                </FormSelect>

                                <FormSelect colWidth={2} name="Nivel_Prioridade" label="Nível Prioridade">
                                  <option value="baixa">Baixa</option>
                                  <option value="media">Média</option>
                                  <option value="alta">Alta</option>
                                </FormSelect>

                                <FormInput colWidth={3} name="Descricao" label="Descrição" />
                                <FormInput colWidth={3} name="Ordenacao" label="Ordenação" />
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleButtonClickInst(values.Nivel_Complexidade, values.Nivel_Prioridade, values.Descricao, values.Ordenacao)}
                                  >
                                    Adicionar
                                  </button>

                                </div>
                                {produtosInst.length > 0 && (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Nível Complexidade</th>
                                        <th>Nível Prioridade</th>
                                        <th>Descrição</th>
                                        <th>Ordenação</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {produtosInst.map((inst, index) => (
                                        <tr key={index}>
                                          <td>{inst.nivel_Complexidade}</td>
                                          <td>{inst.nivel_Prioridade}</td>
                                          <td>{inst.descricao}</td>
                                          <td>{inst.ordenacao}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                )}
                              </div>












                              <br></br>

                              <div className="form-group form-material row">
                                <FormSelect
                                  colWidth={2}
                                  name="Marca"
                                  label="Localizar Deposito"
                                  value={selectedDeposito}
                                  onChange={(e) => setSelectedDeposito(e.target.value)}
                                >
                                  {depositos.map((deposito) => (
                                    <option key={deposito.iD_Deposito} value={deposito.iD_Deposito}>
                                      {deposito.nome_Deposito}
                                    </option>
                                  ))}
                                </FormSelect>

                                <FormSelect
                                  colWidth={3}
                                  name="iD_Produto_fs"
                                  label="Produto"
                                  value={selectedIDProduto}
                                  onChange={(e) => setSelectedIDProduto(e.target.value)}
                                >
                                  {representantes.map((representante) => (
                                    <option key={representante.iD_Produto} value={representante.iD_Produto}>
                                      {representante.nome_Produto}
                                    </option>
                                  ))}
                                </FormSelect>
                                <FormInput colWidth={1} name="quantidade_fs" label="Quantidade" numero={true} />

                                <div className="form-group col-md-1">
                                  <div className="col-md-1">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => handleButtonClickProduct(selectedIDProduto, values.quantidade_fs)}
                                    >
                                      Adicionar
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {produtosRel.length > 0 && (
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Deposito</th>
                                      <th>ID Produto</th>
                                      <th>Nome Produto</th>
                                      <th>Quantidade</th>
                                      <th>Valor Unitario (R$)</th>
                                      <th>Valor Total</th>
                                      <th>Unidade</th>
                                      <th>Outros Custos</th>
                                      <th>Incidir Impostos Pagos ?</th>
                                      <th>Ações</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {produtosRel.map((row, index) => (
                                      <tr key={index}>
                                        <td>{row.nome_Deposito}</td>
                                        <td>{row.iD_Produto}</td>
                                        <td>{row.nome_Produto}</td>
                                        <td>
                                          <td>
                                            <input
                                              type="number"
                                              value={row.quantidade}
                                              onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            />
                                          </td>
                                        </td>
                                        <td>{isNaN(row.valor_Unitario) ? '' : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_Unitario)}</td>
                                        <td>{isNaN(row.valor_Total) ? '' : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_Total)}</td>
                                        <td>{row.unidade_de_Estoque}</td>
                                        <td>
                                          <input type="number" value={row.outros_Custos || ''} onChange={(e) => handleOtherCostsChange(index, parseFloat(e.target.value))} />
                                        </td>
                                        <td>
                                          <input type="checkbox" checked={row.incidir_Impostos_Pagos} onChange={(e) => handleTaxChange(index, e.target.checked)} />
                                        </td>
                                        <td>
                                          <span className='btn btn-danger' onClick={() => handleDeleteRow(index)}>Delete</span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Salvar</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Salvando ..." : "Salvar"}
                          </button>
                          <Link to="/Empresas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </page>


    </div>
  )
}

export default EditProdutos