import React, { useEffect, useState } from 'react';
import { api, api_data } from '../../services/api'
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";

import './resSenha.css';

function ResSenhaPage() {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const navigate = useNavigate()
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    const requestBody = {
        email,
        password,
        repassword,
        token
    };

    console.log("Corpo da solicitação:", requestBody);

    try {
        const response = await api.post("/efetua-reset", requestBody)

        if (response.status === 200) {
            alert("Operação realizada com sucesso!");
            navigate("/login")
        } else {
            console.error("Algo deu errado. Tente novamente.")
        }
    } catch (error) {
        alert("algo deu errado, tente novamente mais tarde")
        console.error(error)
    } finally {
        setLoading(false);
    }
}



useEffect(() => {
  document.body.classList.add('no-padding-top_reset-senha');

  const hash = window.location.hash;
  const parts = hash.split("&email_req");

  if (parts.length > 1) {
      const tokenParts = parts[0].split("token=");
      if (tokenParts.length > 1) {
          const tokenFromUrl = tokenParts[1];
          setToken(tokenFromUrl);
      }
  }

  return () => {
      document.body.classList.remove('no-padding-top_reset-senha');
  }
}, []);


  useEffect(() => {
    console.log("ola")
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    console.log(token)
    const email_req = params.get("email_req");

    if (email_req) {
      const email = atob(email_req);  
      setEmail(email);  
      console.log("Token: ", token, "Email: ", email);
    }
    
    
  }, []);

  useEffect(() => {
    console.log(token);
  }, [token]);

  return (
    <div className="form-container_reset-senha">
      <form className="form_reset-senha" onSubmit={handleSubmit}>
        
        <label className="label_reset-senha">
          Senha:
          <input className="input_reset-senha" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
        </label>
        <label className="label_reset-senha">
          Digite a Senha novamente:
          <input className="input_reset-senha" type="password" name="repassword" value={repassword} onChange={e => setRepassword(e.target.value)} />
        </label>
        <div className="submit-container">
          
          <button  type="submit" id="btnResetSenha">
                    {loading ? (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      "Salvar"
                    )}
                  </button>
        </div>
      </form>
    </div>
  );
}

export default ResSenhaPage;
