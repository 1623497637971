import React, { useState } from 'react'
import FormInModal from "../../../GlobalComponents/ModalComponents/FormInModal"
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { api_data } from '../../../services/api';


function ModalPesFormList({ setIsCreating, onEmpresaCreated, setListaCreated  }) {
  const [activeTab, setActiveTab] = useState('cadastro');

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  }

  const initialValues = {};

  const onSubmit = (values, { setSubmitting }) => {
  api_data.post("/api/ListaPreco/AddListaPreco", values)
  .then((response) => {
    console.log(response);
    setListaCreated(true); 
  })

      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
        setIsCreating(false);
      });
  };

  return (
    <div className='panel-body container-fluid'>
     
      <Formik initialValues={initialValues} onSubmit={onSubmit} >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
              <div>
                <div className="panel-body container-fluid">
                  <div className="form-material row">
                    <FormInModal colWidth={3} label="Nome Lista" name="nome_Lista" />
                    <FormInModal colWidth={3} label="Moeda" name="moeda" />
                    <FormInModal colWidth={3} label="Quantidade Inicial" name="qtde_Inicial" />
                    <FormInModal colWidth={3} label="Quantidade Final" name="qtde_Final" />
                    <FormInModal colWidth={3} label="Margem" name="margem" />
                    <FormInModal colWidth={3} label="Numero Lista" name="numero_Lista" />
                    <FormInModal colWidth={3} label="ID Empresa" name="iD_Empresa" />
                
                  </div>
                </div>
              </div>
            
            <span
              className='btn-success btn'
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Criando ..." : "Criar a Lista Preco"}
            </span>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ModalPesFormList
