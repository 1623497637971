import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FormInModal from '../../GlobalComponents/ModalComponents/FormInModal';
import FormPanel from '../../GlobalComponents/FormPanel';
import { api_data } from '../../services/api';


function ModalDetails(props) {
  const navigate = useNavigate();


  const [cliente, setCliente] = useState({});

  const [activeTab, setActiveTab] = useState('cadastro');

  const [activeTab2, setActiveTab2] = useState('endereco');


  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  }


  const handleTabChange2 = (newTab) => {
    setActiveTab2(newTab);
  }


  useEffect(() => {
    async function fetchEmpresa() {
      setCliente({});
      const response = await api_data.get(`/api/Produtos/GetProduto/${props.selectId}`);
      const clienteData = await response.data;
      setCliente(clienteData);
    }
    fetchEmpresa();
  }, [props.selectId]);



  const handleCloseModal = () => {
    document.getElementById('closeModalButton').click();
  };

  const handleEdit = () => {

    handleCloseModal();
    navigate(`/Fornecedores/Edit/${props.selectId}`);
  };


  return (
    <div>
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Produto</h2>
              <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">




              <div class="accordion" id="accordionPanelsStayOpenExample">

                <FormPanel id="exampleHeadingDefaultThree"
                  title="Dados Produto:" context="modal" defaultOpen={true}>



                  <div className="panel-body container-fluid">
                    <div className="form-material row" >
                      <FormInModal colWidth={3} label="Nome do Produto" value={cliente.nome_Produto} />

                      <FormInModal colWidth={3} label="Fornecedor Padrão" value={cliente.fornecedor_Padrao} />

                      <FormInModal colWidth={3} label="Tipo do Produto" value={cliente.tipo_do_Produto} />

                      <FormInModal colWidth={3} label="Marca" value={cliente.marca} />

                      <FormInModal colWidth={2} label="Código do Sistema" value={cliente.codigo_do_Sistema} />

                      <FormInModal colWidth={2} label="Código Fornecedor Padrão" value={cliente.codigo_Fornecedor_Padrao} />

                      <FormInModal colWidth={2} label="Código Produto (SKU)" value={cliente.codigo_do_Produto_SKU} />

                      <FormInModal colWidth={3} label="Tipo/Gênero" value={cliente.tipo_Genero} />

                      <FormInModal colWidth={3} label="Modelo" value={cliente.modelo} />

                      <FormInModal colWidth={3} label="Categorias" value={cliente.categorias} />

                      <FormInModal colWidth={3} label="Validade" value={cliente.validade} />

                      <FormInModal colWidth={3} label="Produto é vendido por" value={cliente.produto_e_vendido_por} />




                    </div>
                    <div className="form-material row">
                      <FormInModal colWidth={3} label="Atividade" value={cliente.atividade} />
                      <FormInModal colWidth={3} label="Profissão" value={cliente.profissao} />
                      <FormInModal colWidth={3} label="Site" value={cliente.site} />
                      <FormInModal colWidth={3} label="Nome Pai" value={cliente.nome_Pai} />
                      <FormInModal colWidth={3} label="Insc Municipal" value={cliente.inscricao_Municipal} />
                      <FormInModal colWidth={3} label="Nacionalidade" value={cliente.nacionalidade} />

                      <div className="form-group col-md-2">
                        <div style={{ position: 'relative' }}>
                          <input
                            type="checkbox"
                            checked={cliente.ocultar_nas_vendas ? true : false}
                            style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            id="ocultar_nas_vendas"
                          />
                          <label className='floating-label' htmlFor="ocultar_nas_vendas"
                            style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                            Ocultar nas Vendas
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-2">
                        <div style={{ position: 'relative' }}>
                          <input
                            type="checkbox"
                            checked={cliente.visivel_no_catalogo ? true : false}
                            style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            id="visivel_no_catalogo"
                          />
                          <label className='floating-label' htmlFor="visivel_no_catalogo"
                            style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                            Visível no Catálogo
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-2">
                        <div style={{ position: 'relative' }}>
                          <input
                            type="checkbox"
                            checked={cliente.cadastro_inativo ? true : false}
                            style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            id="cadastro_inativo"
                          />
                          <label className='floating-label' htmlFor="cadastro_inativo"
                            style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                            Cadastro Inativo
                          </label>
                        </div>
                      </div>



                    </div>


                  </div>



                </FormPanel>

                <FormPanel id="exampleHeadingDefaultTwo"
                  title="Dados Endereço:" context="modal" defaultOpen={true}>



                  <div className="form-group form-material row">
                    <FormInModal colWidth={3} label="Preço de Custo (R$)" value={cliente.preco_de_Custo} real={true} />
                    <FormInModal colWidth={3} label="Desp. Operacionais (%)" value={cliente.desp_Operacionais} />
                    <FormInModal colWidth={3} label="Frete pago na Compra (%)" value={cliente.frete_Pago_na_Compra} />
                    <FormInModal colWidth={3} label="Seguro pago na Compra (%)" value={cliente.seguro_pago_na_Compra} />

                    <FormInModal colWidth={3} label="IPI (%)" value={cliente.ipi} />
                    <FormInModal colWidth={3} label="ICMS (%)" value={cliente.icms} />
                    <FormInModal colWidth={3} label="ICMS ST(%)" value={cliente.icms_st} />
                    <FormInModal colWidth={3} label="FCP ST (%)" value={cliente.fcp_st} />
                    <FormInModal colWidth={6} label="Preço de custo com Acréscimos" value={cliente.preco_de_custo_com_Acrescimos} real={true} />
                    <FormInModal colWidth={6} label="Comissão Representação (%)" value={cliente.comissao_Representacao} />
                    <FormInModal colWidth={6} label="Preço Mínimo Para Venda (R$)" value={cliente.preco_Minimo_para_Venda} real={true} />
                    <FormInModal colWidth={3} label="Comissão Vendedor (R$)" value={cliente.comissao_Vendedor} real={true} />
                    <FormInModal colWidth={3} label="Comissão Vendedor (%)" value={cliente.comissao_Vendedor_Porcento} />
                    <FormInModal colWidth={3} label="Taxa Montagem (R$)" value={cliente.taxa_Montagem} real={true} />
                    <FormInModal colWidth={3} label="Taxa Montagem (%)" value={cliente.taxa_Montagem_Porcento} />
                    <FormInModal colWidth={3} label="(MVA) Lucro R$" value={cliente.mva_Lucro} real={true} />
                    <FormInModal colWidth={3} label="(MVA) Lucro %" value={cliente.mva_Lucro_Porcento} />
                    <FormInModal colWidth={3} label="Preço de Venda (R$)" value={cliente.preco_de_Venda} real={true} />
                    <FormInModal colWidth={3} label="Valor última compra (R$)" value={cliente.valor_ultima_compra} real={true} />


                  </div>

                  <br></br>
                  <div className="form-material row">
                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.nao_emitir_alertas ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="nao_emitir_alertas"
                        />
                        <label className='floating-label' htmlFor="nao_emitir_alertas"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Alertas emitidos?
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.cada_unidade_numero_serie ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="cada_unidade_numero_serie"
                        />
                        <label className='floating-label' htmlFor="cada_unidade_numero_serie"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Unidade com série?
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.permitir_qtd_fracionada ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="permitir_qtd_fracionada"
                        />
                        <label className='floating-label' htmlFor="permitir_qtd_fracionada"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Qtd. fracionada?
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.produto_controlado_lote ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="produto_controlado_lote"
                        />
                        <label className='floating-label' htmlFor="produto_controlado_lote"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Controlado por lote?
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.venda_metragem ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="venda_metragem"
                        />
                        <label className='floating-label' htmlFor="venda_metragem"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Venda por m²?
                        </label>
                      </div>
                    </div>

                  </div>
                  <br></br>



                </FormPanel>



                <FormPanel id="exampleHeadingDefaultTwo"
                  title="Dados Endereço:" context="modal" defaultOpen={true}>



                  <div className="form-group form-material row">
                    <FormInModal colWidth={3} label="Estoque Para compra" value={cliente.estoque_para_compra} />
                    <FormInModal colWidth={3} label="Peso Bruto (KG)" value={cliente.peso_bruto} />
                    <FormInModal colWidth={3} label="Peso Líquido (KG)" value={cliente.peso_liquido} />
                    <FormInModal colWidth={3} label="Unidade de Estoque" value={cliente.unidade_de_estoque} />
                    <FormInModal colWidth={3} label="Número de Série" value={cliente.numero_de_serie} />
                    <FormInModal colWidth={3} label="Prateleira" value={cliente.prateleira} />
                    <FormInModal colWidth={3} label="Tamanho/Dimensões" value={cliente.tamanho_dimensoes} />




                  </div>
                  <div className='form-material row'>
                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.nao_emitir_alertas ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="nao_emitir_alertas"
                        />
                        <label className='floating-label' htmlFor="nao_emitir_alertas"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Emitir alertas?
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.cada_unidade_numero_serie ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="cada_unidade_numero_serie"
                        />
                        <label className='floating-label' htmlFor="cada_unidade_numero_serie"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Unid. com série?
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-md-2">
                      <div style={{ position: 'relative' }}>
                        <input
                          type="checkbox"
                          checked={cliente.permitir_qtd_fracionada ? true : false}
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                          id="permitir_qtd_fracionada"
                        />
                        <label className='floating-label' htmlFor="permitir_qtd_fracionada"
                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                          Qtd. fracionada?
                        </label>
                      </div>
                    </div>

                  </div>
                  <br></br>

                </FormPanel>
              </div>

              <FormPanel id="exampleHeadingDefaultTwo"
                title="Dados Endereço:" context="modal" defaultOpen={true}>



                <div className="form-group form-material row">
                  <FormInModal colWidth={2} label="Grupo Tributario" value={cliente.grupo_tributario} />
                  <FormInModal colWidth={2} label="CFOP Padrão" value={cliente.cfop_padrao} />
                  <FormInModal colWidth={2} label="Código NCM" value={cliente.codigo_ncm} />
                  <FormInModal colWidth={2} label="Código CEST" value={cliente.codigo_cest} />
                  <FormInModal colWidth={2} label="EAN - Código de Barras" value={cliente.ean} />
                  <FormInModal colWidth={2} label="Unidade Comercial" value={cliente.unidade_comercial} />
                  <FormInModal colWidth={2} label="Origem da Mercadoria" value={cliente.origem_da_mercadoria} />
                  <FormInModal colWidth={2} label="Fabricante" value={cliente.fabricante} />
                  <FormInModal colWidth={2} label="Código de Beneficio Fiscal" value={cliente.codigo_de_beneficio_fiscal} />
                  <FormInModal colWidth={2} label="Situação Tributária IPI" value={cliente.situacao_tributaria_ipi} />
                  <FormInModal colWidth={2} label="EX TIPI" value={cliente.ex_tipi} real={true} />
                </div>

                <div className="form-group form-material row">
                  <div className='form-group col-12 col-md-12'>
                    <FormInModal label="Informações Adicionais para NFe" value={cliente.informacoes_adicionais_nfe} />
                  </div>
                </div>


              </FormPanel>




            </div>
            <div className="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" className="btn btn-primary" onClick={handleEdit}>Editar</button>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalDetails