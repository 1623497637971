import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FormInModal from '../../GlobalComponents/ModalComponents/FormInModal';
import FormPanel from '../../GlobalComponents/FormPanel';

import { api_data } from '../../services/api';


function ModalDelete(props) {
  const navigate = useNavigate();
  const [cliente, setCategoria] = useState({});


  const [activeTab, setActiveTab] = useState('cadastro');

  const [activeTab2, setActiveTab2] = useState('endereco');


  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  }


  const handleTabChange2 = (newTab) => {
    setActiveTab2(newTab);
  }

  useEffect(() => {
    async function fetchEmpresa() {
      setCategoria({});
      const response = await api_data.get(`/api/Cadastrais/GetCliente/${props.selectId}`);

      const categoriaData = response.data;
      console.log(categoriaData);
      setCategoria(categoriaData);
    }
    fetchEmpresa();
  }, [props.selectId]);


  const limparEmpresa = () => {
    setCategoria({});

    console.log('cliquei')

  };

  const closeModal = () => {
    const modal = document.querySelector('#exampleModalCenterDelete');
    const modalBackdrop = document.querySelector('.modal-backdrop');

    modal.classList.remove('show');
    modal.setAttribute('aria-modal', 'false');
    modal.setAttribute('style', 'display: none');
    document.body.classList.remove('modal-open');

    if (modalBackdrop) {
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
  };

  const handleDelete = () => {
    api_data.delete(`/api/Cadastrais/DeleteCliente/${props.selectId}`)
      .then(response => {
        if (response.status === 200) {
          limparEmpresa();
          closeModal();
          navigate(0);
        } else {
          console.log('Erro ao excluir Cliente');
        }
      })
      .catch(error => console.log(error));
  };


  return (
    <div><div>
      <div className="modal fade" id="exampleModalCenterDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDeleteTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-danger modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLongTitle">Deletar o Cliente ?</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" >&times;</span>
              </button>
            </div>
            <div className="modal-body">



              <div className="container-fluid">
                <div className="form-material row">
                  <FormInModal colWidth={1} label="ID" value={cliente.id} />
                  <FormInModal colWidth={2} label="Pessoa" value={cliente.pessoa} />
                  <FormInModal colWidth={3} label="CNPJ" value={cliente.cnpj} />
                  <FormInModal colWidth={2} label="CPF" value={cliente.cpf} />
                  <FormInModal colWidth={2} label="Consumidor Final" value={cliente.consumidor_Final} />

                  <div className="form-group col-md-1"><div style={{ position: 'relative' }}>
                    <input
                      type="checkbox"
                      checked={cliente.categoria_Cliente ? true : false}
                      style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                    />
                    <label className='floating-label' htmlFor={cliente.categoria_Cliente}
                      style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                      Cliente
                    </label>
                  </div>
                  </div>

                </div>



              </div>
              <div className="form-material row">
                <div className="form-group col-md-1"><div style={{ position: 'relative' }}>
                  <input
                    type="checkbox"
                    checked={cliente.categoria_Cliente ? true : false}
                    style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                  />
                  <label className='floating-label' htmlFor={cliente.categoria_Cliente}
                    style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                    Fornecedor
                  </label>
                </div>
                </div>
                <div className="form-group col-md-1"></div>
                <FormInModal colWidth={5} label="Razao Social" value={cliente.razao_Social} />
                <FormInModal colWidth={5} label="Nome Fantasia" value={cliente.nome_Fantasia} />
              </div>

              <div class="accordion" id="accordionPanelsStayOpenExample">

                <FormPanel id="exampleHeadingDefaultThree"
                  title="Dados Cadastrais:" context="modal">

                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab === 'cadastro' ? 'active' : ''}`}
                        id="dadosCadastraisTab"
                        onClick={() => handleTabChange('cadastro')}
                        role="tab"
                        aria-controls="dadosCadastrais"
                        aria-selected={activeTab === 'cadastro'}>Cadastro</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab === 'detalhes' ? 'active' : ''}`}
                        id="detalhesCadastraisTab"
                        onClick={() => handleTabChange('detalhes')}
                        role="tab"
                        aria-controls="detalhesCadastrais"
                        aria-selected={activeTab === 'detalhes'}>Cadastro Form 2</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="nav-tabContet">
                    <div className="tab-pane" id="dadosCadastrais" role="tabpanel" aria-labelledby="dadosCadastraisTab" style={{ display: activeTab === 'cadastro' ? 'block' : 'none' }}>
                      <div className="panel-body container-fluid">
                        <div className="form-material row">
                          <FormInModal colWidth={3} label="Insc Estadual" value={cliente.inscricao_Estadual} />
                          <FormInModal colWidth={3} label="RG" value={cliente.rg} />
                          <FormInModal colWidth={3} label="Indicador Dest" value={cliente.indicador_IE_Destinatario} />
                          <FormInModal colWidth={3} label="Emissor" value={cliente.emissor} />
                          <FormInModal colWidth={3} label="Data Abertura" value={cliente.data_Abertura} />
                          <FormInModal colWidth={3} label="Data Nascimento" value={cliente.data_Nascimento} />
                          <FormInModal colWidth={3} label="Estado Civil" value={cliente.estado_Civil} />
                          <FormInModal colWidth={3} label="Email" value={cliente.email} />
                          <FormInModal colWidth={3} label="Fone Fixo" value={cliente.foneFixo} />
                          <FormInModal colWidth={3} label="Celular" value={cliente.celular} />
                          <div className="form-group col-md-1"><div style={{ position: 'relative' }}>
                            <input
                              type="checkbox"
                              checked={cliente.zona_Franca ? true : false}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            />
                            <label className='floating-label' htmlFor={cliente.zona_Franca}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                              Zona Franca
                            </label>
                          </div>
                          </div>

                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={3} label="Atividade" value={cliente.atividade} />
                          <FormInModal colWidth={3} label="Profissão" value={cliente.profissao} />
                          <FormInModal colWidth={3} label="Site" value={cliente.site} />
                          <FormInModal colWidth={3} label="Nome Pai" value={cliente.nome_Pai} />
                          <FormInModal colWidth={3} label="Insc Municipal" value={cliente.inscricao_Municipal} />
                          <FormInModal colWidth={3} label="Nacionalidade" value={cliente.nacionalidade} />

                          <div className="form-group col-md-1"><div style={{ position: 'relative' }}>
                            <input
                              type="checkbox"
                              checked={cliente.permite_Credito ? true : false}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            />
                            <label className='floating-label' htmlFor={cliente.permite_Credito}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                              Permite Créd. ICMS
                            </label>
                          </div>
                          </div>


                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={3} label="ID Estrangeiro" value={cliente.iD_Estrangeiro} />
                          <FormInModal colWidth={3} label="Regime Tributario" value={cliente.regime_Tributario} />
                          <FormInModal colWidth={3} label="Nome Mae" value={cliente.nome_Mae} />
                          <FormInModal colWidth={3} label="CNAE" value={cliente.cnae} />
                          <FormInModal colWidth={3} label="Cartao Fidelidade" value={cliente.cartao_Fidelidade} />
                          <div className="form-group col-md-1"><div style={{ position: 'relative' }}>
                            <input
                              type="checkbox"
                              checked={cliente.simples_Nacional ? true : false}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            />
                            <label className='floating-label' htmlFor={cliente.simples_Nacional}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                              Simples Nacional
                            </label>
                          </div>
                          </div>
                          <div className='panel-body container-fluid'>
                            <div className='form-material row'>
                              <div className="form-group col-md-12" data-plugin="formMaterial">
                                <table className="table table-hover table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Outros Contatos</th>
                                      <th>Tipo</th>
                                      <th>Fone ou Email</th>
                                      <th>Obs</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cliente.contatos && cliente.contatos.map((contato, index) => (
                                      <tr key={index}>
                                        <td>{contato.contato}</td>
                                        <td>{contato.tipo_Contato}</td>
                                        <td>{contato.fone_Email}</td>
                                        <td>{contato.obs}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="tab-pane" id="detalhesCadastrais" role="tabpanel" aria-labelledby="detalhesCadastraisTab" style={{ display: activeTab === 'detalhes' ? 'block' : 'none' }}>
                      <div className="panel-body container-fluid">
                        <div className="form-material row">
                          <FormInModal colWidth={3} label="Data Cadastro" value={cliente.data_Cadastro} />
                          <FormInModal colWidth={3} label="Data Primeira Compra" value={cliente.data_Primeira_Compra} />
                          <FormInModal colWidth={3} label="Data Ultima Compra" value={cliente.data_Ultima_Compra} />
                          <FormInModal colWidth={3} label="Fonte" value={cliente.fonte} />

                        </div>
                        <div className="form-material row">
                          <div className="form-group col-md-5">
                            <label className="form-control">
                              <input type="checkbox" value="" disabled="disabled" />
                              Bloquear Cadastro - Motivo
                            </label>
                            <textarea rows="3" cols="40" readonly>
                            </textarea>
                          </div>
                          <div className="form-group col-md-7">
                            <label className="form-control">

                              Referencias Bancarias e comerciais
                            </label>
                            <textarea rows="3" cols="60" readonly>
                            </textarea>
                          </div>
                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={5} label="Usuario Bloqueou" value={cliente.usuario_Bloqueou} />
                          <FormInModal colWidth={1} label="ID Emp" value={cliente.iD_Empresa} />
                          <FormInModal colWidth={1} label="ID Emp" value={cliente.iD_Empresa} />

                        </div>
                      </div>
                    </div>

                  </div>


                </FormPanel>

                <FormPanel id="exampleHeadingDefaultTwo"
                  title="Dados Endereço:" context="modal">



                  <ul className="nav nav-tabs" id="myTab2" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab2 === 'endereco' ? 'active' : ''}`}
                        id="enderecoTab"
                        onClick={() => handleTabChange2('endereco')}
                        role="tab"
                        aria-controls="endereco"
                        aria-selected={activeTab2 === 'endereco'}>Endereço</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab2 === 'localEntrega' ? 'active' : ''}`}
                        id="localEntregaTab"
                        onClick={() => handleTabChange2('localEntrega')}
                        role="tab"
                        aria-controls="localEntrega"
                        aria-selected={activeTab2 === 'localEntrega'}>Local Entrega</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab2 === 'localCobranca' ? 'active' : ''}`}
                        id="localCobrancaTab"
                        onClick={() => handleTabChange2('localCobranca')}
                        role="tab"
                        aria-controls="localCobranca"
                        aria-selected={activeTab2 === 'localCobranca'}>Local Cobrança</a>
                    </li>
                  </ul>


                  <div className="tab-content" id="nav-tabContent2">
                    <div className={`tab-pane fade ${activeTab2 === 'endereco' ? 'show active' : ''}`} id="endereco" role="tabpanel" aria-labelledby="enderecoTab">
                      <div className="panel-body container-fluid">
                        <div className="form-material row">


                          <div className="form-group col-md-2"><div style={{ position: 'relative' }}>
                            <input
                              type="checkbox"
                              checked={cliente.local_Entrega_Diferente ? true : false}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            />
                            <label className='floating-label' htmlFor={cliente.local_Entrega_Diferente}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                              Local de Entrega Diferente Deste
                            </label>
                          </div>
                          </div>
                          <div className="form-group col-md-3"></div>

                          <div className="form-group col-md-1"><div style={{ position: 'relative' }}>
                            <input
                              type="checkbox"
                              checked={cliente.local_Cobranca_Diferente ? true : false}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                            />
                            <label className='floating-label' htmlFor={cliente.local_Cobranca_Diferente}
                              style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                              Local de Entrega Diferente Deste
                            </label>
                          </div>
                          </div>

                        </div>
                        <br />
                        <br />
                        <div className="form-material row">
                          <FormInModal colWidth={3} label="CEP" value={cliente.cep} />


                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={5} label="Logradouro" value={cliente.logradouro} />
                          <FormInModal colWidth={1} label="Numero" value={cliente.numero} />
                          <FormInModal colWidth={4} label="Complemento" value={cliente.complemento} />
                          <FormInModal colWidth={2} label="Bairro" value={cliente.bairro} />
                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={1} label="Código Pais" value={cliente.codigo_Pais} />
                          <FormInModal colWidth={3} label="País" value={cliente.pais} />
                          <FormInModal colWidth={1} label="Código UF" value={cliente.codigo_UF} />
                          <FormInModal colWidth={1} label="UF" value={cliente.uF} />
                          <FormInModal colWidth={2} label="Código Municipio" value={cliente.codigo_Municipio} />
                          <FormInModal colWidth={4} label="Municipio" value={cliente.municipio} />


                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab2 === 'localEntrega' ? 'show active' : ''}`} id="localEntrega" role="tabpanel" aria-labelledby="localEntregaTab">
                      <div className="panel-body container-fluid">
                        <div className="form-material row">
                          <h4 className="mx-a">Endereço de Entrega</h4>
                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={3} label="CEP Entrega" value={cliente.ceP_Entrega} />
                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={5} label="Logradouro Entrega" value={cliente.logradouro_Entrega} />
                          <FormInModal colWidth={1} label="Número" value={cliente.numero_Entrega} />
                          <FormInModal colWidth={4} label="Complemento Entrega" value={cliente.complemento_Entrega} />
                          <FormInModal colWidth={2} label="Bairro Entrega" value={cliente.bairro_Entrega} />

                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={1} label="Código País Entrega" value={cliente.codigo_Pais_Entrega} />
                          <FormInModal colWidth={3} label="País Entrega" value={cliente.pais_Entrega} />
                          <FormInModal colWidth={1} label="Código UF Entrega" value={cliente.codigo_UF_Entrega} />
                          <FormInModal colWidth={1} label="UF Entrega" value={cliente.uF_Entrega} />
                          <FormInModal colWidth={2} label="Código Município Entrega" value={cliente.codigo_Municipio_Entrega} />
                          <FormInModal colWidth={4} label="Município Entrega" value={cliente.municipio_Entrega} />
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab2 === 'localCobranca' ? 'show active' : ''}`} id="localCobranca" role="tabpanel" aria-labelledby="localCobrancaTab">
                      <div className="panel-body container-fluid">
                        <div className="form-material row">
                          <h4 className="mx-auto">Endereço de Cobrança</h4>
                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={2} label="CEP Cobranca" value={cliente.ceP_Cobranca} />
                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={5} label="Logradouro Cobrança" value={cliente.logradouro_Cobranca} />
                          <FormInModal colWidth={1} label="Número" value={cliente.numero_Cobranca} />
                          <FormInModal colWidth={4} label="Complemento Cobrança" value={cliente.complemento_Cobranca} />
                          <FormInModal colWidth={2} label="Bairro Cobrança" value={cliente.bairro_Cobranca} />

                        </div>
                        <div className="form-material row">
                          <FormInModal colWidth={1} label="Código País Cobrança" value={cliente.codigo_Pais_Cobranca} />
                          <FormInModal colWidth={3} label="País Cobrança" value={cliente.pais_Cobranca} />
                          <FormInModal colWidth={1} label="Código UF Cobrança" value={cliente.codigo_UF_Cobranca} />
                          <FormInModal colWidth={1} label="UF Cobrança" value={cliente.uF_Cobranca} />
                          <FormInModal colWidth={2} label="Código Município Cobrança" value={cliente.codigo_Municipio_Cobranca} />
                          <FormInModal colWidth={4} label="Município Cobrança" value={cliente.municipio_Cobranca} />

                        </div>
                      </div>
                    </div>
                  </div>


                </FormPanel>



                <FormPanel id="exampleHeadingDefaultFour"
                  title="Informações de Venda:" context="modal">


                  <div className="panel-body container-fluid">
                    <div className="form-material row">
                      <FormInModal colWidth={4} label="Condicao Pag" value={cliente.condicao_Pagamento} />
                      <FormInModal colWidth={2} label="Vendendor" value={cliente.iD_Vendedor} />
                      <FormInModal colWidth={4} label="Condicao Pag" value={cliente.nome_Fantasia} />
                      <FormInModal colWidth={2} label="Condicao Pag" value={cliente.comissaoVendedor} />

                    </div>
                    <div className="form-material row">
                      <FormInModal colWidth={2} label="Desconto (%)" value={cliente.desconto} />
                      <FormInModal colWidth={2} label="Limite de Crédito" value={cliente.limite_Credito} />
                      <FormInModal colWidth={2} label="ID Representante" value={cliente.iD_Representante} />
                      <FormInModal colWidth={4} label="Razão Social Representante" value={cliente.razao_Social} />
                      <FormInModal colWidth={2} label="Comissão (%)" value={cliente.comissaoRepresentante} />

                    </div>
                  </div>
                  <div class="panel-body container-fluid">
                    <div class="form-material row">
                      <div class="form-group col-md-12" data-plugin="formMaterial">
                        <textarea class="form-control " rows="5" cols="15" readonly>
                        </textarea>
                        <label class="floating-label">Observação</label>
                      </div>
                    </div>
                  </div>


                </FormPanel>
              </div>









            </div>
            <div className="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>

              <button type="button" className="btn btn-primary" onClick={handleDelete}>Excluir</button>


            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ModalDelete