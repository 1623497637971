import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import { api_data } from '../../services/api';

import { Modal } from 'bootstrap';

function ModalDelete(props) {


    const navigate = useNavigate();

    const [lancamento, setLancamento] = useState({});

    useEffect(() => {
        async function fetchEmpresa() {
            console.log(props.selectId)
            setLancamento({});
            const response = await api_data.get(`/api/Lancamentos/GetLancamento/${props.selectId}`);

            console.log(response)
            const categoriaData = await response.data;
            setLancamento(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setLancamento({});
        console.log(lancamento)
        console.log('cliquei')
    };

    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };

    const handleEdit = () => {
        limparEmpresa();
        handleCloseModal();

        navigate(`/Lancamento/Edit/${props.selectId}`);
    };

    const handleDelete = () => {
        api_data.delete(`/api/Lancamento/DeleteLancamento/${props.selectId}`)
            .then(response => {
                if (response.status === 200) {
                    limparEmpresa();
                    handleCloseModal();
                    navigate(0);
                } else {
                    console.log('Erro ao excluir Lancamento');
                }
            })
            .catch(error => console.log(error));
    };

    return (
        <div>
            <div className="modal fade" id="exampleModalCenterDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDeleteTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-danger modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Deletar o Lançamento</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <div class="accordion" id="accordionPanelsStayOpenExample">


                                <FormPanel id="exampleHeadingDefaultTwo"
                                    title="Informações de Lançamento:" context="modal">

                                    <br></br>
                                    <br></br>

                                    <div className="form-material row">
                                        <div className="form-group col-md-2" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={lancamento.cliente_Fornecedor} />
                                            <label className="floating-label">Cliente / Fornecedor </label>

                                        </div>
                                        <div className="form-group col-md-2" data-plugin="formMaterial">
                                            <input className="form-control" readOnly value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lancamento.valor)} />
                                            <label className="floating-label">Valor</label>
                                        </div>
                                        <div className="form-group col-md-2" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={lancamento.planoConta} />
                                            <label className="floating-label">Plano de conta</label>
                                        </div>
                                        <div className="form-group col-md-3" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={lancamento.forma_Pagamento} />
                                            <label className="floating-label">Forma de Pagamento</label>
                                        </div>

                                        <div className="form-group col-md-3" data-plugin="formMaterial">
                                            <input
                                                className="form-control"
                                                readOnly
                                                value={lancamento.data_Vencimento ? lancamento.data_Vencimento.slice(0, 10) : ''}
                                            />
                                            <label className="floating-label">Data do vencimento</label>

                                        </div>
                                        <div className="form-group col-md-3" data-plugin="formMaterial">
                                            <input className="form-control" readOnly value={lancamento.data_Competencia ? lancamento.data_Competencia.slice(0, 10) : ''} />
                                            <label className="floating-label">Data da competencia</label>
                                        </div>

                                    </div>


                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultThree" title="Mostrar Mais Campos :" context="modal">
                                    <div className='panel-body container-fluid'>
                                        <div className="form-material row">
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " readOnly />
                                                <label className="floating-label">Centro de Custos</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " readOnly value={lancamento.desconto_Per} />
                                                <label className="floating-label">Desconto (%)</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lancamento.desconto_Real)} />
                                                <label className="floating-label">Desconto (R$)</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.grupo} />
                                                <label className="floating-label">Desconto Até o vencimento(%)</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lancamento.juros_Antecipacao_Rs)} />
                                                <label className="floating-label">Entrada / Adiamento (R$)</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.numero_Nome_Documento} />
                                                <label className="floating-label">Numero / Nome documento</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.grupo} />
                                                <label className="floating-label">Grupo</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lancamento.taxa_De_Juros_Per)} />
                                                <label className="floating-label">Juros Antecipação (R$)</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.numero_De_Parcelas} />
                                                <label className="floating-label">Juros Antecipação (%)</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" readOnly value={lancamento.data_Vencimento_Original ? lancamento.data_Vencimento_Original.slice(0, 10) : ''} />
                                                <label className="floating-label">Data vencimento Original</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" readOnly value={lancamento.data_Cadastro ? lancamento.data_Cadastro.slice(0, 10) : ''} />
                                                <label className="floating-label">Data Cadastro</label>
                                            </div>

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" readOnly value={lancamento.data_Quitacao ? lancamento.data_Quitacao.slice(0, 10) : ''} />
                                                <label className="floating-label">Data Quitação</label>
                                            </div>
                                        </div>

                                    </div>
                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultThree" title="Negativação SERASA :" context="modal">
                                    <div className='panel-body container-fluid'>
                                        <div className="form-material row">

                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.dias_Em_Atraso_Para_Negativar} />
                                                <label className="floating-label">Dias em atraso para negativar</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.tipo_De_Operacao} />
                                                <label className="floating-label">Instrução negativação</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" readOnly value={lancamento.data_Negativacao ? lancamento.data_Negativacao.slice(0, 10) : ''} />
                                                <label className="floating-label">Data Negativação</label>
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={lancamento.negativar ? true : false}
                                                    style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                                                />
                                                <label className='floating-label' htmlFor={lancamento.despesa}
                                                    style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                                                    Negativado
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultThree" title="Detalhes do Pagamento :" context="modal">
                                    <div className='panel-body container-fluid'>
                                        <div className="form-material row">
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.tipo_pagamento} />
                                                <label className="floating-label">Forma de Pagamento</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control " value={lancamento.formaPagamento} />
                                                <label className="floating-label">Tipo de Pagamento</label>
                                            </div>
                                            <div className="form-group col-md-3" data-plugin="formMaterial">
                                                <input className="form-control" readOnly value={lancamento.data_pagamento ? lancamento.data_pagamento.slice(0, 10) : ''} />
                                                <label className="floating-label">Data de Pagamento</label>
                                            </div>

                                        </div>
                                    </div>
                                </FormPanel>


                            </div>




                        </div>



                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" onClick={handleDelete}>Delete</button>

                        </div>

                    </div>

                </div>
            </div>
        </div >

    )
}

export default ModalDelete