import React, { useState } from 'react';

import ModalTableEmp from './ModalTableEmp';
import ModalPesFormEmp from './ModalPesFormEmp';

function ModalPesquisarEmp({ onEmpresaIdChange, setFieldValue, setEmpCreated }) {
  const [isCreating, setIsCreating] = useState(false);
  const [empresaCreated, setEmpresaCreated] = useState(false);

  const handleCreateClick = () => {
    setIsCreating(true);
  };

  const handleCancelClick = () => {
    setIsCreating(false);
  };

  const handleEmpresaIdChange = (id) => {
    console.log(`Empresa ID selecionado: ${id}`);
  };
  
  const handleEmpresaCreated = () => {
    setEmpresaCreated(!empresaCreated);
  };

  return (
    <div>
      <div className="modal fade" id="ModalEmpPesquisar" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLongTitle">Pesquisar Empresa</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" >&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <a 
                className="btn btn-success" 
                style={{ color: 'white' }} 
                onClick={isCreating ? handleCancelClick : handleCreateClick}
              >
                <i className="fa fa-plus-circle"></i>&nbsp;&nbsp; {isCreating ? 'Voltar' : 'Incluir uma nova Empresa'}
              </a>
              {isCreating ? <ModalPesFormEmp setIsCreating={setIsCreating} onEmpresaCreated={handleEmpresaCreated} setEmpCreated={setEmpCreated} /> : <ModalTableEmp onIdRepChange={(value) => setFieldValue('iD_Empresa', value)} />}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fechar</button>            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPesquisarEmp;
