import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api, api_data } from '../../services/api';
import { useNavigate } from "react-router-dom";

function ModalDetails(props) {

    const navigate = useNavigate();

    const [usuario, setUsuario] = useState({});
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        async function fetchEmpresasList() {
            try {
                const response = await api_data.get('/api/Bancos/GetEmpresasList');
                setEmpresas(response.data); 
            } catch (error) {
                console.error('Ocorreu um erro ao buscar a lista de empresas', error);
            }
        }
        fetchEmpresasList();
    }, []);

    const handleEditClick = () => {
        
        document.getElementById('closeModalButton').click();

       
        setTimeout(() => {
            navigate(`/Usuario/Edit/${props.selectId}`);
        }, 300); 
    };


    
    useEffect(() => {
        async function fetchEmpresa() {
            setUsuario({});

            const response = await api.get(`/GetUsuario/${props.selectId}`);
            const usuarioData = await response.data;

            
            const empresaCorrespondente = empresas.find(e => e.ID_Empresa === usuarioData.iD_Empresa);
            if (empresaCorrespondente) {
                usuarioData.empresaRazaoSocial = empresaCorrespondente.Razao_Social;
            }

            setUsuario(usuarioData);
        }
        fetchEmpresa();
    }, [props.selectId, empresas]);

    const limparEmpresa = () => {
        setUsuario({});
        console.log(usuario)
        console.log('cliquei')

    };

    const closeModal = () => {
        const modal = document.querySelector('#exampleModalCenter');
        const modalBackdrop = document.querySelector('.modal-backdrop');

        modal.classList.remove('show');
        modal.setAttribute('aria-modal', 'false');
        modal.setAttribute('style', 'display: none');
        document.body.classList.remove('modal-open');

        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Usuario</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>

                                <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input key={usuario.id} className='form-control' data-plugin="formatter" value={usuario.id} />
                                        <label className='floating-label'>ID</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={usuario.apelido} className='form-control' data-plugin="formatter" value={usuario.apelido} />
                                        <label className='floating-label'>Apelido</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={usuario.userName} className='form-control' readOnly value={usuario.userName} />
                                        <label className='floating-label'>Nome de Usuario</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={usuario.email} className='form-control' readOnly value={usuario.email} />
                                        <label className='floating-label'>Email</label>
                                    </div>


                                </div>
                                <br></br>
                                <br></br>
                                <div className='form-material row'>


                                    <div className='form-group col-md-5' data-plugin="formMaterial">
                                        <input key={usuario.iD_Empresa} className='form-control' data-plugin="formatter" value={usuario.empresaRazaoSocial || usuario.iD_Empresa} />
                                        <label className='floating-label'>Empresa</label>
                                    </div>

                                    
                                    




                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button id="closeModalButton" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <span className="btn btn-primary" onClick={handleEditClick}>Editar</span>
          

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails