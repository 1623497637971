import React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState, useEffect, useMemo } from 'react';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';
import FormInReal from '../../GlobalComponents/FormInReal';
import FormDate from '../../GlobalComponents/FormDate';


import ModalPesquisarRep from "./ModalCliente/ModalPesquisarRep";
import ModalPesquisarPlano from "./ModalContas/ModalPesquisarPlano";
function EditLancamentos() {
  const { id } = useParams();

  const [representantes, setRepresentantes] = useState([]);
  const [contas, setContas] = useState([]);
  const [lancamentos, setLancamentos] = useState([])

  const [iD_BancoAtual, setID_Cliente] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api_data.get(`/api/Lancamentos/GetLancamento/${id}`);
        setLancamentos(response.data);
        console.log(lancamentos)
        setLoading(false);
      } catch (error) {
        console.log("Erro ao recuperar os dados do usuario");
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchRepresentantes = async () => {
      const response = await api_data.get('api/Cadastrais/GetAllRazaoSocial');
      setRepresentantes(response.data);
    };

    const fetchContas = async () => {
      const response = await api_data.get('api/Categorias_Contas/GetAllRequests');
      setContas(response.data);
    };

    fetchRepresentantes();
    fetchContas();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    iD_Lancamento: id,
    ...lancamentos,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const fieldsToConvert = ["valor", "desconto_Real", "juros_Antecipacao_rs", "taxa_De_Juros_Per", "desconto_Per"];
  
    fieldsToConvert.forEach(field => {
      if (values[field]) {
        values[field] = parseFloat(values[field].toString().replace("R$ ", "").replace(/\./g, "").replace(",", "."));
      }
    });
  
    try {
      const response = await api_data.put(`/api/Lancamentos/UpdateLancamento/${id}`, values);
      console.log(response);
      navigate("/Lancamentos");
      setShowErrorMessage(false);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
      setShowErrorMessage(true);
    } finally {
      setSubmitting(false);
    }
  };
  





  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Lancamentos</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Lancamentos</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/Lancamentos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Lancamentos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Lancamento:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormSelect colWidth={2} name="cliente_Fornecedor" label="Cliente Fornecedor">
                        {representantes.map((representante) => (
                          <option key={representante.iD_Cadastrais} value={representante.iD_Cadastrais}>
                            {representante.razao_Social}
                          </option>
                        ))}
                      </FormSelect>
                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalRepPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>

                      <FormSelect colWidth={2} name="planoConta" label="Plano de Conta">
                        {contas.map((contas) => (
                          <option key={contas.iD_Categorias_Contas} value={contas.iD_Categorias_Contas}>
                            {contas.nome}
                          </option>
                        ))}
                      </FormSelect>
                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalPlanoPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>


                      <FormInReal colWidth={3} name="valor" label="Valor(R$)" />
                      <FormInput colWidth={2} name="forma_Pagamento" label="Forma de Pagamento" />
                    </div>
                    <div className="form-group form-material row">
                      <FormInput colWidth={2} name="conta_bancaria" label="Conta Bancaria" />
                      <FormDate colWidth={3} name="data_Competencia" label="Data de competência" />
                      <FormDate colWidth={3} name="data_Vencimento" label="Data de vencimento" />
                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="plnContas" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Foi quitado ?</label>
                        </div>
                      </div>
                    </div>
                  </FormPanel>



                  <FormPanel id="exampleHeadingDefaultSix" href="#exampleCollapseDefaultSix" ariaControls="exampleCollapseDefaultSix"
                    title="Mostrar mais Campos:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <div className='form-group col-12 col-md-3' data-plugin="formMaterial">
                        <div className='input-group'>
                          <Field name="ctrContas">
                            {({ field }) => (
                              <input type="text" className="form-control" {...field} />
                            )}
                          </Field>
                          <label className='floating-label'>Centro de Custos</label>
                          <button type='button' className='btn btn-primary'>Autorizar</button>
                        </div>
                      </div>
                      <FormInput colWidth={2} name="numero_Nome_Documento" label="Cliente / Fornecedor" />
                      <FormInput colWidth={3} name="desconto_Per" label="Desconto (%)" />
                      <FormInReal colWidth={3} name="desconto_Real" label="Desconto (R$)" />
                      <FormInput colWidth={2} name="grupo" label="Desconto Até o vencimento(%)" />
                      <FormInReal colWidth={2} name="juros_Antecipacao_Rs" label="Entrada / Adiamento (R$)" />
                      <FormInput colWidth={2} name="juros_Antecipacao_Per" label="Numero / Nome documento" />
                      <FormDate colWidth={2} name="data_Vencimento_Original" label="Data Vencimento Original" />
                      <FormInReal colWidth={2} name="taxa_De_Juros_Per" label="Juros Antecipação (R$) " />
                      <FormInput colWidth={2} name="numero_De_Parcelas" label="Juros Antecipação (%)" />

                      <FormDate colWidth={3} name="data_Cadastro" label="Data Cadastro" />
<FormDate colWidth={2} name="data_Quitacao" label="Data Quitação" />

                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="conciliado" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Foi Conciliado ?</label>
                        </div>
                      </div>






                    </div>
                    <div className="form-group form-material row">
                      <FormInput colWidth={12} name="linha_Digitavel_Do_Documento" label="Linha digitavel do documento" ></FormInput>
                    </div>
                    <div className="form-group form-material row">
                      <div className='form-group col-12 col-md-6'>
                        <Field name="observacoes" as="textarea" className='form-control'></Field>
                        <label className='floating-label'>Observações</label>
                      </div>
                      <div className='form-group col-12 col-md-6'>
                        <Field name="breveDescricao" as="textarea" className='form-control'></Field>
                        <label className='floating-label'>Breve descrição</label>
                      </div>
                    </div>
                  </FormPanel>


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Negativação SERASA:" defaultOpen={true}>

                    <div className="form-group form-material row">
                      <FormInput colWidth={3} name="dias_Em_Atraso_Para_Negativar" label="Dias em atraso para negativar" />
                      <FormDate colWidth={3} name="data_Negativacao" label="Data negativação" numero={true} />

                      <FormSelect colWidth={4} name="tipo_De_Operacao" label="Tipo de Operação">
                        <option value="" label="Selecionar opção" />
                        <option value="Outras operações">Outras operações</option>
                        <option value="Prestação de serviço/Vendas de Mercadoria">Prestação de serviço/Vendas de Mercadoria</option>
                        <option value="Duplicata">Duplicata</option>
                        <option value="Aluguel">Aluguel</option>
                      </FormSelect>

                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="Negativar" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Negativar ?</label>
                        </div>
                      </div>

                    </div>
                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultSeven" href="#exampleCollapseDefaultSeven" ariaControls="exampleCollapseDefaultSeven"
                    title="Campos para Remessa de Pagamento :" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={2} name="tipo_pagamento" label="Forma de Pagamento" />
                      <FormSelect colWidth={3} name="formaPagamento" label="Tipo de pagamento">
                        <option value="0">Selecione</option>
                        <option value="1">Crédito em Conta Corrente no Itaú</option>
                        <option value="2">Cheque Pagamento/Administrativo</option>
                        <option value="3">DOC "C"</option>
                        <option value="4">Crédito em conta corrente de mesma Titularidade</option>
                        <option value="5">DOC "D"</option>
                        <option value="6">Ordem de Pagamento à Disposição</option>
                        <option value="7">Pagamento de Concessionárias</option>
                        <option value="8">DARF Normal</option>
                        <option value="9">GPS - Guia de Previdencia Social</option>
                        <option value="10">DARF Simples</option>
                        <option value="11">IPTU/ISS/OUTROS Tributos Municipais</option>
                        <option value="12">GARE - SP ICMS</option>
                        <option value="13">Pagamentos de Títulos em Cobrança no Itaú</option>
                        <option value="14">Pagamentos de Títulos em Cobrança em Outros Bancos</option>
                        <option value="15">Nota Fiscal - Liquidação Eletrônica</option>
                        <option value="16">FGTS</option>
                        <option value="17">TED - Outro Titular</option>
                        <option value="18">TED - Mesmo Titular</option>
                        <option value="19">PIX Transferência Itaú</option>
                        <option value="20">PIX Transferência Outros Bancos</option>
                        <option value="21">PIX QR-CODE Itaú</option>
                        <option value="22">PIX QR-CODE Outros Bancos</option>
                        <option value="23">GNRE e Tributos com Código de Barras</option>
                      </FormSelect>

                      <FormDate colWidth={3} name="data_pagamento" label="Data de Pagamento" />



                    </div>
                  </FormPanel>



                


                  <br></br>

                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar a Categoria</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar a Categoria"}
                          </button>
                          <Link to="/Categoria_Contas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <ModalPesquisarRep setFieldValue={setFieldValue} onIdRepChange={(value) => { setID_Cliente(value) }} />
                <ModalPesquisarPlano setFieldValue={setFieldValue} onIdRepChange={(value) => { setID_Cliente(value) }} />


              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default EditLancamentos