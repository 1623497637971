import React, { useState, useEffect } from 'react';
import { api_data } from '../../services/api';
import { useNavigate } from "react-router-dom";

function ModalDelete(props) {
    const navigate = useNavigate();
    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});
            const response = await api_data.get(`/api/GrupoAll/GetGrupo_Pessoa/${props.selectId}`);
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };

    const closeModal = () => {
        const modal = document.querySelector('#exampleModalCenterDelete');
        const modalBackdrop = document.querySelector('.modal-backdrop');

        modal.classList.remove('show');
        modal.setAttribute('aria-modal', 'false');
        modal.setAttribute('style', 'display: none');
        document.body.classList.remove('modal-open');

        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
    };

    const handleDelete = () => {
        api_data.delete(`/api/GrupoAll/DeleteGrupo_Pessoas/${props.selectId}`)
            .then(response => {
                if (response.status === 200) {
                    limparEmpresa();
                    closeModal();


                    navigate(0);
                } else {
                    console.log('Erro ao excluir o Centro Custo');
                }
            })
            .catch(error => console.log(error));
    };


    return (
        <div><div>
            <div className="modal fade" id="exampleModalCenterDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDeleteTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-danger modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Deletar o Grupo Pessoas</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                <div className='form-group col-md-2' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.iD_GrupoPessoas} name="iD_GrupoLancamentos" />
                                        <label className='floating-label'>ID Grupo Pessoas</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.nome} name="nome" />
                                        <label className='floating-label'>Nome</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input className='form-control' data-plugin="formatter" value={categoria.observacoes} name="observacoes" />
                                        <label className='floating-label'>Observações</label>
                                    </div>
                                  
                                </div>
                               
                            </div>




                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>

                            <button type="button" className="btn btn-primary" onClick={handleDelete}>Excluir</button>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ModalDelete