import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';

import "../../Styles/MediaPrintContext/print.css"
import "../../Styles/DatatableStyle/tableStyle.css"
import 'react-datepicker/dist/react-datepicker.css';


import copy from 'clipboard-copy';
import { unparse } from 'papaparse';

import ModalDetails from './ModalDetails';
import ModalDelete from './ModalDelete';

import { api_data } from '../../services/api';
import FormInput from '../../GlobalComponents/FormInput';
import FormInputLess from '../../GlobalComponents/FormInputLess';
import FormSelectLess from '../../GlobalComponents/FormSelectLess';
import DatePicker from 'react-datepicker';


function copyToClipboard(data) {
  const clipboardText = data.map(row => Object.values(row).join('\t')).join('\n');
  copy(clipboardText);
}

function downloadCSV(data) {

  const csv = unparse(data);


  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  link.download = 'data.csv';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


//No style, é possivel adicionar o estilo que vai ser feito para a tabela
function printTable(data, columns) {
  const html = `
    <html>
      <head>
        <title>Print table</title>
        <style>
         
        </style>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              ${columns.map(column => `<th>${column.Header}</th>`).join('')}
            </tr>
          </thead>
          <tbody>
            ${data.map(row => `
              <tr>
                ${columns.map(column => `<td>${row[column.accessor]}</td>`).join('')}
              </tr>
            `).join('')}
          </tbody>
        </table>
        <script>
          (function() {
            window.print();
            setTimeout(function() { window.close(); }, 500);
          })();
        </script>
      </body>
    </html>
  `;

  const printWindow = window.open('', '_blank');
  printWindow.document.write(html);
  printWindow.document.close();
}




function DatatablePlugin() {
  const [data, setData] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDiv, setShowDiv] = useState(false);
  const [clienteFornecedorValue, setClienteFornecedorValue] = useState("");
  const [selectId, setSelectId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaldoModalOpen, setIsSaldoModalOpen] = useState(false);
  const [isTransferenciaModalOpen, setIsTransferenciaModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [originalData, setOriginalData] = useState(data);
  const [selectedDateOption, setSelectedDateOption] = useState(data);
  const [selectedFilter, setSelectedFilter] = useState('');



  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);

  


  const handleChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };


  const fetchData = async () => {
    setIsLoading(true);
    let result;

    result = await api_data.get('/api/Lancamentos/GetLancamentos');

    const dataWithActionID = result.data.map(item => ({ ...item, actionID: item.iD_Lancamento }));
    setOriginalData(dataWithActionID);
    setData(dataWithActionID);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [idEmpresa]);




  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'iD_Lancamento',
        width: '5%',
      },
      {
        Header: 'Cliente / Fornecedor',
        accessor: 'cliente_Fornecedor',
        width: '20%',
      },
      {
        Header: 'Valor',
        accessor: 'valor',
        width: '15%',
        Cell: ({ value }) =>
          new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),


      },
      {
        Header: 'Plano Conta',
        accessor: 'PlanoConta',
        width: '15%',
      },
      {
        Header: 'Documento',
        accessor: 'numero_Nome_Documento',
        width: '10%',
      },
      {
        Header: 'Data vencimento',
        accessor: row => new Date(row.data_Vencimento).toISOString().split('T')[0],
        width: '10%',
      }
      ,

      {
        Header: 'Ação',
        width: '10%',
        accessor: 'actionID',
        Cell: ({ value }) => (
          <>
            <Link to={`/Lancamentos/Edit/${value}`}
              className="btn btn-info btn-icon on-default edit-row"
              style={{ marginRight: '3px' }}>
              <i className="icon md-edit" aria-hidden="true"></i>
            </Link>
            <button
              className="btn btn-warning btn-icon on-default fc-row"
              data-bs-toggle="modal"
              style={{ marginRight: '3px' }}
              data-bs-target="#exampleModalCenter"
              onClick={() => handleModalOpen(value)}
            >
              <i className="icon md-card" aria-hidden="true"></i>
            </button>

            <button
              className="btn btn-danger btn-icon on-default fc-row"
              data-bs-toggle="modal"
              style={{ marginRight: '3px' }}
              data-bs-target="#exampleModalCenterDelete"
              onClick={() => handleDeleteModalOpen(value)}>
              <i className="icon md-delete" aria-hidden="true"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    pageCount
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  function handleModalOpen(id) {
    setSelectId(id);
    setIsModalOpen(true);
  }

  function handleDeleteModalOpen(id) {
    setSelectId(id);
    setIsDeleteModalOpen(true);
  }

  const handleDateOption = (option) => {
    const today = new Date();
    let startDate, endDate;
    console.log(option)
    switch (option) {
      case '1':
        startDate = new Date(today);
        endDate = new Date(today);
        break;

      case '2':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        endDate = new Date(today);
        endDate.setDate(today.getDate() - 1);
        break;

      case '3':
        startDate = new Date(today);
        startDate.setDate(today.getDate() + 1);
        endDate = new Date(today);
        endDate.setDate(today.getDate() + 1);
        break;

      case '6':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 6);
        endDate = new Date(today);
        break;


      case '4':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay());
        endDate = new Date(today);
        endDate.setDate(today.getDate() + (6 - today.getDay()));
        break;

      case '5':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - (today.getDay() - 1));
        endDate = new Date(today);
        endDate.setDate(today.getDate() + (7 - today.getDay()));
        break;

      case '14':
        startDate = new Date(today);
        startDate.setDate(today.getDate() + 7);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        break;

      case '7':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7 - today.getDay());
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        break;

      case '8':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7 - (today.getDay() - 1));
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 4);
        break;

      case '9':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        endDate = today;
        break;

      case '10':
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;

      case '13':
        startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        break;

      case '11':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 30);
        endDate = today;
        break;

      case '12':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
    }

    setValue1(startDate);
    setValue2(endDate);
  };


  const filterTable = () => {
    let filteredData = originalData;
    let selectedDateField = '';

    switch (selectedFilter) {
      case 'DataVencimento':
        selectedDateField = 'data_Vencimento';
        break;
      case 'DataQuitacao':
        selectedDateField = 'data_Quitacao';
        break;
      case 'DataCompetencia':
        selectedDateField = 'data_Competencia';
        break;
      default:
        break;
    }

    if (value1 && value2 && selectedDateField) {
      const startDate = new Date(value1).toISOString().split('T')[0];
      const endDate = new Date(value2).toISOString().split('T')[0];

      filteredData = filteredData.filter(row => {
        console.log(selectedDateField)
        const dateField = new Date(row[selectedDateField]).toISOString().split('T')[0];
        return dateField >= startDate && dateField <= endDate;
      });
    }

    filteredData = filteredData.filter(row =>
      Object.keys(filters).every(key => {
        if (row[key] === null || row[key] === undefined) return false;
        if (typeof row[key] === 'string') {
          return row[key].includes(filters[key]);
        } else if (typeof row[key] === 'number') {
          return row[key] === Number(filters[key]);
        } else {
          return false;
        }
      })
    );

    setData(filteredData);
  };







  const updateDateValues = (selectedDateOption) => {
    const today = new Date();
    let startDate, endDate;

    switch (selectedDateOption) {
      case "1": // Hoje
        startDate = today;
        endDate = today;
        break;
      case "11": // Últimos 30 Dias
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 30);
        endDate = today;
        break;
      // Adicione mais casos aqui
      default:
        return;
    }

    setValue1(startDate);
    setValue2(endDate);
  };



  const clearFilters = () => {
    setIsLoading(true);
    setFilters({});
    setSelectedDateOption({})
    setData(originalData);
    setShowDiv(false);
    setTimeout(() => setShowDiv(true), 0);
    setTimeout(() => setIsLoading(false), 100);


  };

  const contasAPagar = data.filter(item => item.despesa === true)
  .map(item => item.valor)
  .flat()
  .reduce((acc, cur) => acc + cur, 0);

  const contasAReceber = data.filter(item => item.despesa === false || item.despesa === null)
  .map(item => item.valor)
  .reduce((acc, cur) => acc + cur, 0);
  const pagesToShow = 5;
  const pagesIndex = Math.max(Math.min(pageIndex - Math.floor(pagesToShow / 2), pageCount - pagesToShow), 0);

  return (
    <div>
      {isLoading ? (
        <div className="loadingContainer">
          <div className="spinner"></div>
          <span className="loadingText">Carregando . . .</span>
        </div>
      ) : (
        <div className="MainDiv">
          <div className="container p-5">
            <div className='topTableLancamentos'>
              <div className='btn-input-div'>
                <div className='btn-div'>
                  <button className='CopyBtn' onClick={() => copyToClipboard(data)}>Copy</button>
                  <button className='csvBtn' onClick={() => downloadCSV(data)}>CSV</button>
                  <button className='pdfBtn' onClick={() => printTable(data, columns)}>Print</button>
                </div>
                <button onClick={() => setShowDiv(!showDiv)} class="btn btn-primary">Busca Avançada</button>

                <input
                  className='form-control input-searchSaldoEsp'
                  onChange={e => setGlobalFilter(e.target.value || undefined)}
                  placeholder="Procurar"
                />
              </div>
              {showDiv && (
                <div className='divFiltros'>
                  <div className="campoFiltro form-control">
                    <div className="form-group form-material row">
                      <FormInputLess colWidth={3} label={"Cliente Fornecedor"} name="cliente_Fornecedor" onChange={(e) => handleChange(e, "cliente_Fornecedor")} />
                      <FormInputLess colWidth={3} label={"Plano de Conta"} name="planoConta" onChange={(e) => handleChange(e, "planoConta")} />
                      <FormInputLess colWidth={3} label={"Forma de Pagamento"} name="formaPagamento" onChange={(e) => handleChange(e, "FormaPagamento")} />
                      <FormInputLess colWidth={3} label={"Empresa"} name="empresa" onChange={(e) => handleChange(e, "empresa")} />
                      <FormInputLess colWidth={3} label={"Conta Bancária"} name="conta_bancaria" onChange={(e) => handleChange(e, "conta_bancaria")} />
                      <FormInputLess colWidth={3} label={"Grupo"} name="grupo" onChange={(e) => handleChange(e, "grupo")} />
                      <FormInputLess colWidth={3} label={"Centro de Custos"} name="centro_custos" onChange={(e) => handleChange(e, "centro_custos")} />

                      <FormInputLess colWidth={3} label={"Valor"} name="valor" onChange={(e) => handleChange(e, "valor")} />
                      <FormSelectLess colWidth={3} label={"Tipo de Lançamento"} name="tipoLancamento" onChange={handleChange}>

                        <option value="0" selected="">Tudo</option>
                        <option value="1">Receitas</option>
                        <option value="2">Despesas</option>
                        <option value="3">Receitas de Vendas</option>
                        <option value="4">Comissões de Vendedores</option>
                      </FormSelectLess>
                      <FormSelectLess colWidth={3} label={"Situação de Lançamento"}>

                        <option value="3">Cheque a Compensar</option>
                        <option value="0">Todas</option>
                        <option value="1">Quitado</option>
                        <option value="2">Não Quitado</option>

                      </FormSelectLess>



                      <div className="form-group col-md-1" style={{ marginRight: '15px' }}>
                        <button className="btn btn-primary" onClick={() => filterTable()}>Filtrar</button>
                      </div>

                      <div className="form-group col-md-1">
                        <button className="btn btn-secondary" onClick={clearFilters}>
                          Limpar Filtros
                        </button>
                      </div>


                    </div>
                  </div>
                  <div className="campoData">
                    <div className="form-control tituloFiltro1">
                      <div className="form-group form-material row ">
                        <FormSelectLess label={"Situação de Lançamento"} colWidth={12} onChange={(e) => handleDateOption(e.target.value)}>
                          <option value="0">Personalizado</option>
                          <option value="1">Hoje</option>
                          <option value="2">Ontem</option>
                          <option value="3">Amanhã</option>
                          <option value="4">Esta Semana(dom. até sáb.)</option>
                          <option value="5">Esta Semana(seg. até dom.)</option>
                          <option value="14">Próxima semana</option>
                          <option value="6">Últimos 7 Dias</option>
                          <option value="7">Semana Passada(dom. até sáb)</option>
                          <option value="8">Última Semana Útil(seg. até sex.)</option>
                          <option value="9">Últimos 14 Dias</option>
                          <option value="10">Este Mês</option>
                          <option value="13">Próximo mês</option>
                          <option value="11">Últimos 30 Dias</option>
                          <option value="12">Mês Passado</option>
                        </FormSelectLess>
                      </div>
                    </div>
                    <div className="form-control tituloFiltro2">
                      <div className="form-material row">
                        <div className="form-group col-md-6">
                          <label className="floating-label" style={{ marginTop: "-7px" }}>Data Inicial</label>
                          <DatePicker
                            className="form-control"
                            selected={value1}
                            onChange={(val) => setValue1(val)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="floating-label" style={{ marginTop: "-7px" }}>Data Final</label>
                          <DatePicker
                            className="form-control"
                            selected={value2}
                            onChange={(val) => setValue2(val)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                          />
                        </div>
                      </div>
                    </div>


                    <div className="form-control tituloFiltro1">
                      <div className="form-group form-material row ">
                        <FormSelectLess label={"Filtrar Por"} colWidth={12} onChange={(e) => setSelectedFilter(e.target.value)}>
                          <option value="">Não filtrar por data</option>
                          <option value="DataVencimento">Data Vencimento</option>
                          <option value="DataQuitacao">Data Quitação</option>
                          <option value="DataCompetencia">Data Competência</option>
                        </FormSelectLess>

                      </div>
                    </div>

                  </div>
                </div>
              )}
              <div className='tableSaldo'>
                <table border="1">
                  <thead>
                    <tr>
                      <th>Periodo</th>
                      <th>Contas a Pagar</th>
                      <th>Contas a Receber</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Previsão do período</td>
                      <td>R$ {contasAPagar}</td>
                      <td>R$ {contasAReceber}</td>
                      <td>R$ {contasAReceber - contasAPagar}</td>
                    </tr>
                    <tr>
                      <td>Realizado período</td>
                      <td>R$ {contasAPagar}</td>
                      <td>R$ {contasAReceber}</td>
                      <td>R$ {contasAReceber - contasAPagar}</td>
                    </tr>
                    <tr>
                      <td>Não pago período</td>
                      <td>R$ 1100</td>
                      <td>R$ 1600</td>
                      <td>R$ {contasAReceber - contasAPagar}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table {...getTableProps()} className="table table-hover dataTable table-striped w-full dtr-inline">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="trTable">
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }} className="thTable">
                        {column.render('Header')}
                        <span className="sort-indicator">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <i className="fas fa-sort-down"></i>
                              : <i className="fas fa-sort-up"></i>
                            : <i className="fas fa-sort"></i>
                          }
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} style={{ width: cell.column.width }}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='bottomTable'>
              <div>
                Mostrando de {(pageIndex * pageSize) + 1} até {(pageIndex * pageSize) + page.length} de {data.length} registros
              </div>
              <div className="pagination-div">
                <button className='btnBack btnPagination' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button className='btnBack btnPagination' onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>
                {[...Array(Math.min(pageCount, pagesToShow)).keys()].map(pageNumber => {
                  const realPageNumber = pagesIndex + pageNumber;
                  return (
                    <button
                      className={`btnGoToPage ${realPageNumber === pageIndex ? 'active' : ''}`}
                      onClick={() => gotoPage(realPageNumber)}
                      disabled={realPageNumber >= pageCount}>
                      {realPageNumber + 1}
                    </button>
                  );
                })}
                <button className='btnNext btnPagination' onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
                <button className='btnNext btnPagination' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>
              </div>
            </div>
            <ModalDetails
              selectId={selectId}
              onClose={() => setIsModalOpen(false)}

            />


            <ModalDelete
              selectId={selectId}
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DatatablePlugin;
