import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import FormDate from "../../GlobalComponents/FormDate";
import { estados } from '../EmpresasPage/ObjectsPage/Estados';

import { api_data } from './../../services/api';

import InputMask from 'react-input-mask';


import $ from 'jquery';
import ModalPesquisarEmp from "./ModalEmpresa/ModalPesquisarEmp";
import ModalPesquisarList from "./ModalListaPreco/ModalPesquisarList";
import ModalPesquisarRep from "./ModalRepresentantes/ModalPesquisarRep";
import ModalPesquisarVen from "./ModalVendedores/ModalPesquisarVen";
import ModalPesquisarTra from "./ModalTransportadora/ModalPesquisarTra";

function CreateClientes() {

  const navigate = useNavigate();
  const [vendedores, setVendedores] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [tabelaprecos, setTabelaprecos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [representantes, setRepresentantes] = useState([]);


  const [empresaId, setEmpresaId] = useState(null);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);


  const [empresaCreated, setEmpresaCreated] = useState(false);



  const [empCreated, setEmpCreated] = useState(false);
  const [listaCreated, setListaCreated] = useState(false);
  const [repCreated, setRepCreated] = useState(false);
  const [venCreated, setVenCreated] = useState(false);
  const [traCreated, setTraCreated] = useState(false);

  const [activeTab, setActiveTab] = useState('cadastro');
  const [activeTab2, setActiveTab2] = useState('enderecoCadastral');
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const [clienteData, setClienteData] = useState({
    razao_Social: '',
    nome_Fantasia: '',
  });
  const [cnpj, setCnpj] = useState('');
  const [cep, setCep] = useState('');


  const handleEmpresaIdChange = (id) => {
    setEmpresaId(id);
  };



  const fetchAndSetData = (url, setter) => {
    api_data.get(url)
      .then((response) => response.data)
      .then((data) => setter(data));
  };

  useEffect(() => {
    Promise.all([
      api_data.get('/api/Bancos/GetEmpresasList'),
      api_data.get('/api/Cadastrais/VendedoresGet'),
      api_data.get('/api/Cadastrais/TransportadorasGet'),
      api_data.get('/api/Cadastrais/ListaPrecosGet'),
      api_data.get('/api/Cadastrais/RepresentantesGet')
    ])
      .then((responses) => Promise.all(responses.map((response) => response.data)))
      .then((data) => {
        setEmpresas(data[0]);
        setVendedores(data[1]);
        setTransportadoras(data[2]);
        setTabelaprecos(data[3]);
        setRepresentantes(data[4]);
      });
  }, [empresaCreated]);

  useEffect(() => {
    api_data.get('/api/Bancos/GetEmpresasList')
      .then((response) => {
        setEmpresas(response.data);
        setEmpCreated(false);
      });
  }, [empCreated]);

  useEffect(() => {
    api_data.get('/api/Cadastrais/ListaPrecosGet')
      .then((response) => {
        setTabelaprecos(response.data);
        setListaCreated(false);
      });
  }, [listaCreated]);

  useEffect(() => {
    api_data.get('/api/Cadastrais/RepresentantesGet')
      .then((response) => {
        setRepresentantes(response.data);
        setRepCreated(false);
      });
  }, [repCreated]);

  useEffect(() => {
    api_data.get('/api/Cadastrais/VendedoresGet')
      .then((response) => {
        setVendedores(response.data);
        setVenCreated(false);
      });
  }, [venCreated]);

  useEffect(() => {
    api_data.get('/api/Cadastrais/TransportadorasGet')
      .then((response) => {
        setTransportadoras(response.data);
        setTraCreated(false);
      });
  }, [traCreated]);


  const handleInputChange = (e, index, field) => {
    const newContatos = [...contatos];
    newContatos[index][field] = e.target.value;
    setContatos(newContatos);
  };

  const [contatos, setContatos] = useState([
    {
      contato: "",
      tipo_Contato: "",
      fone_Email: "Fone Fixo",
      obs: ""
    }
  ]);

  const adicionarContato = () => {
    setContatos([
      ...contatos,
      {
        contato: "",
        tipo_Contato: "",
        fone_Email: "Fone Fixo",
        obs: ""
      }
    ]);
  };

  const initialValues = {
    iD_Empresa: null,
    Contatos: []

  };



  const onSubmit = (values, { setSubmitting }) => {
    values.Contatos = contatos;
  
    
    values.diferenciado = values.diferenciado ? 'SIM' : 'NÃO';
  
    api_data
      .post("/api/Cadastrais/AddCliente", values)
      .then((response) => {
        console.log(response);
        navigate("/Clientes");
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.data && error.response.data.errors) {
          console.log('Validation errors:', error.response.data.errors);
        }
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  

  const fetchClienteData = async (cnpj, setFieldValue) => {
    console.log(cnpj);
    const cleanCNPJ = cnpj.replace(/[./-]/g, '');
    console.log(cleanCNPJ);

    try {
      const response = await api_data.get(`/api/Cadastrais/GetClientData/${cleanCNPJ}`);
      const data = await response.data;
      if (data.status === 'OK') {
        console.log("sucesso");
        setFieldValue('razao_Social', data.nome);
        setFieldValue('nome_Fantasia', data.fantasia);

        setFieldValue('logradouro', data.logradouro);
        setFieldValue('logradouro_Cobranca', data.logradouro);
        setFieldValue('logradouro_Entrega', data.logradouro);




        setFieldValue('numero', data.numero);
        setFieldValue('numero_Cobranca', data.numero);
        setFieldValue('numero_Entrega', data.numero);



        setFieldValue('complemento', data.complemento);
        setFieldValue('complemento_Cobranca', data.complemento);
        setFieldValue('complemento_Entrega', data.complemento);

        setFieldValue('bairro', data.bairro);
        setFieldValue('bairro_Cobranca', data.bairro);
        setFieldValue('bairro_Entrega', data.bairro);


        setFieldValue('codigoMunicipio', data.municipio);
        setFieldValue('municipio_Cobranca', data.municipio);
        setFieldValue('municipio_Entrega', data.municipio);


        setFieldValue('email', data.email);
        setFieldValue('fonefixo', data.telefone);

        setFieldValue('cep', data.cep);
        setFieldValue('ceP_Cobranca', data.cep);
        setFieldValue('ceP_Entrega', data.cep);



        setFieldValue('codigo_UF', data.uf);
        setFieldValue('codigo_UF_Entrega', data.uf);
        setFieldValue('codigo_UF_Cobranca', data.uf);
      } else {
        console.error('Erro ao buscar dados do cliente:', data.message);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do cliente:', error);
    }
  };



  const fetchAdressData = async (cep, setFieldValue) => {
    console.log(cep);
    const cleanCEP = cep.replace(/[./-]/g, '');
    console.log(cleanCEP);

    try {
      const response = await api_data.get(`/api/Cadastrais/GetClientAdress/${cleanCEP}`);
      console.log(response)
      const data = await response.data;
      console.log(data)
      if (data.cep) {
        console.log("sucesso");

        setFieldValue('logradouro', data.logradouro);
        setFieldValue('logradouro_Cobranca', data.logradouro);
        setFieldValue('logradouro_Entrega', data.logradouro);




        setFieldValue('numero', data.numero);
        setFieldValue('numero_Cobranca', data.numero);
        setFieldValue('numero_Entrega', data.numero);



        setFieldValue('complemento', data.complemento);
        setFieldValue('complemento_Cobranca', data.complemento);
        setFieldValue('complemento_Entrega', data.complemento);

        setFieldValue('bairro', data.bairro);
        setFieldValue('bairro_Cobranca', data.bairro);
        setFieldValue('bairro_Entrega', data.bairro);


        setFieldValue('codigoMunicipio', data.municipio);
        setFieldValue('municipio_Cobranca', data.municipio);
        setFieldValue('municipio_Entrega', data.municipio);




        setFieldValue('cep', data.cep);
        setFieldValue('ceP_Cobranca', data.cep);
        setFieldValue('ceP_Entrega', data.cep);



        setFieldValue('codigo_UF', data.uf);
        setFieldValue('codigo_UF_Entrega', data.uf);
        setFieldValue('codigo_UF_Cobranca', data.uf);
      } else {
        console.error('Erro ao buscar dados do cliente:');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do cliente:');
    }
  };

  const handleSearchClick = (setFieldValue) => {
    fetchClienteData(cnpj, setFieldValue);
  };

  const handleAdressClick = (setFieldValue) => {
    fetchAdressData(cep, setFieldValue);
  };

  const handleSelectChange = (event) => {
    setEmpresaId(event.target.value);
  };

  return (
    <div>



      <page>
        <div className="page-header">
          <h1 className="page-title">Clientes</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Clientes</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/CLientes">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Clientes</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, handleChange, setFieldValue }) => (

              <Form>


                <div class="accordion accordion-flush" id="accordionFlushExample">



                  <FormPanel id="exampleHeadingDefaultOne"
                    title="Informações do Cliente:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormSelect colWidth={1} name="pessoa" label="Pessoa">
                        <option value="Física">Física</option>
                        <option value="Júridica">Júridica</option>
                      </FormSelect>

                      <div className="form-group col-md-3" data-plugin="formMaterial">

                        <Field name="cnpj">
                          {({ field }) => (
                            <InputMask
                              {...field}
                              className="form-control"
                              type="text"
                              id="cnpj"
                              mask="99.999.999/9999-99"
                              onChange={(e) => {
                                handleChange(e);
                                setCnpj(e.target.value);
                              }}
                            />
                          )}
                        </Field>
                        <label className="floating-label">CNPJ</label>
                      </div>


                      {/* <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
                      <Field name={name}>
                        {({ field }) => (
                          mask 
                            ? <InputMask {...field} mask={mask} className='form-control' value={value || field.value} />
                            : <input className='form-control' {...field} value={value || field.value}></input>
                        )}
                      </Field>
                      <label className='floating-label'>{label}</label>
                    </div> */}



                      <span className="input-group-append">
                        <a
                          className="btn btn-icon btn-default"
                          style={{ height: '40px', display: 'inline-block' }}
                          onClick={() => handleSearchClick(setFieldValue)}
                        >
                          <i className="icon md-search" aria-hidden="true"></i>
                        </a>


                      </span>
                      <FormInput colWidth={3} name="cpf" label="CPF" mask="999.999.999-99" />

                      <FormSelect colWidth={2} name="consumidor_Final" label="Consumidor Final">
                        <option value="1 - Consumidor Final">Consumidor Final</option>
                        <option value="0 - Não">Não</option>
                      </FormSelect>




                    </div>
                    <div className="form-material row">
                      <FormInput colWidth={3} name="razao_Social" label="Razao Social" />
                      <FormInput colWidth={3} name="nome_Fantasia" label="Nome Fantasia" />




                      <div className={`form-group col-md-1`} data-plugin="formMaterial">
                        <div style={{ position: 'relative' }}>
                          <Field name="categoria_Cliente" type={'checkbox'} style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}></Field>
                          <label className='floating-label' style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)' }}>Cliente</label>
                        </div>
                      </div>
                      <div className={`form-group col-md-1`} data-plugin="formMaterial">
                        <div style={{ position: 'relative' }}>
                          <Field name="categoria_Fornecedor" type={'checkbox'} style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}></Field>
                          <label className='floating-label' style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)' }}>Fornecedor</label>
                        </div>
                      </div>


                      <div className={`form-group col-md-1`} data-plugin="formMaterial">
                        <div style={{ position: 'relative' }}>
                          <Field name="diferenciado" type={'checkbox'} style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}></Field>
                          <label className='floating-label' style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)' }}>Diferenciado</label>
                        </div>
                      </div>

                    </div>

                  </FormPanel>





                  <FormPanel id="exampleHeadingDefaultFive"
                    title="Informações do Cadastro:" defaultOpen={true}>
                    <div>
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a className={`nav-link ${activeTab === 'cadastro' ? 'active' : ''}`}
                            onClick={() => setActiveTab('cadastro')}>
                            Cadastro
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className={`nav-link ${activeTab === 'detalhes' ? 'active' : ''}`}
                            onClick={() => setActiveTab('detalhes')}>
                            Detalhes
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        {activeTab === 'cadastro' && (
                          <div className="tab-pane active" id="cadastro" role="tabpanel" aria-labelledby="cadastro-tab">  <div className="panel-body container-fluid">
                            <div className="form-material row">
                              <FormInput colWidth={2} name="inscricao_Estadual" label="Inscrição Estadual" />
                              <FormInput colWidth={2} name="rg" label="RG" />
                              <FormSelect colWidth={2} name="tipoConta" label="Tipo de Conta">
                                <option>1</option>
                                <option>1</option>
                              </FormSelect>
                              <FormInput colWidth={2} name="emissor" label="Emissor" />
                              <FormDate colWidth={2} name="data_Abertura" label="Data Abertura" />
                              <FormDate colWidth={2} name="data_Nascimento" label="Data Nascimento" />
                              <FormInput colWidth={2} name="isuframa" label="ISUFRAMA" />
                              <FormInput colWidth={3} name="estado_Civil" label="Estado Civil" />
                              <FormInput colWidth={3} name="cnae" label="CNAE" />
                              <FormInput colWidth={3} name="cartao_Fidelidade" label="Cartão Fidelidade" />
                              <FormInput colWidth={4} name="email" label="Email" />
                              <FormInput colWidth={2} name="fonefixo" label="Fone Fixo" />
                              <FormInput colWidth={3} name="celular" label="Celular" />
                              <div style={{ position: 'relative' }}>
                                <Field type="checkbox" name="zona_Franca" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                                <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Zona Franca</label>
                              </div>
                            </div>
                            <div className="form-group form-material row">
                              <FormInput colWidth={6} name="atividade" label="Atividade" />
                              <FormInput colWidth={6} name="profissao" label="Profissãor" />
                              <FormInput colWidth={3} name="site" label="Site" />
                              <FormInput colWidth={3} name="nacionalidade" label="nacionalidade" />
                              <div style={{ position: 'relative' }}>
                                <Field type="checkbox" name="permite_Credito_ICMS" id="permite_Credito_ICMS" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                                <label className='floating-label' htmlFor="permite_Credito_ICMS" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Permite Créd. ICMS</label>
                              </div>
                            </div>

                            <div className="form-material row">
                              <FormInput colWidth={3} name="iD_Estrangeiro" label="ID Estrangeiro" />
                              <FormInput colWidth={3} name="regime_Tributario" label="Regime Tributario" />
                              <FormInput colWidth={3} name="nome_Mae" label="Nome Mae" />
                              <FormInput colWidth={3} name="inscricao_Municipal" label="Inscricao Municipal" />
                              <FormInput colWidth={3} name="nome_Pai" label="Nome Pai" />
                              <div style={{ position: 'relative' }}>
                                <Field type="checkbox" name="simples_Nacional" id="simples_Nacional" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                                <label className='floating-label' htmlFor="simples_Nacional" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Simples Nacional</label>
                              </div>
                            </div>

                            <div className="testTable">
                              <span className="input-group-append">
                                <button
                                  id="btnAdicionarContato"
                                  type="button"
                                  className="btn btn-icon btn-default"
                                  onClick={adicionarContato}
                                >
                                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                                </button>
                              </span>
                              <table
                                id="tabelaOutrosContatos"
                                className="table table-bordered table-responsive-md table-striped text-center"
                              >
                                <thead>
                                  <tr>
                                    <th>Outros Contatos</th>
                                    <th>Tipo</th>
                                    <th>Fone ou Email</th>
                                    <th>Obs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {contatos.map((contato, index) => (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          value={contato.nome}
                                          onChange={(e) => handleInputChange(e, index, "contato")}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={contato.tipo}
                                          onChange={(e) => handleInputChange(e, index, "tipo_contato")}
                                        />
                                      </td>
                                      <td>
                                        <select
                                          value={contato.foneOuEmail}
                                          onChange={(e) => handleInputChange(e, index, "fone_email")}
                                        >
                                          <option value="Fone Fixo">Fone Fixo</option>
                                          <option value="Celular">Celular</option>
                                          <option value="E-mail">E-mail</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          value={contato.obs}
                                          onChange={(e) => handleInputChange(e, index, "obs")}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                          </div>
                          </div>
                        )}

                        {activeTab === 'detalhes' && (
                          <div className="panel-body container-fluid">
                            <div className="form-material row">

                              <FormDate colWidth={3} name="data_Cadastro" label="Data Cadastro" />
                              <FormDate colWidth={3} name="data_Primeira_Compra" label="Primeira Compra" />
                              <FormDate colWidth={3} name="data_Ultima_Compra" label="Ultima Compra" />

                              <FormInput colWidth={3} name="fonte" label="fonte" />

                            </div>
                            <div className="form-material row">
                              <FormInput colWidth={3} name="ramo" label="Ramo" />
                              <FormInput colWidth={9} name="capital_Social" label="Capital Social" />
                            </div>
                            <div className="form-material row">
                              <div className="form-group col-md-5" data-plugin="formMaterial">
                                <label className="form-control">
                                  <Field type="checkbox" name="permite_Credito_ICMS" id="permite_Credito_ICMS" />

                                  Bloquear Cadastro - Motivo
                                </label>
                                <textarea className="form-control">

                                </textarea>
                                <span>Motivo Bloqueio</span>
                              </div>
                              <div className="form-group col-md-7">
                                <label>Referencias Bancarias</label>
                                <textarea className="form-control"></textarea>
                                <span>Referencias Bancarias</span>
                              </div>
                            </div>
                            <div className="form-material row">

                              <FormInput colWidth={5} name="usuario_Bloqueou" label="Usuario Bloqueou" />
                              <FormSelect colWidth={1} name="iD_Empresa" label="ID Empresa">
                                {empresas.map((empresas) => (
                                  <option key={empresas.ID_Empresa} value={empresas.ID_Empresa}>
                                    {empresas.ID_Empresa}
                                  </option>
                                ))}
                              </FormSelect>
                              <div className="form-group col-md-6">
                                <div className="input-group">

                                  <Field name="iD_Empresa">
                                    {({ field, meta }) => (
                                      <>
                                        <select
                                          {...field}
                                          className="form-control form-select form-control-label-sm mb-2"
                                        >
                                          {empresas.map((empresas) => (
                                            <option key={empresas.ID_Empresa} value={empresas.ID_Empresa}>
                                              {empresas.Razao_Social}
                                            </option>
                                          ))}

                                        </select>
                                        <label className='floating-label'>
                                        </label>


                                      </>
                                    )}
                                  </Field>
                                  <span className="input-group-append">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#ModalEmpPesquisar"

                                      className="btn btn-icon btn-default"
                                      style={{ height: '40px', display: 'inline-block' }}

                                    >
                                      <i className="icon md-search" aria-hidden="true"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>

                            </div>

                          </div>
                        )}
                      </div>
                    </div>
                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultSix"
                    title="Endereços:" defaultOpen={true}>
                    <div className="nav nav-tabs" id="tabsLocaisEnderecos" role="tablist">
                      <div className="nav-item">
                        <a
                          className={`nav-link ${activeTab2 === 'enderecoCadastral' ? 'active' : ''}`}
                          id="enderecoCadastralTab"
                          onClick={(e) => { e.preventDefault(); setActiveTab2('enderecoCadastral'); }}
                        >
                          Endereco Cadastral
                        </a>
                      </div>
                      <div className="nav-item">
                        <a
                          className={`nav-link ${activeTab2 === 'localEntrega' ? 'active' : ''}`}
                          id="localEntregaTab"
                          onClick={(e) => { e.preventDefault(); setActiveTab2('localEntrega'); }}
                        >
                          Local de Entrega
                        </a>
                      </div>
                      <div className="nav-item">
                        <a
                          className={`nav-link ${activeTab2 === 'localCobranca' ? 'active' : ''}`}
                          id="localCobrancaTab"
                          onClick={(e) => { e.preventDefault(); setActiveTab2('localCobranca'); }}
                        >
                          Local de Cobrança
                        </a>
                      </div>
                    </div>

                    <div className="tab-content" id="nav-tabContent">
                      {activeTab2 === 'enderecoCadastral' && (
                        <div className="tab-pane fade show active" id="enderecoCadastral" role="tabpanel" aria-labelledby="enderecoCadastralTab">
                          <div className="panel-body container-fluid">
                            <div className="form-material row">
                              <div className="form-group col-md-2" data-plugin="formMaterial">

                                <Field name="cep">
                                  {({ field }) => (
                                    <InputMask
                                      {...field}
                                      className="form-control"
                                      type="text"
                                      id="cnpj"
                                      mask="99999-999"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setCep(e.target.value);
                                      }}
                                    />
                                  )}
                                </Field>
                                <label className="floating-label">CEP</label>
                              </div>
                              <div className="form-group col-md-1">
                                <a
                                  className="btn btn-icon btn-default"
                                  style={{ height: '40px', display: 'inline-block' }}
                                  onClick={() => handleAdressClick(setFieldValue)}
                                >
                                  <i className="icon md-pin" aria-hidden="true"></i>
                                </a>
                              </div>

                              <div className="checkbox">
                                <label>
                                  <input type="checkbox" />
                                  Local de Entrega Diferente deste
                                </label>
                              </div>
                              <div className="checkbox ml-20">
                                <label>
                                  <input type="checkbox" />
                                  Local de Cobrança diferente deste
                                </label>
                              </div>
                            </div>


                            <div className="form-material row">
                              <FormInput colWidth={5} name="logradouro" label="Logradouro" />
                              <FormInput colWidth={1} name="numero" label="Numero" />
                              <FormInput colWidth={4} name="complemento" label="Complemento" />
                              <FormInput colWidth={2} name="bairro" label="Bairro" />


                            </div>
                            <div className="form-material row">
                              <FormInput colWidth={1} name="codigo_Pais" label="Codigo Pais" />
                              <FormSelect colWidth={3} name="codigo_Pais" label="Codigo Pais">
                                <option value={"BR"}>BR</option>
                                <option value={"US"}>US</option>
                                <option value={"CA"}>CA</option>
                              </FormSelect>


                              <FormInput colWidth={1} name="codigo_UF" label="UF" />
                              <FormSelect colWidth={1} name="codigo_UF" label="Código UF">
                                {estados.map((estado) => (
                                  <option key={estado.value} value={estado.value}>
                                    {estado.label}
                                  </option>
                                ))}
                              </FormSelect>

                              <FormInput colWidth={2} name="codigoMunicipio" label="Municipio" />
                              <FormSelect colWidth={4} name="codigoMunicipio" label="Municipio">
                                <option value={"SP"}>SP</option>
                                <option value={"RJ"}>RJ</option>
                                <option value={"AM"}>AM</option>
                              </FormSelect>
                            </div>

                          </div>
                        </div>
                      )}
                      {activeTab2 === 'localEntrega' && (
                        <div className="panel-body container-fluid">
                          <div className="form-material row">
                            <h4 className="mx-auto">Endereço de entrega</h4>
                          </div>
                          <br></br>
                          <div className="form-material row">
                            <FormInput colWidth={2} name="ceP_Entrega" label="CEP" />
                            <div className="form-group col-md-1">
                              <div className="input-group-append">
                                <span className="input-group-append">
                                  <button className="btn btn-icon btn-default">
                                    <i className="icon md-pin"></i>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-material row">
                            <FormInput colWidth={5} name="logradouro_Entrega" label="Logradouro" />
                            <FormInput colWidth={1} name="numero_Entrega" label="Numero" />
                            <FormInput colWidth={4} name="complemento_Entrega" label="Complemento" />
                            <FormInput colWidth={2} name="bairro_Entrega" label="Bairro" />

                          </div>
                          <div className="form-material row">
                            <FormInput colWidth={1} name="codigo_Pais_Entrega" label="Codigo Pais" />
                            <FormSelect colWidth={2} name="codigo_Pais_Entrega" label="">
                              <option value={"BR"}>BR</option>
                              <option value={"US"}>US</option>
                              <option value={"CA"}>CA</option>
                            </FormSelect>

                            <FormInput colWidth={1} name="codigo_UF_Entrega" label="Codigo UF" />
                            <FormSelect colWidth={1} name="codigo_UF_Entrega" label="Codigo UF">
                              {estados.map((estado) => (
                                <option key={estado.value} value={estado.value}>
                                  {estado.label}
                                </option>
                              ))}
                            </FormSelect>

                            <FormInput colWidth={4} name="municipio_Entrega" label="Municipio Entrega" />
                            <FormSelect colWidth={3} name="codigo_Municipio_Entrega" label="Municipio Cod.Cobranca">
                              <option value={"BR"}>BR</option>
                              <option value={"US"}>US</option>
                              <option value={"CA"}>CA</option>
                            </FormSelect>
                          </div>



                        </div>
                      )}

                      {activeTab2 === 'localCobranca' && (
                        <div className="panel-body container-fluid">
                          <div className="form-material row">
                            <h4 className="mx-auto">Endereço de Cobrança</h4>
                          </div>
                          <div className="form-material row">
                            <FormInput colWidth={2} name="ceP_Cobranca" label="CEP" />
                            <div className="form-group col-md-1">
                              <span className="input-group-append">
                                <button className="btn btn-icon btn-default">
                                  <i className="icon md-pin"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="form-material row">
                            <FormInput colWidth={5} name="logradouro_Cobranca" label="Logradouro" />
                            <FormInput colWidth={1} name="numero_Cobranca" label="Numero" />
                            <FormInput colWidth={4} name="complemento_Cobranca" label="Complemento" />
                            <FormInput colWidth={2} name="bairro_Cobranca" label="Bairro" />

                          </div>
                          <div className="form-material row">
                            <FormInput colWidth={1} name="codigo_Pais_Cobranca" label="Codigo Pais" />
                            <FormSelect colWidth={2} name="codigo_Pais_Cobranca" label="">
                              <option>US</option>
                              <option>CA</option>
                              <option>BR</option>
                            </FormSelect>

                            <FormInput colWidth={1} name="codigo_UF_Cobranca" label="Codigo UF" />
                            <FormSelect colWidth={1} name="codigo_UF_Cobranca" label="Codigo UF">
                              {estados.map((estado) => (
                                <option key={estado.value} value={estado.value}>
                                  {estado.label}
                                </option>
                              ))}
                            </FormSelect>

                            <FormInput colWidth={2} name="municipio_Cobranca" label="Municipio Cobranca" />
                            <FormSelect colWidth={5} name="codigo_Municipio_Cobranca" label="Municipio Cod.Cobranca">
                              <option value={"BR"}>BR</option>
                              <option value={"US"}>US</option>
                              <option value={"CA"}>CA</option>
                            </FormSelect>
                          </div>
                        </div>
                      )}
                    </div>
                  </FormPanel>



                  <br></br>

                  <div className="panel">
                    <div className="row">
                      <div className="col-md-6">
                        <FormPanel id="informacaoVendas" title="Informacao Venda:" defaultOpen={true}>
                          <div class="accordion-body container-fluid">
                            <div class="form-group form-material row">
                              <FormSelect colWidth={2} name="iD_Tabela_Precos" label="Listas de Preços">
                                {tabelaprecos.map((tabela) => (
                                  <option key={tabela.iD_ListaPreco} value={tabela.iD_ListaPreco}>
                                    {tabela.nome_Lista}
                                  </option>
                                ))}
                              </FormSelect>
                              <div class="col-md-1">
                                <span class="input-group-append">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalListaPesquisar"
                                    class="btn btn-icon btn-default"
                                    style={{ height: '40px', display: 'inline-block' }}
                                  >
                                    <i class="icon md-search" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                              <FormSelect colWidth={2} name="iD_Transportadora" label="Transportadora">
                                {transportadoras.map((transportadora) => (
                                  <option key={transportadora.iD_Transportadora} value={transportadora.iD_Transportadora}>
                                    {transportadora.razao_Social}
                                  </option>
                                ))}
                              </FormSelect>
                              <div class="col-md-1">
                                <span class="input-group-append">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalTraPesquisar"
                                    class="btn btn-icon btn-default"
                                    style={{ height: '40px', display: 'inline-block' }}
                                  >
                                    <i class="icon md-search" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                              <FormSelect colWidth={2} name="iD_Representante" label="Representante">
                                {representantes.map((representante) => (
                                  <option key={representante.iD_Representante} value={representante.iD_Representante}>
                                    {representante.razao_Social}
                                  </option>
                                ))}
                              </FormSelect>
                              <div class="col-md-1">
                                <span class="input-group-append">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalRepPesquisar"
                                    class="btn btn-icon btn-default"
                                    style={{ height: '40px', display: 'inline-block' }}
                                  >
                                    <i class="icon md-search" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                              <FormSelect colWidth={2} name="iD_Vendedor" label="Vendendor">
                                {vendedores.map((vendedor) => (
                                  <option key={vendedor.iD_Vendendor} value={vendedor.iD_Vendendor}>
                                    {vendedor.razao_Social}
                                  </option>
                                ))}
                              </FormSelect>
                              <div class="col-md-1">
                                <span class="input-group-append">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalVenPesquisar"
                                    class="btn btn-icon btn-default"
                                    style={{ height: '40px', display: 'inline-block' }}
                                  >
                                    <i class="icon md-search" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                              <FormInput colWidth={3} name="codigo_Pagamento" label="Prazos praticados" />
                              <FormInput colWidth={3} name="comissaorepresentante" label="Comissao" />
                            </div>
                          </div>
                        </FormPanel>
                      </div>
                      <div className="col-md-6">
                        <FormPanel id="Observacoes" title="Observações:" defaultOpen={true}>
                          <textarea rows="5" cols="60"></textarea>
                        </FormPanel>
                      </div>
                    </div>
                  </div>


                </div>



                <div className="panel">
                  <div className="panel-body container-fluid">
                    <div className="form-group form-material row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                        {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel editar a Categoria</p>}
                        <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                          {isSubmitting ? "Criando ..." : "Criar o Cliente"}
                        </button>
                        <Link to="/Clientes">
                          <a className="btn btn-danger">
                            <i className="icon md-grid" aria-hidden="true"></i>
                            <span style={{ color: "white" }}>Cancelar e não salvar</span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <ModalPesquisarEmp onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpresaCreated={setEmpresaCreated} />  */}

                <ModalPesquisarEmp onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setEmpCreated={setEmpCreated} />

                <ModalPesquisarList onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setListaCreated={setListaCreated} />

                <ModalPesquisarRep onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setRepCreated={setRepCreated} />

                <ModalPesquisarVen onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setVenCreated={setVenCreated} />

                <ModalPesquisarTra onEmpresaIdChange={handleEmpresaIdChange} setFieldValue={setFieldValue} setTraCreated={setTraCreated} />

              </Form>
            )}
          </Formik>
        </div>
      </page>



    </div>
  )
}

export default CreateClientes