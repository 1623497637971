import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';

import { api, createSession } from '../services/api'

export const AuthContext = createContext();

let logoutTimer;

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expirationTime, setExpirationTime] = useState(null);

    useEffect(() => {
        const recoveredUser = localStorage.getItem('user');
        const token = localStorage.getItem('token');

        if (recoveredUser && token) {
            setUser(JSON.parse(recoveredUser));

            const decodedToken = jwtDecode(token);
            const expTime = decodedToken.exp * 1000;
            setExpirationTime(expTime);


            const timeout = expTime - Date.now();
            logoutTimer = setTimeout(() => {
                logout();
            }, timeout);
        }

        setLoading(false);
    }, [])

    const login = async (email, password) => {
        const response = await createSession(email, password);
        const loggedUser = response.data;
        const expirationTime = response.data.expiresIn * 1000 + Date.now(); 
    
        localStorage.setItem("user", JSON.stringify(loggedUser));
        localStorage.setItem("expirationTime", JSON.stringify(expirationTime)); 
    
        setUser(loggedUser);
        setExpirationTime(expirationTime);
        
        
        logoutTimer = setTimeout(() => {
            logout();
        }, response.data.expiresIn * 1000); 
    
        navigate("/");
    }
    
    useEffect(() => {
        const recoveredUser = localStorage.getItem('user');
        const expirationTime = JSON.parse(localStorage.getItem('expirationTime'));
    
        if (recoveredUser && expirationTime) {
            setUser(JSON.parse(recoveredUser));
            setExpirationTime(expirationTime);
    
            const timeout = expirationTime - Date.now();
            logoutTimer = setTimeout(() => {
                logout();
            }, timeout);
        }
    
        setLoading(false);
    }, []);
    


    const logout = async () => {
        try {
            
            await api.post('/logout');  
           
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            api.defaults.headers.Authorization = null;
            setUser(null);
            clearTimeout(logoutTimer);

           
            navigate("/login");
        } catch (error) {
            console.error("Erro ao fazer logout: ", error);
        }
    };


   
    const getTokenExpiration = async () => {
        try {
            const response = await api.get('/VerifyToken');

            if (response.status === 200) {
               
                return response.data.secondsRemaining;
            } else {
                
                logout();
            }
        } catch (error) {
            console.error('Erro ao verificar o token: ', error);
        }
    };

   
    const showTokenExpiration = async () => {
        const secondsRemaining = await getTokenExpiration();

        if (secondsRemaining != null) {
            alert(`Sessão expira em ${secondsRemaining} segundos.`);
        }
    };

    const showRemainingSessionTime = () => {
        const expirationTime = JSON.parse(localStorage.getItem('expirationTime'));
        const timeNow = Date.now();
        
        if (expirationTime) {
            const timeRemaining = (expirationTime - timeNow) / 1000; 
            alert(`Sessão expira em ${Math.round(timeRemaining)} segundos.`);
        }
    };
    
    const decreaseExpirationTime = () => {
        const oldExpirationTime = JSON.parse(localStorage.getItem('expirationTime'));
        const newExpirationTime = oldExpirationTime - 1000 * 1000;  
        localStorage.setItem("expirationTime", JSON.stringify(newExpirationTime));
        setExpirationTime(newExpirationTime);
        
        clearTimeout(logoutTimer); 
        const timeout = newExpirationTime - Date.now(); 
    
        
        logoutTimer = setTimeout(() => {
            logout();
        }, timeout);
    };
    

    // const reduceExpirationTime = () => {
    //     const newExpirationTime = expirationTime - 1000 * 1000; // test3
    //     setExpirationTime(newExpirationTime);   
    //     clearTimeout(logoutTimer);
    //     const timeout = newExpirationTime - Date.now();
    //     logoutTimer = setTimeout(() => {
    //         logout();
    //     }, timeout);
    // }

    return (
        <AuthContext.Provider value={{ authenticated: !!user, user, loading, login, logout, 
        expirationTime, showTokenExpiration, showRemainingSessionTime, decreaseExpirationTime }}>
            {children}
        </AuthContext.Provider>
    );
}
