import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_data } from '../../services/api';
import FormPanel from '../../GlobalComponents/FormPanel';




function ModalDetails({ selectId, isOpen }) {
    const navigate = useNavigate();

    const [deposito, setDeposito] = useState({});



    useEffect(() => {

        if (!isOpen) return;

        console.log("test")
        async function fetchEmpresa() {
            setDeposito({});

            const response = await api_data.get(`/api/Depositos/GetDeposito/${selectId}`)
            const depositoData = await response.data;
            setDeposito(depositoData);
        }
        fetchEmpresa();
    }, [selectId, isOpen]);

    const limparEmpresa = () => {
        setDeposito({});
        console.log(deposito)
        console.log('cliquei')

    };


    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };


    const handleEdit = () => {

        handleCloseModal();
        navigate(`/Depositos/Edit/${selectId}`);
    };


    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Deposito</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <FormPanel id="exampleHeadingDefaultFour" title="Informações do Depósito:" context="modal" defaultOpen={true}>
                                    <div className='form-material row'>

                                        <div className='form-group col-md-4' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.nome_Deposito} name="nome_Deposito" />
                                            <label className='floating-label'>Nome do Depósito</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.nome_Empresa} name="nome_Empresa" />
                                            <label className='floating-label'>Nome da Empresa</label>
                                        </div>
                                    </div>
                                    <div className='form-material row'>

                                        <div className='form-group col-md-4' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.responsavel_Estoque} name="responsavel_Estoque" />
                                            <label className='floating-label'>Responsável Estoque</label>
                                        </div>

                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.telefone_Estoque} name="telefone_Estoque" />
                                            <label className='floating-label'>Telefone Estoque</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.email_Estoque} name="email_Estoque" />
                                            <label className='floating-label'>Email Estoque</label>
                                        </div>
                                    </div>
                                </FormPanel>

                                <FormPanel id="depositoDetails" title="Endereço:" context="modal" defaultOpen={true}>

                                    <div className='form-material row'>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.logradouro} name="logradouro" />
                                            <label className='floating-label'>Logradouro</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.numero} name="numero" />
                                            <label className='floating-label'>Número</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.complemento} name="complemento" />
                                            <label className='floating-label'>Complemento</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.bairro} name="bairro" />
                                            <label className='floating-label'>Bairro</label>
                                        </div>
                                    </div>
                                    <div className='form-material row'>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.cep} name="cep" />
                                            <label className='floating-label'>CEP</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.cidade} name="cidade" />
                                            <label className='floating-label'>Cidade</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.codigo_Municipio} name="codigo_Municipio" />
                                            <label className='floating-label'>Código Município</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.uf} name="uf" />
                                            <label className='floating-label'>UF</label>
                                        </div>
                                    </div>


                                    <div className='form-material row'>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.codigo_UF} name="codigo_UF" />
                                            <label className='floating-label'>Código UF</label>
                                        </div>
                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                            <input className='form-control' value={deposito.pais} name="pais" />
                                            <label className='floating-label'>País</label>
                                        </div>
                                    </div>



                                </FormPanel>
                            </div>




                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" onClick={handleEdit}>Editar</button>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails