import React, { useState, useEffect } from 'react';
import { api_data } from '../../services/api';
import { useNavigate } from "react-router-dom";

function ModalDelete(props) {
    const navigate = useNavigate();
    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});        
            const response = await api_data.get(`/api/Categorias_Contas/GetCategoria/${props.selectId}`);
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };

    const closeModal = () => {
        const modal = document.querySelector('#exampleModalCenterDelete');
        const modalBackdrop = document.querySelector('.modal-backdrop');

        modal.classList.remove('show');
        modal.setAttribute('aria-modal', 'false');
        modal.setAttribute('style', 'display: none');
        document.body.classList.remove('modal-open');

        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
    };

    const handleDelete = () => {     
        api_data.delete(`/api/Categorias_Contas/DeleteCategoria/${props.selectId}`)
        .then(response => {
            if (response.status === 200) {
                limparEmpresa();
                closeModal();
                

                navigate(0);
            } else {
                console.log('Erro ao excluir Cliente');
            }
        })
        .catch(error => console.log(error));
    };


    return (
        <div><div>
            <div className="modal fade" id="exampleModalCenterDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDeleteTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-danger modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Deletar a empresa</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                            
                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input key={categoria.nome} className='form-control' data-plugin="formatter" value={categoria.nome} />
                                        <label className='floating-label'>Nome</label>
                                    </div>
                                    <div className='form-group col-md-4' data-plugin="formMaterial">
                                        <input key={categoria.codHierarquico} className='form-control' readOnly value={categoria.codHierarquico} />
                                        <label className='floating-label'>Hierarquia</label>
                                    </div>
                                    <div className="form-group col-md-1"></div>
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type="checkbox"                                         
                                            checked={categoria.despesa ? true : false}
                                             style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }}
                                         />
                                          <label className='floating-label' htmlFor={categoria.despesa}  
                                          style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>
                                            Despesa
                                        </label>
                                        </div>

                                </div>                                
                            <br></br>
                            <br></br>
                                <div className='form-material row'>
                                    

                                        <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.codNatureza} className='form-control' data-plugin="formatter" value={categoria.codNatureza} />
                                        <label className='floating-label'>Código Natureza</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.grupoDRE} className='form-control' readOnly value={categoria.grupoDRE} />
                                        <label className='floating-label'>Grupo /DRE</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.tipoConta} className='form-control' data-plugin="formatter" value={categoria.tipoConta} />
                                        <label className='floating-label'>Tipo da Conta</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.grupoLancamento} className='form-control' readOnly value={categoria.grupoLancamento} />
                                        <label className='floating-label'>Grupo Lancamento</label>
                                    </div>



                                     
                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            
                            <button type="button" className="btn btn-primary" onClick={handleDelete}>Excluir</button>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ModalDelete