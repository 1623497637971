import React, { useState } from 'react'
import FormInModal from "../../../GlobalComponents/ModalComponents/FormInModal"
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { api_data } from '../../../services/api';


function ModalPesFormTra({ setIsCreating, onEmpresaCreated, setTraCreated  }) {
  const [activeTab, setActiveTab] = useState('cadastro');

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  }

  const initialValues = {};

  const onSubmit = (values, { setSubmitting }) => {
  api_data.post("/api/Transportadora/AddTransportadoras", values)
  .then((response) => {
    console.log(response);
    setTraCreated(true); 
  })

      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
        setIsCreating(false);
      });
  };

  return (
    <div className='panel-body container-fluid'>
    <div className="form-material row">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'cadastro' ? 'active' : ''}`}
            id="dadosCadastraisTab"
            onClick={() => handleTabChange('cadastro')}
            role="tab"
            aria-controls="dadosCadastrais"
            aria-selected={activeTab === 'cadastro'}>Cadastro</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'detalhes' ? 'active' : ''}`}
            id="detalhesCadastraisTab"
            onClick={() => handleTabChange('detalhes')}
            role="tab"
            aria-controls="detalhesCadastrais"
            aria-selected={activeTab === 'detalhes'}>Cadastro Form 2</a>
        </li>
      </ul>

    </div>
    <Formik initialValues={initialValues} onSubmit={onSubmit} >
      {({ isSubmitting, handleSubmit }) => (
        <Form>
          <div className="tab-content" id="nav-tabContet">
            <div className="tab-pane" id="dadosCadastrais" role="tabpanel" aria-labelledby="dadosCadastraisTab" style={{ display: activeTab === 'cadastro' ? 'block' : 'none' }}>

              <div className="panel-body container-fluid">
                <div className="form-material row">
                  <FormInModal colWidth={3} label="Razao Social" name="razao_Social" />
                  <FormInModal colWidth={3} label="Nome Fantasia" name="nome_Fantasia" />
                  <FormInModal colWidth={3} label="Pessoa" name="pessoa" />
                  <FormInModal colWidth={3} label="Logradouro" name="logradouro" />
                  <FormInModal colWidth={3} label="Numero" name="numero" />
                  <FormInModal colWidth={3} label="Complemento" name="complemento" />
                  <FormInModal colWidth={3} label="Bairro" name="bairro" />
                  <FormInModal colWidth={3} label="CEP" name="cep" />
                  <FormInModal colWidth={3} label="UF" name="uf" />
                  <FormInModal colWidth={3} label="Codigo_UF" name="codigo_UF" />
                  <FormInModal colWidth={3} label="Cod. Municipio" name="codigo_Municipio" />
                  <FormInModal colWidth={3} label="CNPJ/CPF" name="cnpJ_CPF" />
                  <FormInModal colWidth={3} label="RG" name="iE_RG" />

                  


                </div>
              </div>
            </div>
            <div className="tab-pane" id="detalhesCadastrais" role="tabpanel" aria-labelledby="detalhesCadastraisTab" style={{ display: activeTab === 'detalhes' ? 'block' : 'none' }}>
              <div className="panel-body container-fluid">
                <div className="form-material row">
                 
                  <FormInModal colWidth={3} label="Telefone 1" name="telefone_1" />
                  <FormInModal colWidth={3} label="Telefone 2" name="telefone_2" />
                  <FormInModal colWidth={3} label="Celular" name="celular" />

                  <FormInModal colWidth={3} label="Contato" name="contato" />
                  <FormInModal colWidth={3} label="Email" name="email" />
                  <FormInModal colWidth={3} label="Site" name="site" />
                  <FormInModal colWidth={3} label="Comissão" name="tipo_Comissao" />
                  <FormInModal colWidth={3} label="ID Empresa" name="iD_Empresa" />
                  <FormInModal colWidth={3} label="Setor" name="setor" />

                </div>
              </div>
            </div>
          </div>
          <span
            className='btn-success btn'
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Criando ..." : "Registrar a Transportadora"}
          </span>

        </Form>
      )}
    </Formik>
  </div>
  )
}

export default ModalPesFormTra
