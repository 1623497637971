import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState } from 'react';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';


function CreateEmpresa() {


  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const initialValues = {};
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const onSubmit = (values, { setSubmitting }) => {

    if (values.despesa === 'true') {
      values.despesa = true;
    } else if (values.despesa === 'false') {
      values.despesa = false;
    }

    api_data
      .post("/api/Categorias_Contas/AddCategoria", values)
      .then((response) => {
        console.log(response);
        navigate("/Categorias_Contas")
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const [file, setFile] = useState(null);

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };





  const addCategory = async (categoryValues) => {
    try {
      const response = await api_data.post("/api/Categorias_Contas/AddCategoria", categoryValues);
      console.log(response);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const handleCreateCategories = () => {
    if (!file) {
      alert('Nenhum arquivo selecionado');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const csvData = event.target.result;
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        transformHeader: header => header.trim(),
        transform: value => value.trim(),
        complete: async function (results) {
          const data = results.data;
          let reqCount = 0;
          for (let category of data) {
            if (reqCount < 5) {
              const categoryValues = {
                ID_Categorias_Contas: category.ID_Categorias_Contas,
                nome: category.nome.replace(/"/g, ''),
                despesa: category.despesa === 'true',
                plnContas: category.plnContas === 'true',
                tipoConta: category.tipoConta.replace(/"/g, ''),
                codNatureza: category.codNatureza.replace(/"/g, ''),
                grupoDRE: category.grupoDRE.replace(/"/g, ''),
                ctrContas: category.ctrContas.replace(/"/g, ''),
                grupoLancamento: category.grupoLancamento.replace(/"/g, ''),
                codHierarquico: parseFloat(category.codHierarquico),
              };

              const success = await addCategory(categoryValues); // requisição
              if (success) {
                reqCount++;
                console.log('Categoria adicionada com sucesso');
              } else {
                console.log('Erro ao adicionar categoria');
                break;
              }
            } else {
              console.log('LIMITE ATINGIDO');
              break;
            }
          }
        }
      });
    };
    reader.readAsText(file);
  };












  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Categorias Contas</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Categorias</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/Categorias_Contas">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Categorias</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Categorias:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={2} name="nome" label="Nome" />
                      <FormInput colWidth={3} name="codHierarquico" label="Codigo Hierarquico(Ex.: 10, 10.01, 10.01.02)" />
                      <FormInput colWidth={2} name="grupoDRE" label="Grupo de conta/DRE" />
                      <FormInput colWidth={2} name="grupoLancamento" label="Grupo de lancamento" />


                      <div className='form-group form-material col-12 col-md-3' style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ position: 'relative', marginRight: '20px' }}>
                          <Field name="despesa" type="radio" value="true" />
                          <label style={{ marginLeft: '5px' }}>Despesa</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <Field name="despesa" type="radio" value="false" />
                          <label style={{ marginLeft: '5px' }}>Não Despesa</label>
                        </div>
                      </div>




                    </div>
                    <div className="form-group form-material row">
                      <div className='form-group col-12 col-md-3' data-plugin="formMaterial">
                        <div className='input-group'>
                          <Field name="ctrContas">
                            {({ field }) => (
                              <input type="text" className="form-control" {...field} />
                            )}
                          </Field>
                          <label className='floating-label'>Centro de Custos</label>
                          <button type='button' className='btn btn-primary'>Autorizar</button>
                        </div>
                      </div>

                      <FormSelect colWidth={2} name="tipoConta" label="Tipo de Conta">
                        <option>S - Sintetica</option>
                        <option>A - Analitica</option>
                      </FormSelect>


                      <FormSelect colWidth={2} name="codNatureza" label="Codigo Natureza">
                        <option value="01 - Contas de Ativo">01 - Contas de Ativo</option>
                        <option value="02 - Contas de Passivo">02 - Contas de Passivo</option>
                        <option value="03 - Patrimônio Líquido">03 - Patrimônio Líquido</option>
                        <option value="04 - Contas de Resultado">04 - Contas de Resultado</option>
                        <option value="05 - Contas de Compensação">05 - Contas de Compensação</option>
                        <option value="06 - Outras">06 - Outras</option>
                      </FormSelect>

                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="plnContas" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Desativar Plano de Contas</label>
                        </div>
                      </div>
                    </div>
                  </FormPanel>




                  <FormPanel id="exampleHeadingDefaultOne" href="#exampleCollapseDefaultOne" ariaControls="exampleCollapseDefaultOne"
                    title="Migrar plano de Contas" defaultOpen={true}>
                    <p style={{ fontSize: '23px', color: 'black' }}><b>Migrar registros de plano de contas</b></p>
                    <p>Essa funcionalidade ira Mudar o Plano de Contas dos seguintes registros </p>
                    <ol>
                      <li>Lançamento</li>
                      <li>Vendas</li>
                      <li>Notas de entrada</li>
                      <li>Contratos</li>
                    </ol>
                    <br />
                    <br />

                    <div className='form-group form-material row'>
                      <FormInput colWidth={3} name="telefone_1" label="Migrar De" />
                      <FormInput colWidth={3} name="telefone_1" label="Migrar Para" />


                      <div className='form-group col-12 col-md-2' data-plugin="formMaterial">
                        <div className='input-group'>
                          <button className='btn btn-primary'>Migrar Plano</button>
                        </div>
                      </div>



                    </div>
                  </FormPanel>




                  <br></br>




                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar a Categoria</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar a Categoria"}
                          </button>
                          <Link to="/Categoria_Contas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateEmpresa