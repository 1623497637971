import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'
import { api_data } from '../../services/api';

import { Modal } from 'bootstrap';

function ModalDetails(props) {

   
    const navigate = useNavigate();

    const [banco, setCategoria] = useState({});

    useEffect(() => {
        async function fetchEmpresa() {
            console.log(props.selectId)
            setCategoria({});
            const response = await api_data.get(`/api/Bancos/GetBanco/${props.selectId}`);
            console.log(response)
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(banco)
        console.log('cliquei')
    };

    const handleCloseModal = () => {
        document.getElementById('closeModalButton').click();
    };

    const handleEdit = () => {
        limparEmpresa();
        handleCloseModal();

        navigate(`/Bancos/Edit/${props.selectId}`);
    };

    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Detalhes do Banco</h2>
                            <button id="closeModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">



                            <div class="accordion" id="accordionPanelsStayOpenExample">


                                <FormPanel id="exampleHeadingDefaultTwo"
                                    title="Informações de Endereço:" context="modal">

                                    <br></br>
                                    <br></br>

                                    <div className="form-material row">
                                        <div className="form-group col-md-2" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={banco.banco} />
                                            <label className="floating-label">Banco</label>

                                        </div>
                                        <div className="form-group col-md-2" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={banco.agencia} />
                                            <label className="floating-label">Agencia</label>
                                        </div>
                                        <div className="form-group col-md-2" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={banco.numero} />
                                            <label className="floating-label">Numero</label>
                                        </div>
                                        <div className="form-group col-md-3" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={banco.saldo} />
                                            <label className="floating-label">Saldo</label>
                                        </div>

                                        <div className="form-group col-md-3" data-plugin="formMaterial">
                                            <input className="form-control " readOnly value={banco.iD_Empresa} />
                                            <label className="floating-label">Empresa</label>
                                        </div>
                                    </div>


                                </FormPanel>

                                <FormPanel id="exampleHeadingDefaultThree" title="Observação :" context="modal">
                                    <div className='panel-body container-fluid'>
                                        <div className="form-material row">
                                            <div className="form-group col-md-6" data-plugin="formMaterial">
                                                <div className="panel-heading" id="exampleHeadingDefaultSeven" role="tab">
                                                    <label>Observação:</label>
                                                </div>
                                                <textarea className="form-control" id="textareaDefault" rows="4"></textarea>
                                            </div>

                                            <div className="form-group col-md-6" data-plugin="formMaterial">
                                                <table className="table table-hover table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Usuários de Bancos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {banco.bancos_Usuarios && banco.bancos_Usuarios.map((usuarioBanco, index) => (
                                                            <tr key={index}>
                                                                <td>{usuarioBanco.usuario}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </FormPanel>

                            </div>





                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" onClick={handleEdit}>Editar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetails