import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState, useEffect } from 'react';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';
import FormInReal from '../../GlobalComponents/FormInReal';
import FormDate from '../../GlobalComponents/FormDate';

import ModalPesquisarRep from "./ModalCliente/ModalPesquisarRep";
import ModalPesquisarPlano from "./ModalContas/ModalPesquisarPlano";
import { addMonths, format } from 'date-fns';
import {  toast } from 'react-toastify';



function CreateLancamentos() {
  const [representantes, setRepresentantes] = useState([]);
  const [contas, setContas] = useState([]);

  const [iD_BancoAtual, setID_Cliente] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [parcelasValue, setParcelasValue] = useState(0);
  const [valorValue, setValorValue] = useState(0);
  const currentDate = new Date();
  const [parcelas, setParcelas] = useState([]);
  const [addParcelaDif, setAddParcelaDif] = useState(false);


  const [valorParcela, setValorParcela] = useState('');
  const [dataNegativacao, setDataNegativacao] = useState('');


  const handleAddParcela = () => {

    const newParcela = {
      Valor: valorParcela,
      DataVencimento: dataNegativacao,
    };

    setParcelas([...parcelas, newParcela]);
    setAddParcelaDif(true);

  };

  const showParcelas = () => {
    console.log(parcelas)
  };


  const notify = () => {
    toast.success("Criado com Sucesso", { autoClose: 3000 });
  };
  
  const notifyError = () => {
    toast.error("Falha na criação, tente novamente", { autoClose: 3000 });
  };

  const handleButtonClick = (numParcelas, valor, planoconta) => {
    console.log(numParcelas, valor);
    console.log(planoconta)
    if (valor) {
      valor = parseFloat(
        valor
          .toString()
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", ".")
      );
    } else {
      valor = 0;
    }
    setParcelasValue(numParcelas);
    setValorValue(valor);
    setShowTable(true);
  };

  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const initialValues = {};
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const onSubmit = async (values, { setSubmitting }) => {
    const fieldsToConvert = ["Valor", "desconto_real", "juros_antecipacao_rs", "taxa_de_juros_per", "desconto_per"];

    fieldsToConvert.forEach(field => {
      if (values[field]) {
        values[field] = parseFloat(values[field].toString().replace("R$ ", "").replace(/\./g, "").replace(",", "."));
      }
    });

    values.Parcelas = parcelas;

    try {
      const response = await api_data.post("/api/Lancamentos/AddLancamento", values);
      console.log(response);
      notify()
      navigate("/Lancamentos");
      setShowErrorMessage(false);
    } catch (error) {
      notifyError()
      console.error('Error:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
      setShowErrorMessage(true);
    } finally {
      setSubmitting(false);
    }
  };

  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchRepresentantes = async () => {
      const response = await api_data.get('api/Cadastrais/GetAllRazaoSocial');
      setRepresentantes(response.data);
    };

    const fetchContas = async () => {
      const response = await api_data.get('api/Categorias_Contas/GetAllRequests');
      setContas(response.data);
    };

    fetchRepresentantes();
    fetchContas();
  }, []);

  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Lancamentos</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Lancamentos</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/Lancamentos">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Lancamentos</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik
            initialValues={{ ...initialValues, parceloamentoOpt: '', Numero_De_Parcelas: 0, tipoOperacao: "meses" }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Lancamento:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormSelect colWidth={2} name="cliente_Fornecedor" label="Cliente Fornecedor">
                        {representantes.map((representante) => (
                          <option key={representante.iD_razao_Social} value={representante.iD_razao_Social}>
                            {representante.razao_Social}
                          </option>
                        ))}
                      </FormSelect>
                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalRepPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>
                      <div class="form-check form-switch" hidden>
                        <Field class="form-check-input" type="checkbox" name="despesa" />

                      </div>

                      <FormSelect colWidth={2} name="PlanoConta" label="Plano de Conta">
                        {contas.map((contas) => (
                          <option key={contas.iD_Categorias_Contas} value={contas.nome}>
                            {contas.nome}
                          </option>
                        ))}
                      </FormSelect>

                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalPlanoPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>


                      <FormInReal colWidth={3} name="Valor" label="Valor(R$)" />
                      <FormInput colWidth={2} name="Forma_Pagamento" label="Forma de Pagamento" />
                    </div>
                    <div className="form-group form-material row">
                      <FormInput colWidth={2} name="conta_bancaria" label="Conta Bancaria" />
                      <FormDate colWidth={3} name="Data_Competencia" label="Data de competência" />
                      <FormDate colWidth={3} name="Data_Vencimento" label="Data de vencimento" />
                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="plnContas" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Foi quitado ?</label>
                        </div>
                      </div>
                    </div>
                  </FormPanel>



                  <FormPanel id="exampleHeadingDefaultSix" href="#exampleCollapseDefaultSix" ariaControls="exampleCollapseDefaultSix"
                    title="Mostrar mais Campos:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <div className='form-group col-12 col-md-3' data-plugin="formMaterial">
                        <div className='input-group'>
                          <Field name="ctrContas">
                            {({ field }) => (
                              <input type="text" className="form-control" {...field} />
                            )}
                          </Field>
                          <label className='floating-label'>Centro de Custos</label>
                          <button type='button' className='btn btn-primary'>Autorizar</button>
                        </div>
                      </div>
                      <FormInput colWidth={2} name="numero_nome_documento" label="Cliente / Fornecedor" />
                      <FormInput colWidth={3} name="desconto_per" label="Desconto (%)" />
                      <FormInReal colWidth={3} name="desconto_real" label="Desconto (R$)" />
                      <FormInput colWidth={2} name="grupo" label="Desconto Até o vencimento(%)" />
                      <FormInReal colWidth={2} name="juros_antecipacao_rs" label="Entrada / Adiamento (R$)" />
                      <FormInput colWidth={2} name="juros_antecipacao_per" label="Numero / Nome documento" />
                      <FormDate colWidth={2} name="data_vencimento_original" label="Data Vencimento Original" />
                      <FormInReal colWidth={2} name="taxa_de_juros_per" label="Juros Antecipação (R$) " />
                      <FormInput colWidth={2} name="numero_de_parcelas" label="Juros Antecipação (%)" />

                      <FormDate colWidth={3} name="data_cadastro" label="Data Cadastro" />
                      <FormDate colWidth={2} name="data_quitacao" label="Data Quitação" />
                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="conciliado" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Foi Conciliado ?</label>
                        </div>
                      </div>






                    </div>
                    <div className="form-group form-material row">
                      <FormInput colWidth={12} name="Linha_Digitavel_Do_Documento" label="Linha digitavel do documento" ></FormInput>
                    </div>
                    <div className="form-group form-material row">
                      <div className='form-group col-12 col-md-6'>
                        <Field name="observacoes" as="textarea" className='form-control'></Field>
                        <label className='floating-label'>Observações</label>
                      </div>
                      <div className='form-group col-12 col-md-6'>
                        <Field name="breveDescricao" as="textarea" className='form-control'></Field>
                        <label className='floating-label'>Breve descrição</label>
                      </div>
                    </div>
                  </FormPanel>


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Negativação SERASA:" defaultOpen={true}>

                    <div className="form-group form-material row">
                      <FormInput colWidth={3} name="Dias_Em_Atraso_Para_Negativar" label="Dias em atraso para negativar" />
                      <FormDate colWidth={3} name="Data_Negativacao" label="Data negativação" numero={true} />

                      <FormSelect colWidth={4} name="Tipo_De_Operacao" label="Tipo de Operação">
                        <option value="" label="Selecionar opção" />
                        <option value="Outras operações">Outras operações</option>
                        <option value="Prestação de serviço/Vendas de Mercadoria">Prestação de serviço/Vendas de Mercadoria</option>
                        <option value="Duplicata">Duplicata</option>
                        <option value="Aluguel">Aluguel</option>
                      </FormSelect>

                      <div className='form-group form-material'>
                        <div style={{ position: 'relative' }}>
                          <Field type="checkbox" name="Negativar" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                          <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Negativar ?</label>
                        </div>
                      </div>

                    </div>
                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultSeven" href="#exampleCollapseDefaultSeven" ariaControls="exampleCollapseDefaultSeven"
                    title="Campos para Remessa de Pagamento :" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={2} name="tipo_pagamento" label="Forma de Pagamento" />
                      <FormSelect colWidth={3} name="formaPagamento" label="Tipo de pagamento">
                        <option value="0">Selecione</option>
                        <option value="1">Crédito em Conta Corrente no Itaú</option>
                        <option value="2">Cheque Pagamento/Administrativo</option>
                        <option value="3">DOC "C"</option>
                        <option value="4">Crédito em conta corrente de mesma Titularidade</option>
                        <option value="5">DOC "D"</option>
                        <option value="6">Ordem de Pagamento à Disposição</option>
                        <option value="7">Pagamento de Concessionárias</option>
                        <option value="8">DARF Normal</option>
                        <option value="9">GPS - Guia de Previdencia Social</option>
                        <option value="10">DARF Simples</option>
                        <option value="11">IPTU/ISS/OUTROS Tributos Municipais</option>
                        <option value="12">GARE - SP ICMS</option>
                        <option value="13">Pagamentos de Títulos em Cobrança no Itaú</option>
                        <option value="14">Pagamentos de Títulos em Cobrança em Outros Bancos</option>
                        <option value="15">Nota Fiscal - Liquidação Eletrônica</option>
                        <option value="16">FGTS</option>
                        <option value="17">TED - Outro Titular</option>
                        <option value="18">TED - Mesmo Titular</option>
                        <option value="19">PIX Transferência Itaú</option>
                        <option value="20">PIX Transferência Outros Bancos</option>
                        <option value="21">PIX QR-CODE Itaú</option>
                        <option value="22">PIX QR-CODE Outros Bancos</option>
                        <option value="23">GNRE e Tributos com Código de Barras</option>
                      </FormSelect>

                      <FormDate colWidth={3} name="data_pagamento" label="Data de Pagamento" />



                    </div>
                  </FormPanel>



                  <FormPanel id="exampleHeadingDefaultSeven" href="#exampleCollapseDefaultSeven" ariaControls="exampleCollapseDefaultSeven"
                    title="Parcelamento ou Recorrência:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <div className='col-md-3'>
                        <Field type="radio" name="parceloamentoOpt" value="naoParcelar" />
                        <label htmlFor="naoParcelar">Não Parcelar</label>
                      </div>
                    </div>
                    <div className="form-group form-material row">

                      <div className='col-md-12'>
                        <div className="row">

                          <div className='col-md-3'>
                            <Field type="radio" name="parceloamentoOpt" value="configManual" />
                            <label htmlFor="configManual">Configurar Manualmente</label>
                          </div>
                          <div className='col-md-3'>
                            <Field type="radio" name="parceloamentoOpt" value="parcelamentoAuto" />
                            <label htmlFor="parcelamentoAuto">Parcelamento Automático</label>
                          </div>
                          <div className='col-md-3'>
                            <Field type="radio" name="parceloamentoOpt" value="recorrenciaAuto" />
                            <label htmlFor="recorrenciaAuto">Recorrência Automática</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    {isSubmitting && <p>Enviando...</p>}
                    <Field name="parceloamentoOpt">
                      {({ field }) => (
                        <>
                          {field.value === 'configManual' &&
                            <div className="form-group form-material row">
                              <FormInReal colWidth={2} name="desconto_per" label="Valor da parcela (R$)" value={valorParcela} onChange={(e) => setValorParcela(e.target.value)} />
                              <FormDate colWidth={3} name="test3" label="Data negativação" numero={true} value={dataNegativacao} onChange={(e) => setDataNegativacao(e.target.value)} />
                              <div className="col-md-2">
                                <button type="button" className="btn btn-primary" onClick={handleAddParcela}>Adicionar Parcela</button>
                                {/* <button type="button" onClick={showParcelas}> tst</button> */}
                              </div>

                              {addParcelaDif && (
                                <table>
                                  <thead>
                                    <tr>
                                      <th style={{ width: "35%" }}>Descrição</th>
                                      <th style={{ width: "20%" }}>Valor</th>
                                      <th style={{ width: "15%" }}>Plano de Conta</th>
                                      <th style={{ width: "15%" }}>Vencimento</th>
                                      <th style={{ width: "15%" }}>Competencia</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {parcelas.map((parcela, index) => (
                                      <tr key={index}>
                                        <td>
                                          Parcela com Data de vencimento {parcela.DataVencimento ? parcela.DataVencimento.toLocaleDateString() : ''}{' '}
                                          com o valor {(parcela.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>

                                        <td>{(parcela.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{values.PlanoConta || 'Não selecionado'}</td>
                                        <td>{parcela.DataVencimento ? parcela.DataVencimento.toLocaleDateString() : ''}</td>

                                        <td>Exemplo</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}

                            </div>
                          }
                          {field.value === 'parcelamentoAuto' &&
                            <div className="form-group form-material row">

                              <FormSelect colWidth={3} name="parcelamento" label="Intervalo">

                                <option value="meses">Meses</option>
                                <option value="dias">Dias</option>
                                <option value="Periodos Parcelento">Periodos Parcelamento</option>

                              </FormSelect>
                              <FormInput colWidth={3} name="Taxa_De_Juros_Per" label="Taxa de Juros (%)" numero={true} />
                              <FormInput colWidth={3} name="Numero_De_Parcelas" label="Número de Parcelas" numero={true} />

                              <div className="col-md-1">
                                <div className='form-group form-material'>
                                  <div style={{ position: 'relative' }}>
                                    <Field type="checkbox" name="plnContas" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                                    <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Negativar ?</label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleButtonClick(values.Numero_De_Parcelas, values.Valor, values.PlanoConta)}
                                >
                                  Adicionar Parcela
                                </button>
                              </div>

                              {showTable && (
                                <table>
                                  <thead>
                                    <tr>
                                      <th style={{ width: "35%" }}>Descrição</th>
                                      <th style={{ width: "20%" }}>Valor</th>
                                      <th style={{ width: "15%" }}>Plano de Conta</th>
                                      <th style={{ width: "15%" }}>Vencimento</th>
                                      <th style={{ width: "15%" }}>Competencia</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.from({ length: parcelasValue }, (_, index) => {
                                      const newData = addMonths(currentDate, index);
                                      return (
                                        <tr key={index}>
                                          <td>Despesa referente ao plano de conta {values.PlanoConta} parcela {index + 1} de {parcelasValue}</td>
                                          <td>{(valorValue / parcelasValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                          <td>{values.PlanoConta}</td>
                                          <td>{format(newData, 'dd/MM/yyyy')}</td>
                                          <td>Exemplo</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>

                                </table>
                              )}

                            </div>
                          }
                          {field.value === 'recorrenciaAuto' &&
                            <div className="form-group form-material row">

                              <FormSelect colWidth={3} name="tipoOperacao" label="Intervalo">

                                <option value="meses">Meses</option>
                                <option value="dias">Dias</option>
                                <option value="Periodos Parcelento">Periodos Parcelamento</option>

                              </FormSelect>
                              <FormInput colWidth={3} name="tst64" label="Taxa de Juros (%)" numero={true} />
                              <FormInput colWidth={3} name="planoconta6123" label="Número de Parcelas" numero={true} />

                              <div className="col-md-1">
                                <div className='form-group form-material'>
                                  <div style={{ position: 'relative' }}>
                                    <Field type="checkbox" name="plnContas" id="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(45%)' }} />
                                    <label className='floating-label' htmlFor="plnContas" style={{ position: 'absolute', top: '-15%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}>Negativar ?</label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <button type="button" className="btn btn-primary">Adicionar Parcela</button>
                              </div>

                            </div>
                          }
                        </>
                      )}
                    </Field>
                  </FormPanel>


                  <br></br>

                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar a Categoria</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar a Categoria"}
                          </button>
                          <Link to="/Categoria_Contas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <ModalPesquisarRep setFieldValue={setFieldValue} onIdRepChange={(value) => { setID_Cliente(value) }} />
                <ModalPesquisarPlano setFieldValue={setFieldValue} onIdRepChange={(value) => { setID_Cliente(value) }} onIdCatChange={(value) => { setID_Cliente(value) }} />


              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateLancamentos