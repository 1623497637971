import React, { useMemo, useState, useEffect } from "react";
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { api_data } from '../../../services/api';


import "../../../Styles/DatatableStyle/tableStyle.css"

async function fetcher(url) {
    const res = await fetch(url);
    return res.json();
}

function ModalTableDestino(props) {
    const [data, setData] = useState([]);
    const [filterInput, setFilterInput] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const result = await api_data.get('/api/Bancos/GetBancosInfo')

            setData(result.data);
        }
        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'iD_Banco',
                width: '2%',
            },
            {
                Header: 'Banco',
                accessor: 'banco',
                width: '50%',

            },
            {
                Header: 'Saldo',
                accessor: 'saldo',
                width: '50%',
                Cell: ({ value }) =>
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),

            },


            {
                Header: 'Ação',
                accessor: 'actionID',
                Cell: ({ row }) => (
                    <span
                        data-bs-dismiss="modal"
                        class="btn btn-secondary"
                        onClick={() => {
                            props.onIdRepChange(row.original.iD_Banco);
                        }}
                    >
                        Selecione
                    </span>

                ),
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, pageSize },
        pageCount,
        gotoPage,
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const pageOptions = [];
    for (let i = Math.max(0, pageIndex - 2); i <= Math.min(pageCount - 1, pageIndex + 2); i++) {
        pageOptions.push(i);
    }


    return (
        <div className="MainDiv">
            <div className="container p-5">
                <div className="d-flex justify-content-end mb-3">
                    <input
                        value={filterInput}
                        onChange={(e) => {
                            setFilterInput(e.target.value || undefined);
                            setGlobalFilter(e.target.value || undefined);
                        }}
                        placeholder="Procurar . . ."
                        className="form-control"
                        style={{ width: "200px" }}
                    />
                </div>

                <table {...getTableProps()} className="table table-hover dataTable table-striped w-full dtr-inline">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className='thTable'>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <div className="bottomTable">
                    <div>
                        Mostrando de {(pageIndex * pageSize) + 1} até {(pageIndex * pageSize) + page.length} de {data.length} registros
                    </div>
                    <div className="pagination-div">
                        <span className="btnPagination" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </span>
                        <span className="btnPagination" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </span>
                        {pageOptions.map(pageOption => (
                            <span
                                key={pageOption}
                                className={pageIndex === pageOption ? 'currentPage' : 'btnPage'}
                                onClick={() => gotoPage(pageOption)}
                            >
                                {pageOption + 1}
                            </span>
                        ))}
                        <span className="btnPagination" onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </span>
                        <span className="btnPagination" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalTableDestino