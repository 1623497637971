import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { estados } from './ObjectsPage/Estados';
import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import InputMask from 'react-input-mask';
import { api_data } from '../../services/api';
import cnaeData from './CNAE.json';
import './list.css';

function CreateEmpresa() {
  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const initialValues = {};
  const [cnpj, setCnpj] = useState('');
  const [cep, setCep] = useState('');

  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const onSubmit = (values, { setSubmitting }) => {
    console.log("ola")
    api_data
      .post("/api/Empresa/AddEmpresa", values)
      .then((response) => {
        console.log(response);
        navigate("/Empresas")
        setShowErrorMessage(false);

      })
      .catch((error) => {
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [tributos, setTributos] = useState([]);
  const [suggestionsTributo, setSuggestionsTributo] = useState([]);

  useEffect(() => {
    api_data.get("/api/GrupoAll/Grupos_Tributario")
      .then((response) => {
        setTributos(response.data);
        setSuggestionsTributo([]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

const handleInputChangeTribute = (event) => {
    const value = event.target.value;
    if (!value) {
      setSuggestionsTributo([]);
      return;
    }
    const filteredSuggestions = tributos.filter(item =>
      item.nome_Grupo.toLowerCase().includes(value.toLowerCase()) 
    );
    setSuggestionsTributo(filteredSuggestions);
  };

// JSX part remains the same

  const handleBlurTribute = () => {
    setTimeout(() => setSuggestions([]), 100);
  };

  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (!value) {
      setSuggestions([]);
      return;
    }
    const filteredSuggestions = cnaeData.filter(item =>
      item.desc.toLowerCase().includes(value.toLowerCase()) ||
      item.cod.toString().includes(value)
    );
    setSuggestions(filteredSuggestions);
  };
  const handleBlur = () => {
    setTimeout(() => setSuggestions([]), 100);
  };


  const fetchClienteData = async (cnpj, setFieldValue) => {
    console.log(cnpj);
    const cleanCNPJ = cnpj.replace(/[./-]/g, '');
    console.log(cleanCNPJ);

    try {
      const response = await api_data.get(`/api/Cadastrais/GetClientData/${cleanCNPJ}`);
      const data = await response.data;
      if (data.status === 'OK') {
        console.log("sucesso");
        setFieldValue('razao_Social', data.nome);
        setFieldValue('nome_Fantasia', data.fantasia);

        setFieldValue('Endereco', data.logradouro);
        setFieldValue('Numero', data.numero);

        setFieldValue('Complemento', data.complemento);
        setFieldValue('Bairro', data.bairro);
        setFieldValue('Municipio', data.municipio);
        setFieldValue('Email', data.email);
        setFieldValue('telefone_1', data.telefone);

        setFieldValue('CEP', data.cep);
        console.log(cep)

        setFieldValue('UF', data.uf);


      } else {
        console.error('Erro ao buscar dados do cliente:', data.message);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do cliente:', error);
    }
  };

  const handleSearchClick = (setFieldValue) => {
    fetchClienteData(cnpj, setFieldValue);
  };


  const fetchCepData = async (cep, setFieldValue) => {
    const cleanCep = cep.replace(/[.-]/g, '');
    console.log(cleanCep);

    try {
      const response = await api_data.get(`/api/Empresa/GetCEP/${cleanCep}`);
      const data = await response.data;
      if (data.erro !== true) {
        console.log("sucesso");
        setFieldValue('Endereco', data.logradouro);
        setFieldValue('Complemento', data.complemento);
        setFieldValue('Bairro', data.bairro);
        setFieldValue('Municipio', data.localidade);
        setFieldValue('CEP', data.cep);
        setFieldValue('UF', data.uf);
        setFieldValue('Codigo_Municipio', data.ibge);
      } else {
        console.error('Erro ao buscar dados do CEP:', data.message);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do CEP:', error);
    }
  };

  const handleSearchCepClick = (setFieldValue) => {
    fetchCepData(cep, setFieldValue);
  };





  return (
    <div>
      <page>
        <div className="page-header">
          <h1 className="page-title">Empresas</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Empresa</li>
          </ol>
          <div className="page-header-actions">
            <Link to="/Empresas">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Empresas</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, handleChange, setFieldValue }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                  {/* PARTECNPJ */}
                  <div className='panel'>



                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">


                        <div className="form-group col-md-3" data-plugin="formMaterial">

                          <Field name="cnpj">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                className="form-control"
                                type="text"
                                id="cnpj"
                                mask="99.999.999/9999-99"
                                onChange={(e) => {
                                  handleChange(e);
                                  setCnpj(e.target.value);
                                }}
                              />
                            )}
                          </Field>
                          <label className="floating-label">CNPJ</label>
                        </div>

                        <span className="input-group-append">
                          <a
                            className="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                            onClick={() => handleSearchClick(setFieldValue)}
                          >
                            <i className="icon md-search" aria-hidden="true"></i>
                          </a>


                        </span>
                     
                        
                        {/* <div className="form-group col-md-3" data-plugin="formMaterial">
                          <div className="form-material">
                            <Field name="CNAc" className="form-control" as="input" onChange={(e) => {
                              setFieldValue("CNAc", e.target.value);
                              handleInputChange(e);
                            }} />
                              <label className="floating-label">CNAE</label>
                            {suggestions.length > 0 && (
                              <ul className="suggestions-list">
                              {suggestions.map(suggestion => (
                                <li key={suggestion.cod} onClick={() => {
                                  setFieldValue("CNAc", `${suggestion.cod} ${suggestion.desc}`);
                                  setSuggestions([]);
                                }}>
                                 {suggestion.cod}  {suggestion.desc}
                                </li>
                              ))}
                            </ul>
                            )}
                          </div>
                        </div> */}

                        <div className="form-group col-md-4" data-plugin="formMaterial">
                          <Field name="nome_Fantasia">
                            {({ field }) => (
                              <input type="text" className="form-control" {...field} />
                            )}
                          </Field>
                          <label className='floating-label'>Nome Fantasia</label>
                          <span className="text-danger"></span>

                        </div>
                        <div className="form-group col-md-4" data-plugin="formMaterial">
                          <Field name="razao_Social">
                            {({ field }) => (
                              <input type="text" className="form-control" {...field} />
                            )}
                          </Field>
                          <label className='floating-label'>Razao Social</label>
                          <span className="text-danger"></span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <FormPanel id="exampleHeadingDefaultTwo"
                    title="Informações de Endereço:" defaultOpen={true}>
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <FormInput colWidth={5} name="Endereco" label="Endereco" />
                        <FormInput colWidth={1} name="Numero" label="Numero" />
                        <FormInput colWidth={3} name="Complemento" label="Complemento" />
                        <FormInput colWidth={3} name="Bairro" label="Bairro" />
                      </div>
                      <div className="form-group form-material row">
                        <div className="form-group col-md-3" data-plugin="formMaterial">
                          <div className="input-group">

                            <div className="form-control-wrap">
                              <Field name="CEP">
                                {({ field }) => (
                                  <InputMask
                                    {...field}
                                    className="form-control"
                                    type="text"
                                    id="CEP"
                                    mask="99999-999"
                                    onChange={(e) => {
                                      handleChange(e);
                                      setCep(e.target.value);
                                    }}
                                  />
                                )}
                              </Field>
                              <label className='floating-label'>CEP</label>
                            </div>
                            <span class="input-group-append">
                              <span class="btn btn-icon btn-default"
                                onClick={() => handleSearchCepClick(setFieldValue)}>
                                <i class="icon md-pin" aria-hidden="true"></i>

                              </span>
                            </span>
                          </div>
                        </div>
                        <Field name="UF">
                          {({ field }) => (
                            <div className="form-group col-md-1" data-plugin="formMaterial">
                              <select className='form-control uf' {...field}>
                                {estados.map((estado) => (
                                  <option key={estado.value} value={estado.value}>{estado.label}</option>
                                ))}
                              </select>
                              <label className='floating-label'>UF</label>
                            </div>
                          )}
                        </Field>
                        <FormInput colWidth={1} name="UF" label="Codigo UF" />
                        <FormInput colWidth={5} name="Municipio" label="Municipio" />
                        <FormInput colWidth={2} name="Codigo_Municipio" label="Codigo Munipio" />
                      </div>
                    </div>
                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultThree"
                    title="Informações de Email:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={4} name="Email" label="Email" />
                      <FormInput colWidth={1} name="Porta" label="Porta" />
                      <FormInput colWidth={2} name="SMTP" label="SMTP" />
                      <FormInput colWidth={3} name="Login" label="Login" />
                      <FormInput colWidth={2} name="Senha" label="Senha" />
                    </div>

                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultFive"
                    title="Informações de Contato:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={2} name="telefone_1" label="telefone 1" />
                      <FormInput colWidth={4} name="contato_1" label="Contato 1" />
                      <FormInput colWidth={2} name="telefone_2" label="Telefone 2" />
                      <FormInput colWidth={4} name="contato_2" label="Contato 2" />
                    </div>

                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultFour"
                    title="Informações de Contato:" defaultOpen={true}>
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <FormInput colWidth={2} name="Inscricao_Estadual" label="Inscri. Estadual" />
                        <FormInput colWidth={2} name="Inscricao_Municipal" label="Inscri. Municipal" />
                        <div className="form-group col-md-3" data-plugin="formMaterial">
                          <div className="form-material">
                            <Field name="Regime_Tributario" className="form-control" as="input"
                              onChange={(e) => {
                                setFieldValue("Regime_Tributario", e.target.value);
                                handleInputChangeTribute(e);
                              }}
                              onBlur={handleBlur}
                            />
                            <label className="floating-label">Regime Tributario</label>
                            {suggestionsTributo.length > 0 && (
                              <ul className="suggestions-list">
                                {suggestionsTributo.map(suggestion => (
                                  <li key={suggestion.cod} onClick={() => {
                                    setFieldValue("Regime_Tributario", `${suggestion.nome_Grupo} `);
                                    setSuggestionsTributo([]);
                                  }}>
                                    {suggestion.nome_Grupo} 
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>


                        <div className="form-group col-md-3" data-plugin="formMaterial">
                          <div className="form-material">
                            <Field name="CNAE" className="form-control" as="input"
                              onChange={(e) => {
                                setFieldValue("CNAE", e.target.value);
                                handleInputChange(e);
                              }}
                              onBlur={handleBlur}
                            />
                            <label className="floating-label">CNAE</label>
                            {suggestions.length > 0 && (
                              <ul className="suggestions-list">
                                {suggestions.map(suggestion => (
                                  <li key={suggestion.cod} onClick={() => {
                                    setFieldValue("CNAE", `${suggestion.cod} ${suggestion.desc}`);
                                    setSuggestions([]);
                                  }}>
                                    {suggestion.cod}  {suggestion.desc}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>

                      </div>
                      <div className="form-group form-material row">
                        <FormInput colWidth={4} name="Perfil_Fiscal" label="Perfil Fiscal" />
                        {/* <FormInput colWidth={4} name="Tipo_Atividade" label="Tipo Atividade" /> */}
                        <FormInput colWidth={1} name="Aliquota_Imposto" label="Aliq. Imposto" />
                        <FormInput colWidth={1} name="PIS" label="PIS" />
                        <FormInput colWidth={1} name="COFINS" label="Cofins" />
                        <FormInput colWidth={1} name="Apuracao_IPI" label="IPI" />
                      </div>
                    </div>
                  </FormPanel>

                  <br></br>






                  <div className="panel">
                    <div className="row">
                      <div className="col-md-6">
                        <FormPanel id="informacaoVendas" title="Informacao Venda:" defaultOpen={true}>
                          <div className="accordion-body container-fluid">
                            <div className="form-group form-material row">
                              <div className="form-group col-md-12">
                                <input data-plugin="dropify" data-default-file="" />
                              </div>
                            </div>
                          </div>
                        </FormPanel>
                      </div>
                      <div className="col-md-6">
                        <FormPanel id="Observacoes" title="Observações:" defaultOpen={true}>
                          <textarea rows="5" cols="60"></textarea>
                        </FormPanel>
                      </div>
                    </div>
                  </div>



                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar a Empresa</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar a Empresa"}
                          </button>
                          <Link to="/Empresas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateEmpresa