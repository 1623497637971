import React, { useState } from 'react';

import ModalTableRep from './ModalTableRep';

function ModalPesquisarRep({ onEmpresaIdChange, setFieldValue, setRepCreated }) {
  const [isCreating, setIsCreating] = useState(false);
  const [empresaCreated, setEmpresaCreated] = useState(false);

  
  const handleEmpresaIdChange = (id) => {
    console.log(`Empresa ID selecionado: ${id}`);
  };
  
  const handleEmpresaCreated = () => {
    setEmpresaCreated(!empresaCreated);
  };

  return (
    <div>
      <div className="modal fade" id="ModalRepPesquisar" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-warning modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLongTitle">Pesquisar o Cliente/Fornecedor</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" >&times;</span>
              </button>
            </div>
            <div className="modal-body">
              
            <ModalTableRep onIdRepChange={(value) => setFieldValue('cliente_Fornecedor', value)} setFieldValue={setFieldValue} />

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPesquisarRep;
