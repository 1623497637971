import React from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';

const FormInputLess = ({ colWidth, name, label, value, mask, numero, onChange }) => {
    return (
      <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
        <input
          className='form-control'
          onChange={onChange}
          style={numero ? {appearance: 'textfield'} : {}}
        />
        <label className='floating-label'>{label}</label>
      </div>
    );
  };
  


export default FormInputLess;
