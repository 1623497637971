import React from 'react';
import { Field } from 'formik';


function FormSelect({ colWidth, name, label, children, onChange }) {
  return (
    <div className={`form-group col-md-${colWidth}`}>
      <Field name={name}>
        {({ field, meta }) => (
          <>
            <select
              {...field}
              className={`form-control${meta.touched && meta.error ? ' is-invalid' : ''}`}
              onChange={e => {
                field.onChange(e);
                console.log("Selected value:", e.target.value);
                if (onChange) {
                  onChange(e);
                }
              }}
            >
              {children}
            </select>
            <label className='floating-label'>{label}</label>
            {meta.touched && meta.error && (
              <div className='invalid-feedback'>{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
}

  
  export default FormSelect;