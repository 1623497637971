import React, { useState, useEffect } from 'react';
import { api_data } from '../../services/api';
import { useNavigate } from "react-router-dom";

function ModalDelete(props) {
    const navigate = useNavigate();
    const [categoria, setCategoria] = useState({});



    useEffect(() => {
        async function fetchEmpresa() {
            setCategoria({});        
            const response = await api_data.get(`/api/Controle_Cheque/GetCheque/${props.selectId}`)
            const categoriaData = await response.data;
            setCategoria(categoriaData);
        }
        fetchEmpresa();
    }, [props.selectId]);

    const limparEmpresa = () => {
        setCategoria({});
        console.log(categoria)
        console.log('cliquei')

    };

    const closeModal = () => {
        const modal = document.querySelector('#exampleModalCenterDelete');
        const modalBackdrop = document.querySelector('.modal-backdrop');

        modal.classList.remove('show');
        modal.setAttribute('aria-modal', 'false');
        modal.setAttribute('style', 'display: none');
        document.body.classList.remove('modal-open');

        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
    };

    const handleDelete = () => {     
        api_data.delete(`/api/Controle_Cheque/DeleteCentroCusto/${props.selectId}`)
        .then(response => {
            if (response.status === 200) {
                limparEmpresa();
                closeModal();
                

                navigate(0);
            } else {
                console.log('Erro ao excluir o Cheque');
            }
        })
        .catch(error => console.log(error));
    };


    return (
        <div><div>
            <div className="modal fade" id="exampleModalCenterDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDeleteTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-danger modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLongTitle">Deletar o Cheque</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='panel-body container-fluid'>
                                <div className='form-material row'>
                                    <div className='form-group col-md-1' data-plugin="formMaterial">
                                        <input key={categoria.iD_Cheque} className='form-control' readOnly value={categoria.iD_Cheque} />
                                        <label className='floating-label'>ID</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.empresa} className='form-control' value={categoria.empresa} />
                                        <label className='floating-label'>Empresa</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.clienteFornecedor} className='form-control' value={categoria.clienteFornecedor} />
                                        <label className='floating-label'>Cliente/Fornecedor</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.valor} className='form-control' value={categoria.valor} />
                                        <label className='floating-label'>Valor</label>
                                    </div>
                                </div>
                                <br></br>
                                <div className='form-material row'>
                                    
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.banco} className='form-control' value={categoria.banco} />
                                        <label className='floating-label'>Banco</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.agencia} className='form-control' value={categoria.agencia} />
                                        <label className='floating-label'>Agência</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.conta} className='form-control' value={categoria.conta} />
                                        <label className='floating-label'>Conta</label>
                                    </div>
                                </div>
                                <br></br>
                                <div className='form-material row'>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.num_cheq} className='form-control' value={categoria.num_Cheq} />
                                        <label className='floating-label'>Nº Cheque</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.dig_agenc} className='form-control' value={categoria.dig_Agenc} />
                                        <label className='floating-label'>Dígito Agência</label>
                                    </div>
                                    <div className='form-group col-md-3' data-plugin="formMaterial">
                                        <input key={categoria.dig_conta} className='form-control' value={categoria.dig_Conta} />
                                        <label className='floating-label'>Dígito Conta</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            
                            <button type="button" className="btn btn-primary" onClick={handleDelete}>Excluir</button>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ModalDelete