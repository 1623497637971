import React from 'react';

import { Field } from 'formik';

const FormInModal = ({ colWidth, label, value, name, real }) => {
    const colClassName = `form-group col-md-${colWidth} `;
    const formattedValue = value && real ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : value;

    return (
        <div className={colClassName}>
            {name ? 
                <Field name={name} className="form-control" data-plugin="formatter" />
                :
                <input value={formattedValue || ''} className="form-control" readOnly data-plugin="formatter" />
            }
            <label className="floating-label">{label}</label>
        </div>
    );
};




export default FormInModal;
