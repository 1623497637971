import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState, useEffect } from 'react';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';
import FormInReal from '../../GlobalComponents/FormInReal';
import FormDate from '../../GlobalComponents/FormDate';

import ModalPesquisarRep from "./ModalDestino/ModalPesquisarRep";
import ModalPesquisarDestino from './ModalOrigem/ModalPesquisarDestino';
import {  toast } from 'react-toastify';


function CreateTransferencia() {
  const [representantes, setRepresentantes] = useState([]);


  const navigate = useNavigate();

  const [created, setCreated] = React.useState(false);
  const initialValues = {};
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dataVencToggle, setDataVencToggle] = useState(false);
  const [iD_BancoAtual, setID_BancoAtual] = useState(null);
  const [iD_BancoDestino, setID_BancoDestino] = useState(null);




  const notify = () => {
    toast.success("Criado com Sucesso", { autoClose: 3000 });
  };
  
  const notifyError = () => {
    toast.error("Falha na criação, tente novamente", { autoClose: 3000 });
  };

  const onSubmit = (values, { setSubmitting }) => {
    values.con_data_venc = dataVencToggle;
    console.log(values.totalBancoTransferido)
    if (values.totalBancoTransferido) {
      values.totalBancoTransferido = parseFloat(
        values.totalBancoTransferido
          .toString()
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", ".")
      );
    } else {
      values.totalBancoTransferido = 0;
    }
    console.log(values.totalBancoTransferido)

    api_data
      .post("/api/Bancos/TransferenciaBancos", values)
      .then((response) => {
        notify()
        console.log(response);
        setShowErrorMessage(false);
      })
      .catch((error) => {
        notifyError()
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await api_data.get('api/Bancos/GetBancosInfo');
      setRepresentantes(response.data);
    };

    fetchData();
  }, []);

  const [file, setFile] = useState(null);




  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Transferencia Bancaria</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Transferencia Bancaria</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/TransferenciaBancaria">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Transferencia Bancaria</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Centro de Custos:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormSelect colWidth={2} name="iD_BancoAtual" label="Banco Origem">
                        {representantes.map((representante) => (
                          <option key={representante.iD_Banco} value={representante.iD_Banco}>
                            {representante.banco}
                          </option>
                        ))}
                      </FormSelect>
                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalRepPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>


                      <FormSelect colWidth={2} name="iD_BancoTransferido" label="Banco Destino">
                        {representantes.map((representante) => (
                          <option key={representante.iD_Banco} value={representante.iD_Banco}>
                            {representante.banco}
                          </option>
                        ))}
                      </FormSelect>
                      <div class="col-md-1">
                        <span class="input-group-append">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#ModalDestinoPesquisar"
                            class="btn btn-icon btn-default"
                            style={{ height: '40px', display: 'inline-block' }}
                          >
                            <i class="icon md-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>
                      <FormInput colWidth={2} name="documento" label="Documento" />
                      <FormInReal colWidth={2} name="totalBancoTransferido" label="Valor R$ " />
                      <FormDate colWidth={2} name="dataTransferencia" label="Data Transferência" />



                    </div>
                    <div className="form-group form-material row">
                    <div className='form-group col-12 col-md-6'>
                        <Field name="observacoes" as="textarea" className='form-control'></Field>
                        <label className='floating-label'>Observações</label>
                      </div>
                    </div>

                  </FormPanel>









                  <br></br>




                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar a Categoria</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar a Categoria"}
                          </button>
                          <Link to="/Categoria_Contas">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <ModalPesquisarRep setFieldValue={setFieldValue} onIdRepChange={(value) => { setID_BancoAtual(value) }} />
                <ModalPesquisarDestino setFieldValue={setFieldValue} onIdRepChange={(value) => { setID_BancoDestino(value) }} />

              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateTransferencia