import React, { useState } from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';

const FormPassword = ({ colWidth, name, label, value, mask }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
      <div style={{ position: 'relative' }}>
        <Field name={name}>
          {({ field }) => (
            mask
              ? <InputMask {...field} mask={mask} className='form-control' type={showPassword ? 'text' : 'password'} value={value || field.value} />
              : <input className='form-control' {...field} type={showPassword ? 'text' : 'password'} value={value || field.value}></input>
          )}
        </Field>
        <label className='floating-label'>{label}</label>
        <button
          type="button"
          style={{
            position: 'absolute',
            right: '10px',
            top: '1px',
            background: 'none',
            border: 'none',
            outline: 'none',
            fontSize: '17px'
          }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <i class="fa fa-eye-slash" aria-hidden="true"/> : <i class="fa fa-eye" aria-hidden="true"/>}
        </button>
      </div>
    </div>
  );
};

export default FormPassword;
