import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { NumericFormat as NumberFormat } from 'react-number-format';
import { api_data } from '../../services/api';
import { useNavigate } from 'react-router-dom';

function ModalTransferencia(props) {
  
  const navigate = useNavigate();

  const [bancos, setBancos] = useState([]);
  const [idBancoTransferido, setIdBancoTransferido] = useState(null);
  const [totalTransferencia, setTotalTransferencia] = useState(0);
  const [selectId, setSelectId] = useState(null);
  const [banco, setBanco] = useState('');
  const [saldo, setSaldo] = useState('');

  useEffect(() => {
    setSelectId(props.selectId);
  }, [props.selectId]);

  useEffect(() => {
    async function fetchBanco() {
      try {
        const response = await api_data.get(`/api/Bancos/GetBanco/${selectId}`);
        setBanco(response.data.banco);
        setSaldo(response.data.saldo);
      } catch (error) {
        console.error('Erro ao buscar dados do banco:', error);
      }
    }

    if (selectId) {
      fetchBanco();
    }
  }, [selectId]);


  useEffect(() => {
    console.log(props.selectId);
    const fetchBancos = async () => {
      try {
        const response = await api_data.get('/api/Bancos/GetBancosInfo');
        const data = response.data;
        setBancos(data);
        setIdBancoTransferido(data[0]?.iD_Banco || null);
      } catch (error) {
        console.error('Erro ao buscar os bancos:', error);
      }
    };

    fetchBancos();
  }, []);


  const handleTransferencia = async () => {
    if (idBancoTransferido === selectId) {
      alert('Não é possível transferir para o mesmo banco');
      return;
    }

    const valorTransferencia = parseFloat(totalTransferencia);

    if (valorTransferencia > parseFloat(saldo)) {
      alert('O valor da transferência é maior que o saldo do banco');
      return;
    }

    try {
      console.log('idBancoTransferido:', idBancoTransferido);
      console.log('totalTransferencia:', totalTransferencia);

      const payload = {
        ID_BancoAtual: props.selectId,
        ID_BancoTransferido: idBancoTransferido,
        TotalBancoTransferido: valorTransferencia,
      };
      console.log('payload:', payload);

      const response = await api_data.post('/api/Bancos/TransferenciaBancos', payload);

      if (response.status === 200) {
        console.log('Transferência realizada com sucesso');

      

        navigate(0);
      } else {
        console.error('Erro ao realizar a transferencia', response);
        const errorData = response.data;
        console.error('Detalhes do erro ', errorData);
      }
    } catch (error) {
      console.error('Erro ao realizar a transferencia:', error);
    }
  };







  return (
    <div className="modal fade"
    id="modalTransferencia"
    tabIndex="-1" role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg modal-primary modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLabel">Transferência</h2>
          <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div className="modal-body">
            {/* <div>{props.selectId}</div> */}

            <div className="panel-body container-fluid">
              <div className="form-group form-material row">
                <div className="form-group col-12 col-md-8" data-plugin="formMaterial">

                  <div className="input-group">
                    <div className="form-control-wrap pointclick">
                      <input id="Banco" className="form-control" readOnly value={banco} />

                      <label htmlFor="Banco" className="floating-label"></label>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-4 pointclick" data-plugin="formMaterial">
                  {/* <input id="saldoValue" className="form-control" readOnly value={saldo} /> */}
                  <NumberFormat
                    value={saldo}
                    className="form-control"
                    readOnly
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                  />

                  <label htmlFor="saldoValue" className="floating-label"></label>
                </div>
              </div>
              <div className="form-material row">
                <div className="form-group col-md-8" data-plugin="formMaterial">
                  <label>Escolha o Banco para transferir</label>
                  <select
                    id="dropDown"
                    className="form-control form-select form-control-label-sm mb-2"
                    aria-label="form-select-lg example"
                    name="idBancoDestino"
                    onChange={(e) => {
                      setIdBancoTransferido(parseInt(e.target.value));
                    }}
                  >
                    {bancos.map((banco) => (
                      <option key={banco.iD_Banco} value={banco.iD_Banco}>
                        {banco.banco || 'Banco não informado'}
                      </option>
                    ))}
                  </select>


                </div>
                <div className="form-group col-md-4" data-plugin="formMaterial">
                  <label htmlFor="TotalBancoTransf">Total Transferir</label>
                  <NumberFormat
                    value={totalTransferencia}
                    className="form-control"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    onValueChange={({ formattedValue }) => {
                      const floatValue = parseFloat(
                        formattedValue.replace("R$ ", "").replace(".", "").replace(",", ".")
                      );
                      setTotalTransferencia(floatValue);
                    }}
                  />


                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" onClick={handleTransferencia}>Salvar</button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalTransferencia