import React from 'react';
import { Field } from 'formik';

function FormCheckbox({ colWidth, name, label }) {
    return (
        <div className={`form-group col-md-${colWidth}`} data-plugin="formMaterial">
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Field name={name} type='checkbox' />
            <label className='floating-label' style={{ flex: 1, marginLeft: '50px', overflowWrap: 'break-word', maxWidth: 'calc(100% - 60px)' }}>{label}</label>
          </div>
        </div>
    );
}

export default FormCheckbox;
