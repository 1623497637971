import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState } from 'react';
import Papa from 'papaparse';

import FormInput from '../../GlobalComponents/FormInput';
import FormPanel from '../../GlobalComponents/FormPanel'
import FormSelect from '../../GlobalComponents/FormSelect'

import { api_data } from '../../services/api';
import FormInReal from '../../GlobalComponents/FormInReal';
import FormDate from '../../GlobalComponents/FormDate';

function CreateCheque() {




  const [created, setCreated] = React.useState(false);
  const initialValues = {};
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dataVencToggle, setDataVencToggle] = useState(false);

  const onSubmit = (values, { setSubmitting }) => {
    values.con_data_venc = dataVencToggle;
    console.log(values.valor_limite)
    if (values.valor_limite) {
      values.valor_limite = parseFloat(
        values.valor_limite
          .toString()
          .replace("R$ ", "")
          .replace(/\./g, "")
          .replace(",", ".")
      );
    } else {
      values.valor_limite = 0;
    }
    console.log(values.valor_limite)

    api_data
      .post("/api/Centro_Custos/AddCentroCusto", values)
      .then((response) => {
        console.log(response);
        setShowErrorMessage(false);
      })
      .catch((error) => {
        console.error(error);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };







  return (
    <div>
      <page>
        <div className="page-header">


          <h1 className="page-title">Controle de Cheque</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a>Home</a></li>
            <li className="breadcrumb-item"><a>Lista</a></li>
            <li className="breadcrumb-item active">Controle de Cheque</li>


          </ol>
          <div className="page-header-actions">
            <Link to="/ControleCheque">
              <p>
                <a id="btnlink" className="btn btn-edit btnLink"><i className="fa fa-arrow-circle-left"></i>&nbsp;&nbsp; Voltar para lista de Cheques</a>
              </p>
            </Link>
          </div>
        </div>
        <div className="page-content container-fluid">
          <Formik initialValues={initialValues} onSubmit={onSubmit} >
            {({ isSubmitting }) => (
              <Form>
                <div class="accordion accordion-flush" id="accordionFlushExample">


                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Informações de Cadastro:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInput colWidth={4} name="empresa" label="Empresa" />
                      <FormInput colWidth={4} name="ClienteFornecedor" label="Cliente/Fornecedor " />
                    </div>

                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Dados de Pagamento:" defaultOpen={true}>
                    <div className="form-group form-material row">
                      <FormInReal colWidth={4} name="valor" label="Valor (R$) "></FormInReal>
                      <FormSelect colWidth={4} name="status_cheque" label="Status do Cheque">
                        <option value="A Compensar">A Compensar</option>
                        <option value="Pago">Pago</option>
                        <option value="Devolvido">Devolvido</option>

                      </FormSelect>
                      <FormDate colWidth={4} name="valor" label="Data de Vencimento "></FormDate>
                    </div>

                  </FormPanel>

                  <FormPanel id="exampleHeadingDefaultFive" href="#exampleCollapseDefaultFive" ariaControls="exampleCollapseDefaultFive"
                    title="Dados Financeiros:" defaultOpen={true}>
                    <div className="form-group form-material row">

                      <FormSelect colWidth={4} name="banco" label="Banco">
                        <option value="">Selecione</option>
                        <option value="001">001 - Banco do Brasil</option>
                        <option value="004">004 - Banco do Nordeste</option>
                        <option value="014">014 - State Street Brasil S.A.</option>
                        <option value="033">033 - Santander</option>
                        <option value="041">041 - Banrisul</option>
                        <option value="047">047 - Banco Banese</option>
                        <option value="070">070 - Banco BRB</option>
                        <option value="077">077 - Banco Inter</option>
                        <option value="084">084 - Cooperativa Uniprime Norte do Paraná</option>
                        <option value="085">085 - Cecred / Ailos</option>
                        <option value="097">097 - Credisis</option>
                        <option value="099">099 - Cooperativa Uniprime Central</option>
                        <option value="104">104 - Caixa Econômica Federal</option>
                        <option value="133">133 - Cresol</option>
                        <option value="136">136 - Unicred do Brasil</option>
                        <option value="197">197 - Stone Pagamentos</option>
                        <option value="136237">237 - Unicred Bradesco</option>
                        <option value="237">237 - Bradesco</option>
                        <option value="260">260 - NuBank</option>
                        <option value="274">274 - Grafeno</option>
                        <option value="341">341 - Itaú</option>
                        <option value="362">362 - Cielo Pagamentos</option>
                        <option value="389">389 - Banco Mercantil do Brasil</option>
                        <option value="399">399 - HSBC</option>
                        <option value="422">422 - Banco Safra</option>
                        <option value="637">637 - Banco Sofisa</option>
                        <option value="707">707 - Daycoval</option>
                        <option value="748">748 - Sicredi</option>
                        <option value="756">756 - Sicoob</option>
                        <option value="999">999 - PagHiper</option>
                        <option value="0">0 - Outros</option>
                      </FormSelect>

                      <FormInput colWidth={4} name="agencia" label="Agência" numero={true}></FormInput>

                      <FormInput colWidth={4} name="conta" label="Conta" numero={true}></FormInput>

                      <FormInput colWidth={4} name="num_Cheq" label="Número Cheque" numero={true}></FormInput>
                      <FormInput colWidth={4} name="dig_Agenc" label="Digito Agência" numero={true}></FormInput>
                      <FormInput colWidth={4} name="dig_Conta" label="Digito Conta" numero={true}></FormInput>

                    </div>

                  </FormPanel>








                  <br></br>




                  <div className="panel">
                    <div className="panel-body container-fluid">
                      <div className="form-group form-material row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                          {showErrorMessage && <p style={{ marginRight: '20px', color: 'red', fontSize: '15px', fontWeight: 'bold' }}>Não foi possivel criar o Cheque</p>}
                          <button className='btn-success btn' style={{ marginRight: '10px' }} type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Criando ..." : "Criar o Cheque"}
                          </button>
                          <Link to="/ControleCheque">
                            <a className="btn btn-danger">
                              <i className="icon md-grid" aria-hidden="true"></i>
                              <span style={{ color: "white" }}>Cancelar e não salvar</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </page>

    </div>
  )
}

export default CreateCheque